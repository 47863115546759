import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ProductDataForm } from '../../../app/models/Product';
import useProductService from '../../../app/services/hooks/useProductService';
import { Unit } from '../../../app/models/Unit';
import { PackingForm } from '../../../app/models/PackingForm';
import { Sector } from '../../../app/models/Sector';
import { OriginArea } from '../../../app/models/OriginArea';
import ProductForm from './forms/ProductForm';
import { Currency } from '../../../app/models/Currency';
import { Tax } from '../../../app/models/Tax';
import { ItemCategory } from '../../../app/models/ItemCategory';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProductCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [product, setProduct] = useState<ProductDataForm>({ name: '', description: '' });
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [priceUnits, setPriceUnits] = useState<Unit[]>([]);
    const [units, setunits] = useState<Unit[]>([]);
    const [packingForms, setPackingForms] = useState<PackingForm[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [originAreas, setOriginAreas] = useState<OriginArea[]>([]);
    const [itemCategories, setItemCategories] = useState<ItemCategory[]>([]);
    const [taxes, setTaxes] = useState<Tax[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingCreateProduct, createProduct, fetchingStoreProduct, storeProduct } =
        useProductService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createProduct({
            onSuccess: (response: ServiceResponse) => {
                setCurrencies(response.data.currencies);
                setPriceUnits(response.data.units);
                setunits(response.data.units);
                setPackingForms(response.data.packing_forms);
                setSectors(response.data.sectors);
                setOriginAreas(response.data.origin_areas);
                setItemCategories(response.data.item_categories);
                setTaxes(response.data.taxes);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando producto...');
        storeProduct(product, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateProduct ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ProductForm
                        productDataForm={product}
                        setProductDataForm={setProduct}
                        packingForms={packingForms}
                        sectors={sectors}
                        originAreas={originAreas}
                        itemCategories={itemCategories}
                        currencies={currencies}
                        priceUnits={priceUnits}
                        units={units}
                        taxesData={taxes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreProduct} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreProduct} />
                </div>
            </div>
        </>
    );
};

export default ProductCreate;
