import React from 'react';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;

};

const SocietyClientFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
        
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="name">
                            Razón Social
                        </label>
                        <input
                            title="Razón Social"
                            type="name"
                            id="name"
                            name="name"
                            className="form-control form-control-sm"
                            value={filter.name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
};

export default SocietyClientFilter;
