import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useSocietyProviderService from '../../../app/services/hooks/useSocietyProviderService';
import { SocietyProviderDataForm } from '../../../app/models/SocietyProvider';
import { ProviderContact } from '../../../app/models/ProviderContact';
import { Provider } from '../../../app/models/Provider';
import SocietyProviderForm from './forms/SocietyProviderForm';
import useProviderContactService from '../../../app/services/hooks/useProviderContactService';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';

interface Props {
    societyId: number;
    societyProviderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietyProviderEdit = ({ societyId, societyProviderId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditSocietyProvider, editSocietyProvider, fetchingUpdateSocietyProvider, updateSocietyProvider } =
        useSocietyProviderService();

    const {
        fetchingGetBusinessNameContactsByProvider,
        getBusinessNameContactByProvider
    } = useBusinessNameContactService()

    const [societyProvider, setSocietyProvider] = useState<SocietyProviderDataForm>(
        {
            provider_id: 0,
            account_number: ''
        }
    )
    const [societyProviderFirst, setSocietyProviderFirst] = useState<SocietyProviderDataForm>(
        {
            provider_id: 0,
            account_number: ''
        }
    )
    const providerIdSelected = useRef<number | undefined>(0);

    const [ProviderContacts, setProviderContacts] = useState<ProviderContact[]>([]);
    const [Providers, setProviders] = useState<Provider[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando datos proveedor...');
        editSocietyProvider(societyProviderId, {
            onSuccess: (response: ServiceResponse) => {

                const providers = response.data.providers.map((provider: Provider) => {
                    return {
                      ...provider,
                      account_number: `${societyId}-${provider.business_name_id}`
                    };
                  });
               
                setProviders(providers);
                setProviderContacts(response.data.provider_contacts);
                providerIdSelected.current = response.data.society_provider.provider_id;
                setSocietyProvider(response.data.society_provider);
                setSocietyProviderFirst(response.data.society_provider);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {

        if (societyProvider.provider_id == undefined) {
            providerIdSelected.current = undefined;
        }

        if (societyProvider.provider_id && societyProvider.provider_id != providerIdSelected.current) {

            getBusinessNameContactByProvider(societyProvider.provider_id, {
                    onSuccess: (response: ServiceResponse) => {

                        providerIdSelected.current = societyProvider.provider_id ?? 0;
                        setErrorFields(undefined);

                        setProviderContacts(response.data.provider_contacts);

                        if(societyProvider.provider_id == societyProviderFirst.provider_id){

                            setSocietyProvider(
                                {
                                    ...societyProvider,
                                    business_name_contact_id: societyProviderFirst.business_name_contact_id ?? undefined,
                                    account_number: societyProviderFirst.account_number
                                }
                            )

                        }else{
                            setSocietyProvider(
                                {
                                    ...societyProvider,
                                    business_name_contact_id: undefined
                                }
                            )
                        }
                        
                       
                    },
                    onError: (response: ServiceResponse) => {
                        setSocietyProvider({
                            ...societyProvider,
                            provider_id: providerIdSelected.current
                        });
                    }
                })
                
            }
    }, [societyProvider.provider_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando información de proveedor...');
        updateSocietyProvider(societyProviderId, societyProvider, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditSocietyProvider ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SocietyProviderForm
                        societyProviderForm={societyProvider}
                        setSocietyProviderForm={setSocietyProvider}
                        providers={Providers}
                        providerContacts={ProviderContacts}
                        errorFields={errorFields}
                        loadingProviderContact={fetchingGetBusinessNameContactsByProvider}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateSocietyProvider}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateSocietyProvider} />
                </div>
            </div>
        </>
    );
};

export default SocietyProviderEdit;
