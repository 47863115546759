import React from 'react';
import { CheckingAccountFilters } from '../../../../app/models/CheckingAccount';

type Props = {
    filter: CheckingAccountFilters;
    setFilter: (filter: CheckingAccountFilters) => void;
};

const CheckingAccountFilter = ({ filter, setFilter }: Props) => {

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_first">
                                Fecha de emisión (desde)
                            </label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="issue_date_first"
                                name="issue_date_first"
                                className="form-control form-control-sm"
                                value={filter.issue_date_first}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_last">
                                Fecha de emisión (hasta)
                            </label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="issue_date_last"
                                name="issue_date_last"
                                className="form-control form-control-sm"
                                value={filter.issue_date_last}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    {/* <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="movement_type">
                            Tipo de Movimiento
                        </label>
                        <select
                            id="movement_type"
                            name="movement_type"
                            className="form-control form-select-sm"
                            value={filter.movement_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'CREDIT'} value="CREDIT">
                                Cargo
                            </option>
                            <option key={'DEBIT'} value="DEBIT">
                                Abono
                            </option>
                        </select>
                    </div>
                </div> */}
                </div>
            </div>
        </div>
    );
};

export default CheckingAccountFilter;
