import React, { useContext, useEffect, useState } from 'react'
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { AppContext } from '../../contexts/AppContext';
import useGuideService from '../../app/services/hooks/useGuideService';
import { GuideDataForm, GuideFilters } from '../../app/models/Guide';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import DefaultCard from '../../components/default/DefaultCard';
import GuideDataTable from './components/tables/GuideDataTable';


const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Guías de Despacho',
        url: '/guides',
        isActive: true
    }
];

const Guides = () => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const { fetchingGetGuides, getGuides } = useGuideService();
    const [ guides, setGuides ] = useState<GuideDataForm[]>([]);
    const [ totalRows, setTotalRows ] = useState<number>(0);
    const [ guideFilter, setGuideFilter ] = useState<GuideFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllGuides();
    }, [guideFilter]);
    
    const getAllGuides = () => {
        getGuides(guideFilter, {
            onSuccess: (response: ServiceResponse) => {
                setGuides(response.data.guides);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };
    
    const reloadTable = () => {
        getAllGuides();
    };

    return (
        <>
            <Breadcrumbs pageSection="Guías" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <GuideDataTable
                    guides={guides}
                    loading={fetchingGetGuides}
                    filter={guideFilter}
                    setFilter={setGuideFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>
        
        </>
    )
}

export default Guides
