import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

import useServiceManifestDetailService from '../../../app/services/hooks/useServiceManifestDetailService';
import { ServiceManifestDetailDataForm } from '../../../app/models/ServiceManifestDetail';
import ServiceManifestDetailFormShow from './forms/ServiceManifestDetailFormShow';

interface Props {
    serviceManifestDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestDetailShow = ({
    serviceManifestDetailId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingShowServiceManifestDetail, showServiceManifestDetail } =
        useServiceManifestDetailService();

    const [serviceManifestDetail, setserviceManifestDetail] =
        useState<ServiceManifestDetailDataForm>({});

    const [itemType, setItemType] = useState<string>('');

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        show();
    }, []);

    const show = () => {
        if (showLoading) showLoading('loading', 'Cargando posición de hoja de entrada...');
        showServiceManifestDetail(serviceManifestDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setItemType(response.data.service_manifest_detail.item_type);
                setserviceManifestDetail(response.data.service_manifest_detail);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingShowServiceManifestDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestDetailFormShow
                        serviceManifestDetailDataForm={serviceManifestDetail}
                        setserviceManifestDetailDataForm={setserviceManifestDetail}
                        itemType={itemType}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={cancel} title="Cerrar" />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDetailShow;
