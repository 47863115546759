import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { BankAccountType } from '../../../app/models/BankAccountType';
import BankAccountTypeForm from './forms/BankAccountTypeForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    bankAccountType: BankAccountType;
    setBankAccountType: (bankAccountType: BankAccountType) => void;
    errorFields?: any;
}

const BankAccountTypeFormContainer = ({
    fetching,
    action,
    cancel,
    bankAccountType,
    setBankAccountType,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BankAccountTypeForm
                        bankAccountTypeDataForm={bankAccountType}
                        setBankAccountTypeDataForm={setBankAccountType}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default BankAccountTypeFormContainer;
