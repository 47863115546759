import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { PurchaseOrderDetailDataForm } from '../../../app/models/PurchaseOrderDetail';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import PurchaseOrderDetailFormShow from './forms/PurchaseOrderDetailFormShow';


interface Props {
    purchaseOrderDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PurchaseOrderDetailShow = ({ purchaseOrderDetailId, onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingShowPurchaseOrderDetail,
        showPurchaseOrderDetail,
    } = usePurchaseOrderDetailService();

 
    const [purchaseOrderDetail, setPurchaseOrderDetail] = useState<PurchaseOrderDetailDataForm>({});

    const [itemType, setItemType] = useState<string>('');

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        show();
    }, []);

    

    const show = () => {
        
        if (showLoading) showLoading('loading', 'Cargando posición de orden de compra...');
        showPurchaseOrderDetail(purchaseOrderDetailId, {
            onSuccess: (response: ServiceResponse) => {

                setItemType(response.data.purchase_order_detail.item_type);
                setPurchaseOrderDetail(response.data.purchase_order_detail);
                
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };


    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingShowPurchaseOrderDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <PurchaseOrderDetailFormShow
                       purchaseOrderDetailDataForm={purchaseOrderDetail}
                       setPurchaseOrderDetailDataForm={setPurchaseOrderDetail}
                       itemType={itemType}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={cancel} title='Cerrar'/>
                </div>
            </div>
        </>
    );
};

export default PurchaseOrderDetailShow;
