import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Batch } from '../../../app/models/Batch';
import { ServiceManifestDetailDataForm } from '../../../app/models/ServiceManifestDetail';
import { AccountingStatuses, Items } from '../../../app/shared/enums';

import { Accounting, AccountingDataForm, AccountingFilters } from '../../../app/models/Accounting';
import { Account } from '../../../app/models/Account';
import AccountingDataTable from './tables/AccountingDataTable';
import AccountingForm from './forms/AccountingForm';
import useAccountingService from '../../../app/services/hooks/useAccountingService';

interface Props {
    serviceManifestDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    itemType: string;
    setItemType: (itemType: string) => void;
    isShow: boolean;
    setPosition: (num: number) => void;
}

const ServiceManifestDetailToAccount = ({
    serviceManifestDetailId,
    onSaved,
    onCancel,
    onError,
    itemType,
    setItemType,
    isShow,
    setPosition
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetAccountingByServiceManifest,
        getAccountingByServiceManifestDetail,
        fetchingStoreAccounting,
        storeAccounting,
        fetchingDeleteAccounting,
        deleteAccounting
    } = useAccountingService();

    const [serviceManifestDetail, setServiceManifestDetail] =
        useState<ServiceManifestDetailDataForm>({});

    const [accountings, setAccountings] = useState<Accounting[]>([]);
    const [accounting, setAccounting] = useState<AccountingDataForm>({
        service_manifest_detail_id: serviceManifestDetailId
    });
    const [totalRows, setTotalRows] = useState<number>(0);
    [];
    const [batches, setBatches] = useState<Batch[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);

    const [errorFields, setErrorFields] = useState<any>();
    const [filter, setFilter] = useState<AccountingFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getToAccount();
    }, [filter]);

    const getToAccount = () => {
        getAccountingByServiceManifestDetail(filter, serviceManifestDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setItemType(response.data.item_type);
                setPosition(response.data.position);
                setAccounting({
                    ...accounting,
                    item_type: response.data.item_type
                });

                setAccounts(response.data.accounts);
                setServiceManifestDetail(response.data.service_manifest_detail);
                if (response.data.accounting) {
                    const accounts = response.data.accounting.accounts.map((account: any) => {
                        return {
                            value: account.id,
                            label: account.name
                        };
                    });

                    setAccounting({
                        ...accounting,
                        issue_date: response.data.accounting.issue_date,
                        file:
                            response.data.item_type == Items.SERVICE
                                ? response.data.accounting.service_account.file
                                : undefined,
                        accounts: accounts
                    });
                }
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const Store = () => {
        if (showLoading) showLoading('loading', 'guardando contabilización...');
        storeAccounting(accounting, {
            onSuccess: (response: ServiceResponse) => {
                setErrorFields(undefined);
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                getToAccount();

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    // const destroy = (accountingId: number) => {
    //     const _text = 'Está a punto de eliminar la contabilización #' + accountingId;

    //     useReactConfirmAlert().requestConfirmation({
    //         title: '¿Estás seguro?',
    //         message: _text,
    //         buttons: {
    //             confirmButton: {
    //                 label: 'Si, confirmar',
    //                 onClick: () => {
    //                     if (showLoading) showLoading('loading', 'Eliminando contabilización...');
    //                     deleteAccounting(accountingId, {
    //                         onSuccess: (response: ServiceResponse) => {
    //                             if (hideLoading) hideLoading();

    //                             useReactConfirmAlert().successAlert({
    //                                 title: 'Éxito',
    //                                 message: response.message
    //                             });

    //                             getToAccount();
    //                             if (onSaved) onSaved();
    //                         },
    //                         onError: (response: ServiceResponse) => {
    //                             if (hideLoading) hideLoading();

    //                             useReactConfirmAlert().errorAlert({
    //                                 title: 'Error',
    //                                 message: response.message
    //                             });
    //                         }
    //                     });
    //                 }
    //             },
    //             cancelButton: {
    //                 label: 'No, cancelar',
    //                 onClick: () => {
    //                     setTimeout(() => {
    //                         useReactConfirmAlert().infoAlert({
    //                             title: 'Cancelado',
    //                             message: 'La contabilización no se ha eliminado.'
    //                         });
    //                     }, 0);
    //                 }
    //             }
    //         }
    //     });
    // };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            {fetchingGetAccountingByServiceManifest ? (
                <LazyLoading height="300" />
            ) : (
                <div className="row">
                    <div className="col-12">
                        <AccountingForm
                            accountingDataForm={accounting}
                            setAccountingDataForm={setAccounting}
                            serviceManifestDetail={serviceManifestDetail}
                            accounts={accounts}
                            errorFields={errorFields}
                            isShow={isShow}
                            itemType={itemType}
                        />
                        {!isShow &&
                            serviceManifestDetail.status_text != AccountingStatuses.ACCOUNTED && (
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonCancelForm
                                            callbackCancel={cancel}
                                            locked={fetchingStoreAccounting}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            title="Contabilizar"
                                            callbackSave={Store}
                                            locked={fetchingStoreAccounting}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            )}

            {/* <div className="col-12">
                    <hr />
                    <AccountingDataTable
                        accountings={accountings}
                        totalRows={totalRows}
                        loading={fetchingGetAccountingByServiceManifest}
                        filter={filter}
                        setFilter={setFilter}
                        byAccountService={true}
                        destroy={!isShow?destroy:undefined}
                        expandableRows={true}
                        expandableRowsComponent={AccountingExpandableRow}
                        expandableRowsComponentProps={{"onSaved": onSaved, "getToAccount": getToAccount, "isShow": isShow, 'itemType': itemType}}
                        expandableIcon={
                            { 
                                collapsed: <i title="Editar" className={`mdi mdi-${!isShow?'pencil text-warning':'eye text-info'} font-size-18 mx-2`} />, 
                                expanded: <i className={`mdi mdi-${!isShow?'pencil':'eye'} font-size-18 mx-2`} /> 
                            }
                        }
                    />

                </div> */}
        </>
    );
};

export default ServiceManifestDetailToAccount;
