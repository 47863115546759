import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import { QuoteShowDataForm } from '../../../app/models/Quote';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { ServiceResponse } from '../../../app/services/shared/interfaces';

import QuoteDetailsShowContainer from '../components/QuoteDetailShowContainer';
import { Tab, Tabs } from 'react-bootstrap';
import QuoteTab from '../components/tabs/QuoteTab';
import QuoteRouteSegmentsContainer from '../components/QuoteRouteSegmentsContainer';
import { QuoteRouteSegment } from '../../../app/models/QuoteRouteSegment';
import { toast } from 'react-toastify';
import useNavigationPage from '../../../hooks/useNavigationPage';

type RouterParams = {
    id: string;
};

const QuoteShow = () => {

    const { navigationPage } = useNavigationPage();
    const { id } = useParams<RouterParams>();
    const [verifyingQuote, setVerifyingQuote] = useState<boolean>(true);

    const { showQuote, fetchingShowQuote } = useQuoteService();
    const [quoteForm, setQuoteForm] = useState<QuoteShowDataForm>({
        client: '',
        created_at: '',
        created_user: '',
        currency: '',
        id: 0,
        last_modified_user: '',
        payment_condition: '',
        payment_method: '',
        quote_details: [],
        society: '',
        status: '',
        status_selected: {
            id: '',
            name: ''
        },
        status_choices: [],
        updated_at: '',
        contact_email: '',
        expired_in_days: 0,
        comment: ''
    });

    const [quoteRouteSegments, setQuoteRouteSegments] = useState<QuoteRouteSegment[]>([]);
    const [quoteCheckDocument, setQuoteCheckDocument] = useState<boolean>(false);

    useEffect(() => {
        show(parseInt(id));
    }, [id]);

    const show = (id: number) => {
        showQuote(id, {
            onSuccess: (response: ServiceResponse) => {
                if (response.data.quote.days_count && response.data.quote.days_count > 0) {
                    setQuoteForm({ ...quoteForm, ['reference']: '', ['days_count']: 0 });
                }

                setQuoteForm(response.data.quote);

                setQuoteCheckDocument(response.data.quote.is_route_segment_document);
                setVerifyingQuote(false);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                navigationPage('/quotes');
                
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Cotizaciones',
            url: '/quotes',
            isActive: false
        },
        {
            name: 'Mostrar Cotización #' + id,
            url: '/quotes/' + id + '/show',
            isActive: true
        }
    ];

    if (verifyingQuote) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            <Breadcrumbs pageSection={`Mostrar cotización #${id}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <QuoteTab
                        fetchingQuote={fetchingShowQuote}
                        quoteShowForm={quoteForm}
                        isFormShow={true}
                    />
                </Tab>

                <Tab eventKey="interlocutors" title="Interlocutores" disabled>
                    Interlocutores
                </Tab>
                <Tab eventKey="options" title="Opciones">
                    <div className="col-12">
                        <QuoteRouteSegmentsContainer
                            quoteCheckDocument={quoteCheckDocument}
                            quoteId={parseInt(id)}
                            quoteRouteSegments={quoteRouteSegments}
                            setQuoteRouteSegments={setQuoteRouteSegments}
                            isFormShow={true}
                        />
                    </div>
                </Tab>
                <Tab eventKey="management" title="Gestión" disabled>
                    Gestión
                </Tab>
            </Tabs>
            <QuoteDetailsShowContainer quoteId={parseInt(id)} />
        </>
    );
};

export default QuoteShow;
