import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useClasificationService from '../../../app/services/hooks/useClasificationService';
import { ClasificationDataForm } from '../../../app/models/Clasification';
import ClasificationFormContainer from './ClasificationFormContainer';

interface Props {
    clasificationId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ClasificationEdit = ({ clasificationId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingEditClasification, editClasification, fetchingUpdateClasification, updateClasification } = useClasificationService();
    const [clasification, setClasification] = useState<ClasificationDataForm>({
        name: '',
        description: '',
        type: 1,
        account_number: ''
    });
    const [relatedClasification, setRelatedClasification] = useState<ClasificationDataForm | null>(null);
    const [relatedClasificationTwo, setRelatedClasificationTwo] = useState<ClasificationDataForm | null>(null);
    const [clasifications, setClasifications] = useState<ClasificationDataForm[]>([]);
    const [clasificationsTwo, setClasificationsTwo] = useState<ClasificationDataForm[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando clasificación...');
        editClasification(clasificationId, {
            onSuccess: (response: ServiceResponse) => {
                setClasification(response.data.clasification);
                setClasifications(response.data.clasifications_one);
                setClasificationsTwo(response.data.clasifications_two);
                setRelatedClasification(response.data.related_clasification);
                setRelatedClasificationTwo(response.data.related_clasification_two)
                if (response.data.related_clasification) {
                    setClasification(prevState => ({
                        ...prevState,
                        clasif_id: response.data.related_clasification.id
                    }));
                }
                if (response.data.related_clasification_two) {
                    setClasification(prevState => ({
                        ...prevState,
                        // clasif_id: response.data.related_clasification.id,
                        clasif_two_id: response.data.related_clasification_two.id
                    }));
                }
    
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };
    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando clasificación...');
        if (!clasification) return;

        updateClasification(clasificationId, clasification, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({ title: 'Éxito', message: response.message });
                toast.success(response.message, { autoClose: 2500 });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({ title: 'Error', message: response.message });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditClasification || !clasification ? (
        <LazyLoading height="300" />
    ) : (
        <ClasificationFormContainer
            fetching={fetchingUpdateClasification}
            action={update}
            cancel={cancel}
            clasificationDataForm={clasification}
            setClasificationsDataForm={setClasification}
            clasifications={clasifications}
            clasificationsTwo={clasificationsTwo}
            errorFields={errorFields}
            loading={false}
            updated={true}
            type={clasification.type}
        />
    );
};

export default ClasificationEdit;
