import React, { useContext, useEffect, useState } from 'react';
import { Place } from '../../../app/models/Place';
import { useHistory } from 'react-router-dom';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';
import {
    RouteSegmentDataForm,
    defaultRouteSegmentDataForm
} from '../../../app/models/RouteSegment';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import RouteSegmentForm from './forms/RouteSegmentForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const RouteSegmentCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinations, setDestinations] = useState<Place[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [errorGetData, setErrorGetData] = useState('');
    const [routeSegment, setRouteSegment] = useState<RouteSegmentDataForm>(
        defaultRouteSegmentDataForm
    );

    const [errorFields, setErrorFields] = useState<any>();

    const {
        fetchingStoreRouteSegment,
        storeRouteSegment,
        fetchingCreateRouteSegment,
        createRouteSegment
    } = useRouteSegmentService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createRouteSegment({
            onSuccess: (response: ServiceResponse) => {
                setOrigins(response.data.origins);
                setDestinations(response.data.destinations);
                setUnits(response.data.units);
                setCurrencies(response.data.currencies);
                // if(response.data.currencies && response.data.units){
                //     setRouteSegment({...routeSegment,
                //                     ['currency_id']: response.data.currencies[0].id,
                //                     ['unit_id']: response.data.units[0].id,
                //                     // ['currency_rate_runner_id']: response.data.currencies[0].id,
                //                     // ['quantity_unit_rate_runner_id']: response.data.units[0].id,
                //                     // ['currency_rate_transporter_id']: response.data.currencies[0].id,
                //                     // ['quantity_unit_rate_transporter_id']: response.data.units[0].id
                //                     })
                // }
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                setErrorGetData(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        // useSweetAlert().spinnerAlert("Guardando tramo...");
        if (showLoading) showLoading('loading', 'Guardando tramo...');
        storeRouteSegment(routeSegment, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                // useSweetAlert().closeSpinnerAlert();
                // if(changeAnimation)changeAnimation('success','Actualización exitosa', true);
                // navigationPage(
                //     '/route-segments/' + response.data.route_segment_id + '/edit'
                // );
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                // useSweetAlert().closeSpinnerAlert();
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                // useSweetAlert().closeSpinnerAlert();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateRouteSegment ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <RouteSegmentForm
                        routeSegmentForm={routeSegment}
                        setRouteSegmentForm={setRouteSegment}
                        origins={origins}
                        destinations={destinations}
                        currencies={currencies}
                        units={units}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreRouteSegment} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreRouteSegment} />
                </div>
            </div>
        </>
    );
};

export default RouteSegmentCreate;
