import React, { useEffect, useRef, useState } from 'react';

import {
    defaultQuoteDataForm,
    QuoteDataForm,
    QuoteOfferTypeDataForm,
    QuoteReferenceDataForm
} from '../../../app/models/Quote';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import QuoteForm from './forms/QuoteForm';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import { Currency } from '../../../app/models/Currency';
import { useHistory } from 'react-router-dom';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { Unit } from '../../../app/models/Unit';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useClientService from '../../../app/services/hooks/useClientService';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const QuoteCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [offerTypes, setOfferTypes] = useState<QuoteOfferTypeDataForm[]>([]);
    const [references, setReferences] = useState<QuoteReferenceDataForm[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const { 
        fetchingStoreQuote, 
        storeQuote, 
        createQuote, 
        fetchingCreateQuote,
    } = useQuoteService();

    const {
        getClientsBySociety,
        fetchingGetClientBySociety
    } = useClientService()

    //formulario
    const [quote, setQuote] = useState<QuoteDataForm>(defaultQuoteDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    const { navigationPage } = useNavigationPage();
    const societyIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        createQuote({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setPaymentMethods(response.data.payment_methods);
                setPaymentsConditions(response.data.payment_conditions);
                setCurrencies(response.data.currencies);
                setReferences(response.data.references);
                setOfferTypes(response.data.offer_types);
            }
        });
    }, []);

    useEffect(() => {

        if (quote.society_id == undefined) {
            societyIdSelected.current = undefined;
        }

        if (quote.society_id && quote.society_id != societyIdSelected.current) {
            
            getClientsBySociety(Number(quote.society_id), {
                onSuccess: (response: ServiceResponse) => {
                    societyIdSelected.current = Number(quote.society_id) ?? 0;
                 
                    setClients(response.data.clients);
                    setQuote(
                        {
                            ...quote,
                            client_id: 0
                        }
                    )
                },
                onError: (response: ServiceResponse) => {
                    setQuote({
                        ...quote,
                        society_id: Number(societyIdSelected.current)
                    });
                }
            })
        }
        
    }, [quote.society_id]);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando cotización...');
        storeQuote(quote, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                navigationPage('/quotes/' + response.data.quote_id + '/edit');

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateQuote ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteForm
                        quoteForm={quote}
                        setQuoteForm={setQuote}
                        societies={societies}
                        clients={clients}
                        paymentConditions={paymentConditions}
                        references={references}
                        paymentMethods={paymentMethods}
                        currencies={currencies}
                        errorFields={errorFields}
                        offerTypes={offerTypes}
                        isCreated={true}
                        loadingClient={fetchingGetClientBySociety}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreQuote} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreQuote} />
                </div>
            </div>
        </>
    );
};

export default QuoteCreate;
