import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BatchDataForm, BatchFilters } from '../../models/Batch';

const useBatchService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBatches, setFetchingGetBatches] = useState(false);
    const [fetchingShowBatch, setFetchingShowBatch] = useState(false);
    const [fetchingStoreBatch, setFetchingStoreBatch] = useState(false);
    const [fetchingEditBatch, setFetchingEditBatch] = useState(false);
    const [fetchingCreateBatch, setFetchingCreateBatch] = useState(false);
    const [fetchingUpdateBatch, setFetchingUpdateBatch] = useState(false);
    const [fetchingDeleteBatch, setFetchingDeleteBatch] = useState(false);
    const [fetchingActiveBatch, setFetchingActiveBatch] = useState(false);
    const [fetchingGetBatchesByDepotAndCategory, setFetchingGetBatchesByDepotAndCategory] = useState(false);
    const [fetchingGetBatchesByProduct, setFetchingGetBatchesByProduct] = useState(false);

    const getBatches = (batchFilters: BatchFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(batchFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BATCH.GET_BATCHES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBatches
        });
    };

    const showBatch = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH.SHOW_BATCH.replace(':id', id.toString()),
            setFetching: setFetchingShowBatch
        });
    };

    const storeBatch = (batch: BatchDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BATCH.STORE_BATCH,
            body: batch,
            setFetching: setFetchingStoreBatch
        });
    };

    const editBatch = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH.EDIT_BATCH.replace(':id', id.toString()),
            setFetching: setFetchingEditBatch
        });
    };

    const createBatch = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH.CREATE_BATCH,
            setFetching: setFetchingCreateBatch
        });
    };

    const updateBatch = (id: number, batch: BatchDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BATCH.UPDATE_BATCH.replace(':id', id.toString()),
            body: batch,
            setFetching: setFetchingUpdateBatch
        });
    };

    const deleteBatch = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BATCH.DELETE_BATCH.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBatch
        });
    };

    const activeBatch = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BATCH.ACTIVE_BATCH.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBatch
        });
    };

    const getBatchesByDepotAndCategory = (id: number, depotId: number, categoryId: number, events: ServiceEvents = {}) => {
        const url = EndPoints.BATCH.GET_BY_DEPOT
        .replace(':depot_id', depotId.toString())
        .replace(':category_id', categoryId.toString())
        .replace(':id', id.toString()
        );
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBatchesByDepotAndCategory
        });
    };

    const getBatchesByProduct = (productId: number, events: ServiceEvents = {}) => {
        const url = EndPoints.BATCH.GET_BY_PRODUCT.replace(':product_id', productId.toString());
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBatchesByProduct
        });
    };

    return {
        fetchingGetBatches,
        fetchingShowBatch,
        fetchingStoreBatch,
        fetchingEditBatch,
        fetchingUpdateBatch,
        fetchingDeleteBatch,
        fetchingActiveBatch,
        fetchingCreateBatch,
        fetchingGetBatchesByDepotAndCategory,
        fetchingGetBatchesByProduct,
        getBatches,
        showBatch,
        storeBatch,
        createBatch,
        editBatch,
        updateBatch,
        deleteBatch,
        activeBatch,
        getBatchesByDepotAndCategory,
        getBatchesByProduct
    };
};

export default useBatchService;
