import React, { useContext, useEffect, useState } from 'react';

import { Client, ClientFilters } from '../../app/models/Client';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useClientService from '../../app/services/hooks/useClientService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import ClientCreate from './components/ClientCreate';
import ClientEdit from './components/ClientEdit';
import { AppContext } from '../../contexts/AppContext';
import ClientDataTable from './components/ClientDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Clientes',
        url: '/clients',
        isActive: true
    }
];

const Clients = () => {

    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetClients, getClients, deleteClient } = useClientService();
    const [clients, setClients] = useState<Client[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [clientIdEdit, setClientIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    

    const [filter, setFilter] = useState<ClientFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllClients();
    }, [filter]);

    const getAllClients = () => {
        getClients(filter, {
            onSuccess: (response: ServiceResponse) => {
                setClients(response.data.clients);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };


    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllClients();
    };

    const showEdit = (id: number) => {
        navigationPage(`/clients/${id}/edit`);
       
    };

    const showShow = (id: number) => {
        navigationPage(`/clients/${id}/show`)
       
    };

    const destroy = (clientId: number) => {
        const _text = 'Está a punto de eliminar el cliente #' + clientId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando cliente...');
                deleteClient(clientId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el cliente #' + clientId
                        });
                        reloadTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El cliente no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Clientes" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    {/* <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showCreate}
                                    title="Cliente"
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <ClientDataTable
                            clients={clients}
                            totalRows={totalRows}
                            loading={fetchingGetClients}
                            edit={showEdit}
                            show={showShow}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Cliente"
                    backdrop={true}
                    showFooter={false}
                >
                    <ClientCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Clients;
