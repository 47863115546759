import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { ClasificationDataForm,ClasificationFilters} from '../../../../app/models/Clasification';
import ClasificationFilter from './ClasificationFilter';
import ClasificationSwitchChange from '../ClasificationSwitchChange';

interface Props {
    clasifications: ClasificationDataForm[];
    totalRows: number;
    loading?: boolean;
    active?: (id: number, active: boolean) => void;
    edit?: (id: number) => void;
    destroy?: (clasificationId: number) => void;
    filter: ClasificationFilters;
    setFilter: (filter: ClasificationFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;

}

const ClasificationDataTable = ({
    clasifications,
    totalRows,
    loading = false,
    active,
    destroy,
    edit,
    filter,
    setFilter,
    paginationRowsPerPageOptions,
    callbackSuccess
}:Props)=> {
    const columns = [
        {
            name: 'Nombre',
            selector: (row: ClasificationDataForm) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: ClasificationDataForm) => <span className="">{row.name}</span>
        },
        {
            name: 'Tipo',
            selector: (row: ClasificationDataForm) => row.type,
            sortable: true,
            sortField: 'type',
            cell: (row: ClasificationDataForm) => <span className="">{row.type}</span>
        },
        {
            name: 'Número de cuenta',
            selector: (row: ClasificationDataForm) => row.account_number,
            sortable: true,
            sortField: 'account_number',
            width: '135px',
            cell: (row: ClasificationDataForm) => <span className="">{row.account_number}</span>
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: ClasificationDataForm, index: any, column: any) => (
                <ClasificationSwitchChange
                    
                    checkValue={row.is_active ?? true}
                    clasificationId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: ClasificationDataForm) => row.id,
            sortable: false,
            cell: (row: ClasificationDataForm) => (
                <div>
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <ClasificationFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={clasifications}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default ClasificationDataTable;
