import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { ProductDataForm, ProductFilters } from '../../models/Product';

const useProductService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetProducts, setFetchingGetProducts] = useState(false);
    const [fetchingShowProduct, setFetchingShowProduct] = useState(false);
    const [fetchingCreateProduct, setFetchingCreateProduct] = useState(false);
    const [fetchingStoreProduct, setFetchingStoreProduct] = useState(false);
    const [fetchingEditProduct, setFetchingEditProduct] = useState(false);
    const [fetchingUpdateProduct, setFetchingUpdateProduct] = useState(false);
    const [fetchingDeleteProduct, setFetchingDeleteProduct] = useState(false);
    const [fetchingActiveProduct, setFetchingActiveProduct] = useState(false);
    const [fetchingGetProduct, setFetchingGetProduct] = useState(false);
    const [fetchingGetProductWithPurchaseOrder, setFetchingGetProductWithPurchaseOrder] = useState(false);

    const getProducts = (productFilters: ProductFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(productFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PRODUCTS.GET_PRODUCTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetProducts
        });
    };

    const createProduct = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.CREATE_PRODUCT,
            setFetching: setFetchingCreateProduct
        });
    };

    const storeProduct = (product: ProductDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PRODUCTS.STORE_PRODUCT,
            body: product,
            setFetching: setFetchingStoreProduct
        });
    };

    const showProduct = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.SHOW_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingShowProduct
        });
    };

    const editProduct = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.EDIT_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingEditProduct
        });
    };

    const updateProduct = (id: number, product: ProductDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PRODUCTS.UPDATE_PRODUCT.replace(':id', id.toString()),
            body: product,
            setFetching: setFetchingUpdateProduct
        });
    };

    const deleteProduct = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PRODUCTS.DELETE_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteProduct
        });
    };

    const activeProduct = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PRODUCTS.ACTIVE_PRODUCT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveProduct
        });
    };

    const getProduct = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.GET_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingGetProduct
        });
    };

    const getProductWithPurchaseOrder = (id: number, purchase_order_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.GET_PRODUCT_WITH_PURCHASE_ORDER
            .replace(':id', id.toString())
            .replace(':purchase_order_id', purchase_order_id.toString()),
            setFetching: setFetchingGetProductWithPurchaseOrder
        });
    };

    return {
        fetchingGetProducts,
        fetchingShowProduct,
        fetchingCreateProduct,
        fetchingStoreProduct,
        fetchingEditProduct,
        fetchingUpdateProduct,
        fetchingDeleteProduct,
        fetchingActiveProduct,
        fetchingGetProduct,
        fetchingGetProductWithPurchaseOrder,
        getProducts,
        showProduct,
        createProduct,
        storeProduct,
        editProduct,
        updateProduct,
        deleteProduct,
        activeProduct,
        getProduct,
        getProductWithPurchaseOrder
    };
};

export default useProductService;
