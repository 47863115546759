import React from 'react';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
};

const GuideFilter = ({ filter, setFilter }: Props) => {

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
            <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="start_date">
                            Desde
                        </label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control form-control-sm"
                            value={filter.start_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="end_date">
                            Hasta
                        </label>
                        <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            className="form-control form-control-sm"
                            value={filter.end_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="client_rut">
                            RUT Cliente
                        </label>
                        <input
                            title="Cliente"
                            type="text"
                            id="client_rut"
                            name="client_rut"
                            className="form-control form-control-sm"
                            value={filter.client_rut}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="client_name">
                            Nombre Cliente
                        </label>
                        <input
                            title="Cliente"
                            type="text"
                            id="client_name"
                            name="client_name"
                            className="form-control form-control-sm"
                            value={filter.client_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="dispatch_program">
                            Despacho
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="dispatch_program"
                            name="dispatch_program"
                            className="form-control form-control-sm"
                            value={filter.dispatch_program}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                
            </div>
            <div className="row">
            <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="carrier_rut">
                            RUT Conductor
                        </label>
                        <input
                            title="carrier_rut"
                            type="text"
                            id="carrier_rut"
                            name="carrier_rut"
                            className="form-control form-control-sm"
                            value={filter.carrier_rut}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="carrier_name">
                            Nombre Conductor
                        </label>
                        <input
                            title="carrier_name"
                            type="text"
                            id="carrier_name"
                            name="carrier_name"
                            className="form-control form-control-sm"
                            value={filter.carrier_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck">
                            Tracto
                        </label>
                        <input
                            title="Tracto"
                            type="text"
                            id="truck"
                            name="truck"
                            className="form-control form-control-sm"
                            value={filter.truck}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="folio">
                            Folio
                        </label>
                        <input
                            title="Folio"
                            type="text"
                            id="folio"
                            name="folio"
                            className="form-control form-control-sm"
                            value={filter.folio}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Origen
                        </label>
                        <input
                            title="Origen"
                            type="text"
                            id="origin"
                            name="origin"
                            className="form-control form-control-sm"
                            value={filter.origin}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="destiny">
                            Destino
                        </label>
                        <input
                            title="Destino"
                            type="text"
                            id="destiny"
                            name="destiny"
                            className="form-control form-control-sm"
                            value={filter.destiny}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuideFilter;
