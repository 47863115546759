import React, { useEffect, useState } from 'react';

import { Carrier, CarrierFilters } from '../../app/models/Carrier';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useCarrierService from '../../app/services/hooks/useCarrierService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import CarrierCreate from './components/CarrierCreate';
import CarrierEdit from './components/CarrierEdit';
import CarrierDataTable from './components/CarrierDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Conductores',
        url: '/Carriers',
        isActive: true
    }
];

const Carriers = () => {
    const { navigationPage } = useNavigationPage();

    const { fetchingGetCarriers, getCarriers, deleteCarrier } = useCarrierService();
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    const [CarrierIdEdit, setCarrierIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [totalRows, setTotalRows] = useState<number>(0);
    const [filter, setFilter] = useState<CarrierFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllCarriers();
    }, [filter]);

    const getAllCarriers = () => {
        getCarriers(filter, {
            onSuccess: (response: ServiceResponse) => {
                setCarriers(response.data.carriers);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showEdit = (CarrierId: number) => {
        setShowingEdit(true);
        setCarrierIdEdit(CarrierId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setCarrierIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllCarriers();
    };

    const destroy = (CarrierId: number) => {
        const _text = 'Está a punto de eliminar el conductor #' + CarrierId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteCarrier(CarrierId, {
                    onSuccess: () => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el conductor #' + CarrierId
                        });
                        reloadTable();
                    },
                    onError: () => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'No se ha podido el conductor #' + CarrierId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El conductor no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Conductores" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo conductor" />}
                />
                <CarrierDataTable
                    carriers={carriers}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetCarriers}
                    filter={filter}
                    setFilter={setFilter}
                    totalRows={totalRows}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear conductor"
                    backdrop={true}
                    showFooter={false}
                >
                    <CarrierCreate
                        onSaved={() => {
                            toast.success('Transportista Registrado');
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}

                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Conductor"
                    backdrop={true}
                    showFooter={false}
                >
                    <CarrierEdit
                        CarrierId={CarrierIdEdit}
                        onSaved={() => {
                            toast.success('Conductor Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}

                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Carriers;
