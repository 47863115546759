import React from 'react';
import { SectorFilters } from '../../../../app/models/Sector';

type Props = {
    filter: SectorFilters;
    setFilter: (filter: SectorFilters) => void;
};

const SectorFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="name">
                            Nombre
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="name"
                            name="name"
                            className="form-control form-control-sm"
                            value={filter.name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectorFilter;
