import React, { useEffect, useState } from 'react';
import { Product } from '../../../app/models/Product';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Service } from '../../../app/models/Service';
import useTransportationQuoteDetailService from '../../../app/services/hooks/useTransportationQuoteDetailService';
import {
    QuoteDataForm,
    TransportationQuoteDetailDataForm,
    ActionResquest,
    defaultTransportationQuoteDetailDataForm,
    defaultEditTransportationQuoteDetailDataForm
} from '../../../app/models/Quote';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import QuoteDetailForm from './forms/QuoteDetailForm';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { RouteSegment } from '../../../app/models/RouteSegment';
import { QuoteRouteSegment } from '../../../app/models/QuoteRouteSegment';
import { toast } from 'react-toastify';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';

interface Props {
    quoteId: number;
    quoteDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    quoteForm: QuoteDataForm;
    setQuoteForm: (quoteForm: QuoteDataForm) => void;
    setQuoteRouteSegmentTable: (quoteRouteSegment: QuoteRouteSegment[]) => void;
    reloadQuoteRouteSegmentTable: () => void;
}

const QuoteDetailEdit = ({
    quoteId,
    quoteDetailId,
    onSaved,
    onCancel,
    onError,
    quoteForm,
    setQuoteForm
}: Props) => {
    const {
        editTransportationQuoteDetail,
        updateTransportationQuoteDetail,
        fetchingUpdateTransportationQuoteDetail,
        getQuoteRouteSegmentsToQuoteDetail,
        fetchingGetQuoteRouteSegmentsToQuoteDetail
    } = useTransportationQuoteDetailService();

    const { fetchingShowRsToTransportationQuoteDetail, showRsToTransportationQuoteDetail } =
        useQuoteRouteSegmentService();

    const [errorFields, setErrorFields] = useState<any>();

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinies, setDestinies] = useState<Place[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [quoteRouteSegments, setQuoteRouteSegments] = useState<QuoteRouteSegment[]>([]);
    const [action, setAction] = useState<ActionResquest>({});
    const [disableUpdate, setDisableUpdate] = useState<boolean>(false);

    const [quoteDetail, setQuoteDetail] = useState<TransportationQuoteDetailDataForm>({
        ...defaultTransportationQuoteDetailDataForm,
        ['quote_id']: quoteId,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0,
        ['service_id']: 0,
        ['product_id']: 0,
        ['quantity']: 0,
        ['first_dispatch_date']: '',
        ['description']: ''
    });

    const [quoteRouteSegmentEdit, setQuoteRouteSegmentEdit] =
        useState<TransportationQuoteDetailDataForm>({
            ...defaultEditTransportationQuoteDetailDataForm,
            ['quote_id']: quoteId,
            ['route_segment_id']: undefined,
            ['quantity']: 0,
            ['agreed_distance']: 0,

            quote_route_segment: {}
        });

    useEffect(() => {
        edit();
    }, [quoteDetailId]);

    useEffect(() => {
        if (
            quoteDetail.route_segment_id &&
            quoteRouteSegmentEdit.quote_route_segment_id &&
            quoteDetail.quote_route_segment &&
            quoteDetail.route_segment_id == quoteDetail.quote_route_segment.route_segment_id
        ) {
            setQuoteRouteSegments([]);
            setErrorFields(undefined);
            console.log('tramo de edit');
            setQuoteDetail({
                ...quoteDetail,
                ...defaultTransportationQuoteDetailDataForm,
                ...quoteRouteSegmentEdit
            });
        } else if (
            quoteDetail.route_segment_id &&
            quoteDetail.quote_route_segment &&
            quoteDetail.route_segment_id != quoteRouteSegmentEdit.route_segment_id
        ) {
            console.log('diferente tramo');

            setQuoteRouteSegments([]);
            showRsToTransportationQuoteDetail(quoteDetail.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    setQuoteDetail({
                        ...quoteDetail,
                        ...defaultTransportationQuoteDetailDataForm,
                        ...response.data.route_segment,
                        action_to_do: action.first_time
                    });
                }
            });
        }
    }, [quoteDetail.route_segment_id]);

    const edit = () => {
        useSweetAlert().spinnerAlert();
        editTransportationQuoteDetail(quoteDetailId, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setOrigins(response.data.origins);
                setDestinies(response.data.destinies);
                setServices(response.data.services);
                setRouteSegments(response.data.route_segments);
                setAction(response.data.actions);
                if (!response.data.can_update) {
                    setDisableUpdate(true);
                }
                const data = response.data.transportation_quote_detail;
                setQuoteDetail({ ...data });

                delete data.service_id;
                delete data.product_id;
                delete data.first_dispatch_date;
                delete data.description;
                setQuoteRouteSegmentEdit(data);
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando detalle cotización...', 400);
        updateTransportationQuoteDetail(quoteDetailId, quoteDetail, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();

                if (response.data.continue) {
                    console.log('continuar');
                    questionAction(response.message);

                    return;
                }

                setQuoteForm({ ...quoteForm, ...response.data.quote_date });
                toast.success(response.message);

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const questionAction = (message: string) => {
        useSweetAlert().requestConfirmationTwoOptions({
            title: '¿Qué desea hacer?',
            text: message,
            addButtonText: 'Agregar opción',
            updateButtonText: 'Actualizar opción',
            onConfirmAdd: () => {
                quoteDetail.action_to_do = action.add;
                setQuoteDetail({ ...quoteDetail });

                update();
            },
            onConfirmUpdate: () => {
                console.log('traer las opciones de ruta');
                useSweetAlert().spinnerAlert('Consultando opciones de ruta...', 400);
                getQuoteRouteSegmentsToQuoteDetail(
                    quoteDetail.quote_id!,
                    quoteDetail.route_segment_id!,
                    {
                        onSuccess: (response: ServiceResponse) => {
                            useSweetAlert().closeSpinnerAlert();
                            setQuoteRouteSegments(response.data.quote_route_segments);
                            setQuoteDetail({ ...quoteDetail, action_to_do: action.update });
                        },
                        onError: (response: ServiceResponse) => {
                            useSweetAlert().closeSpinnerAlert();
                            toast.error(response.message);
                        }
                    }
                );
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'No se ha ejecutado ninguna acción.'
                });
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteDetailForm
                        products={products}
                        quantityUnits={quantityUnits}
                        currencies={currencies}
                        origins={origins}
                        destinies={destinies}
                        services={services}
                        routeSegments={routeSegments}
                        quoteDetailForm={quoteDetail}
                        setQuoteDetailForm={setQuoteDetail}
                        errorFields={errorFields}
                        quoteRouteSegments={quoteRouteSegments}
                        fetching={fetchingShowRsToTransportationQuoteDetail}
                        disableUpdate={disableUpdate}
                    />
                    {/* <QuoteRouteSegmentToQuoteDetailTable
                        quoteRouteSegments={quoteRouteSegments}
                        
                        quoteDetailForm={quoteDetail}
                        setQuoteDetailForm={setQuoteDetail}
                       
                        /> */}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateTransportationQuoteDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateTransportationQuoteDetail}
                    />
                </div>
            </div>
        </>
    );
};

export default QuoteDetailEdit;
