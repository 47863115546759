import React, { useEffect } from 'react';

import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';

import { MassiveLoad } from '../../../../app/models/MassiveLoad';

interface Props {
    setMassiveLoad: (massiveLoad: MassiveLoad) => void;
    massiveLoad: MassiveLoad;

    errorFields?: any;
}

const MassiveLoadForm = ({
    setMassiveLoad,
    massiveLoad,

    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setMassiveLoad({ ...massiveLoad, ['file']: e[0] });
        }
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-form-label">Archivo excel</label>
                <div className="col-md-12">
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'file'}
                        objectFile={massiveLoad?.file}
                        onFocus={() => onFocusRemove('file')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MassiveLoadForm;
