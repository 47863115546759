import React from 'react';
import { ServiceManifestDataForm } from '../../../../app/models/ServiceManifest';

interface Props {
    serviceManifestDataForm: ServiceManifestDataForm;
}

const ServiceManifestShowForm = ({ serviceManifestDataForm }: Props) => {
    if (!serviceManifestDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedad</label>
                    <input
                        name="society"
                        id="society"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.society}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedor</label>
                    <input
                        name="provider"
                        id="provider"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.provider}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Orden de compra</label>
                    <input
                        name="purchase_order"
                        id="purchase_order"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.purchase_order}
                        readOnly={true}
                    />
                </div>
            </div>
            <hr />

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Rut sociedad</label>
                    <input
                        name="society_rut"
                        id="society_rut"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.society_rut}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razón social sociedad</label>
                    <input
                        name="society_business_name"
                        id="society_business_name"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.society_business_name}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Rut proveedor</label>
                    <input
                        name="provider_rut"
                        id="provider_rut"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.provider_rut}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razón social proveedor</label>
                    <input
                        name="provider_business_name"
                        id="provider_business_name"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.provider_business_name}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Condición de entrega</label>
                    <input
                        name="incoterm"
                        id="incoterm"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.incoterm}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Destino</label>
                    <input
                        name="destiny"
                        id="destiny"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.destiny}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Forma de pago</label>
                    <input
                        name="payment_method"
                        id="payment_method"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.payment_method}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Condición de pago</label>
                    <input
                        name="payment_condition"
                        id="payment_condition"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.payment_condition}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Folio</label>
                    <input
                        type="text"
                        className="form-control"
                        name="folio"
                        id="folio"
                        value={serviceManifestDataForm.folio}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Fecha emisión de factura</label>
                    <input
                        name="issue_date"
                        id="issue_date"
                        type="date"
                        className="form-control"
                        value={serviceManifestDataForm.issue_date}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Factura</label>
                    {serviceManifestDataForm.file_url ? (
                        <div>
                            <a
                                href={serviceManifestDataForm.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success btn-sm"
                                role="button"
                                aria-pressed="true"
                            >
                                Factura
                            </a>
                        </div>
                    ) : (
                        <div>
                            <p>No tiene factura</p>
                        </div>
                    )}
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-12 col-form-label">Fecha emisión de entrega</label>
                    <input
                        type="date"
                        className="form-control"
                        name="delivery_date"
                        id="delivery_date"
                        value={serviceManifestDataForm.delivery_date}
                        readOnly={true}
                    />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestShowForm;
