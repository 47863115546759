import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Incoterm } from '../../../app/models/Incoterm';
import IncotermForm from './forms/IncotermForm';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    incoterm: Incoterm;
    setIncoterm: (incoterm: Incoterm) => void;
    errorFields?: any;
}

const IncotermFormContainer = ({
    fetching,
    action,
    cancel,
    incoterm,
    setIncoterm,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <IncotermForm
                        incotermDataForm={incoterm}
                        setIncotermDataForm={setIncoterm}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default IncotermFormContainer;
