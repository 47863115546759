import React from 'react';
import { TransportationOrderDataForm } from '../../../../app/models/TransportationOrder';
import { Link } from 'react-router-dom';
import { TransportationQuoteDetail } from '../../../../app/models/TransportationQuoteDetail';

interface Props {
    transportationOrderDataForm: TransportationOrderDataForm;
    quotes: TransportationQuoteDetail[];
}

const TransportationOrderShowForm = ({ transportationOrderDataForm, quotes }: Props) => {
    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cotización</label>
                <div className="col-md-9">
                    {transportationOrderDataForm.transportation_quote_detail_id ? (
                        <Link
                            to={`/quotes/${
                                quotes
                                    .find((item) => {
                                        return (
                                            item.id ==
                                            transportationOrderDataForm.transportation_quote_detail_id
                                        );
                                    })
                                    ?.name?.replace('#', '')
                                    .split('|')[0]
                            }/show`}
                        >
                            {
                                quotes.find((item) => {
                                    return (
                                        item.id ==
                                        transportationOrderDataForm.transportation_quote_detail_id
                                    );
                                })?.name
                            }
                        </Link>
                    ) : (
                        <input
                            type="text"
                            name="quote_id"
                            className="form-control"
                            readOnly={true}
                            value="No asignada"
                        />
                    )}
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="society"
                        className="form-control"
                        readOnly={true}
                        value={transportationOrderDataForm.society}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cliente</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="client"
                        className="form-control"
                        readOnly={true}
                        value={transportationOrderDataForm.client}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Contacto</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="contact_email"
                        className="form-control"
                        readOnly={true}
                        value={transportationOrderDataForm.contact_email}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Forma de Pago</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="payment_method"
                        className="form-control"
                        readOnly={true}
                        value={transportationOrderDataForm.payment_method}
                    />
                </div>
            </div>
            {transportationOrderDataForm.days_count &&
            transportationOrderDataForm.days_count > 0 ? (
                <>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Condición de pago</label>
                        <div className="col-md-9">
                            <input
                                type="text"
                                className="form-control"
                                name="payment_condition"
                                value={transportationOrderDataForm.reference}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Cantidad de días</label>
                        <div className="col-md-9">
                            <input
                                type="text"
                                className="form-control"
                                name="payment_condition"
                                value={transportationOrderDataForm.days_count}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Condición de Pago</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            name="payment_condition"
                            className="form-control"
                            readOnly={true}
                            value={transportationOrderDataForm.payment_condition}
                        />
                    </div>
                </div>
            )}
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Moneda</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="currency"
                        className="form-control"
                        readOnly={true}
                        value={String(transportationOrderDataForm.currency)}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderShowForm;
