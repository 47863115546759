import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { CompanyTypeDataForm } from '../../../../app/models/CompanyType';
import { SectorDataForm } from '../../../../app/models/Sector';

type Props = {
    sectorDataForm: SectorDataForm;
    setSectorDataForm: (sector: SectorDataForm) => void;
    errorFields?: any;
};

const SectorForm = ({ sectorDataForm, setSectorDataForm, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setSectorDataForm({ ...sectorDataForm, [name]: value });
    };

    return (
        <form>
            <div className="row">
                <div className="mb-2 row needs-validation">
                    <label className="col-md-12 col-form-label">Nombre</label>
                    <div className="col-md-12">
                        <input
                            name="name"
                            id="name"
                            type="text"
                            className={`form-control ${fieldHasError('name')}`}
                            value={sectorDataForm.name}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('name')}
                            placeholder="Ingrese nombre del sector"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SectorForm;
