import React, { useEffect, useState } from 'react';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { ForeignExchanges, ForeignExchangeFilters } from '../../../../app/models/ForeignExchange';
import ForeignExchangesFilters from './ForeignExchangeFilter';
import { formatAmount, formatMoney } from '../../../../utils/utils';
import { momentParseDate } from '../../../../helpers';

interface Props {
    foreignExchanges: ForeignExchanges[];
    totalRows: number;
    loading?: boolean;
    show?: (id: number) => void;
    filter: ForeignExchangeFilters;
    setFilter: (filter: ForeignExchangeFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const ForeignExchangeDataTable = ({
    foreignExchanges,
    totalRows,
    loading = false,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    
    const columns = [
        {
            name: 'Fecha de cambio',
            selector: (row: any) => row.exchange_date,
            sortable: true,
            sortField: 'exchange_date',
            cell: (row: ForeignExchanges, index: any, column: any) => <span className="">{momentParseDate(row.exchange_date)}</span>
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: ForeignExchanges, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Valor CLP',
            selector: (row: any) => row.value,
            sortable: true,
            sortField: 'value',
            cell: (row: ForeignExchanges, index: any, column: any) => 
            <span className="">
                {row.value ? formatAmount(row.value) : ''} CLP</span>
        },
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <div className="col">
                        <ForeignExchangesFilters filter={filter} setFilter={setFilter}/>
                    </div>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={foreignExchanges}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default ForeignExchangeDataTable;
