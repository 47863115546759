import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Currency } from '../../../../app/models/Currency';
import { Tax } from '../../../../app/models/Tax';

type Props = {
    incotermDataForm: Tax;
    setIncotermDataForm: (tax: Tax) => void;
    errorFields?: any;
};

const IncotermForm = ({ incotermDataForm, setIncotermDataForm, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | 
        React.ChangeEvent<HTMLSelectElement> |
        React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setIncotermDataForm({ ...incotermDataForm, [name]: value });
    };

    return (
        <>

            <div className="mb-2 row needs-validation">
                <label className="col-md-12 col-form-label">Nombre</label>
                <div className="col-md-12">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={incotermDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre la condición de entrega"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
         
            <div className="mb-2 row align-items-center">
                <div className="col-md-12">
                    <label htmlFor="description" className="col-md-3 col-form-label">
                        Descripción
                    </label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={incotermDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IncotermForm;
