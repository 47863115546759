import React, { useContext } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import {
    TransportationQuoteDetail,
    TransportationQuoteDetailFilters
} from '../../../../app/models/TransportationQuoteDetail';
import { momentParseDate } from '../../../../helpers';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { Roles } from '../../../../app/shared/enums';
import { AuthContext } from '../../../../contexts/AuthContext';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface Props {
    quoteDetails: TransportationQuoteDetail[];
    callbackSuccess?: () => void;
    loading?: boolean;
    totalRows: number;
    edit?: (quoteDetailId: number) => void;
    destroy?: (quoteDetailId: number) => void;
    show?: (quoteDetailId: number) => void;
    filter: TransportationQuoteDetailFilters;
    setFilter: (filter: TransportationQuoteDetailFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const QuoteDetailsDataTable = ({
    quoteDetails,
    totalRows,
    filter,
    setFilter,
    loading = false,
    callbackSuccess,
    edit,
    destroy,
    show
}: Props) => {
    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Posición',
            selector: (row: any) => row.postion,
            sortable: true,
            sortField: 'position',
            cell: (row: any, index: any, column: any) => <span className="">{row.position}</span>
        },
        {
            name: 'Producto',
            selector: (row: any) => row.postion,
            sortable: true,
            sortField: 'product_name',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.product_name}</span>
            )
        },
        {
            name: 'Servicio',
            selector: (row: any) => row.service,
            sortable: true,
            sortField: 'service',
            cell: (row: any, index: any, column: any) => <span className="">{row.service}</span>
        },
        {
            name: 'Tramo',
            selector: (row: any) => row.route_segment_id,
            sortable: true,
            sortField: 'route_segment_id',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.route_segment_id}</span>
            )
        },
        {
            name: 'Origen',
            selector: (row: any) => row.origin,
            sortable: true,
            sortField: 'origin',
            cell: (row: any, index: any, column: any) => <span className="">{row.origin}</span>
        },
        {
            name: 'Destino',
            selector: (row: any) => row.destiny,
            sortable: true,
            sortField: 'destiny',
            cell: (row: any, index: any, column: any) => <span className="">{row.destiny}</span>
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: any, index: any, column: any) => {
                return formatNumber(row.quantity);
            }
        },
        {
            name: 'Unidad',
            selector: (row: any) => row.product_unit,
            sortable: true,
            sortField: 'quantity_unit',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.product_unit}</span>
            )
        },
        {
            name: 'Tarifa de venta',
            selector: (row: any) => row.rate,
            sortable: true,
            sortField: 'rate',
            cell: (row: any, index: any, column: any) => {
                return formatMoney(row.rate);
            }
        },
        {
            name: 'Unidad',
            selector: (row: any) => row.currency,
            sortable: true,
            sortField: 'unit',
            cell: (row: any, index: any, column: any) => {
                return row.currency + '/' + row.product_unit;
            }
        },
        {
            name: 'F. Despacho',
            selector: (row: any) => row.first_dispatch_date,
            sortable: true,
            sortField: 'first_dispatch_date',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.first_dispatch_date}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            width: '135px',
            cell: (row: any, index: any, column: any) => {
                const lockedDestroy = row.status == 'CONFIRMADA';
                return (
                    <div className="">
                        {show && (
                            <ButtonTableAction
                                callbackFunction={() => show(row.id ?? -1)}
                                classIcon={'mdi mdi-eye'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar este registro.'}
                                title={'Ver'}
                            />
                        )}
                        {edit && (
                            <ButtonTableAction
                                callbackFunction={() => edit(row.id ?? -1)}
                                classIcon={'mdi mdi-pencil'}
                                colorIcon={'text-warning'}
                                errorMessage={'No se puede editar este registro.'}
                                title={'Editar'}
                            />
                        )}
                        {destroy &&
                            auth?.roles &&
                            (auth?.roles.includes(Roles.MANAGERS) ||
                                auth?.roles.includes(Roles.OPERATIONS_MANAGER)) && (
                                <ButtonTableAction
                                    callbackFunction={() => destroy(row.id ?? -1)}
                                    locked={lockedDestroy}
                                    classIcon={'mdi mdi-delete'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede eliminar este registro.'}
                                    title={'Eliminar'}
                                />
                            )}
                    </div>
                );
            }
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={quoteDetails}
                    progressPending={loading}
                    filter={filter}
                    setFilter={setFilter}
                    paginationTotalRows={totalRows}
                />
            </div>
        </div>
    );
};

export default QuoteDetailsDataTable;
