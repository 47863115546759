import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ContactType, ContactTypeDataForm } from '../../../app/models/ContactType';
import ContactTypeForm from './forms/ContactTypeForm';
import { BusinessEntity } from '../../../app/models/BusinessEntity';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    contactType: ContactTypeDataForm;
    setContactType: (contactType: ContactTypeDataForm) => void;
    businessEntities: BusinessEntity[];
    errorFields?: any;
}

const ContactTypeFormContainer = ({
    fetching,
    action,
    cancel,
    contactType,
    setContactType,
    businessEntities,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ContactTypeForm
                        contactTypeDataForm={contactType}
                        setContactTypeDataForm={setContactType}
                        businessEntities={businessEntities}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ContactTypeFormContainer;
