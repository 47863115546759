import React from 'react';

interface Props {
    salesInvoice:any;
}

const SalesInvoiceTotal = ({ salesInvoice }: Props) => {
    return (
        <div className="sales-invoice-total">
            <div className="row justify-content-end">
                <div className="col-md-3">
                    <div className="form-group mb-2">
                        <label htmlFor="totalNet">Total Neto:</label>
                        <input
                            type="text"
                            id="totalNet"
                            className="form-control"
                            value={salesInvoice?.total_neto || ''}
                            disabled
                        />
                    </div>

                    <div className="form-group mb-2">
                        <label htmlFor="totalTax">Total Impuestos:</label>
                        <input
                            type="text"
                            id="totalTax"
                            className="form-control"
                            value={salesInvoice?.total_tax || ''}
                            disabled
                        />
                    </div>

                    <div className="form-group mb-2">
                        <label htmlFor="totalSale">Total:</label>
                        <input
                            type="text"
                            id="totalSale"
                            className="form-control"
                            value={salesInvoice?.total_sale || ''}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesInvoiceTotal;
