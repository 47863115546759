import React, { useState, useContext } from 'react';
import PaymentMethodContainer from './PaymentMethodContainer';
import { PaymentMethod, defaultPaymentMethod } from '../../../app/models/PaymentMethod';
import { AppContext } from '../../../contexts/AppContext';
import useSweetAlert from '../../../hooks/useSweetAlert';
import usePaymentMethodService from '../../../app/services/hooks/usePaymentMethodService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PaymentMethodCreate = ({ onSaved, onCancel, onError }: Props) => {

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(defaultPaymentMethod);
    const { storePaymentMethod, fetchingStorePaymentMethod } = usePaymentMethodService();

    const [errorFields, setErrorFields] = useState<any>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando foma de pago...', 400);
        storePaymentMethod(paymentMethod, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <PaymentMethodContainer 
                action={store} 
                cancel={cancel} 
                paymentMethod={paymentMethod} 
                setPaymentMethod={setPaymentMethod}
                errorFields={errorFields}
            />
        </>
    )
}

export default PaymentMethodCreate
