import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { PurchaseOrderDetail, PurchaseOrderDetailDataForm, PurchaseOrderDetailFilters } from '../../models/PurchaseOrderDetail';

const usePurchaseOrderDetailService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetPurchaseOrderDetailsByPurchaseOrder, setFetchingGetPurchaseOrderDetailsByPurchaseOrder] = useState(false);
    const [fetchingShowPurchaseOrderDetail, setFetchingShowPurchaseOrderDetail] = useState(false);
    const [fetchingCreatePurchaseOrderDetail, setFetchingCreatePurchaseOrderDetail] = useState(false);
    const [fetchingStorePurchaseOrderDetail, setFetchingStorePurchaseOrderDetail] = useState(false);
    const [fetchingEditPurchaseOrderDetail, setFetchingEditPurchaseOrderDetail] = useState(false);
    const [fetchingUpdatePurchaseOrderDetail, setFetchingUpdatePurchaseOrderDetail] = useState(false);
    const [fetchingDeletePurchaseOrderDetail, setFetchingDeletePurchaseOrderDetail] = useState(false);
    const [fetchingGetPurchaseOrderDetail, setFetchingGetPurchaseOrderDetail] = useState(false);
    const [fetchingGetPurchaseOrderDetailsFinished, setFetchingGetPurchaseOrderDetailsFinished] = useState(false);
    
    const getPurchaseOrderDetailsByPurchaseOrder = (
        purchaseOrderDetailFilters: PurchaseOrderDetailFilters,
        purchase_order_id: number, 
        events: ServiceEvents = {}) => {
        const queryString = Object.entries(purchaseOrderDetailFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PURCHASE_ORDER_DETAIL.GET_PURCHASE_ORDER_DETAILS_BY_PURCHASE_ORDER}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':purchase_order_id', purchase_order_id.toString()),
            setFetching: setFetchingGetPurchaseOrderDetailsByPurchaseOrder
        });
    };

    const showPurchaseOrderDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.SHOW_PURCHASE_ORDER_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingShowPurchaseOrderDetail
        });
    };

    const getPurchaseOrderDetail = (id: number, service_manifest_detail_id: number, events: ServiceEvents = {}) => {
      
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.GET_PURCHASE_ORDER_DETAIL.replace(':id', id.toString()
            ).replace(':service_manifest_detail_id', service_manifest_detail_id.toString()),
            setFetching: setFetchingGetPurchaseOrderDetail
        });
    };

    const createPurchaseOrderDetail = (item_type: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.CREATE_PURCHASE_ORDER_DETAIL.replace(':item_type', item_type),
            setFetching: setFetchingCreatePurchaseOrderDetail
        });
    };

    const storePurchaseOrderDetail = (purchaseOrderDetail: PurchaseOrderDetailDataForm, events: ServiceEvents = {}) => {
        
        doPost({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.STORE_PURCHASE_ORDER_DETAIL,
            body: purchaseOrderDetail,
            setFetching: setFetchingStorePurchaseOrderDetail
        });
    };

    const editPurchaseOrderDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.EDIT_PURCHASE_ORDER_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingEditPurchaseOrderDetail
        });
    };

    const updatePurchaseOrderDetail = (id: number, purchaseOrderDetail: PurchaseOrderDetailDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.UPDATE_PURCHASE_ORDER_DETAIL.replace(':id', id.toString()),
            body: purchaseOrderDetail,
            setFetching: setFetchingUpdatePurchaseOrderDetail
        });
    };

    const deletePurchaseOrderDetail = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PURCHASE_ORDER_DETAIL.DELETE_PURCHASE_ORDER_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingDeletePurchaseOrderDetail
        });
    };

    const getPurchaseOrderDetailsFinished = (
        filter: PurchaseOrderDetailFilters,
        dispatch_program_id: number,
        events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PURCHASE_ORDER_DETAIL.GET_PURCHASE_ORDER_DETAILS_FINISHED}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':dispatch_program_id', dispatch_program_id.toString()),
            setFetching: setFetchingGetPurchaseOrderDetailsFinished
        });
    };

   

    return {
        fetchingGetPurchaseOrderDetailsByPurchaseOrder,
        fetchingShowPurchaseOrderDetail,
        fetchingGetPurchaseOrderDetail,
        fetchingCreatePurchaseOrderDetail,
        fetchingStorePurchaseOrderDetail,
        fetchingEditPurchaseOrderDetail,
        fetchingUpdatePurchaseOrderDetail,
        fetchingDeletePurchaseOrderDetail,
        fetchingGetPurchaseOrderDetailsFinished,
        getPurchaseOrderDetailsByPurchaseOrder,
        showPurchaseOrderDetail,
        getPurchaseOrderDetail,
        createPurchaseOrderDetail,
        storePurchaseOrderDetail,
        editPurchaseOrderDetail,
        updatePurchaseOrderDetail,
        deletePurchaseOrderDetail,
        getPurchaseOrderDetailsFinished
    };
};

export default usePurchaseOrderDetailService;
