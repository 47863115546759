import React, { useEffect } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Currency } from '../../../../app/models/Currency';
import { TransportationOrderDataForm } from '../../../../app/models/TransportationOrder';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { setCleanInputError } from '../../../../utils/utils';
import { Unit } from '../../../../app/models/Unit';
import {
    RunnerAndTransporterRate,
    TransportationOrderItemDataForm
} from '../../../../app/models/TransportationOrderItem';

interface Props {
    transportationOrderitemForm: any;
    setTransportationOrderItemForm: (
        transportationOrderItem: TransportationOrderItemDataForm
    ) => void;
    currencies: Currency[];
    quantityUnits: Unit[];
    runnerAndTransporterRates?: RunnerAndTransporterRate[];
    errorFields?: any;
    disabledInputRates?: boolean;
}

const TransportationOrderRatesForm = ({
    transportationOrderitemForm,
    setTransportationOrderItemForm,
    currencies,
    quantityUnits,
    runnerAndTransporterRates = [],
    errorFields,
    disabledInputRates = false
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTransportationOrderItemForm({ ...transportationOrderitemForm, [name]: value });
    };

    const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (String(name) == 'rate_runner') {
            const foundCurrency = currencies.find(
                (item) => item.id === transportationOrderitemForm.currency_rate_runner_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setTransportationOrderItemForm({
                    ...transportationOrderitemForm,
                    [name]: value.replace(/\D/g, '')
                });

                return;
            }
        } else if (String(name) == 'rate_transporter') {
            const foundCurrency = currencies.find(
                (item) => item.id === transportationOrderitemForm.currency_rate_transporter_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';

            if (currency === 'CLP') {
                setTransportationOrderItemForm({
                    ...transportationOrderitemForm,
                    [name]: value.replace(/\D/g, '')
                });

                return;
            }
        }

        setTransportationOrderItemForm({ ...transportationOrderitemForm, [name]: value });
    };

    // const handleChangeSelectTwo = (name: string, value: number | string | null) => {
    //     setTransportationOrderForm({ ...transportationOrderForm, [name]: value });
    // };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj: TransportationOrderItemDataForm = { [name]: value };

        if (name == 'currency_rate_runner_id') {
            obj.rate_runner = format_currency(transportationOrderitemForm.rate_runner, value);
        } else if (name == 'currency_rate_transporter_id') {
            obj.rate_transporter = format_currency(
                transportationOrderitemForm.rate_transporter,
                value
            );
        } else if (name == 'runner_transport_rates_id') {
            const rateTransportAndRunner = runnerAndTransporterRates.find(
                (item) => item.id == value
            );
            if (rateTransportAndRunner) {
                obj.rate_runner = rateTransportAndRunner.rate_runner;
                obj.currency_rate_runner_id = rateTransportAndRunner.currency_rate_runner_id;
                obj.quantity_unit_rate_runner_id =
                    rateTransportAndRunner.quantity_unit_rate_runner_id;

                obj.rate_transporter = rateTransportAndRunner.rate_transporter;
                obj.currency_rate_transporter_id =
                    rateTransportAndRunner.currency_rate_transporter_id;
                obj.quantity_unit_rate_transporter_id =
                    rateTransportAndRunner.quantity_unit_rate_transporter_id;
            }
        }
        setTransportationOrderItemForm({ ...transportationOrderitemForm, ...obj });
    };

    const format_currency = (currency_rate: any, value: any) => {
        let rate = String(currency_rate);

        const foundCurrency = currencies.find((item) => item.id === value);
        const currency = foundCurrency ? foundCurrency.name : '';

        if (currency_rate && currency === 'CLP') {
            const indice = rate.search(/\D/);

            if (indice !== -1) {
                rate = rate.substring(0, indice);
            }
        }

        return rate;
    };

    if (!transportationOrderitemForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tarifa Runner-Transportista</label>
                    <SelectTwo
                        name="runner_transport_rates_id"
                        id="runner_transport_rates_id"
                        inputValue={transportationOrderitemForm.runner_transport_rates_id}
                        options={SelectTwoMapperOptions(runnerAndTransporterRates, 'id', ['name'])}
                        hasError={fieldHasError('runner_transport_rates_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('runner_transport_rates_id', value.value)
                        }
                        placeholder={'Seleccione tarifa'}
                        onFocus={() => onFocusRemove('runner_transport_rates_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('runner_transport_rates_id')}
                    </div>
                </div>
            </div>

            <hr />

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa Runner</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="rate_runner"
                                className={`form-control ${fieldHasError('rate_runner')}`}
                                id="rate_runner"
                                onFocus={() => onFocusRemove('rate_runner')}
                                value={transportationOrderitemForm.rate_runner}
                                onChange={handleRateChange}
                                disabled={disabledInputRates}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate_runner')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_rate_runner_id"
                                id="currency_rate_runner_id"
                                inputValue={transportationOrderitemForm.currency_rate_runner_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_rate_runner_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_rate_runner_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_rate_runner_id')}
                                placeholder={'moneda'}
                                disabled={disabledInputRates}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_rate_runner_id')}
                            </div>
                        </div>
                        <div className="col-3">
                            <SelectTwo
                                name="quantity_unit_rate_runner_id"
                                id="quantity_unit_rate_runner_id"
                                inputValue={
                                    transportationOrderitemForm.quantity_unit_rate_runner_id
                                }
                                options={SelectTwoMapperOptions(quantityUnits)}
                                hasError={fieldHasError('quantity_unit_rate_runner_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'quantity_unit_rate_runner_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('quantity_unit_rate_runner_id')}
                                placeholder={'unidad'}
                                disabled={disabledInputRates}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity_unit_rate_runner_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa Transportista</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="rate_transporter"
                                className={`form-control ${fieldHasError('rate_transporter')}`}
                                id="rate_transporter"
                                onFocus={() => onFocusRemove('rate_transporter')}
                                value={transportationOrderitemForm.rate_transporter}
                                onChange={handleRateChange}
                                disabled={disabledInputRates}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate_transporter')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_rate_transporter_id"
                                id="currency_rate_transporter_id"
                                inputValue={
                                    transportationOrderitemForm.currency_rate_transporter_id
                                }
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_rate_transporter_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'currency_rate_transporter_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('currency_rate_transporter_id')}
                                placeholder={'moneda'}
                                disabled={disabledInputRates}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_rate_transporter_id')}
                            </div>
                        </div>
                        <div className="col-3">
                            <SelectTwo
                                name="quantity_unit_rate_transporter_id"
                                id="quantity_unit_rate_transporter_id"
                                inputValue={
                                    transportationOrderitemForm.quantity_unit_rate_transporter_id
                                }
                                options={SelectTwoMapperOptions(quantityUnits)}
                                hasError={fieldHasError('quantity_unit_rate_transporter_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'quantity_unit_rate_transporter_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('quantity_unit_rate_transporter_id')}
                                placeholder={'unidad'}
                                disabled={disabledInputRates}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity_unit_rate_transporter_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransportationOrderRatesForm;
