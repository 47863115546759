import MenuTop from './layouts/MenuTop';
import '../../assets/scss/theme.scss';
import { AppContext } from '../../contexts/AppContext';
import { useContext } from 'react';
import FullScreenLoading from './components/FullScreenLoading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/scss/custom/components/_spinner.scss';
import '../../assets/scss/custom/components/_confirm-alert.scss';
import Sidebar from './layouts/Sidebar';
import Footer from './layouts/Footer';
import SideBarPro from './layouts/SideBarPro';

interface Props {
    children: JSX.Element[] | JSX.Element;
    showLayouts?: boolean;
}

const MainTheme = ({ children, showLayouts = true }: Props) => {
    const { showingLoading, loadingData, hideLoading } = useContext(AppContext);
    return (
        <>
            <div id="confirm-alert-on">{/* acá se carga el alert de forma dinámica */}</div>
            {showingLoading && (
                <FullScreenLoading loadingData={loadingData!} hideLoading={hideLoading!} />
            )}

            {showLayouts ? (
                <>
                    <div id="layout-wrapperd">
                        {
                            <>
                                <SideBarPro />

                                <div className="main-content">
                                    <div className="page-content">
                                        <div className="container-fluid">{children}</div>
                                    </div>
                                </div>
                                <Footer />
                            </>
                        }
                    </div>
                </>
            ) : (
                <div>{children}</div>
            )}
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default MainTheme;
