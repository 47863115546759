import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Bank } from '../../../app/models/Bank';
import BankForm from './forms/BankForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    bank: Bank;
    setBank: (bank: Bank) => void;
    errorFields?: any;
}

const BankFormContainer = ({
    fetching,
    action,
    cancel,
    bank,
    setBank,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BankForm
                        bankDataForm={bank}
                        setBankDataForm={setBank}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default BankFormContainer;
