import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Define las props y sus tipos
interface ActiveControlProps {
    status: boolean;
    id?: number;
    callback: (status: boolean) => void;
}

const ActiveControl: React.FC<ActiveControlProps> = ({ status, id = 1, callback }) => {
    // const [active, setActive] = useState<boolean>(status);

    // useEffect(() => {

    // }, [status]); // Añadir 'status' a la lista de dependencias para que el efecto se ejecute cuando 'status' cambie

    return (
        <div className="square-switch d-flex  form-check form-switch">
            <input
                type="checkbox"
                id={`active_${id}`}
                className={`form-check-input ${status ? 'active' : ''}`}
                checked={status}
                onChange={() => {
                    // setActive(!active);
                    callback(!status);
                }}
            />
            <label
                className="my-auto"
                htmlFor={`active_${id}`}
                data-on-label="Si"
                data-off-label="No"
            />
        </div>
    );
};

export default ActiveControl;

if (document.getElementById('ActiveControl')) {
    ReactDOM.render(
        <ActiveControl status={false} callback={() => console.log('Switch toggled!')} />,
        document.getElementById('ActiveControl')
    );
}
