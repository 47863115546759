import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Currency } from '../../../app/models/Currency';
import CurrencyForm from './forms/CurrencyForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    currency: Currency;
    setCurrency: (currency: Currency) => void;
    errorFields?: any;
}

const CurrencyFormContainer = ({
    fetching,
    action,
    cancel,
    currency,
    setCurrency,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CurrencyForm
                        currencyDataForm={currency}
                        setCurrencyDataForm={setCurrency}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default CurrencyFormContainer;
