import React, { useEffect, useState } from 'react';
import { DispatchProgramDocument } from '../../../../app/models/DispatchProgramDocument';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';

interface DispatchProgramDocumentFormProps {
    fetching: boolean;
    onAction: () => void;
    onCancel: () => void;
    dispatchProgramDocumentId: number;
    dispatchProgramDocument: DispatchProgramDocument[];
    setDispatchProgramDocument: (dispatchProgram: DispatchProgramDocument[]) => void;
    errorFields?: any;
}

const DispatchProgramDocumentForm = ({
    fetching,
    onAction,
    onCancel,
    dispatchProgramDocumentId,
    dispatchProgramDocument,
    setDispatchProgramDocument,
    errorFields
}: DispatchProgramDocumentFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleDeleteDocument = (index: number) => {
        const newDocumentList = [...dispatchProgramDocument];
        newDocumentList.splice(index, 1);
        setDispatchProgramDocument(newDocumentList);
    };

    const handleChangeDocumentFile = (e: FileList | null, index: number) => {
        if (!e) {
            return;
        }

        const newDocument = {
            ...dispatchProgramDocument[index],
            file: e[0],
            dispatch_program_id: dispatchProgramDocumentId
        };

        const newDocumentList = [...dispatchProgramDocument];
        newDocumentList[index] = newDocument;

        setDispatchProgramDocument(newDocumentList);
    };

    if (fetching) {
        return <LazyLoading height="300" />;
    }

    function cancel() {
        onCancel();
    }

    const action = () => {
        onAction();
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={() => setDispatchProgramDocument([...dispatchProgramDocument, {}])}
            >
                Agregar documento
            </button>
            {dispatchProgramDocument.map((_, index) => (
                <div key={index} className="row">
                    <div className="col-md ">
                        <InputButtonFile
                            name={`document${index}`}
                            handleChangeDocumentFile={(e) => handleChangeDocumentFile(e, index)}
                            objectFile={dispatchProgramDocument[index]?.file}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage(`document${index}`)}
                        </div>
                    </div>
                    <div className="col-auto">
                        <span
                            onClick={() => handleDeleteDocument(index)}
                            className="text-danger icon-pointer mx-2"
                        >
                            <i className="mdi mdi-delete font-size-18" />
                        </span>
                    </div>
                </div>
            ))}

            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramDocumentForm;
