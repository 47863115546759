import React from 'react';
import { Truck, TruckFilters } from '../../../app/models/Truck';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';
import TruckFilter from './TruckFilter';

interface Props {
    trucks: Truck[];
    totalRows: number;
    loading?: boolean;
    edit?: (truckId: any) => void;
    destroy?: (truckId: any) => void;
    filter: TruckFilters;
    setFilter: (filter: TruckFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const TruckDataTable = ({
    trucks,
    totalRows,
    loading = false,
    edit,
    destroy,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Truck, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Patente',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'patent',
            cell: (row: Truck, index: any, column: any) => <span className="">{row.patent}</span>
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: Truck, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-md-0 mt-3">
                <div className="row">
                    <div className="col-auto">
                        <TruckFilter filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={trucks}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default TruckDataTable;
