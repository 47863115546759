import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import usePurchaseOrderService from '../../../app/services/hooks/usePurchaseOrderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';

import { AppContext } from '../../../contexts/AppContext';
import { PurchaseOrderStatus, PurchaseorderDataForm, defaultPurchaseOrderDataForm } from '../../../app/models/PurchaseOrder';
import PurchaseOrderShowForm from '../components/forms/PurchaseOrderShowForm';
import PurchaseOrderDetailContainer from '../components/PurchaseOrderDetailContainer';
import useNavigationPage from '../../../hooks/useNavigationPage';


const PurchaseOrderShow = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Ordenes de compra',
            url: '/purchase-orders',
            isActive: false
        },
        {
            name: 'Ver orden de compra #' + id,
            url: '/purchase-orders/' + id + '/show',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        showPurchaseOrder,
        fetchingShowPurchaseOrder
    } = usePurchaseOrderService();

   
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseorderDataForm>(defaultPurchaseOrderDataForm);
  
    useEffect(() => {
        reloadModule();
    }, [id]);

    const show = (id: number) => {
        showPurchaseOrder(id, {
            onSuccess: (response: ServiceResponse) => {
               
                setPurchaseOrder(response.data.purchase_order);
                
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/purchase-orders');
                }
            }
        });

    };

    const reloadModule = () => {
        show(parseInt(id));
    };

    return (
        <>
            <Breadcrumbs pageSection={`Información orden de compra #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingShowPurchaseOrder ? (
                            <>
                                <PurchaseOrderShowForm
                                    purchaseOrderDataForm={purchaseOrder}
                                />
                               
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                {/* <div className="col-md-6">
                    <DefaultCard>
                        <ChangeStatus
                            status={String(purchaseOrder?.status)}
                            statuses={statuses}
                            requestChangeStatus={requestChangeStatus}
                            fetchingChangeStatus={fetchingChangeStatusPurchaseOrder}
                        />
                    </DefaultCard>
                </div> */}
            </div>

            {!fetchingShowPurchaseOrder ? (
                <>
                    <PurchaseOrderDetailContainer
                        purchaseOrderId={parseInt(id)}
                        isShow={true}
                    />
                </>
            ) : null}
        </>
    );
};

export default PurchaseOrderShow;
