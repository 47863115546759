import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { ServiceManifestDataForm } from '../../../../app/models/ServiceManifest';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { DailyCarrier } from '../../../../app/models/DailyCarrier';
import { Provider } from '../../../../app/models/Provider';

interface Props {
    form: ServiceManifestDataForm;
    setForm: (form: ServiceManifestDataForm) => void;
    providers: Provider[];
    errorFields?: any;
    deleteServiceManifest: () => void;
}

const extra_fields_select = {

    provider_id: [
        'has_service_manifest'
    ]
}

const condition_styles = {

    has_service_manifest: {
        
        key: 'has_service_manifest',
        properties: {
            option: {
                color: {
                    normal: 'brown',
                    hover: '',
                }, 
                background: {
                    normal: '',
                    hover: ''
                },
                fontWeight:{
                    normal: '',
                    hover: ''
                } 
            }
        }
    }
};

const ServiceManifestForm = (
    { 
        form, 
        setForm, 
        providers, 
        errorFields,
        deleteServiceManifest
    }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setForm({ ...form, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if(name === 'provider_id'){

            if(value === undefined){
                obj.total_guide = 0;
                obj.provider = '';
            }
            else{

                const provider = providers.find((provider: Provider) => provider.id === value);

                if(provider){

                    obj.total_guide = provider.total_guide ?? 0;
                    obj.provider = provider.name ?? '';
                }

            }
            setErrors({
                ...errors,
                total_guide:''
            });
        }
        
        setForm({ ...form, ...obj });
    };

    

    return (
        <>
            <div className="mb-2 row">
                
                <div className="col-md-6">
                    <label className="col-form-label">Fecha de emisión</label>
                    <input
                        type="date"
                        className={`form-control  ${fieldHasError('issue_date')}`}
                        name="issue_date"
                        id="issue_date"
                        value={form.issue_date}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de emisión"
                        onFocus={() => onFocusRemove('issue_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
                
            </div>
            <div className="mb-2 row ">
                <div className="col-md-6">
                    <label className="col-form-label">Transportista</label>
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={form.provider_id}
                        options={SelectTwoMapperOptions(
                            providers, 
                            'id', 
                            ['name'], 
                            extra_fields_select.provider_id, 
                            condition_styles.has_service_manifest
                        )}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('provider_id', value?.value)
                        }
                        placeholder={'Seleccione transportista'}
                        onFocus={() => onFocusRemove('provider_id')}
                        isClearable={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                    {
                        form.provider_id &&

                        <>
                            <div className="mb-2 row">
                    
                                <div className="col-md-12">
                                    <label className="col-form-label">Cantidad guías finalizadas</label>
                                    <input
                                        name="total_guide"
                                        id="total_guide"
                                        type="text"
                                        className="form-control"
                                        value={form.total_guide}
                                        readOnly={true}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('total_guide')}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="d-flex mt-2">
                                <button className="btn btn-danger btn-sm" onClick={deleteServiceManifest}>
                                    <i className="fa fa-trash" /> Eliminar hoja de entrada transportista
                                </button>
                            </div>
                        </>
                    }
                    
                </div>
            </div>

           
        </>
    );
};

export default ServiceManifestForm;
