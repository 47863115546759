import React from 'react';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
};

const UserFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="rut">
                            Rut
                        </label>
                        <input
                            title="Rut"
                            type="text"
                            id="rut"
                            name="rut"
                            className="form-control form-control-sm"
                            value={filter.rut}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="first_name">
                            Nombre
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-control form-control-sm"
                            value={filter.first_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="last_name">
                            Apellido paterno
                        </label>
                        <input
                            title="Apellido paterno"
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-control form-control-sm"
                            value={filter.last_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="second_last_name">
                            Apellido materno
                        </label>
                        <input
                            title="Apellido materno"
                            type="text"
                            id="second_last_name"
                            name="second_last_name"
                            className="form-control form-control-sm"
                            value={filter.second_last_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserFilter;
