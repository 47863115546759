export interface ProviderType {
    id?: number;
    name: string;
}

export const defaultProviderType: ProviderType = {
    name: ''
};

export interface ProviderTypeFilters {
    name?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
