import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Lottie from 'lottie-react';
import { getAnimationIcon } from '../utils/utils';

const targetId = 'confirm-alert-on';

interface ButtonProps {
    confirmButton?: any;
    cancelButton?: any;
    updateButton?: any;
}

interface RequestConfirmationProps {
    title: string;
    message: string
    message_list?: string[];
    buttons?: ButtonProps;
    onClickOutsideFunction?: () => void;
}

interface AlertNotificationTypeProps {
    title: string;
    message: string;
    confirmButtonText?: string;
    icon?: string;
    message_list?: string[];
}

const addBodyClass = () => {
    const confirmAlertOne = document.getElementById('confirm-alert-on');
    confirmAlertOne?.classList.add('confirm-alert-all');
    // document.body.classList.add('react-confirm-alert-body-element');
};

const removeBodyClass = () => {
    const confirmAlertOne = document.getElementById('confirm-alert-on');
    confirmAlertOne?.classList.remove('confirm-alert-all');
    // document.body.classList.remove('react-confirm-alert-body-element');
};

const useReactConfirmAlert = () => {
    const requestConfirmation = ({
        title = '¿Estás seguro?',
        message = 'Confirme para continuar',
        message_list = [],
        buttons = {
            confirmButton: {
                label: 'Si',
                onClick: () => {
                    return;
                }
            },

            cancelButton: {
                label: 'No',
                onClick: () => {
                    return;
                }
            }
        },
        onClickOutsideFunction
    }: RequestConfirmationProps) => {
        addBodyClass();

        confirmAlert({
            title: title,
            message: message,
            buttons: [buttons.cancelButton, buttons.confirmButton],
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <Lottie
                            animationData={getAnimationIcon('warning')}
                            loop={true}
                            style={{ width: '100px', height: '120px', margin: '0 auto' }}
                        />
                        <h1>{title}</h1>
                        <div className="text-alert">
                            <p>{message}</p>
                        </div>
                        {message_list.length > 0 && (
                            <div className="text-start">
                                {message_list.map((message, index) => (
                                    <li key={index}>{message}</li>
                                ))}
                            </div>
                        )}
                        <div className="react-confirm-alert-button-group">
                            <button
                                className="btn btn-light"
                                onClick={() => {
                                    // Coloca aquí la lógica para manejar el "Yes" (por ejemplo, this.handleClickDelete())
                                    buttons.cancelButton.onClick();
                                    onClose();
                                }}
                            >
                                {buttons.cancelButton.label}
                            </button>
                            <div className="mx-1"></div>
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    // Coloca aquí la lógica para manejar el "Yes" (por ejemplo, this.handleClickDelete())
                                    buttons.confirmButton.onClick();
                                    onClose();
                                }}
                            >
                                Si, aceptar
                            </button>
                        </div>
                    </div>
                );
            },
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {
                removeBodyClass();
            },
            onClickOutside: () => {
                if (onClickOutsideFunction) {
                    onClickOutsideFunction();
                }
                removeBodyClass();
            },
            onkeyPress: () => {
                return;
            },
            onKeypressEscape: () => {
                return;
            },
            overlayClassName: '',
            targetId: targetId
        });
    };

    const alertNotification = ({
        title,
        message,
        confirmButtonText = 'Ok',
        icon = 'success',
        message_list = []
    }: AlertNotificationTypeProps) => {
        addBodyClass();
        confirmAlert({
            title: title,
            message: message,

            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <Lottie
                            animationData={getAnimationIcon(icon)}
                            loop={false}
                            style={{ width: '100px', height: '120px', margin: '0 auto' }}
                        />
                        <h1>{title}</h1>
                        <div className="text-alert mb-4">
                            <p>{message}</p>
                        </div>
                        {message_list.length > 0 && (
                            <div className="text-start">
                                {message_list.map((message, index) => (
                                    <li key={index}>{message}</li>
                                ))}
                            </div>
                        )}

                        <div className="react-confirm-alert-button-group">
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {confirmButtonText}
                            </button>
                        </div>
                    </div>
                );
            },
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {
                removeBodyClass();
            },
            onClickOutside: () => {
                removeBodyClass();
            },
            onkeyPress: () => {
                return;
            },
            onKeypressEscape: () => {
                return;
            },
            overlayClassName: '',
            targetId: targetId
        });
    };

    const requestConfirmationMulti = ({
        title = '¿Estás seguro?',
        message = 'Confirme para continuar',
        buttons = {
            confirmButton: {
                label: 'agregar',
                onClick: () => {
                    return;
                }
            },
            updateButton: {
                label: 'actualizar',
                onClick: () => {
                    return;
                }
            },

            cancelButton: {
                label: 'cancelar',
                onClick: () => {
                    return;
                }
            }
        }
    }: RequestConfirmationProps) => {
        addBodyClass();

        confirmAlert({
            title: title,
            message: message,
            buttons: [buttons.cancelButton, buttons.confirmButton],
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <Lottie
                            animationData={getAnimationIcon('warning')}
                            loop={true}
                            style={{ width: '100px', height: '120px', margin: '0 auto' }}
                        />
                        <h1>{title}</h1>
                        <div className="text-alert">
                            <p>{message}</p>
                        </div>
                        <div className="react-confirm-alert-button-group">
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    buttons.confirmButton.onClick();
                                    onClose();
                                }}
                            >
                                Si, aceptar
                            </button>
                            <div className="mx-1"></div>
                            <button
                                className="btn btn-warning"
                                onClick={() => {
                                    buttons.updateButton.onClick();
                                    onClose();
                                }}
                            >
                                Si, aceptar
                            </button>
                            <div className="mx-1"></div>
                            <button
                                className="btn btn-light"
                                onClick={() => {
                                    buttons.cancelButton.onClick();
                                    onClose();
                                }}
                            >
                                {buttons.cancelButton.label}
                            </button>
                        </div>
                    </div>
                );
            },
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {
                removeBodyClass();
            },
            onClickOutside: () => {
                removeBodyClass();
            },
            onkeyPress: () => {
                return;
            },
            onKeypressEscape: () => {
                return;
            },
            overlayClassName: '',
            targetId: targetId
        });
    };

    const successAlert = ({
        title,
        message,
        confirmButtonText = 'Ok',
        message_list = []
    }: AlertNotificationTypeProps) => {
        alertNotification({
            title: title,
            message: message,
            confirmButtonText: confirmButtonText,
            icon: 'success',
            message_list: message_list
        });
    };

    const infoAlert = ({
        title,
        message,
        confirmButtonText = 'Ok'
    }: AlertNotificationTypeProps) => {
        alertNotification({
            title: title,
            message: message,
            confirmButtonText: confirmButtonText,
            icon: 'info'
        });
    };

    const warningAlert = ({
        title,
        message,
        confirmButtonText = 'Ok'
    }: AlertNotificationTypeProps) => {
        alertNotification({
            title: title,
            message: message,
            confirmButtonText: confirmButtonText,
            icon: 'warning'
        });
    };

    const errorAlert = ({
        title,
        message,
        confirmButtonText = 'Ok',
        message_list = []
    }: AlertNotificationTypeProps) => {
        alertNotification({
            title: title,
            message: message,
            confirmButtonText: confirmButtonText,
            icon: 'error',
            message_list: message_list
        });
    };

    return {
        requestConfirmation,
        requestConfirmationMulti,
        successAlert,
        infoAlert,
        warningAlert,
        errorAlert
    };
};

export default useReactConfirmAlert;
