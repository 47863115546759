import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useBankAccountClientService from '../../../app/services/hooks/useBankAccountClientService';
import { BankAccountClientDataForm, BankAccountClientFilters } from '../../../app/models/BankAccountClient';
import DefaultToolBar from '../../../components/default/DefaultToolBar';

import BankAccountClientCreate from './BankAccountClientCreate';
import BankAccountClientEdit from './BankAccountClientEdit';
import BankAccountClientDataTable from './tables/BankAccountClientDataTable';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import BusinessNameBankAccountDataTable from '../../BusinessName/components/tables/BusinessNameBankAccountDataTable';
import { BusinessNameBankAccountDataForm } from '../../../app/models/BusinessNameBankAccount';

interface Props {
    clientId: number;
    isShow?: boolean;
}

const BankAccountClientContainer = ({ clientId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllBusinessNameBankAccountsByClient,
        fetchingGetAllBusinessNameBankAccountsByClient
    } = useBusinessNameBankAccountService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [bankAccountClientIdEdit, setBankAccountClientIdEdit] = useState<number>(-1);
    const [bankAccountClientIdShow, setBankAccountClientIdShow] = useState<number>(-1);
    
    const [bankAccountClients, setBankAccountClients] = useState<BusinessNameBankAccountDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BankAccountClientFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (bankAccountClientId: number) => {
        setShowingEdit(true);
        setBankAccountClientIdEdit(bankAccountClientId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setBankAccountClientIdEdit(-1);
    };

    const showShow = (bankAccountClientId: number) => {
       
        setShowingShow(true);
        setBankAccountClientIdShow(bankAccountClientId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setBankAccountClientIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

  
    
    const reloadTable = () => {
        getAllBusinessNameBankAccountsByClient(filter, clientId, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccountClients(response.data.bank_accounts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Cuentas bancarias</div>
                <div className="row">
                    
                    <div className="col-12">
                        <BusinessNameBankAccountDataTable
                            businessNamebankAccounts={bankAccountClients}
                            loading={fetchingGetAllBusinessNameBankAccountsByClient}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default BankAccountClientContainer;
