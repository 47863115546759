import React, { useEffect, useState } from 'react';
import { ProviderContactDataForm } from '../../../../app/models/ProviderContact';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { ContactType } from '../../../../app/models/ContactType';


type Props = {
    providerContactDataForm: ProviderContactDataForm;
    setProviderContactDataForm: (provider: ProviderContactDataForm) => void;
    contactTypes: ContactType[];
    errorFields?: any;
};

const ProviderContactForm = ({ providerContactDataForm, setProviderContactDataForm, contactTypes, errorFields }: Props) => {
    
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
       
        setProviderContactDataForm({ ...providerContactDataForm, [name]: value });
        
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setProviderContactDataForm({ ...providerContactDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-form-label">Nombre</label>
                <div className="col-md-12">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={providerContactDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre contacto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-form-label">Email</label>
                <div className="col-md-12">
                    <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={providerContactDataForm.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de contacto</label>
                    <SelectTwo
                        name="contact_type_id"
                        id="contact_type_id"
                        inputValue={providerContactDataForm.contact_type_id}
                        options={SelectTwoMapperOptions(contactTypes, 'id', ['name'])}
                        hasError={fieldHasError('contact_type_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('contact_type_id', value.value)}
                        placeholder={'Seleccione tipo contacto'}
                        onFocus={() => onFocusRemove('contact_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('contact_type_id')}
                    </div>
                </div>
            </div>

        </>
    );
};

export default ProviderContactForm;
