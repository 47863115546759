import React, { useState } from 'react';

interface Props {
    changeStatus: (
        value: string,
        massiveChange: boolean,
        setSelected: (status: string) => void
    ) => void;
    statuses: any[];
    className?: string;
    massive_change?: boolean;
    default_option?: string;
}

const ChangeStatusRows = ({
    changeStatus,
    statuses,
    className = '',
    massive_change = false,
    default_option = 'Seleccione estado'
}: Props) => {
    const [selected, setSelected] = useState<string>('');

    return (
        <select
            className={`form-control ${className}`}
            value={selected}
            onChange={(e) => changeStatus(e.target.value, massive_change, setSelected)}
        >
            <option key={''} value="">
                {default_option}
            </option>
            {statuses.map((item, key) => (
                <option key={item.value} value={item.value}>
                    {item.label}
                </option>
            ))}
        </select>
    );
};

export default ChangeStatusRows;
