import { Place } from './Place';
import { Product } from './Product';
import { Unit } from './Unit';
import { Currency } from './Currency';
import { TransportationOrderItemStatus } from './TransportationOrderItemStatus';

export interface TransportationOrderItemDataForm {
    transportation_order_id?: number;
    id?: number;
    description?: string;
    service?: string;
    service_id?: number;
    status?: string;
    status_choices?: TransportationOrderItemStatus[];
    status_selected?: TransportationOrderItemStatus[];
    dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number;
    rate?: number;
    destiny_id?: number;
    destiny?: Place;
    origin_id?: number;
    origin?: Place;
    product_id?: number;
    product?: Product;
    quantity_unit_id?: number;
    quantity_unit?: Unit;
    currency_id?: number | string;
    currency?: Currency;
    currency_name?: string;
    created_at?: Date | string;
    updated_at?: Date | string;
    quantity_available?: number;
    quantity_used?: number;
    missing_dispatch_quantity?: number;

    rate_runner?: number | string;
    currency_rate_runner_id?: number | string;
    quantity_unit_rate_runner_id?: number | string;
    rate_transporter?: number | string;
    currency_rate_transporter_id?: number | string;
    quantity_unit_rate_transporter_id?: number | string;

    route_id?: number;
    agreed_distance?: number;
    can_update_rates?: number;
    runner_transport_rates?: RunnerAndTransporterRate;
    runner_transport_rates_id?: number;
}

export interface TransportationOrderItemsFilters {
    transportation_order_id?: number;
    id?: number;
    description?: string;
    service?: string;
    service_id?: number;
    status?: string;
    status_choices?: TransportationOrderItemStatus[];
    status_selected?: TransportationOrderItemStatus[];
    dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number;
    rate?: number;
    destiny_id?: number;
    destiny?: Place;
    origin_id?: number;
    origin?: Place;
    product_id?: number;
    product?: Product;
    quantity_unit_id?: number;
    quantity_unit?: Unit;
    currency_id?: number | string;
    currency?: Currency;
    currency_name?: string;
    created_at?: Date | string;
    updated_at?: Date | string;
    quantity_available?: number;
    quantity_used?: number;
    missing_dispatch_quantity?: number;
    rate_runner?: number | string;
    currency_rate_runner_id?: number | string;
    quantity_unit_rate_runner_id?: number | string;
    rate_transporter?: number | string;
    currency_rate_transporter_id?: number | string;
    quantity_unit_rate_transporter_id?: number | string;
    route_id?: number;
    agreed_distance?: number;
    can_update_rates?: number;
    runner_transport_rates?: RunnerAndTransporterRate;
    runner_transport_rates_id?: number;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

// export interface TransportationOrderItemRatesDataForm{

//     rate_runner?: number | string;
//     currency_rate_runner_id?:number | string;
//     quantity_unit_rate_runner_id?:number | string;
//     rate_transporter?:number | string;
//     currency_rate_transporter_id?:number | string;
//     quantity_unit_rate_transporter_id?:number | string;

// }

export interface TransportationOrderItemCreateDataForm {
    quantity?: number;
    rate?: number;

    quantity_unit_id?: number;
    currency_id?: number | string;

    rate_runner?: number | string;
    currency_rate_runner_id?: number | string;
    quantity_unit_rate_runner_id?: number | string;
    rate_transporter?: number | string;
    currency_rate_transporter_id?: number | string;
    quantity_unit_rate_transporter_id?: number | string;

    route_id?: number;
    agreed_distance?: number;
}

export interface TransportationOrderItemShowDataForm {
    // quote_id?: number;
    id?: number;
    description?: string;
    dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number | string;
    quantity_unit?: number | string;
    rate?: number | string;
    service?: string;
    currency?: string;
    destiny?: number | string;
    origin?: number | string;
    created_at?: string;
    updated_at?: string;

    route_segment_id?: number;
    quote_route_segment_id?: number;
    agreed_distance?: number;

    rate_runner?: number;
    currency_rate_runner?: string;
    quantity_unit_rate_runner?: string;
    rate_transporter?: number;
    currency_rate_transporter?: string;
    quantity_unit_rate_transporter?: string;
}

export interface SplitOrderItem {
    quantity: number;
    dispatch_date: string;
}

export interface SplitDataForm {
    transportation_order_item_id: number;
    transportation_sub_order_items: SplitOrderItem[];
}

export const defaultTransportationOrderItemDataForm: TransportationOrderItemDataForm = {
    transportation_order_id: undefined,
    id: undefined,
    description: '',
    service: '',
    service_id: 0,
    dispatch_date: '',
    product_name: '',
    product_rate: 0,
    product_unit: '',
    quantity: 0,
    rate: 0,
    destiny_id: 0,
    destiny: undefined,
    origin_id: 0,
    origin: undefined,
    product_id: 0,
    product: undefined,
    quantity_unit_id: 0,
    quantity_unit: undefined,
    currency_id: undefined,
    currency: undefined,
    currency_name: '',
    status: '',
    created_at: '',
    updated_at: '',
    status_selected: undefined,
    agreed_distance: 0,

    rate_runner: 0,
    rate_transporter: 0
};

export const defaultTransportationOrderCreateItemDataForm: TransportationOrderItemCreateDataForm = {
    rate: 0,
    quantity_unit_id: 0,
    currency_id: 0,
    rate_runner: 0,
    rate_transporter: 0
};

export const defaultTransportationOrderItemEditDataForm: TransportationOrderItemDataForm = {
    rate: 0,
    quantity_unit_id: 0,
    currency_id: 0
};

export const defaultTransportationOrderItemRatesDataForm: TransportationOrderItemDataForm = {
    rate_runner: 0,
    rate_transporter: 0
};

export interface RunnerAndTransporterRate {
    id?: number;
    name?: string;
    rate_runner?: number;
    currency_rate_runner_id?: number;
    quantity_unit_rate_runner_id?: number;
    rate_transporter?: number;
    currency_rate_transporter_id?: number;
    quantity_unit_rate_transporter_id?: number;
}
