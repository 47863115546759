import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { SectorDataForm } from '../../../app/models/Sector';
import SectorForm from './forms/SectorForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    sector: SectorDataForm;
    setSector: (sector: SectorDataForm) => void;
    errorFields?: any;
}

const SectorFormContainer = ({
    fetching,
    action,
    cancel,
    sector,
    setSector,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <SectorForm
                        sectorDataForm={sector}
                        setSectorDataForm={setSector}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default SectorFormContainer;
