import React, { useEffect, useState } from 'react';
import { UserDataForm } from '../../../../app/models/User';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { nationalityOptions } from '../../../../utils/utils';
import useRut from '../../../../hooks/useRut';
import default_avatar from '../../../../assets/images/default-avatar.png';
import { Group } from '../../../../app/models/Group';

interface GeneralUserProps {
    userForm: UserDataForm;
    setUserForm: (user: UserDataForm) => void;
    groups?: Group[];
    errorFields?: any;
    isCreate?: boolean;
    isProfile?: boolean;
    isDisabled?: boolean;
}

const UserForm = ({
    userForm,
    setUserForm,
    groups,
    errorFields,
    isCreate = false,
    isProfile = false,
    isDisabled = false
}: GeneralUserProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();
    const [image, setImage] = useState(userForm.avatar ? userForm.avatar : default_avatar);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const dataOnChangeImages = (e: any) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            setImage(URL.createObjectURL(selectedFile));
            setUserForm({
                ...userForm,
                [e.target.name]: selectedFile
            });
        }
    };

    const removeImage = () => {
        setImage(default_avatar); // Limpiar el estado de la imagen
        setUserForm({
            ...userForm,
            avatar: undefined // Limpiar la propiedad avatar en el estado del formulario
        });
        const fileInput = document.getElementById('avatar') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            event.target = event.target as HTMLInputElement;
            setUserForm({ ...userForm, [name]: event.target.checked });
        } else {
            if (name == 'rut') {
                setUserForm({ ...userForm, [name]: formatRut(value) });
            } else {
                // si name contiene un punto quiere decir que hay que cambiar un objeto dentro del objeto
                if (name.includes('.')) {
                    const [first, second] = name.split('.');
                    setUserForm({
                        ...userForm,
                        [first]: { ...userForm, [second]: value }
                    });
                } else {
                    setUserForm({ ...userForm, [name]: value });
                }
            }
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setUserForm({ ...userForm, ...obj });
    };

    if (!userForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12 text-center d-flex">
                    <div className="mx-auto mb-3">
                        <label htmlFor="avatar">
                            <img
                                src={
                                    typeof image === 'string' && image !== ''
                                        ? image
                                        : window.URL.createObjectURL(image as Blob)
                                }
                                className="rounded-circle pointer bg-white"
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    objectFit: 'cover',
                                    border: '2px solid #ccc'
                                }}
                            />
                        </label>
                    </div>
                    <input
                        type="file"
                        className="custom-file-input"
                        style={{ height: '0px', width: '0px' }}
                        id="avatar"
                        name="avatar"
                        onChange={dataOnChangeImages}
                    />
                </div>
                {userForm.avatar && (
                    <div className="text-center mb-2">
                        <button
                            type="button"
                            onClick={removeImage}
                            className="btn btn-sm btn-danger"
                        >
                            Remover imagen
                        </button>
                        {/* <span onClick={removeImage} className="icon-pointer" title="Eliminar">
                    <i className="text-danger mdi mdi-delete font-size-24"></i>
                </span> */}
                    </div>
                )}
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('avatar')}
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">RUT</label>
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        value={userForm.rut}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT"
                        disabled={isDisabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        className={`form-control ${fieldHasError('first_name')}`}
                        value={userForm.first_name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('first_name')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('first_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Paterno</label>
                    <input
                        name="last_name"
                        id="last_name"
                        type="text"
                        className={`form-control ${fieldHasError('last_name')}`}
                        value={userForm.last_name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('last_name')}
                        placeholder="Ingrese el apellido paterno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('last_name')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Materno</label>

                    <input
                        name="second_last_name"
                        id="second_last_name"
                        type="text"
                        className={`form-control ${fieldHasError('second_last_name')}`}
                        value={userForm.second_last_name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('second_last_name')}
                        placeholder="Ingrese el apellido materno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('second_last_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className={`col-md-${isCreate ? '6' : '12'}`}>
                    <label className="col-form-label">Email</label>

                    <input
                        name="email"
                        id="email"
                        type="email"
                        className={`form-control ${fieldHasError('email')}`}
                        value={userForm.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese el email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
                {isCreate && (
                    <div className="col-md-6">
                        <label className="col-form-label">Contraseña</label>
                        <input
                            name="password"
                            id="password"
                            type="password"
                            className={`form-control ${fieldHasError('password')}`}
                            value={userForm.password}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('password')}
                            placeholder="Ingrese la contraseña"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('password')}
                        </div>
                    </div>
                )}
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-6">
                    <label className="col-form-label">Nacionalidad</label>
                    <SelectTwo
                        name="nationality"
                        id="nationality"
                        inputValue={userForm.nationality}
                        options={SelectTwoMapperOptions(nationalityOptions, 'value', ['label'])}
                        hasError={fieldHasError('nationality') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('nationality', value?.value)
                        }
                        placeholder={'Seleccione Nacionalidad'}
                        onFocus={() => onFocusRemove('nationality')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('nationality')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Sexo</label>
                    <SelectTwo
                        name="gender"
                        id="gender"
                        inputValue={userForm.gender}
                        options={SelectTwoMapperOptions([
                            { id: 'MASCULINO', name: 'Masculino' },
                            { id: 'FEMENINO', name: 'Femenino' }
                        ])}
                        hasError={fieldHasError('gender') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('gender', value?.value)}
                        placeholder={'Seleccione sexo'}
                        onFocus={() => onFocusRemove('gender')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('gender')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6 ">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                onChange={handleChange}
                                value={userForm.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option defaultValue={'+56'} value="+56">
                                    +56
                                </option>
                            </select>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                className="form-control"
                                value={userForm.phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Fecha de Nacimiento</label>
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('birthdate')}`}
                        name="birthdate"
                        id="birthdate"
                        value={userForm.birthdate}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de despacho"
                        onFocus={() => onFocusRemove('birthdate')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('birthdate')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Dirección </label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className={`form-control ${fieldHasError('address')}`}
                        value={userForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                {isProfile ? (
                    <div>
                        <label className="col-form-label">Roles</label>

                        {userForm.groups && userForm.groups?.length > 0 ? (
                            <div className="fs-4">
                                {userForm.groups.map((group: Group) => (
                                    <span key={group.id} className="badge bg-info mx-1">
                                        {group.name}
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <div className="fs-5">No tiene roles asignados.</div>
                        )}
                    </div>
                ) : (
                    <div className="col-md-6">
                        <label className="col-form-label">Rol</label>
                        <SelectTwo
                            name="groups"
                            id="groups"
                            inputValue={userForm.groups}
                            options={SelectTwoMapperOptions(groups!)}
                            hasError={fieldHasError('groups') !== ''}
                            onChange={(value: any) =>
                                setUserForm({
                                    ...userForm,
                                    groups: value
                                })
                            }
                            isMulti={true}
                            placeholder={'Seleccione rol'}
                            onFocus={() => onFocusRemove('groups')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('groups')}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserForm;
