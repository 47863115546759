import React, { useEffect } from 'react';
import { setCleanInputError } from '../../../../utils/utils';
import { Guide } from '../../../../app/models/DispatchProgramItem';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { ServiceManifestDetailDataForm } from '../../../../app/models/ServiceManifestDetail';

interface DispatchProgramItemProps {
    setGuide: (guide: Guide | ServiceManifestDetailDataForm) => void;
    guide: Guide;
    errorFields?: any;
    labelDate?: string;
}

const DispatchProgramItemGuideForm = ({
    setGuide,
    guide,
    errorFields,
    labelDate="Fecha Real Despacho"
}: DispatchProgramItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setGuide({ ...guide, ['file']: e[0] });
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
       
        setGuide({ ...guide, [name]: value });
    };

    const handleRemoveFile = (name: string) => {
        setGuide({
            ...guide,
            [name]: undefined
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };
    
    useEffect(() => {
        console.log(guide.file)
    },[guide.file])

    return (
        <>
            <h5 className="mb-3">Datos Guia</h5>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Archivo</label>
                <div className="col-md-9">
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        handleRemoveFile={(value: any) => handleRemoveFile('file')}
                        name={'file'}
                        objectFile={guide?.file}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
                <label className="col-md-3 col-form-label">Folio Guia</label>
                <div className="col-md-9">
                    <input
                        className={`form-control ${fieldHasError('folio')}`}
                        name="folio"
                        id="folio"
                        type="text"
                        value={guide.folio}
                        onChange={handleChange}
                        placeholder="Ingrese el folio de la guia de despacho"
                        onFocus={() => onFocusRemove('folio')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('folio')}
                    </div>
                </div>
                <label className="col-md-3 col-form-label">Neto Guia</label>
                <div className="col-md-9">
                    <input
                        className={`form-control ${fieldHasError('total')}`}
                        name="total"
                        id="total"
                        type="number"
                        value={guide.total}
                        onChange={handleChange}
                        placeholder="Ingrese el total neto de la guia de despacho"
                        onFocus={() => onFocusRemove('total')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('total')}
                    </div>
                </div>
                <label className="col-md-3 col-form-label">Tara Guia</label>
                <div className="col-md-9">
                    <input
                        className={`form-control ${fieldHasError('tara_total')}`}
                        name="tara_total"
                        id="tara_total"
                        type="number"
                        value={guide.tara_total}
                        onChange={handleChange}
                        placeholder="Ingrese la tara de la guia de despacho"
                        onFocus={() => onFocusRemove('tara_total')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('tara_total')}
                    </div>
                </div>
                <label className="col-md-3 col-form-label">{labelDate}</label>
                <div className="col-md-9">
                    <input
                        className={`form-control ${fieldHasError('actual_dispatch_date')}`}
                        name="actual_dispatch_date"
                        id="actual_dispatch_date"
                        type="date"
                        value={guide.actual_dispatch_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('actual_dispatch_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('actual_dispatch_date')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemGuideForm;
