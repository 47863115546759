import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import useSocietyProviderService from '../../../app/services/hooks/useSocietyProviderService';
import { SocietyProviderDataForm, SocietyProviderFilters } from '../../../app/models/SocietyProvider';
import SocietyProviderCreate from './SocietyProviderCreate';
import SocietyProviderEdit from './SocietyProviderEdit';
import SocietyProviderDataTable from './tables/SocietyProviderDataTable';

interface Props {
    societyId: number;
    isShow?: boolean;
}

const SocietyProviderContainer = ({ societyId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getSocietyProvidersBySociety,
        fetchingGetSocietyProvidersBySociety,
        deleteSocietyProvider,
        fetchingDeleteSocietyProvider,
    } = useSocietyProviderService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [societyProviderIdEdit, setSocietyProviderIdEdit] = useState<number>(-1);
    const [societyProviderIdShow, setSocietyProviderIdShow] = useState<number>(-1);
    
    const [societyProviders, setSocietyProviders] = useState<SocietyProviderDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<SocietyProviderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (societyProviderId: number) => {
        setShowingEdit(true);
        setSocietyProviderIdEdit(societyProviderId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setSocietyProviderIdEdit(-1);
    };

    const showShow = (societyProviderId: number) => {
       
        setShowingShow(true);
        setSocietyProviderIdShow(societyProviderId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setSocietyProviderIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

  
    const destroy = (societyProviderId: number) => {
        const _text = 'Está a punto de eliminar el proveedor #' + societyProviderId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando proveedor...');
                        deleteSocietyProvider(societyProviderId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El proveedor no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const reloadTable = () => {
        getSocietyProvidersBySociety(filter, societyId, {
            onSuccess: (response: ServiceResponse) => {
                setSocietyProviders(response.data.society_providers);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Proveedores</div>
                <div className="row">
                    <div className="col-12">
                        {!isShow ? (
                            <DefaultToolBar left={<ButtonCreate callbackCreate={showCreate} />} />
                        ) : null}
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <SocietyProviderDataTable
                                societyProviders={societyProviders}
                                loading={fetchingGetSocietyProvidersBySociety}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                            />
                        ) : (
                            <SocietyProviderDataTable
                                societyProviders={societyProviders}
                                edit={showEdit}
                                destroy={destroy}
                                loading={fetchingGetSocietyProvidersBySociety}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Agregar Proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyProviderCreate
                        societyId={societyId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title='Editar información Proveedor'
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyProviderEdit
                        societyId={societyId}
                        societyProviderId={societyProviderIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        
                        onCancel={hideEdit}
                        onError={hideEdit}
                        
                    />
                </DefaultModal>
            ) : null} 

            {/* {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title='Ver información Proveedor'
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyProviderShow
                        societyProviderId={societyProviderIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}  */}
        </>
    );
};

export default SocietyProviderContainer;
