import React, { useContext, useEffect, useState } from 'react';

import { defaultProviderType, ProviderType } from '../../../app/models/ProviderType';
import useProviderTypeService from '../../../app/services/hooks/useProviderTypeService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ProviderTypeFormContainer from './ProviderTypeFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderTypeCreate = ({ onSaved, onCancel, onError }: Props) => {
    const {
        fetchingStoreProviderType,
        storeProviderType,
        createProviderType,
        fetchingCreateProviderType
    } = useProviderTypeService();
    const [providerType, setProviderType] = useState<ProviderType>(defaultProviderType);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);


    const [errorFields, setErrorFields] = useState<any>();


    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createProviderType({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        storeProviderType(providerType, {
            onSuccess: (response: ServiceResponse) => {
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: () => {
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateProviderType ? (
        <LazyLoading height={300} />
    ) : (
        <ProviderTypeFormContainer
            fetching={fetchingStoreProviderType}
            action={store}
            cancel={cancel}
            providerType={providerType}
            setProviderType={setProviderType}
            errorFields={errorFields}
        />
    );
};

export default ProviderTypeCreate;
