import React from 'react';
import { PaymentStatusFilters } from '../../../../app/models/PaymentStatus';

type Props = {
    filter: PaymentStatusFilters;
    setFilter: (filter: PaymentStatusFilters) => void;
};

const PaymentStatusFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            {/* primera fila */}
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="dispatch_program_id">
                            Despacho
                        </label>
                        <input
                            title="Despacho"
                            type="number"
                            id="dispatch_program_id"
                            name="dispatch_program_id"
                            className="form-control form-control-sm"
                            value={filter.dispatch_program_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatusFilter;
