import React, { useContext, useEffect, useState } from 'react';
import { DailyCarrier, defaultDailyCarrier } from '../../../app/models/DailyCarrier';
import useDailyCarrierService from '../../../app/services/hooks/useDailyCarrierService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import DailyCarrierFormContainer from './DailyCarrierFormContainer';
import { Truck } from '../../../app/models/Truck';
import { Carrier } from '../../../app/models/Carrier';
import { Provider } from '../../../app/models/Provider';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    dailyCarrierId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DailyCarrierEdit = ({ dailyCarrierId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditDailyCarrier,
        editDailyCarrier,
        fetchingUpdateDailyCarrier,
        updateDailyCarrier
    } = useDailyCarrierService();

    const [dailyCarrier, setDailyCarrier] = useState<DailyCarrier>(defaultDailyCarrier);
    const [errorFields, setErrorFields] = useState<any>();
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [hoppers, setHoppers] = useState<Carrier[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando transporte...');
        editDailyCarrier(dailyCarrierId, {
            onSuccess: (response: ServiceResponse) => {
                setDailyCarrier(response.data.daily_carrier);
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHoppers(response.data.hoppers);
                setProviders(response.data.providers);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando transporte...');
        updateDailyCarrier(dailyCarrierId, dailyCarrier, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditDailyCarrier ? (
        <LazyLoading height="300" />
    ) : (
        <DailyCarrierFormContainer
            fetching={fetchingUpdateDailyCarrier}
            action={update}
            cancel={cancel}
            dailyCarrier={dailyCarrier}
            setDailyCarrier={setDailyCarrier}
            errorFields={errorFields}
            carriers={carriers}
            providers={providers}
            trucks={trucks}
            hoppers={hoppers}
        />
    );
};

export default DailyCarrierEdit;
