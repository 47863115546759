import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import React, { CSSProperties, useEffect,useState } from 'react';

import { SocietySignature,SocietySignatureDataForm,SocietySignatureFilters,SocietySignatureDataFormT } from '../../../../app/models/SocietySignature';
import SocietySignatures from '../..';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';


// const defaultImage = 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg';
const defaultImage ='https://cdn.icon-icons.com/icons2/3251/PNG/512/signature_regular_icon_203276.png';
interface Props {
    societySignatures: SocietySignature[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    destroy?: (id: number) => void;
    show?: (id: number) => void;
    filter: SocietySignatureFilters;
    setFilter: (filter: SocietySignatureFilters) => void;
    callbackSuccess: () => void;
}

const imageStyle: React.CSSProperties = {
    width: '50px', 
    height: '50px',
    objectFit: 'cover', 
    borderRadius: '15px', 
    border: '1px solid #eaeaea', 
  };

  

const SocietySignatureDataTable = ({
    societySignatures,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
}: Props) => {


    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(defaultImage);
  
    const handleImageClick = (signature: string | null) => {
        setCurrentImage(signature ?? defaultImage); 
        setOpen(true);
      };


    const columns = [

        {
            name: 'Firma',
            cell: (row: SocietySignatureDataForm) => {
                const imageUrl = row.signature ?? defaultImage; 
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '60px', 
                        width: '60px',  
                        padding: '4px',  
                    }}>
                        <img
                            src={imageUrl}
                            alt={`Firma de ${row.name || 'desconocido'}`}
                            // style={{
                            //     maxWidth: '100%',  // Ocupa todo el ancho del contenedor
                            //     maxHeight: '100%', // Ocupa todo el alto del contenedor
                            //     objectFit: 'contain', // Mantiene la relación de aspecto sin cortar la imagen
                            //     borderRadius: '4px', // Bordes ligeramente redondeados
                            //     border: '1px solid #eaeaea', // Borde para contraste
                            //     boxSizing: 'border-box', // Incluye el borde y el padding en el ancho y alto
                            // }}
                            style={imageStyle}
                            onClick={() => handleImageClick(imageUrl)}
                        />
                    </div>
                );
            }
        },


        {
            name: 'Nombre',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: SocietySignatureDataForm ,index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Cargo',
            selector: (row: any) => row.job_title,
            sortable: true,
            sortField: 'job_title',
            cell: (row: SocietySignatureDataForm ,index: any, column: any) => <span className="">{row.job_title}</span>
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row.society.name,
            sortable: true,
            sortField: 'society_business_name',
            cell: (row: SocietySignatureDataForm,index: any, column: any) => <span>{row.society?.name}</span>
          },
          {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: SocietySignatureDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];
    return (
        <>
             {open && (
                    <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={[{ src: currentImage }]}
                    />
            )}
            <DefaultDataTable
                columns={columns}
                data={societySignatures}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SocietySignatureDataTable;
