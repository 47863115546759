import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { BankAccountType } from '../../../../app/models/BankAccountType';
import { Currency } from '../../../../app/models/Currency';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';
import { setCleanTextAreaError } from '../../../../utils/utils';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { Bank } from '../../../../app/models/Bank';
import { CheckingAccountDataForm } from '../../../../app/models/CheckingAccount';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import { BankAccountReason } from '../../../../app/models/BankAccountReason';
import { BusinessNameBankAccount } from '../../../../app/models/BusinessNameBankAccount';

type Props = {
    checkingAccountDataForm: CheckingAccountDataForm;
    setCheckingAccountDataForm: (checkingAccountDataForm: CheckingAccountDataForm) => void;
    senderBusinessNames: BusinessName[];
    senderBankAccountReasons: BankAccountReason[];
    senderBankAccounts: BusinessNameBankAccount[];
    senderBanks: Bank[];
    senderBankAccountTypes: BankAccountType[];
    receiverBusinessNames: BusinessName[];
    receiverBusinessEntities: BusinessEntity[];
    currencies: Currency[];
    errorFields?: any;
    loadingSenderBankAccount: boolean;
    setReceiverBusinessNames: (businessNames: BusinessName[]) => void;
};

const ProviderPayForm = ({
    checkingAccountDataForm,
    setCheckingAccountDataForm,
    senderBusinessNames,
    senderBankAccountReasons,
    senderBankAccounts,
    senderBanks,
    senderBankAccountTypes,
    receiverBusinessNames,
    receiverBusinessEntities,
    currencies,
    errorFields,
    loadingSenderBankAccount,
    setReceiverBusinessNames
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();
   
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setCheckingAccountDataForm({ ...checkingAccountDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, name: string) => {
        if (e) {
            
            const obj = { [name]:  e[0]};
            
            setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        if(name === 'sender_business_name_id'){

            const businessName = senderBusinessNames.find(
                (businessName: BusinessName) => businessName.id === value
              );
            if(businessName){
                obj.receiver_business_name_id = 0;
                console.log(businessName.providers)
                setReceiverBusinessNames(businessName.providers ?? []);
                
            }

        }

        setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
    };

    const handleRemoveFile = (name: string) => {

        setCheckingAccountDataForm({
            ...checkingAccountDataForm,
            [name]: undefined
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }

    };
    
    return (
            <>
                <h4>Datos Emisor</h4>
                    <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Sociedad</label>
                            <SelectTwo
                                name="sender_business_name_id"
                                id="sender_business_name_id"
                                inputValue={checkingAccountDataForm.sender_business_name_id}
                                options={SelectTwoMapperOptions(senderBusinessNames)}
                                hasError={fieldHasError('sender_business_name_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_business_name_id', value?.value)
                                }
                                placeholder={'Seleccione sociedad'}
                                onFocus={() => onFocusRemove('sender_business_name_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_business_name_id')}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label className="col-form-label">Motivo cuenta</label>
                        <SelectTwo
                            name="sender_bank_account_reason_id"
                            id="sender_bank_account_reason_id"
                            inputValue={checkingAccountDataForm.sender_bank_account_reason_id}
                            options={SelectTwoMapperOptions(senderBankAccountReasons)}
                            hasError={fieldHasError('sender_bank_account_reason_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('sender_bank_account_reason_id', value?.value)
                            }
                            placeholder={'Seleccione motivo'}
                            onFocus={() => onFocusRemove('sender_bank_account_reason_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('sender_bank_account_reason_id')}
                        </div>
                    </div>
                    {
                        loadingSenderBankAccount?
                        <LazyLoading height={300} />
                        :
                        <>
                        <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Cuenta bancaria</label>
                            <SelectTwo
                                name="sender_bank_account_id"
                                id="sender_bank_account_id"
                                inputValue={checkingAccountDataForm.sender_bank_account_id}
                                options={SelectTwoMapperOptions(senderBankAccounts)}
                                hasError={fieldHasError('sender_bank_account_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_bank_account_id', value?.value)
                                }
                                placeholder={'Seleccione c. Bancaria'}
                                onFocus={() => onFocusRemove('sender_bank_account_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_bank_account_id')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-form-label">Banco</label>
                            <SelectTwo
                                name="sender_bank_id"
                                id="sender_bank_id"
                                inputValue={checkingAccountDataForm.sender_bank_id}
                                options={SelectTwoMapperOptions(senderBanks)}
                                hasError={fieldHasError('sender_bank_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_bank_id', value?.value)
                                }
                                placeholder={'Seleccione banco'}
                                onFocus={() => onFocusRemove('sender_bank_id')}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_bank_id')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Tipo de cuenta</label>
                            <SelectTwo
                                name="sender_account_type_id"
                                id="sender_account_type_id"
                                inputValue={checkingAccountDataForm.sender_account_type_id}
                                options={SelectTwoMapperOptions(senderBankAccountTypes)}
                                hasError={fieldHasError('sender_account_type_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_account_type_id', value?.value)
                                }
                                placeholder={'Seleccione tipo'}
                                onFocus={() => onFocusRemove('sender_account_type_id')}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_account_type_id')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">N° de cuenta</label>
                            <input
                                name="sender_account_number"
                                id="sender_account_number"
                                type="text"
                                onChange={handleChange}
                                className={`form-control ${fieldHasError('sender_account_number')}`}
                                value={checkingAccountDataForm.sender_account_number}
                                onFocus={() => onFocusRemove('sender_account_number')}
                                placeholder="Ingrese el número de cuenta"
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_account_number')}
                            </div>
                        </div>
                    </div>
                        </>
                    }
                    

                    <hr />
                    <h4>Datos Receptor</h4>
                    <div className="mb-2 row">
                        <div className="col-md-qw">
                            <label className="col-form-label">Entidad</label>

                            <SelectTwo
                                name="receiver_business_entity"
                                id="receiver_business_entity"
                                disabled={true}
                                inputValue={checkingAccountDataForm.receiver_business_entity}
                                options={SelectTwoMapperOptions(receiverBusinessEntities)}
                                hasError={fieldHasError('receiver_business_entity') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('receiver_business_entity', value?.value)
                                }
                                placeholder={'Seleccione entidad'}
                                onFocus={() => onFocusRemove('receiver_business_entity')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('receiver_business_entity')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">
                                Razón social
                            </label>
                            <SelectTwo
                                name="receiver_business_name_id"
                                id="receiver_business_name_id"
                                inputValue={checkingAccountDataForm.receiver_business_name_id}
                                options={SelectTwoMapperOptions(receiverBusinessNames, 'id', ['name'])}
                                hasError={fieldHasError('receiver_business_name_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('receiver_business_name_id', value?.value)
                                }
                                placeholder="Seleccione opción"
                                onFocus={() => onFocusRemove('receiver_business_name_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('receiver_business_name_id')}
                            </div>
                        </div>
                    </div>


                    </>
  
                <hr />
                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-form-label">Monto</label>
                        <input
                            name='amount'
                            id='amount'
                            onChange={handleChange}
                            value={checkingAccountDataForm.amount}
                            className={`form-control ${fieldHasError(`amount`)}`}
                            onFocus={() => onFocusRemove(`amount`)}
                            placeholder="Ingrese el monto"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('amount')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Moneda</label>
                        <SelectTwo
                            name="currency_id"
                            id="currency_id"
                            inputValue={checkingAccountDataForm.currency_id}
                            options={SelectTwoMapperOptions(currencies)}
                            hasError={fieldHasError('currency_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('currency_id', value?.value)
                            }
                            placeholder={'Seleccione moneda'}
                            onFocus={() => onFocusRemove('currency_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('currency_id')}
                        </div>
                    </div>
                </div>
                
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-md-12 col-form-label">Comprobante</label>
                        <InputButtonFile
                            handleChangeDocumentFile={handleChangeDocumentFile}
                            name={'file'}
                            handleRemoveFile={(value: any) => handleRemoveFile('file')}
                            objectFile={checkingAccountDataForm?.file}
                            onFocus={() => onFocusRemove('file')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('file')}
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-md-12 col-form-label">Descripción</label>
                        <textarea
                            className={`form-control ${fieldHasError('description')}`}
                            name="description"
                            id="description"
                            value={checkingAccountDataForm.description}
                            onChange={handleChange}
                            placeholder="Ingrese una descripción"
                            onFocus={setCleanTextAreaError}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('description')}
                        </div>
                    </div>
                </div>
            </>
    );

};


export default ProviderPayForm;
