import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { SocietyClientDataForm, SocietyClientFilters } from '../../../../app/models/SocietyClient';
import SocietyClientFilter from './SocietyClientFilter';

interface Props {
    societyClients: SocietyClientDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: SocietyClientFilters;
    setFilter: (filter: SocietyClientFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const SocietyClientDataTable = ({
    societyClients,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        
        {
            name: 'rut',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'rut',
            cell: (row: SocietyClientDataForm, index: any, column: any) => (
                <span className="">{row.client?.rut}</span>
            )
        },
        {
            name: 'Razón Social',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: SocietyClientDataForm, index: any, column: any) => (
                <span className="">{row.client?.name}</span>
            )
        },
        {
            name: 'N° Cuenta Corriente',
            selector: (row: any) => row.account_number,
            sortable: true,
            sortField: 'account_number',
            cell: (row: SocietyClientDataForm, index: any, column: any) => (
                <span className="">{row.account_number}</span>
            )
        },

        {
            name: 'Nombre Contacto',
            selector: (row: any) => row.contact_name,
            sortable: true,
            sortField: 'contact_name',
            cell: (row: SocietyClientDataForm, index: any, column: any) => (
                <span className="">{row.business_name_contact?.name}</span>
            )
        },
        {
            name: 'Email Contacto',
            selector: (row: any) => row.email,
            sortable: true,
            sortField: 'email',
            cell: (row: SocietyClientDataForm, index: any, column: any) => (
                <span className="">{row.business_name_contact?.email}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: SocietyClientDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <SocietyClientFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={societyClients}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SocietyClientDataTable;
