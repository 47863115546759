import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Depot, DepotDataForm, DepotFilters } from '../../models/Depot';

const useDepotService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetDepots, setFetchingGetDepots] = useState(false);
    const [fetchingShowDepot, setFetchingShowDepot] = useState(false);
    const [fetchingStoreDepot, setFetchingStoreDepot] = useState(false);
    const [fetchingEditDepot, setFetchingEditDepot] = useState(false);
    const [fetchingCreateDepot, setFetchingCreateDepot] = useState(false);
    const [fetchingUpdateDepot, setFetchingUpdateDepot] = useState(false);
    const [fetchingDeleteDepot, setFetchingDeleteDepot] = useState(false);
    const [fetchingActiveDepot, setFetchingActiveDepot] = useState(false);

    const getDepots = (depotFilters: DepotFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(depotFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.DEPOT.GET_DEPOTS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetDepots
        });
    };

    const showDepot = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEPOT.SHOW_DEPOT.replace(':id', id.toString()),
            setFetching: setFetchingShowDepot
        });
    };

    const storeDepot = (depot: DepotDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DEPOT.STORE_DEPOT,
            body: depot,
            setFetching: setFetchingStoreDepot
        });
    };

    const editDepot = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEPOT.EDIT_DEPOT.replace(':id', id.toString()),
            setFetching: setFetchingEditDepot
        });
    };

    const createDepot = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEPOT.CREATE_DEPOT,
            setFetching: setFetchingCreateDepot
        });
    };

    const updateDepot = (id: number, depot: DepotDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DEPOT.UPDATE_DEPOT.replace(':id', id.toString()),
            body: depot,
            setFetching: setFetchingUpdateDepot
        });
    };

    const deleteDepot = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DEPOT.DELETE_DEPOT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteDepot
        });
    };

    const activeDepot = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DEPOT.ACTIVE_DEPOT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveDepot
        });
    };

    return {
        fetchingGetDepots,
        fetchingShowDepot,
        fetchingStoreDepot,
        fetchingEditDepot,
        fetchingUpdateDepot,
        fetchingDeleteDepot,
        fetchingActiveDepot,
        fetchingCreateDepot,
        getDepots,
        showDepot,
        storeDepot,
        createDepot,
        editDepot,
        updateDepot,
        deleteDepot,
        activeDepot
    };
};

export default useDepotService;
