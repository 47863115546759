import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useServiceManifestDetailService from '../../../app/services/hooks/useServiceManifestDetailService';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import {
    ServiceManifestDetailDataForm,
    defaultDispatchProgramItemResetData,
    defaultServiceManifestDetailDataForm
} from '../../../app/models/ServiceManifestDetail';
import { PurchaseOrderDetail } from '../../../app/models/PurchaseOrderDetail';
import { Items } from '../../../app/shared/enums';
import ServiceManifestDetailForm from './forms/ServiceManifestDetailForm';
import { Batch } from '../../../app/models/Batch';
import { Provider } from '../../../app/models/Provider';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { Carrier } from '../../../app/models/Carrier';

interface Props {
    serviceManifestId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestDetailCreate = ({ onSaved, onCancel, onError, serviceManifestId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createServiceManifestDetail,
        storeServiceManifestDetail,
        fetchingCreateServiceManifestDetail,
        fetchingStoreServiceManifestDetail
    } = useServiceManifestDetailService();

    const { fetchingGetPurchaseOrderDetail, getPurchaseOrderDetail } =
        usePurchaseOrderDetailService();

    const [serviceManifestDetail, setServiceManifestDetail] =
        useState<ServiceManifestDetailDataForm>({
            ...defaultServiceManifestDetailDataForm,
            service_manifest_id: serviceManifestId,
            purchase_order_detail_id: 0
        });

    const [purchaseOderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetail[]>([]);

    const [itemType, setItemType] = useState<string>('');

    const [errorFields, setErrorFields] = useState<any>();
    const purchaseOrderDetailIdSelected = useRef<number | undefined>(0);
    const purchaseOrderRef = useRef<number>(0);
    const serviceManifestDetailRef = useRef<number>(0);
    const [batches, setBatches] = useState<Batch[]>([]);
    const [resultValue, setResultValue] = useState<number>(0);
    const referenceQuantity = useRef<number>(0);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHopper] = useState<Hopper[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createServiceManifestDetail(serviceManifestId, {
            onSuccess: (response: ServiceResponse) => {
                setPurchaseOrderDetails(response.data.purchase_order_details);
                setProviders(response.data.providers);
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHopper(response.data.hoppers);
                purchaseOrderRef.current = response.data.purchase_order_id;
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (serviceManifestDetail.purchase_order_detail_id == undefined) {
            purchaseOrderDetailIdSelected.current = undefined;
        }
        if (
            serviceManifestDetail.purchase_order_detail_id &&
            serviceManifestDetail.purchase_order_detail_id != purchaseOrderDetailIdSelected.current
        ) {
            getPurchaseOrderDetail(
                serviceManifestDetail.purchase_order_detail_id,
                serviceManifestDetailRef.current,
                {
                    onSuccess: (response: ServiceResponse) => {
                        purchaseOrderDetailIdSelected.current =
                            serviceManifestDetail.purchase_order_detail_id ?? 0;
                        setErrorFields(undefined);

                        if (response.data.purchase_order_detail.item_type == Items.PRODUCT) {
                            
                            setBatches(response.data.batches);
                            setItemType(response.data.purchase_order_detail.item_type);
                        } else {
                            
                            setItemType(response.data.purchase_order_detail.item_type);
                        }
                        setResultValue(response.data.purchase_order_detail.suggested_quantity);
                        referenceQuantity.current = 0;
                        setServiceManifestDetail({
                            ...serviceManifestDetail,
                            item_name: response.data.purchase_order_detail.item_name,
                            price: response.data.purchase_order_detail.price,
                            currency_price: response.data.purchase_order_detail.currency_price,
                            unit_price: response.data.purchase_order_detail.unit_price,
                            unit: response.data.purchase_order_detail.unit,
                            json_tax: response.data.purchase_order_detail.json_tax,
                            quantity: 0,
                            suggested_quantity: response.data.purchase_order_detail.suggested_quantity,
                            quantity_purchase_order_detail: 
                                response.data.purchase_order_detail
                                        .quantity_purchase_order_detail,
                            service_type: response.data.purchase_order_detail.service_type ?? undefined,
                            ...defaultDispatchProgramItemResetData
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        setServiceManifestDetail({
                            ...serviceManifestDetail,
                            purchase_order_detail_id: purchaseOrderDetailIdSelected.current
                        });
                    }
                }
            );
        }
    }, [serviceManifestDetail.purchase_order_detail_id]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando posición en hoja de entrada...');
        storeServiceManifestDetail(serviceManifestDetail, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                setErrorFields(undefined);
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    const setReferenceQuantity = (value: number) => {
        referenceQuantity.current = value;
    }

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateServiceManifestDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestDetailForm
                        serviceManifestDetailDataForm={serviceManifestDetail}
                        setServiceManifestDetailDataForm={setServiceManifestDetail}
                        purchaseOrderDetails={purchaseOderDetails}
                        itemType={itemType}
                        batches={batches}
                        itemLoading={fetchingGetPurchaseOrderDetail}
                        purchaseOrderRef={purchaseOrderRef.current}
                        errorFields={errorFields}
                        resultValue={resultValue}
                        setResultValue={setResultValue}
                        referenceQuantity={referenceQuantity.current}
                        setReferenceQuantity={setReferenceQuantity}
                        providers={providers}
                        carriers={carriers}
                        trucks={trucks}
                        hoppers={hoppers}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreServiceManifestDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreServiceManifestDetail}
                    />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDetailCreate;
