import React from 'react';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import PaymentMethodForm from './forms/PaymentMethodForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    paymentMethod: PaymentMethod;
    setPaymentMethod: (PaymentMethod: PaymentMethod) => void;
    errorFields?: any;
}

const PaymentMethodContainer = ({
    fetching,
    action,
    cancel,
    paymentMethod,
    setPaymentMethod,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <PaymentMethodForm 
                        paymentMethod={paymentMethod} 
                        setPaymentMethod={setPaymentMethod} 
                        errorFields={errorFields} 
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    )
}

export default PaymentMethodContainer
