import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useBankAccountSocietyService from '../../../app/services/hooks/useBankAccountSocietyService';
import { BankAccountSocietyDataForm, BankAccountSocietyFilters } from '../../../app/models/BankAccountSociety';
import DefaultToolBar from '../../../components/default/DefaultToolBar';

import BankAccountSocietyCreate from './BankAccountSocietyCreate';
import BankAccountSocietyEdit from './BankAccountSocietyEdit';
import BankAccountSocietyDataTable from './tables/BankAccountSocietyDataTable';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import { BusinessNameBankAccountDataForm } from '../../../app/models/BusinessNameBankAccount';
import BusinessNameBankAccountDataTable from '../../BusinessName/components/tables/BusinessNameBankAccountDataTable';

interface Props {
    societyId: number;
    isShow?: boolean;
}

const BankAccountSocietyContainer = ({ societyId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllBusinessNameBankAccountsBySociety,
        fetchingGetAllBusinessNameBankAccountsBySociety
    } = useBusinessNameBankAccountService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [bankAccountSocietyIdEdit, setBankAccountSocietyIdEdit] = useState<number>(-1);
    const [bankAccountSocietyIdShow, setBankAccountSocietyIdShow] = useState<number>(-1);
    
    const [bankAccountSocietys, setBankAccountSocietys] = useState<BusinessNameBankAccountDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BankAccountSocietyFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (bankAccountSocietyId: number) => {
        setShowingEdit(true);
        setBankAccountSocietyIdEdit(bankAccountSocietyId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setBankAccountSocietyIdEdit(-1);
    };

    const showShow = (bankAccountSocietyId: number) => {
       
        setShowingShow(true);
        setBankAccountSocietyIdShow(bankAccountSocietyId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setBankAccountSocietyIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

  
    const reloadTable = () => {
        getAllBusinessNameBankAccountsBySociety(filter, societyId, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccountSocietys(response.data.bank_accounts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Cuentas bancarias</div>
                <div className="row">
                    <div className="col-12">
                        <BusinessNameBankAccountDataTable
                            businessNamebankAccounts={bankAccountSocietys}
                            loading={fetchingGetAllBusinessNameBankAccountsBySociety}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default BankAccountSocietyContainer;
