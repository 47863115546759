import React, { useEffect, useState } from 'react';
import {
    IconPasswordChange,
    UserPasswordDataForm,
    defaultIconPasswordChange
} from '../../../../app/models/User';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

interface GeneralUserProps {
    userForm: UserPasswordDataForm;
    setUserForm: (user: UserPasswordDataForm) => void;
    errorFields?: any;
    isProfile?: boolean;
}

const UserPasswordForm = ({
    userForm,
    setUserForm,
    errorFields,
    isProfile = false
}: GeneralUserProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    const [eyeIconOldPassword, setEyeIconOldPassword] =
        useState<IconPasswordChange>(defaultIconPasswordChange);
    const [eyeIconPassword, setEyeIconPassword] =
        useState<IconPasswordChange>(defaultIconPasswordChange);
    const [eyeIconRepeatPassword, setEyeIconRepeatPassword] =
        useState<IconPasswordChange>(defaultIconPasswordChange);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;
        setUserForm({ ...userForm, [name]: value });
    };

    const setIconValues = (
        eyeIconOldField: IconPasswordChange,
        setEyeIconOldField: React.Dispatch<React.SetStateAction<IconPasswordChange>>
    ) => {
        if (eyeIconOldField.type == 'password') {
            setEyeIconOldField({ ...eyeIconOldField, type: 'text', value: false });
        } else {
            setEyeIconOldField({ ...eyeIconOldField, type: 'password', value: true });
        }
    };

    const changeIconEye = (field: string) => {
        if (field == 'old_password') {
            setIconValues(eyeIconOldPassword, setEyeIconOldPassword);
        } else if (field == 'password') {
            setIconValues(eyeIconPassword, setEyeIconPassword);
        } else {
            setIconValues(eyeIconRepeatPassword, setEyeIconRepeatPassword);
        }
    };

    if (!userForm) return null;

    return (
        <>
            {isProfile && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Contraseña actual</label>

                        <div className="input-group">
                            <input
                                name="old_password"
                                id="old_password"
                                type={eyeIconOldPassword.type}
                                className={`form-control ${fieldHasError('old_password')}`}
                                placeholder="Ingrese contraseña actual"
                                value={userForm.old_password}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('old_password')}
                            />
                            <span className="input-group-text">
                                <i
                                    onClick={() => changeIconEye('old_password')}
                                    className={`fa ${
                                        eyeIconOldPassword.value ? 'fa-eye-slash' : 'fa-eye'
                                    }`}
                                ></i>
                            </span>
                        </div>
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('old_password')}
                        </div>
                    </div>
                </div>
            )}
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nueva Contraseña</label>
                    <div className="input-group">
                        <input
                            name="password"
                            id="password"
                            type={eyeIconPassword.type}
                            className={`form-control ${fieldHasError('password')}`}
                            placeholder="Ingrese contraseña nueva"
                            value={userForm.password}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('password')}
                        />
                        <span className="input-group-text">
                            <i
                                onClick={() => changeIconEye('password')}
                                className={`fa ${
                                    eyeIconPassword.value ? 'fa-eye-slash' : 'fa-eye'
                                }`}
                            ></i>
                        </span>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('password')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Repetir contraseña</label>
                    <div className="input-group">
                        <input
                            name="repeat_password"
                            id="repeat_password"
                            type={eyeIconRepeatPassword.type}
                            className={`form-control ${fieldHasError('repeat_password')}`}
                            placeholder="Repetir contraseña nueva"
                            value={userForm.repeat_password}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('repeat_password')}
                        />
                        <span className="input-group-text">
                            <i
                                onClick={() => changeIconEye('repeat_password')}
                                className={`fa ${
                                    eyeIconRepeatPassword.value ? 'fa-eye-slash' : 'fa-eye'
                                }`}
                            ></i>
                        </span>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('repeat_password')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserPasswordForm;
