import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { UserDataForm, defaultUserDataForm } from '../../../app/models/User';
import useUserService from '../../../app/services/hooks/useUserService';
import UserFormContainer from './UserFormContainer';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Group } from '../../../app/models/Group';

interface Props {
    userId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserEdit = ({ userId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditUser, editUser, fetchingUpdateUser, updateUser } = useUserService();
    const [user, setUser] = useState<UserDataForm>(defaultUserDataForm);
    const [groups, setGroups] = useState<Group[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando usuario...');
        editUser(userId, {
            onSuccess: (response: ServiceResponse) => {
                setGroups(response.data.groups);

                const _user = response.data.user;
                _user.groups = response.data.user.groups.map((group: any) => {
                    return {
                        value: group.id,
                        label: group.name
                    };
                });

                setUser(_user);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        // useSweetAlert().spinnerAlert("Actualizando usuario...");
        if (showLoading) showLoading('loading', 'Actualizando usuario...');
        updateUser(userId, user, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                // useSweetAlert().closeSpinnerAlert();
                // if(changeAnimation)changeAnimation('success','Actualización exitosa', true);
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                // useSweetAlert().closeSpinnerAlert();
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                // useSweetAlert().closeSpinnerAlert();
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditUser ? (
        <LazyLoading height="300" />
    ) : (
        <UserFormContainer
            fetching={fetchingUpdateUser}
            action={update}
            cancel={cancel}
            userForm={user}
            setUserForm={setUser}
            groups={groups}
            errorFields={errorFields}
            isDisabled={true}
        />
    );
};

export default UserEdit;
