import React, { useEffect, useState } from 'react';
import { ClasificationDataForm } from '../../../../app/models/Clasification';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';

interface Props {
    clasificationDataForm: ClasificationDataForm;
    setClasificationsDataForm: (clasification: ClasificationDataForm) => void;
    clasifications: ClasificationDataForm[];
    clasificationsTwo: ClasificationDataForm[];
    errorFields?: any;
    type: number;
}

const ClasificationThreeForm = ({
    clasificationDataForm,
    setClasificationsDataForm,
    clasifications,
    clasificationsTwo,
    errorFields,
    type
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [filteredClasificationsTwo, setFilteredClasificationsTwo] = useState<ClasificationDataForm[]>([]);
    const [editableSegment, setEditableSegment] = useState<string>('');
    const [accountNumberBase, setAccountNumberBase] = useState<string>(''); // Para la parte fija del número de cuenta

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);

    useEffect(() => {
        if (clasificationDataForm.clasif_id) {
            const selectedClasif = clasifications.find(
                (clasif) => clasif.id === clasificationDataForm.clasif_id
            );
            const selectedAccountNumber = selectedClasif?.account_number;

            if (selectedAccountNumber) {
                const baseNumber = selectedAccountNumber.slice(0, 3);
                setAccountNumberBase(baseNumber);
                const filteredOptions = clasificationsTwo.filter(
                    (clasifTwo) => clasifTwo.account_number.charAt(0) === selectedAccountNumber.charAt(0)
                );
                setFilteredClasificationsTwo(filteredOptions);
                setClasificationsDataForm({
                    ...clasificationDataForm,
                    type // Añadir el type al estado del formulario
                });
            } else {
                setFilteredClasificationsTwo([]);
            }
        } else {
            setFilteredClasificationsTwo([]);
        }
    }, [clasificationDataForm.clasif_id, clasifications, clasificationsTwo, setClasificationsDataForm]);

    useEffect(() => {
        if (clasificationDataForm.clasif_two_id) {
            const selectedClasifTwo = filteredClasificationsTwo.find(
                (clasif) => clasif.id === clasificationDataForm.clasif_two_id
            );
            const selectedAccountNumberTwo = selectedClasifTwo?.account_number;

            if (selectedAccountNumberTwo) {
                const newAccountNumberBase = selectedAccountNumberTwo.slice(0, 3);
                setAccountNumberBase(newAccountNumberBase);
                setClasificationsDataForm({
                    ...clasificationDataForm,
                    type // Añadir el type al estado del formulario
                });
            }
        }
    }, [clasificationDataForm.clasif_two_id, filteredClasificationsTwo, setClasificationsDataForm]);

    useEffect(() => {
        if (clasificationDataForm.account_number) {
            const parts = clasificationDataForm.account_number.split('-');
            if (parts.length > 2) {
                setEditableSegment(parts[2]);
                setAccountNumberBase(`${parts[0]}-${parts[1]}`);
            } else {
                setEditableSegment('');
                setAccountNumberBase('');
            }
        }
    }, [clasificationDataForm.account_number]);

    const handleEditableSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^\d]/g, '');
        setEditableSegment(value);
        setClasificationsDataForm({
            ...clasificationDataForm,
            account_number: `${accountNumberBase}-${value}-000`,
            type
        });
    };

    const handleSelectChange = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setClasificationsDataForm({
            ...clasificationDataForm,
            ...obj
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name !== 'account_number') {
            setClasificationsDataForm({
                ...clasificationDataForm,
                [name]: value,
                type // Añadir el type al estado del formulario
            });
        }
    };

    const getValidationError = () => {
        const { account_number } = clasificationDataForm;
        if (!account_number) return null;
        const firstSegment = account_number.split('-')[0];
        if (firstSegment === '0') return 'El valor no puede ser 0.';
        if (firstSegment === '') return 'Debe agregar un valor antes del primer guion.';
        return null;
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Primera Clasificación</label>
                    <SelectTwo
                        name="clasif_id"
                        inputValue={clasificationDataForm.clasif_id}
                        hasError={fieldHasError('clasif_id') !== ''}
                        options={SelectTwoMapperOptions(clasifications, 'id', ['account_number', 'name'])}
                        onChange={(value: any) => handleSelectChange('clasif_id', value.value)}
                        onFocus={() => onFocusRemove('clasif_id')}
                        placeholder="Seleccione clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasif_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Segunda Clasificación</label>
                    <SelectTwo
                        name="clasif_two_id"
                        id="clasif_two_id"
                        inputValue={clasificationDataForm.clasif_two_id}
                        hasError={fieldHasError('clasif_two_id') !== ''}
                        options={SelectTwoMapperOptions(filteredClasificationsTwo, 'id', ['account_number', 'name'])}
                        onChange={(value: any) => handleSelectChange('clasif_two_id', value.value)}
                        onFocus={() => onFocusRemove('clasif_two_id')}
                        placeholder="Seleccione clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasif_two_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre cuenta</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name') ? 'is-invalid' : ''}`}
                        value={clasificationDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Código de la Cuenta</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            value={accountNumberBase ? `${accountNumberBase}-` : '-'}
                            readOnly
                            style={{ maxWidth: '60px', textAlign: 'center' }}
                        />
                        <input
                            name="editable_segment_zzz"
                            id="editable_segment_zzz"
                            type="text"
                            className={`form-control ${fieldHasError('account_number') ? 'is-invalid' : ''}`}
                            value={editableSegment}
                            onChange={handleEditableSegmentChange}
                            maxLength={3}
                            placeholder="Ingrese número de cuenta"
                        />
                        <input
                            id="account-number-suffix"
                            type="text"
                            className="input-group-text"
                            value="-000"
                            readOnly
                        />
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account_number')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description') ? 'is-invalid' : ''}`}
                        name="description"
                        id="description"
                        value={clasificationDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClasificationThreeForm;
