import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Tab, Tabs } from 'react-bootstrap';
import { BusinessNameDataForm, defaultBusinessNameForm } from '../../../app/models/BusinessName';
import BusinessNameForm from '../components/forms/BusinessNameForm';
import { ProviderType } from '../../../app/models/ProviderType';
import useBusinessNamesService from '../../../app/services/hooks/useBusinessService';
import BusinessNameContactContainer from '../components/BusinessNameContactContainer';
import BusinessNameBankAccountContainer from '../components/BusinessNameBankAccountContainer';


const BusinessNameEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Razones Sociales',
            url: '/business-names',
            isActive: false
        },
        {
            name: 'Editar razón social #' + id,
            url: '/business-names/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editBusinessName,
        fetchingEditBusinessName,
        fetchingShowBusinessName,
        fetchingUpdateBusinessName,
        updateBusinessName,
    } = useBusinessNamesService();

   
    const [businessName, setBusinessName] = useState<BusinessNameDataForm>(defaultBusinessNameForm);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();


    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editBusinessName(id, {
            onSuccess: (response: ServiceResponse) => {
                setProviderTypes(response.data.provider_types);
                setBusinessName(response.data.business_name);
                
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/business-names');
               
                }
            }
        });

    };


    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (businessName != null) {
            if (showLoading) showLoading('loading', 'Actualizando razón social...');
            updateBusinessName(Number(businessName?.id), businessName, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
              
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar razón social #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditBusinessName ? (
                            <>
                                <BusinessNameForm
                                    businessNameDataForm={businessName} 
                                    setBusinessNameDataForm={setBusinessName} 
                                    providerTypes={providerTypes}
                                    errorFields={errorFields} 
                                />
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateBusinessName}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
            <Tabs defaultActiveKey="bank-accounts" id="bank-accounts" className="mb-3">
                <Tab eventKey="bank-accounts" title="Cuentas bancarias">
                    <BusinessNameBankAccountContainer 
                        businessNameId={parseInt(id)} 
                    />
                </Tab>
                <Tab eventKey="business-name-contacts" title="Contactos">
                    <BusinessNameContactContainer
                        businessNameId={parseInt(id)}
                    />
                </Tab>
            </Tabs>
          
      
        </>
    );
};

export default BusinessNameEdit;
