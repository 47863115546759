import React from 'react';
import { ServiceManifestDocumentFilters } from '../../../../app/models/ServiceManifestDocument';


type Props = {
    filter: ServiceManifestDocumentFilters;
    setFilter: (filter: ServiceManifestDocumentFilters) => void;
};

const ServiceManifestDocumentFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="name">
                            Nombre
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="name"
                            name="name"
                            className="form-control form-control-sm"
                            value={filter.name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <label htmlFor="selectGrande" className="mb-1 font-size-10 ">
                        Tipo de documento
                    </label>
                    <select
                        name="document_type"
                        className="form-select form-select-sm"
                        value={filter.document_type}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option key={''} value="">
                            Todos
                        </option>
                        <option key={'FINAL_REPORT'} value="FINAL_REPORT">
                            Informe final
                        </option>
                        <option key={'PURCHASE_INVOICE'} value="PURCHASE_INVOICE">
                            Factura de compra
                        </option>
                        <option key={'ANNEXES'} value="ANNEXES">
                            Anexos
                        </option>
                    </select>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_from">
                            Fecha de creación desde
                        </label>
                        <input
                            title="Fecha de creación desde"
                            type="date"
                            id="created_at_from"
                            name="created_at_from"
                            className="form-control form-control-sm"
                            value={filter.created_at_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_until">
                            Fecha de creación hasta
                        </label>
                        <input
                            title="Fecha de creación hasta"
                            type="date"
                            id="created_at_until"
                            name="created_at_until"
                            className="form-control form-control-sm"
                            value={filter.created_at_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceManifestDocumentFilter;
