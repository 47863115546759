import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Country } from '../../../../app/models/Country';
import useRut from '../../../../hooks/useRut';
import default_avatar from '../../../../assets/images/default-avatar.png';
import { Region } from '../../../../app/models/Region';
import { Commune } from '../../../../app/models/Commune';

import { CenterDataForm } from '../../../../app/models/Center';
import AddressForm from '../../../../components/default/AddressForm';

type Props = {
    centerDataForm: CenterDataForm;
    setCenterDataForm: (centerDataForm: CenterDataForm) => void;
    countries: Country[];
    regions: Region[];
    communes: Commune[];
    setRegions: (regions: Region[]) => void;
    setCommunes: (communes: Commune[]) => void;
    fetchingRegions?: boolean;
    fetchingCommunes?: boolean;
    errorFields?: any;
};

const CenterForm = ({
    centerDataForm,
    setCenterDataForm,
    countries,
    regions,
    communes,
    setCommunes,
    setRegions,
    fetchingCommunes,
    fetchingRegions,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;

        setCenterDataForm({ ...centerDataForm, [name]: value });
    };

    const handleReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setCenterDataForm({ ...centerDataForm, is_owner: checked });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };
        if (name == 'region_id' && value == undefined) {
            obj.commune_id = undefined;
            setCommunes([]);
        } else if (name == 'country_id' && value == undefined) {
            obj.region_id = undefined;
            obj.commune_id = undefined;
            setRegions([]);
        }
        setCenterDataForm({ ...centerDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={centerDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <AddressForm
                dataForm={centerDataForm}
                countries={countries}
                regions={regions}
                communes={communes}
                fetchingCommunes={fetchingCommunes}
                fetchingRegions={fetchingRegions}
                handleChange={handleChange}
                handleChangeSelectTwo={handleChangeSelectTwo}
                fieldErrorMessage={fieldErrorMessage}
                fieldHasError={fieldHasError}
                onFocusRemove={onFocusRemove}
            />
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Latitud</label>
                    <input
                        name="latitude"
                        id="latitude"
                        type="number"
                        className={`form-control ${fieldHasError('latitude')}`}
                        value={centerDataForm.latitude}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('latitude')}
                        placeholder="Ingrese latitud"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('latitude')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Longitud</label>
                    <input
                        name="longitude"
                        id="longitude"
                        type="number"
                        className={`form-control ${fieldHasError('longitude')}`}
                        value={centerDataForm.longitude}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('longitude')}
                        placeholder="Ingrese longitud"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('longitude')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row align-items-center">
                <label htmlFor="is_owner" className="col-md-3 col-form-label">
                    Es propio
                </label>
                <div className="col-3">
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            style={{ height: '20px', width: '35px' }}
                            type="checkbox"
                            name="is_owner"
                            id="is_owner"
                            className={`form-check-input ${
                                centerDataForm.is_owner ? 'active' : ''
                            }`}
                            checked={centerDataForm.is_owner}
                            onChange={handleReportChange}
                        />
                        {fieldErrorMessage('is_owner')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CenterForm;
