import React, { useEffect } from 'react';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate, momentParseDateTime } from '../../../../helpers';
import { formatAmount, formatMoney } from '../../../../utils/utils';
import { BatchHistoryDataForm, BatchHistoryFilters } from '../../../../app/models/BatchHistory';
import BatchHistoryFilter from './BatchHistoryFilter';


interface Props {
    batchHistories: BatchHistoryDataForm[];
    totalRows: number;
    loading?: boolean;
    show?: (id: number) => void;
    filter: BatchHistoryFilters;
    setFilter: (filter: BatchHistoryFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const BatchHistoryDataTable = ({
    batchHistories,
    totalRows,
    loading = false,
    show,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },

        {
            name: 'Id detalle lote',
            selector: (row: any) => row?.batch_detail_id,
            sortable: true,
            sortField: 'batch_detail_id',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{row.batch_detail_id}</span>
            )
        },
        {
            name: 'Producto',
            selector: (row: any) => row?.item_name,
            sortable: true,
            sortField: 'item_name',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{row.item_name} </span>
            )
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row?.item_quantity,
            sortable: true,
            sortField: 'item_quantity',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">
                    {formatAmount(row.item_quantity ?? 0)}
                    {row.item_unit_quantity}
                </span>
            )
        },
        {
            name: 'Precio unitario',
            selector: (row: any) => row?.price,
            sortable: true,
            sortField: 'price',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">
                    {row.price !== null &&
                        `${formatMoney(row.price ?? 0, row.currency_price)} ${
                            row.currency_price
                        } / ${row.unit_price}`}
                </span>
            )
        },
        // {
        //     name: 'Ingreso desde hoja entrada',
        //     selector: (row: any) => row?.last_quantity_by_smd,
        //     sortable: true,
        //     sortField: 'last_quantity_by_smd',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{formatAmount(row.last_quantity_by_smd??0)}{row.item_unit_quantity}</span>
        //     )
        // },
        // {
        //     name: 'Sociedad',
        //     selector: (row: any) => row?.society,
        //     sortable: true,
        //     sortField: 'society',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">
        //         <div
        //             dangerouslySetInnerHTML={{
        //                 __html: row.society ? String(row.society) : ''
        //             }}
        //         />
        //     </span>
        //     )
        // },
        // {
        //     name: 'Producto etiqueta',
        //     selector: (row: any) => row?.product_label,
        //     sortable: true,
        //     sortField: 'product_label',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{row.product_label} </span>
        //     )
        // },
        // {
        //     name: 'Tipo lote',
        //     selector: (row: any) => row?.batch_type,
        //     sortable: true,
        //     sortField: 'batch_type',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{row.batch_type}</span>
        //     )
        // },
        //
        // {
        //     name: 'Capacidad',
        //     selector: (row: any) => row?.capacity,
        //     sortable: true,
        //     sortField: 'capacity',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{row.capacity} {row.unit_capacity}</span>
        //     )
        // },
        // {
        //     name: 'Almacén',
        //     selector: (row: any) => row?.depot,
        //     sortable: true,
        //     sortField: 'depot',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{row.depot} </span>
        //     )
        // },
        // {
        //     name: 'Estado lote',
        //     selector: (row: any) => row?.batch_status,
        //     sortable: true,
        //     sortField: 'batch_status',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{row.batch_status} </span>
        //     )
        // },
        {
            name: 'Valor Neto',
            selector: (row: any) => row?.price_neto,
            sortable: true,
            sortField: 'price_neto',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{formatMoney(Number(row.price_neto))} </span>
            )
        },
        // {
        //     name: 'Valor Total',
        //     selector: (row: any) => row?.price_total,
        //     sortable: true,
        //     sortField: 'price_total',
        //     cell: (row: BatchHistoryDataForm, index: any, column: any) => (
        //         <span className="">{formatMoney(Number(row.price_total))} </span>
        //     )
        // },
        {
            name: 'Código Movimiento',
            selector: (row: any) => row?.action_status_code,
            sortable: true,
            sortField: 'action_status_code',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{row.action_status_code} </span>
            )
        },
        {
            name: 'Movimiento',
            selector: (row: any) => row?.action_status,
            sortable: true,
            sortField: 'action_status',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{row.action_status} </span>
            )
        },
        {
            name: 'Usuario Contabilización',
            selector: (row: any) => row?.user,
            sortable: true,
            sortField: 'user_account',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">
                    {row.user_account?.first_name} {row.user_account?.last_name}{' '}
                </span>
            )
        },
        {
            name: 'Fecha Hora Contabilización',
            selector: (row: any) => row?.date_account,
            sortable: true,
            sortField: 'date_account',
            cell: (row: BatchHistoryDataForm, index: any, column: any) => (
                <span className="">{momentParseDateTime(row.date_account)} </span>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <BatchHistoryFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={batchHistories}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default BatchHistoryDataTable;
