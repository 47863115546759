import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { Items } from '../../../app/shared/enums';
import { BatchDetailDataForm, BatchDetailFilters } from '../../../app/models/BatchDetail';
import useBatchDetailService from '../../../app/services/hooks/useBatchDetailService';
import BatchDetailDataTable from './tables/BatchDetailDataTable';

interface Props {
    batchId: number;
    isShow?: boolean;
}

const BatchDetailContainer = ({ batchId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getBatchDetailsByBatch,
        fetchingGetBatchDetailsByBatch,
        deleteBatchDetail,
        fetchingDeleteBatchDetail
    } = useBatchDetailService();
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);

    const [batchDetailIdEdit, setBatchDetailIdEdit] = useState<number>(-1);
    const [batchDetailIdShow, setBatchDetailIdShow] = useState<number>(-1);
    const [position, setPosition] = useState<number>(0);
    const [batchDetails, setBatchDetails] = useState<BatchDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BatchDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const reloadTable = () => {
        getBatchDetailsByBatch(filter, batchId, {
            onSuccess: (response: ServiceResponse) => {
                setBatchDetails(response.data.batch_details);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (batchDetailId: number) => {
        setShowingEdit(true);
        setBatchDetailIdEdit(batchDetailId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setBatchDetailIdEdit(-1);
    };

    const showShow = (batchDetailId: number) => {
        setShowingShow(true);
        setBatchDetailIdShow(batchDetailId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setBatchDetailIdShow(-1);
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        {isShow ? (
                            <BatchDetailDataTable
                                batchDetails={batchDetails}
                                loading={fetchingGetBatchDetailsByBatch}
                                totalRows={totalRows}
                                ready={true}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        ) : (
                            <BatchDetailDataTable
                                batchDetails={batchDetails}
                                ready={true}
                                loading={fetchingGetBatchDetailsByBatch}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            {/* {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear detalle lote"
                    backdrop={true}
                    showFooter={false}
                >
                    <BatchDetailCreate
                        batchId={batchId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title={`Editar detalle lote`}
                    backdrop={true}
                    showFooter={false}
                >
                    <BatchDetailEdit
                        batchDetailId={batchDetailIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        
                        onCancel={hideEdit}
                        onError={hideEdit}
                        
                    />
                </DefaultModal>
            ) : null} 
            */}
            {/* {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title={`Ver detalle lote`}
                    backdrop={true}
                    showFooter={false}
                >
                    <BatchDetailshow
                        batchDetailId={batchDetailIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}   */}
        </>
    );
};

export default BatchDetailContainer;
