import { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { ServiceEvents } from "../shared/interfaces";
import { EndPoints } from '../EndPoints';
import { GuideFilters } from "../../models/Guide";


const useGuideService = () => {

    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [ fetchingGetGuides, setFetchingGetGuides ] = useState(false);

    const getGuides = (guideFilters: GuideFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(guideFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.GUIDE.GET_GUIDES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetGuides
        });
    };

    return {
        fetchingGetGuides,
        getGuides
    }

}

export default useGuideService;