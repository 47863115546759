import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Currency } from '../../../../app/models/Currency';
import { Tax } from '../../../../app/models/Tax';

type Props = {
    taxDataForm: Tax;
    setTaxDataForm: (tax: Tax) => void;
    errorFields?: any;
};

const TaxForm = ({ taxDataForm, setTaxDataForm, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> |
           React.ChangeEvent<HTMLSelectElement> |
           React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setTaxDataForm({ ...taxDataForm, [name]: value });
    };

    return (
        <>
          
                <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    
                        <input
                            name="name"
                            id="name"
                            type="text"
                            className={`form-control ${fieldHasError('name')}`}
                            value={taxDataForm.name}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('name')}
                            placeholder="Ingrese nombre de impuesto"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    </div>
                </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Valor</label>
                    <input
                        name="value"
                        id="value"
                        type="number"
                        className={`form-control ${fieldHasError('value')}`}
                        value={taxDataForm.value}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('value')}
                        placeholder="Ingrese valor"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('value')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={taxDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
           
            
        </>
    );
};

export default TaxForm;
