import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { SocietySignature, SocietySignatureFilters } from '../../models/SocietySignature';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useSocietySignatureService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSocietySignature, setFetchingGetSocietySignature] = useState(false);
    const[fetchingCreateSocietySignature, setFetchingCreateSocietySignature] = useState(false);
    const [fetchingShowSocietySignature, setFetchingShowSocietySignature] = useState(false);
    const [fetchingEditSocietySignature, setFetchingEditSocietySignature] = useState(false);    
    const [fetchingStoreSocietySignature, setFetchingStoreSocietySignature] = useState(false);
    const [fetchingUpdateSocietySignature, setFetchingUpdateSocietySignature] = useState(false);
    const [fetchingDeleteSocietySignature, setFetchingDeleteSocietySignature] = useState(false);

    const getSocietySignatures = (societySignatureFilters: SocietySignatureFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(societySignatureFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SOCIETIES_SIGNATURES.GET_SOCIETY_SIGNATURES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSocietySignature
        });
    };

    const createSocietySignature = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETIES_SIGNATURES.CREATE_SOCIETY_SIGNATURE,
            setFetching: setFetchingCreateSocietySignature
        });
    };


    const storeSocietySignature = (societySignature: SocietySignature, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETIES_SIGNATURES.STORE_SOCIETY_SIGNATURE,
            body: societySignature,
            setFetching: setFetchingStoreSocietySignature
        });
    };

    const editSocietySignature= (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETIES_SIGNATURES.EDIT_SOCIETY_SIGNATURE.replace(':id', id.toString()),
            setFetching: setFetchingEditSocietySignature
        });
    };


    const updateSocietySignature = (id: number, societySignature: SocietySignature, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SOCIETIES_SIGNATURES.UPDATE_SOCIETY_SGINATURE.replace(':id', id.toString()),
            body: societySignature,
            setFetching: setFetchingUpdateSocietySignature
        });
    };

    const deleteSocietySignature = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SOCIETIES_SIGNATURES.DELETE_SOCIETY_SIGNATURE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSocietySignature
        });
    };

    return {
        fetchingGetSocietySignature,
        fetchingStoreSocietySignature,
        fetchingUpdateSocietySignature,
        fetchingDeleteSocietySignature,
        fetchingCreateSocietySignature,
        fetchingEditSocietySignature,
        createSocietySignature,
        getSocietySignatures,
        storeSocietySignature,
        updateSocietySignature,
        deleteSocietySignature,
        editSocietySignature,
    };
};

export default useSocietySignatureService;