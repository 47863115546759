import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useBankAccountTypeService from '../../../app/services/hooks/useBankAccountType';
import { BankAccountType } from '../../../app/models/BankAccountType';
import BankAccountTypeFormContainer from './BankAccountTypeFormContainer';

interface Props {
    bankAccountTypeId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BankAccountTypeEdit = ({ bankAccountTypeId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditBankAccountType,
        editBankAccountType,
        fetchingUpdateBankAccountType,
        updateBankAccountType
    } = useBankAccountTypeService();

    const [bankAccountType, setBankAccountType] = useState<BankAccountType>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando tipo de cuenta...');
        editBankAccountType(bankAccountTypeId, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccountType(response.data.bank_account_type);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError();
                toast.error(response.message);
                if (hideLoading) hideLoading();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tipo de cuenta...');
        updateBankAccountType(bankAccountTypeId, bankAccountType, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditBankAccountType ? (
        <LazyLoading height="300" />
    ) : (
        <BankAccountTypeFormContainer
            fetching={fetchingUpdateBankAccountType}
            action={update}
            cancel={cancel}
            bankAccountType={bankAccountType}
            setBankAccountType={setBankAccountType}
            errorFields={errorFields}
        />
    );
};

export default BankAccountTypeEdit;
