import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';

const useNavigationPage = () => {
    const history = useHistory();
    const { eventClick } = useContext(AppContext);
    const [readyToNavigate, setReadyToNavigate] = useState({ url: '', newTab: false });
    useEffect(() => {
        if (readyToNavigate.url && readyToNavigate.url !== '') {
            const { url } = readyToNavigate;
    
            if (eventClick) {
                
                const event = eventClick as unknown as MouseEvent;
                // Verifica si el botón del medio del mouse (button === 1) fue presionado
                if (event.button === 1 || event.ctrlKey) {
                    // Abre en una nueva pestaña si se presionó el botón del medio del mouse
              
                    window.open(url, '_blank');
                } else {

                    if (!readyToNavigate.newTab) {
                        history.push(url);
                    } else {
                        window.open(url, '_blank');
                    }
             
                }
            } else {
                // Procede con la lógica de navegación normal
                
                if (!readyToNavigate.newTab) {
                    history.push(url);
                } else {
                    window.open(url, '_blank');
                }
            }
            // Reset después de la navegación
            setReadyToNavigate({ url: '', newTab: false });
        }
    }, [readyToNavigate, history]);

    const navigationPage = (url: string, newTab = false) => {
        setReadyToNavigate({ url, newTab });
    };

    return {
        navigationPage
    };
};

export default useNavigationPage;
