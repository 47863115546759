import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ContactTypeDataForm } from '../../../app/models/ContactType';
import useContactTypeService from '../../../app/services/hooks/useContactTypeService';

import { BusinessEntity } from '../../../app/models/BusinessEntity';
import ContactTypeForm from './forms/ContactTypeForm';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ContactTypeCreate = ({ onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [contactType, setContactType] = useState<ContactTypeDataForm>({ name: '', business_entity:'' });
    const [businessEntities, setBusinessEntities] = useState<BusinessEntity[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingCreateContactType, createContactType, fetchingStoreContactType, storeContactType } = useContactTypeService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createContactType({
            onSuccess: (response: ServiceResponse) => {
                setBusinessEntities(response.data.business_entities);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando tipo de contacto...');
        storeContactType(contactType, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ContactTypeForm
                        contactTypeDataForm={contactType}
                        setContactTypeDataForm={setContactType}
                        businessEntities={businessEntities}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreContactType} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreContactType} />
                </div>
            </div>
        </>
    );
};

export default ContactTypeCreate;
