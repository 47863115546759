import React from 'react';
import { ProviderType, ProviderTypeFilters } from '../../../app/models/ProviderType';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import ProviderTypeFilter from './ProviderTypeFilter';
import DefaultDataTable from '../../../components/default/DefaultDataTable';

interface Props {
    providerTypes: ProviderType[];
    edit?: (PlaceId: number) => void;
    destroy?: (PlaceId: number) => void;
    show?: (DailyPlaceId: number) => void;
    loading?: boolean;
    filter: ProviderTypeFilters;
    setFilter: (filter: ProviderTypeFilters) => void;
    totalRows: number;
}

const ProviderTypeDataTable = ({
    providerTypes,
    edit,
    destroy,
    loading = false,
    show,
    filter,
    setFilter,
    totalRows
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id'
        },
        {
            name: 'Nombre',
            selector: (row: any) => row?.name,
            sortable: true,
            sortField: 'name'
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="nowrap-cell nowrap-cell-no-min">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="col-12 mt-md-0 mt-3">
                <div className="row">
                    <div className="col-auto">
                        <ProviderTypeFilter filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={providerTypes}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default ProviderTypeDataTable;
