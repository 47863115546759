import React, { useState, useContext, useEffect } from 'react';
import PaymentMethodContainer from './PaymentMethodContainer';
import { PaymentMethod, defaultPaymentMethod } from '../../../app/models/PaymentMethod';
import { AppContext } from '../../../contexts/AppContext';
import useSweetAlert from '../../../hooks/useSweetAlert';
import usePaymentMethodService from '../../../app/services/hooks/usePaymentMethodService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';


interface Props {
    paymentMethodId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PaymentMethodEdit = ({ paymentMethodId, onSaved, onCancel, onError }: Props) => {

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(defaultPaymentMethod);
    const { editPaymentMethod, fetchingEditPaymentMethod, updatePaymentMethod, fetchingUpdatePaymentMethod } = usePaymentMethodService();

    const [errorFields, setErrorFields] = useState<any>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editPaymentMethod(paymentMethodId, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentMethod(response.data.payment_method);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Guardando foma de pago...', 400);
        updatePaymentMethod(paymentMethodId, paymentMethod, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditPaymentMethod ? (
        <LazyLoading height="300" />
    ) : (
        <PaymentMethodContainer 
            action={update} 
            cancel={cancel} 
            paymentMethod={paymentMethod} 
            setPaymentMethod={setPaymentMethod}
            errorFields={errorFields}
            fetching={fetchingUpdatePaymentMethod}
        />
        
    );

}

export default PaymentMethodEdit
