import React, { useEffect } from 'react';
import { BatchDataForm, BatchFilters } from '../../../../app/models/Batch';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';
import BatchFilter from './BatchFilter';
import BatchSwitchChange from '../BatchSwitchChange';

interface Props {
    batches: BatchDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: BatchFilters;
    setFilter: (filter: BatchFilters) => void;
    callbackSuccess: () => void;
    paginationRowsPerPageOptions?: any[];
}

const BatchDataTable = ({
    batches,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row?.society,
            sortable: true,
            sortField: 'society',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.society ? String(row.society) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Producto etiqueta',
            selector: (row: any) => row?.product_label,
            sortable: true,
            sortField: 'product_label',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.product_label} </span>
            )
        },
        {
            name: 'Tipo lote',
            selector: (row: any) => row?.batch_type,
            sortable: true,
            sortField: 'batch_type',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.batch_type}</span>
            )
        },
        {
            name: 'Marca',
            selector: (row: any) => row?.brand,
            sortable: true,
            sortField: 'brand',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.brand}</span>
            )
        },
        {
            name: 'Código',
            selector: (row: any) => row?.code,
            sortable: true,
            sortField: 'code',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.code}</span>
            )
        },
        {
            name: 'Capacidad',
            selector: (row: any) => row?.capacity,
            sortable: true,
            sortField: 'capacity',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">
                    {row.capacity} {row.unit_capacity}
                </span>
            )
        },
        {
            name: 'Actual en lote',
            selector: (row: any) => row.current_quantity_calculated,
            sortable: true,
            sortField: 'current_quantity_calculated',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">
                    {row.current_quantity_calculated} {row.unit_capacity}
                </span>
            )
        },
        {
            name: 'Almacén',
            selector: (row: any) => row?.depot,
            sortable: true,
            sortField: 'depot',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.depot} </span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <span className="">{row.status} </span>
            )
        },

        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <BatchSwitchChange
                    checkValue={row.is_active ?? true}
                    batchId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: BatchDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <BatchFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={batches}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default BatchDataTable;
