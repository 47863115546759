import useFetch from '../../../hooks/useFetch';  
import { useState } from 'react';
import { EndPoints } from '../EndPoints';  

const useSidebarItemsService = () => {
    const { doGet } = useFetch();

    const [fetchingGetSidebarItems, setFetchingGetSidebarItems] = useState(false);
    const [fetchingSearchSidebarItems, setFetchingSearchSidebarItems] = useState(false);


    const getSidebarItems = (events = {}) => {
        doGet({
            ...events,
            url: EndPoints.SIDEBAR_ITEMS.GET_SIDEBAR_ITEMS,  
            setFetching: setFetchingGetSidebarItems,
        });
    };


    const searchSidebarItems = (searchTerm: string, events = {}) => {
        const url = `${EndPoints.SIDEBAR_ITEMS.SEARCH_SIDEBAR_ITEMS}/${searchTerm}`;  // Construye el endpoint correcto con searchTerm
    
        doGet({
            ...events,
            url: url,  // URL corregida
            setFetching: setFetchingSearchSidebarItems,
        });
    };

    return {
        getSidebarItems,
        searchSidebarItems,
        fetchingGetSidebarItems,
        fetchingSearchSidebarItems
    };
};

export default useSidebarItemsService;
