import React from 'react';
import { momentParseDate } from '../../../../helpers';
import { BatchFilters } from '../../../../app/models/Batch';

type Props = {
    filter: BatchFilters;
    setFilter: (filter: BatchFilters) => void;
};

const BatchFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="product_label">
                            Producto etiqueta
                        </label>
                        <input
                            title="Producto etiqueta"
                            type="text"
                            id="product_label"
                            name="product_label"
                            className="form-control form-control-sm"
                            value={filter.product_label}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="batch_type">
                            Tipo lote
                        </label>
                        <input
                            title="Tipo lote"
                            type="text"
                            id="batch_type"
                            name="batch_type"
                            className="form-control form-control-sm"
                            value={filter.batch_type}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <label htmlFor="selectGrande" className="mb-1 font-size-10 ">
                        Estado
                    </label>
                    <select
                        name="status"
                        className="form-select form-select-sm"
                        value={filter.status}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option key={''} value="">
                            Todos
                        </option>
                        <option key={'OPERATIONAL'} value="OPERATIONAL">
                            Operativa
                        </option>
                        <option key={'CLEANING'} value="CLEANING">
                            Limpieza
                        </option>
                        <option key={'MAINTENANCE'} value="MAINTENANCE">
                            Mantención
                        </option>
                        <option key={'BUSY'} value="BUSY">
                            Ocupada
                        </option>
                        <option key={'DELIST'} value="DELIST">
                            De baja
                        </option>
                    </select>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="depot">
                            Almacén
                        </label>
                        <input
                            title="Almacén"
                            type="text"
                            id="depot"
                            name="depot"
                            className="form-control form-control-sm"
                            value={filter.depot}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="row"></div>
        </div>
    );
};

export default BatchFilter;
