import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useDepotService from '../../../app/services/hooks/useDepotService';
import { DepotDataForm, defaultDepotDataForm } from '../../../app/models/Depot';
import { Center } from '../../../app/models/Center';
import { User } from '../../../app/models/User';
import DepotFormContainer from './DepotFormContainer';

interface Props {
    depotId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DepotEditEdit = ({ depotId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditDepot, editDepot, fetchingUpdateDepot, updateDepot } = useDepotService();
    const [depot, setDepot] = useState<DepotDataForm>(defaultDepotDataForm);
    const [centers, setCenters] = useState<Center[]>([]);
    const [supervisors, setSupervisors] = useState<User[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando almacén...');
        editDepot(depotId, {
            onSuccess: (response: ServiceResponse) => {
                setDepot(response.data.depot);
                setCenters(response.data.centers);
                setSupervisors(response.data.supervisors);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando almacén...');
        updateDepot(depotId, depot, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditDepot ? (
        <LazyLoading height="300" />
    ) : (
        <DepotFormContainer
            fetching={fetchingUpdateDepot}
            depot={depot}
            setDepot={setDepot}
            centers={centers}
            supervisors={supervisors}
            action={update}
            cancel={cancel}
            errorFields={errorFields}
        />
    );
};

export default DepotEditEdit;
