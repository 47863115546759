import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useCenterService from '../../../app/services/hooks/useCenterService';
import { CenterDataForm, defaultCenterDataForm } from '../../../app/models/Center';
import CenterFormContainer from './CenterFormContainer';
import { Country } from '../../../app/models/Country';
import { CompanyType } from '../../../app/models/CompanyType';
import { Region } from '../../../app/models/Region';
import { Commune } from '../../../app/models/Commune';
import useCommuneService from '../../../app/services/hooks/useCommuneService';
import useCountryService from '../../../app/services/hooks/useCountryService';

interface Props {
    centerId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CenterEdit = ({ centerId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditCenter, editCenter, fetchingUpdateCenter, updateCenter } =
        useCenterService();
    const [center, setCenter] = useState<CenterDataForm>(defaultCenterDataForm);
    const [firstCenter, setFirstCenter] = useState<CenterDataForm>(defaultCenterDataForm);
    const regionIdSelected = useRef<number | undefined>(undefined);
    const countryIdSelected = useRef<number | undefined>(undefined);
    const [countries, setCountries] = useState<Country[]>([]);
    const [regions, setRegions] = useState<Region[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const { getCommunesByRegion, fetchingGetCommunesByRegion } = useCommuneService();
    const { getRegionsByCountry, fetchingGetRegionsByCountry } = useCountryService();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando centro...');
        editCenter(centerId, {
            onSuccess: (response: ServiceResponse) => {
                setCountries(response.data.countries);
                setRegions(response.data.regions);
                setCommunes(response.data.communes);
                regionIdSelected.current = response.data.center.region_id;
                setCenter(response.data.center);
                setFirstCenter(response.data.center);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (center.region_id == undefined) {
            regionIdSelected.current = undefined;
        }
        if (center.region_id && center.region_id != regionIdSelected.current) {
            getCommunesByRegion(center.region_id, {
                onSuccess: (response: ServiceResponse) => {
                    regionIdSelected.current = center.region_id ?? 0;
                    setCommunes(response.data.communes);
                    if (center.region_id == firstCenter.region_id) {
                        setCenter({
                            ...center,
                            commune_id: firstCenter.commune_id
                        });
                    } else {
                        setCenter({
                            ...center,
                            commune_id: undefined
                        });
                    }
                    setErrorFields({
                        ...errorFields,
                        commune_id: '',
                        region_id: ''
                    });
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                    setCenter({
                        ...center,
                        region_id: regionIdSelected.current
                    });
                }
            });
        }
    }, [center.region_id]);

    useEffect(() => {
        if (center.country_id == undefined) {
            countryIdSelected.current = undefined;
        }
        if (center.country_id && center.country_id != countryIdSelected.current) {
            getRegionsByCountry(center.country_id, {
                onSuccess: (response: ServiceResponse) => {
                    countryIdSelected.current = center.country_id ?? 0;
                    setRegions(response.data.regions);
                    if (center.country_id == firstCenter.country_id) {
                        setCenter({
                            ...center,
                            region_id: firstCenter.region_id
                        });
                    } else {
                        setCenter({
                            ...center,
                            region_id: undefined
                        });
                    }
                    setErrorFields({
                        ...errorFields,
                        region_id: '',
                        country_id: ''
                    });
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                    setCenter({
                        ...center,
                        country_id: countryIdSelected.current
                    });
                }
            });
        }
    }, [center.country_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando centro...');
        updateCenter(centerId, center, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCenter ? (
        <LazyLoading height="300" />
    ) : (
        <CenterFormContainer
            fetching={fetchingUpdateCenter}
            center={center}
            setCenter={setCenter}
            countries={countries}
            regions={regions}
            communes={communes}
            setCommunes={setCommunes}
            setRegions={setRegions}
            action={update}
            cancel={cancel}
            errorFields={errorFields}
            fetchingCommunes={fetchingGetCommunesByRegion}
            fetchingRegions={fetchingGetRegionsByCountry}
        />
    );
};

export default CenterEdit;
