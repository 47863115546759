import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useTaxService from '../../../app/services/hooks/useTaxService';
import { Tax } from '../../../app/models/Tax';
import TaxFormContainer from './TaxFormContainer';


interface Props {
    taxId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TaxEdit = ({ taxId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditTax, editTax, fetchingUpdateTax, updateTax } =
        useTaxService();

    const [tax, setTax] = useState<Tax>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando moneda...');
        editTax(taxId, {
            onSuccess: (response: ServiceResponse) => {
                setTax(response.data.tax);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando impuesto...');
        updateTax(taxId, tax, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditTax ? (
        <LazyLoading height="300" />
    ) : (
        <TaxFormContainer
            fetching={fetchingUpdateTax}
            action={update}
            cancel={cancel}
            tax={tax}
            setTax={setTax}
            errorFields={errorFields}
        />
    );
};

export default TaxEdit;
