import React from 'react';
import { ServiceFilters } from '../../../../app/models/Service';
import { SalesInvoiceFilters } from '../../../../app/models/SalesInvoice';

type Props = {
    filter: SalesInvoiceFilters;
    setFilter: (filter: SalesInvoiceFilters) => void;
};

const ServiceFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };


    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="invoice_number">
                            Nro de Factura
                        </label>
                        <input
                            title="Nro de Factura"
                            type="text"
                            id="invoice_number"
                            name="invoice_number"
                            className="form-control form-control-sm"
                            value={filter.invoice_number}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                <label htmlFor="selectGrande" className="mb-1 font-size-10 ">Estado</label>
                <select
                            
                            name="status"
                            className="form-select form-select-sm"
                            value={filter.status}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'BORRADOR'} value="BORRADOR">
                            Borrador
                            </option>
                            {/* <option key={'ANULADA'} value="ANULADA">
                            Anulada
                            </option> */}
                            <option key={'CONTABILIZADA'} value="CONTABILIZADA">
                            Contabilizada
                            </option>
                        </select>
                </div>
            </div>
        </div>

        
    );
};

export default ServiceFilter;
