import React, { useEffect, useState } from 'react';
import { ClasificationDataForm } from '../../../../app/models/Clasification';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';

interface Props {
    clasificationDataForm: ClasificationDataForm;
    setClasificationsDataForm: (clasificationDataForm: ClasificationDataForm) => void;
    clasifications: ClasificationDataForm[];
    errorFields?: any;
    type: number; 
}

const ClasificationTwoForm = ({
    clasificationDataForm,
    setClasificationsDataForm,
    clasifications,
    errorFields,
    type
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [editableDigit, setEditableDigit] = useState<string>(''); // Para el dígito editable
    const [accountNumberBase, setAccountNumberBase] = useState<string>(''); // Para la parte fija del número de cuenta

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);

    useEffect(() => {
        if (clasificationDataForm.clasif_id && Array.isArray(clasifications)) {
            const selectedClasif = clasifications.find(
                (clasif) => clasif.id === clasificationDataForm.clasif_id
            );
            const selectedAccountNumber = selectedClasif?.account_number;

            if (selectedAccountNumber) {
                const baseNumber = selectedAccountNumber.split('-')[0];
                setAccountNumberBase(baseNumber);
                setEditableDigit(''); // Reiniciar el dígito editable
                if (!clasificationDataForm.account_number) {
                    setClasificationsDataForm({
                        ...clasificationDataForm,
                        account_number: `${baseNumber}--000-000`,
                        type // Añadir el type al estado del formulario
                    });
                }
            } else {
                setAccountNumberBase('');
                setEditableDigit('');
                setClasificationsDataForm({
                    ...clasificationDataForm,
                    account_number: '',
                    type // Añadir el type al estado del formulario
                });
            }
        } else {
            setAccountNumberBase('');
            setEditableDigit('');
            setClasificationsDataForm({
                ...clasificationDataForm,
                account_number: '',
                type // Añadir el type al estado del formulario
            });
        }
    }, [clasificationDataForm.clasif_id, clasifications, setClasificationsDataForm]);

    useEffect(() => {
        if (clasificationDataForm.account_number) {
            const parts = clasificationDataForm.account_number.split('-');
            if (parts.length > 1) {
                setEditableDigit(parts[1]);
                setAccountNumberBase(parts[0]);
            } else {
                setEditableDigit('');
            }
        }
    }, [clasificationDataForm.account_number]);

    const handleEditableDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^\d]/g, ''); // Asegurarse de que solo haya dígitos
        setEditableDigit(value);
        setClasificationsDataForm({
            ...clasificationDataForm,
            account_number: value ? `${accountNumberBase}-${value}-000-000` : `${accountNumberBase}--000-000`
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setClasificationsDataForm({
            ...clasificationDataForm,
            [name]: value
        });
    };

    const handleSelectChange = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setClasificationsDataForm({
            ...clasificationDataForm,
            ...obj
        });
    };

    useEffect(() => {
        if (!editableDigit && accountNumberBase && !clasificationDataForm.account_number) {
            setClasificationsDataForm({
                ...clasificationDataForm,
                account_number: `${accountNumberBase}--000-000`
            });
        }
    }, [editableDigit, accountNumberBase, clasificationDataForm, setClasificationsDataForm]);

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Primera Clasificación</label>
                    <SelectTwo
                        name="clasif_id"
                        id="clasif_id"
                        inputValue={clasificationDataForm.clasif_id}
                        hasError={fieldHasError('clasif_id') !== ''}
                        options={SelectTwoMapperOptions(clasifications, 'id', ['account_number', 'name'])}
                        onChange={(value: any) => handleSelectChange('clasif_id', value.value)}
                        onFocus={() => onFocusRemove('clasif_id')}
                        placeholder="Seleccione clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasif_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre cuenta</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name') ? 'is-invalid' : ''}`}
                        value={clasificationDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Código de la Cuenta</label>
                    <div className="input-group">
                        <input
                            name="accountNumberBase"
                            id="accountNumberBase"
                            type="text"
                            className="input-group-text"
                            value={`${accountNumberBase}-`}
                            readOnly
                            style={{ maxWidth: '60px' }} // Ajusta el ancho máximo del input para accountNumberBase
                        />
                        <input
                            name="editable_digit"
                            id="editable_digit"
                            type="text"
                            className={`form-control ${fieldHasError('account_number') ? 'is-invalid' : ''}`}
                            value={editableDigit}
                            onChange={handleEditableDigitChange}
                            onFocus={() => onFocusRemove('account_number')}
                            placeholder="Ingrese número de cuenta"
                            maxLength={1}
                            style={{ flexGrow: 1 }} // Permite que este input ocupe más espacio
                            disabled={!accountNumberBase} 
                        />
                        <input
                            id="account-number-suffix"
                            type="text"
                            className="input-group-text"
                            value="-000-000"
                            readOnly
                        />
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account_number')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description') ? 'is-invalid' : ''}`}
                        name="description"
                        id="description"
                        value={clasificationDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>

        </>
    );
};

export default ClasificationTwoForm;
