import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import useSocietyContactService from '../../../app/services/hooks/useSocietyContactService';
import { SocietyContactDataForm, SocietyContactFilters } from '../../../app/models/SocietyContact';
import SocietyContactDataTable from './tables/SocietyContactDataTable';
import SocietyContactCreate from './SocietyContactCreate';
import SocietyContactEdit from './SocietyContactEdit';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';
import { BusinessNameContactDataForm } from '../../../app/models/BusinessNameContact';
import BusinessNameContactDataTable from '../../BusinessName/components/tables/BusinessNameContactDataTable';

interface Props {
    societyId: number;
    isShow?: boolean;
}

const SocietyContactContainer = ({ societyId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllBusinessNameContactsBySociety,
        fetchingGetAllBusinessNameContactsBySociety
    } = useBusinessNameContactService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [societyContactIdEdit, setSocietyContactIdEdit] = useState<number>(-1);
    const [societyContactIdShow, setSocietyContactIdShow] = useState<number>(-1);
    
    const [societyContacts, setSocietyContacts] = useState<BusinessNameContactDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<SocietyContactFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (societyContactId: number) => {
        setShowingEdit(true);
        setSocietyContactIdEdit(societyContactId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setSocietyContactIdEdit(-1);
    };

    const showShow = (societyContactId: number) => {
       
        setShowingShow(true);
        setSocietyContactIdShow(societyContactId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setSocietyContactIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

  
    const reloadTable = () => {
        getAllBusinessNameContactsBySociety(filter, societyId, {
            onSuccess: (response: ServiceResponse) => {
                setSocietyContacts(response.data.society_contacts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Contactos sociedad</div>
                <div className="row">
                    <div className="col-12">
                        <BusinessNameContactDataTable
                            contacts={societyContacts}
                            loading={fetchingGetAllBusinessNameContactsBySociety}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default SocietyContactContainer;
