import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import ClasificationForm from './form/ClasificationForm';
import ClasificationTwoForm from './form/ClasificationTwoForm';
import ClasificationThreeForm from './form/ClasificationThreeForm';
import { ClasificationDataForm } from '../../../app/models/Clasification';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    clasificationDataForm: ClasificationDataForm;
    setClasificationsDataForm: (clasification: ClasificationDataForm) => void;
    clasifications: ClasificationDataForm[];
    clasificationsTwo?: ClasificationDataForm[];
    errorFields?: any;
    loading?: boolean;
    updated: boolean;
    type: number; // Añadir el prop type aquí
}

const ClasificationFormContainer = ({
    fetching,
    action,
    cancel,
    clasificationDataForm,
    setClasificationsDataForm,
    clasifications,
    clasificationsTwo,
    errorFields = null,
    loading,
    updated = false,
    type 
}: Props) => {
    const renderForm = () => {
        if (!clasificationDataForm || !type) {
            return <div>Loading...</div>;
        }

        switch (type) {
            case 1:
                return (
                    <ClasificationForm
                        clasificationDataForm={clasificationDataForm}
                        setClasificationsDataForm={setClasificationsDataForm}
                        errorFields={errorFields}
                        type={type}
                    />
                );
            case 2:
                return (
                    <ClasificationTwoForm
                        clasificationDataForm={clasificationDataForm}
                        setClasificationsDataForm={setClasificationsDataForm}
                        clasifications={clasifications}
                        errorFields={errorFields}
                        type={type}
                    />
                );
            case 3:
                return (
                    <ClasificationThreeForm
                        clasificationDataForm={clasificationDataForm}
                        setClasificationsDataForm={setClasificationsDataForm}
                        clasifications={clasifications}
                        clasificationsTwo={clasificationsTwo || []}
                        errorFields={errorFields}
                        type={type}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">{renderForm()}</div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ClasificationFormContainer;
