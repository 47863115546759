import React from 'react';
import { SalesInvoiceHistoryFilters } from '../../../../app/models/SalesInvoiceHistory';

type Props = {
    filter: SalesInvoiceHistoryFilters;
    setFilter: (filter: SalesInvoiceHistoryFilters) => void;
};

const SalesInvoiceHistoryFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row align-items-center">
            <div className="col-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="created_user">
                        Usuario
                    </label>
                    <input
                        type="text"
                        id="created_user"
                        name="created_user"
                        className="form-control form-control-sm"
                        value={filter.created_user?.first_name}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="start_date">
                        Desde
                    </label>
                    <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        className="form-control form-control-sm"
                        value={filter.start_date || ''}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="end_date">
                        Hasta
                    </label>
                    <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        className="form-control form-control-sm"
                        value={filter.end_date || ''}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
        </div>
    );
};

export default SalesInvoiceHistoryFilter;
