import React, { useEffect, useState } from 'react';
import QuoteDetailsDataTable from './tables/QuoteDetailsDataTable';
import {
    TransportationQuoteDetail,
    TransportationQuoteDetailFilters
} from '../../../app/models/TransportationQuoteDetail';
import useTransportationQuoteDetailService from '../../../app/services/hooks/useTransportationQuoteDetailService';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';

import QuoteDetailShowForm from './forms/QuoteDetailShowForm';
import { TransportationQuoteDetailShowDataForm } from '../../../app/models/Quote';

interface Props {
    quoteId: number;
}

const QuoteDetailContainer = ({ quoteId }: Props) => {
    const { getAllTransportationQuoteDetailsByRequest, showTransportationQuoteDetail } =
        useTransportationQuoteDetailService();
    const [totalRows, setTotalRows] = useState<number>(0);
    const [showingShow, setShowingShow] = useState(false);
    const [quoteDetailShow, setDetailShow] = useState<TransportationQuoteDetailShowDataForm>({});
    const [quoteDetails, setQuoteDetails] = useState<TransportationQuoteDetail[]>([]);
    const [filter, setFilter] = useState<TransportationQuoteDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const emptyFunction = () => {
        console.log('nada');
    };

    useEffect(() => {
        if (quoteId) {
            reloadTable();
        }
    }, [quoteId]);

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const hideShow = () => {
        setShowingShow(false);
    };

    const show = (quoteDetailId: number) => {
        showTransportationQuoteDetail(quoteDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setDetailShow(response.data.transportation_quote_detail);
                setShowingShow(true);
            }
        });
    };

    const reloadTable = () => {
        getAllTransportationQuoteDetailsByRequest(quoteId, filter, {
            onSuccess: (response: ServiceResponse) => {
                setQuoteDetails(response.data.transportation_quote_details);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <QuoteDetailsDataTable
                            quoteDetails={quoteDetails}
                            totalRows={totalRows}
                            show={show}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Editar Detalle Cotización"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteDetailShowForm quoteRequestDetail={quoteDetailShow} />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default QuoteDetailContainer;
