import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { Carrier, CarrierFilters } from '../../models/Carrier';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useCarrierService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCarriers, setFetchingGetCarriers] = useState(false);
    const [fetchingShowCarrier, setFetchingShowCarrier] = useState(false);
    const [fetchingCreateCarrier, setFetchingCreateCarrier] = useState(false);
    const [fetchingStoreCarrier, setFetchingStoreCarrier] = useState(false);
    const [fetchingEditCarrier, setFetchingEditCarrier] = useState(false);
    const [fetchingUpdateCarrier, setFetchingUpdateCarrier] = useState(false);
    const [fetchingDeleteCarrier, setFetchingDeleteCarrier] = useState(false);

    const getCarriers = (carrierFilter: CarrierFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(carrierFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.CARRIERS.GET_CARRIERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCarriers
        });
    };

    const showCarrier = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CARRIERS.GET_CARRIER.replace(':id', id.toString()),
            setFetching: setFetchingShowCarrier
        });
    };

    const createCarrier = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CARRIERS.CREATE_CARRIER,
            setFetching: setFetchingCreateCarrier
        });
    };

    const storeCarrier = (carrier: Carrier, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CARRIERS.STORE_CARRIER,
            requestType: 'multipart',
            body: carrier,
            setFetching: setFetchingStoreCarrier
        });
    };

    const editCarrier = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CARRIERS.EDIT_CARRIER.replace(':id', id.toString()),
            setFetching: setFetchingEditCarrier
        });
    };

    const updateCarrier = (id: number, carrier: Carrier, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CARRIERS.UPDATE_CARRIER.replace(':id', id.toString()),
            body: carrier,
            setFetching: setFetchingUpdateCarrier
        });
    };

    const deleteCarrier = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CARRIERS.DELETE_CARRIER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCarrier
        });
    };

    return {
        fetchingGetCarriers,
        fetchingShowCarrier,
        fetchingCreateCarrier,
        fetchingStoreCarrier,
        fetchingEditCarrier,
        fetchingUpdateCarrier,
        fetchingDeleteCarrier,
        getCarriers,
        showCarrier,
        createCarrier,
        storeCarrier,
        editCarrier,
        updateCarrier,
        deleteCarrier
    };
};

export default useCarrierService;
