import { ServiceManifest } from './ServiceManifest';

export interface ServiceManifestDocument {
    id?: number;
    service_manifest?: ServiceManifest;
    service_manifest_id?: number;
    name?: string;
    document_type?: ServiceManifestDocumentType;
    document_type_selected?: string;
    file?: File;
}

export interface ServiceManifestDocumentDataForm {
    id?: number;
    service_manifest?: string;
    service_manifest_id?: number;
    name?: string;
    document_type?: string;
    file?: File;
    file_url?: string;
    created_at?: string;
    position?: number;
}

export interface ServiceManifestDocumentFilters {
    id?: number | null;
    name?: number | '';
    document_type?: string | '';
    created_at_from?: string | '';
    created_at_until?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultServiceManifestDocumentDataForm: ServiceManifestDocumentDataForm = {
    name: '',
    document_type: ''
};

export interface ServiceManifestDocumentType {
    id?: string;
    name?: string;
}
