import React from 'react';
import { ClasificationFilters } from '../../../../app/models/Clasification';

type Props = {
    filter: ClasificationFilters;
    setFilter: (filter: ClasificationFilters) => void;
};

const ClasificationFilter: React.FC<Props> = ({ filter, setFilter }) => {
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(filter);
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="type">
                            Tipo
                        </label>
                        <select
                            id="type"
                            name="type"
                            className="form-control form-control-sm"
                            value={filter.type ?? ''}
                            onChange={(e)=>handlerSelectFilter(e)}
                        >
                            <option value="">Todos</option>
                            <option value="1">Primera</option>
                            <option value="2">Segunda</option>
                            <option value="3">Tercera</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClasificationFilter;
