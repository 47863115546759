import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { Clasification, ClasificationFilters } from '../../models/Clasification';
import { AppContext } from '../../../contexts/AppContext';

const useClasificationService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading } = useContext(AppContext);

    const [fetchingGetClasifications, setFetchingGetClasifications] = useState(false);
    const [fetchingStoreClasification, setFetchingStoreClasification] = useState(false);
    const [fetchingEditClasification, setFetchingEditClasification] = useState(false);
    const [fetchingUpdateClasification, setFetchingUpdateClasification] = useState(false);
    const [fetchingDeleteClasification, setFetchingDeleteClasification] = useState(false);
    const [fetchingCreateClasification, setFetchingCreateClasification] = useState(false);
    const [fetchingActiveClasfication, setFetchingActiveClasification] = useState(false);

    const getClasifications = (filter: ClasificationFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.CLASIFICATIONS.GET_CLASIFICATIONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetClasifications
        });
    };

    const createClasification = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLASIFICATIONS.CREATE_CLASIFICATIONS,
            setFetching: setFetchingCreateClasification
        });
    };

    const storeClasification = (clasificationDataForm: Clasification, events: ServiceEvents = {}) => {
        
        doPost({
            ...events,
            url: EndPoints.CLASIFICATIONS.STORE_CLASIFICATION,
            body: clasificationDataForm,
            setFetching: setFetchingStoreClasification
        });
    };

    const editClasification = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLASIFICATIONS.EDIT_CLASIFICATION.replace(':id', id.toString()),
            setFetching: setFetchingEditClasification
        });
    };

    const updateClasification = (id: number, clasificationDataForm: Clasification, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CLASIFICATIONS.UPDATE_CLASIFICATION.replace(':id', id.toString()),
            body: clasificationDataForm,
            setFetching: setFetchingUpdateClasification
        });
    };

    const deleteClasification = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CLASIFICATIONS.DELETE_CLASIFICATION.replace(':id', id.toString()),
            setFetching: setFetchingDeleteClasification
        });
    };

    const activeClasification = (id: number,active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CLASIFICATIONS.ACTIVE_CLASIFICATION.replace(':id', id.toString()),
            body:{active:active},
            setFetching: setFetchingActiveClasification
        });
    };

    return {
        getClasifications,
        createClasification,
        storeClasification,
        editClasification,
        updateClasification,
        deleteClasification,
        activeClasification,
        fetchingGetClasifications,
        fetchingStoreClasification,
        fetchingEditClasification,
        fetchingUpdateClasification,
        fetchingDeleteClasification,
        fetchingCreateClasification,
        fetchingActiveClasfication,
    };
};

export default useClasificationService;
