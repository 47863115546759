import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import useProviderContactService from '../../../app/services/hooks/useProviderContactService';
import { ProviderContactDataForm, ProviderContactFilters } from '../../../app/models/ProviderContact';
import ProviderContactDataTable from './tables/ProviderContactDataTable';
import ProviderContactCreate from './ProviderContactCreate';
import ProviderContactEdit from './ProviderContactEdit';
import BusinessNameContactDataTable from '../../BusinessName/components/tables/BusinessNameContactDataTable';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';
import { BusinessNameContactDataForm } from '../../../app/models/BusinessNameContact';

interface Props {
    providerId: number;
    isShow?: boolean;
}

const ProviderContactContainer = ({ providerId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetAllBusinessNameContactsByProvider,
        getAllBusinessNameContactsByProvider
    } = useBusinessNameContactService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [providerContactIdEdit, setProviderContactIdEdit] = useState<number>(-1);
    const [providerContactIdShow, setProviderContactIdShow] = useState<number>(-1);
    
    const [providerContacts, setproviderContacts] = useState<BusinessNameContactDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<ProviderContactFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (providerContactId: number) => {
        setShowingEdit(true);
        setProviderContactIdEdit(providerContactId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setProviderContactIdEdit(-1);
    };

    const showShow = (providerContactId: number) => {
       
        setShowingShow(true);
        setProviderContactIdShow(providerContactId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setProviderContactIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);



    const reloadTable = () => {
        getAllBusinessNameContactsByProvider(filter, providerId, {
            onSuccess: (response: ServiceResponse) => {
                setproviderContacts(response.data.provider_contacts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Contactos proveedor</div>
                <div className="row">
                    <div className="col-12">
                        <BusinessNameContactDataTable
                            contacts={providerContacts}
                            loading={fetchingGetAllBusinessNameContactsByProvider}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear contacto proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderContactCreate
                        providerId={providerId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title='Editar contacto proveedor'
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderContactEdit
                        providerContactId={providerContactIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        
                        onCancel={hideEdit}
                        onError={hideEdit}
                        
                    />
                </DefaultModal>
            ) : null} 

            {/* {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title='Ver contacto proveedor'
                    backdrop={true}
                    showFooter={false}
                >
                    <providerContactShow
                        providerContactId={providerContactIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}  */}
        </>
    );
};

export default ProviderContactContainer;
