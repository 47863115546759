import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { SalesInvoiceDetailFilters } from '../../models/SalesInvoiceDetail';
import { ServiceEvents } from '../shared/interfaces';

const useSalesInvoiceDetailService = () => {
    const { doGet,doDelete } = useFetch(); 
    const [fetchingGetSalesInvoiceDetailsBySalesInvoice, setFetchingGetSalesInvoiceDetailsByInvoice] = useState(false);
    const [fetchingDeleteSalesInvoiceDetailsBySalesInvoice, setFetchingDeleteSalesInvoiceDetailsByInvoice] = useState(false);


    const getSalesInvoiceDetailsBySalesInvoice = (filter: SalesInvoiceDetailFilters, salesInvoiceId: number, events: ServiceEvents = {} )=> {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.SALES_INVOICE_DETAIL.GET_SALES_INVOICE_DETAILS_BY_SALES_INVOICE}?${queryString}`;
        
        doGet({
            ...events,
            url: url.replace(':id', salesInvoiceId.toString()),
            setFetching: setFetchingGetSalesInvoiceDetailsByInvoice
        });
    };

    const deleteSalesInvoiceDetail = (salesInvoiceId: number, paymentStatusId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SALES_INVOICE_DETAIL.DELETE_SALES_INVOICE_BY_SALES_INVOICE
                .replace(':id', salesInvoiceId.toString())  
                .replace(':payment_status_id', paymentStatusId.toString()),  
            setFetching: setFetchingDeleteSalesInvoiceDetailsByInvoice
        });
    };

    return {
        getSalesInvoiceDetailsBySalesInvoice, 
        deleteSalesInvoiceDetail,
        fetchingGetSalesInvoiceDetailsBySalesInvoice,
        fetchingDeleteSalesInvoiceDetailsBySalesInvoice, 
    };
};

export default useSalesInvoiceDetailService;

