export interface Carrier {
    id?: number;
    name?: string;
    email?: string;
    rut?: string;
    phone?: string;
    phone_code?: string;
    address?: string;
    documents?: CarrierDocument[];
    transport_company_id?: number;
    transport_provider_name?: string;
    transport_provider_rut?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export const defaultCarrier: Carrier = {
    name: '',
    email: '',
    rut: '',
    phone: '',
    phone_code: '+56',
    address: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    documents: [],
    transport_company_id: -1
};

export interface CarrierDocument {
    id?: number;
    file?: File;
    expire_date?: string;
    type?: string;
    is_expire_date_indefinite?: boolean;
}

export interface CarrierFilters {
    name?: string | '';
    rut?: string | '';
    email?: string | '';
    phone?: string | '';
    address?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
