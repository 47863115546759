import React, { useState } from 'react';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useAuthService from '../../../app/services/hooks/useAuthService';
import { toast } from 'react-toastify';

type Props = {
    setChangePassword: (setChangePassword: boolean) => void;
};

const recoveryPasswordForm = ({ setChangePassword }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [codeRecoveryPassword, setCodeRecoveryPassword] = useState('');
    const { changePasswordByCode } = useAuthService();

    const onClickChangePassword = () => {
        changePasswordByCode(codeRecoveryPassword, password, repeatPassword, {
            onSuccess: (response: ServiceResponse) => {
                setChangePassword(true);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            },
            onFieldError: (response: ServiceResponse) => {
                setErrors(response.data);
            }
        });
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Codigo</label>
                <div className="col-md-12">
                    <input
                        name="code_recovery_password"
                        id="code_recovery_password"
                        type="text"
                        className={`form-control ${fieldHasError('code_recovery_password')}`}
                        value={codeRecoveryPassword}
                        onChange={(event) => setCodeRecoveryPassword(event.target.value)}
                        onFocus={() => onFocusRemove('code_recovery_password')}
                        placeholder="Ingrese un correo electrónico"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('code_recovery_password')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label"> Contraseña </label>
                <div className="col-md-12">
                    <input
                        name="password"
                        id="password"
                        type="password"
                        className={`form-control ${fieldHasError('password')}`}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onFocus={() => onFocusRemove('password')}
                        placeholder="Ingrese un correo electrónico"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('password')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Repetir contraseña</label>
                <div className="col-md-12">
                    <input
                        name="repeat_password"
                        id="repeat_password"
                        type="password"
                        className={`form-control ${fieldHasError('repeat_password')}`}
                        value={repeatPassword}
                        onChange={(event) => setRepeatPassword(event.target.value)}
                        onFocus={() => onFocusRemove('repeat_password')}
                        placeholder="Ingrese un correo electrónico"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('repeat_password')}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button
                        onClick={onClickChangePassword}
                        type="submit"
                        className="btn btn-primary waves-effect waves-light w-100"
                    >
                        Cambiar contraseña
                    </button>
                </div>
            </div>
        </>
    );
};

export default recoveryPasswordForm;
