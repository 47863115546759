export interface Hopper {
    id?: number;
    patent?: string;
    documents?: HopperDocument[];
    truck_patent?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface HopperFilters {
    id?: number | null;
    patent?: string | '';
    truck_patent?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultHopper: Hopper = {
    patent: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    documents: []
};

export interface HopperDocument {
    id?: number;
    file?: File;
    expire_date?: string;
    type?: string;
}
