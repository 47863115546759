import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import BatchHistoryDataTable from './tables/BatchHistoryDataTable';
import useBatchHistoryService from '../../../app/services/hooks/useBatchHistoryService';
import { BatchHistoryDataForm, BatchHistoryFilters } from '../../../app/models/BatchHistory';

interface Props {
    batchId: number;
    isShow?: boolean;
}

const BatchHistoryContainer = ({ batchId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetBatchHistoriesByBatch, getBatchHistoriesByBatch } = useBatchHistoryService();

    const [showingShow, setShowingShow] = useState(false);

    const [batchHistoryIdShow, setBatchHistoryIdShow] = useState<number>(-1);
    const [position, setPosition] = useState<number>(0);
    const [batchHistories, setBatchHistories] = useState<BatchHistoryDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BatchHistoryFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getBatchHistoriesByBatch(filter, batchId, {
            onSuccess: (response: ServiceResponse) => {
                setBatchHistories(response.data.batch_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showShow = (batchHistoryId: number) => {
        setShowingShow(true);
        setBatchHistoryIdShow(batchHistoryId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setBatchHistoryIdShow(-1);
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <BatchHistoryDataTable
                            batchHistories={batchHistories}
                            loading={fetchingGetBatchHistoriesByBatch}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {/* {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title={`Ver detalle historial`}
                    backdrop={true}
                    showFooter={false}
                >
                    <BatchDetailshow
                        batchDetailId={batchHistoryIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}   */}
        </>
    );
};

export default BatchHistoryContainer;
