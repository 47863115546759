import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { PaymentStatusDetailFilters } from '../../models/PaymentStatusDetail';


const usePaymentStatusDetailService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [fetchingGetPaymentStatusDetailByPaymentStatus, setFetchingGetPaymentStatusDetailByPaymentStatus] = useState(false);
    

    const getPaymentStatusDetailsByPaymentStatus= (filter: PaymentStatusDetailFilters, payment_status_id: number, events = {}) => {

        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.PAYMENT_STATUS_DETAIL.GET_PAYMENT_STATUS_DETAILS_BY_PAYMENT_STATUS}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':payment_status_id', payment_status_id.toString()),
            setFetching: setFetchingGetPaymentStatusDetailByPaymentStatus
        });
    };

    return {
        getPaymentStatusDetailsByPaymentStatus,
        fetchingGetPaymentStatusDetailByPaymentStatus
    };
};

export default usePaymentStatusDetailService;
