import React from 'react';
import { BatchDetailFilters } from '../../../../app/models/BatchDetail';

type Props = {
    filter: BatchDetailFilters;
    setFilter: (filter: BatchDetailFilters) => void;
};

const BatchDetailFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row"></div>
            <div className="row"></div>
        </div>
    );
};

export default BatchDetailFilter;
