import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import { InternalSettlementFilters } from '../../../../app/models/InternalSettlement';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

type Props = {
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
    businessNames: BusinessName[];
    businessEntitiesCompare: BusinessEntity[];
    businessNamesCompare: BusinessName[];
};

const InternalSettlementFilter = (
    { 
        filter, 
        setFilter, 
        businessNames, 
        businessEntitiesCompare,
        businessNamesCompare
    }: Props) => {
    const { auth } = useContext(AuthContext);

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {

        const obj = { [e.target.name]: e.target.value };

        setFilter({
            ...filter,
            ...obj
        });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        if(name === 'business_name_id' && value == undefined){

            obj.business_name_id = '';
        }
        else if(name === 'business_name_compare_id' && value == undefined){

            obj.business_name_compare_id = '';
        }

        setFilter({ ...filter, ...obj });
    };

    useEffect(() => {
        console.log(filter);
    }, [filter])

    return (
        <>
            <div className="row justify-content-start">
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_from">
                            Fecha de emisión (desde)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_from"
                            name="issue_date_from"
                            className="form-control form-control-sm"
                            value={filter.issue_date_from}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_until">
                            Fecha de emisión (hasta)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_until"
                            name="issue_date_until"
                            className="form-control form-control-sm"
                            value={filter.issue_date_until}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="movement_type">
                            Tipo de Movimiento
                        </label>
                        <select
                            id="movement_type"
                            name="movement_type"
                            className="form-control form-select-sm"
                            value={filter.movement_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'CREDIT'} value="CREDIT">
                                Cargo
                            </option>
                            <option key={'DEBIT'} value="DEBIT">
                                Abono
                            </option>
                        </select>
                    </div>
                </div>
                
              
            </div>
            <div className="row justify-content-start align-items-start">
            <div className="col-lg-6">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="business_name_id">
                            Sociedades
                        </label>
                        <SelectTwo
                            id="business_name_id"
                            name="business_name_id"
                            inputValue={filter.business_name_id}
                            options={SelectTwoMapperOptions(businessNames ?? [])}
                            onChange={(value: any) => handleChangeSelectTwo('business_name_id', value?.value)}
                            placeholder="Seleccione opción"
                            isClearable={true}
                            size="small"
                        />
                    </div>
                </div>
            </div>
            <div className="row justify-content-start align-items-start">
 
               

                {/*<div className="col-lg-6">*/}
                {/*    <div className="mb-3">*/}
                {/*        <label className="font-size-10 mb-1" htmlFor="business_entity_compare">*/}
                {/*            Entidad comparativa*/}
                {/*        </label>*/}
                {/*        <select*/}
                {/*            id="business_entity_compare"*/}
                {/*            name="business_entity_compare"*/}
                {/*            className="form-control form-select-sm"*/}
                {/*            value={filter.business_entity_compare ?? ''}*/}
                {/*            onChange={(e) => handlerSelectFilter(e)}*/}
                {/*        >*/}
                {/*            <option key={''} value="">*/}
                {/*                Todos*/}
                {/*            </option>*/}
                {/*            {businessEntitiesCompare?.map((businessEntity: BusinessEntity) => (*/}
                {/*                <option key={businessEntity.id} value={businessEntity.id}>*/}
                {/*                    {businessEntity.name}*/}
                {/*                </option>*/}
                {/*            ))}*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="col-lg-6">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="business_name_compare_id">
                            Cuenta Corriente
                        </label>
                        <SelectTwo
                            id="business_name_compare_id"
                            name="business_name_compare_id"
                            inputValue={filter.business_name_compare_id}
                            options={SelectTwoMapperOptions(businessNamesCompare!)}
                            onChange={(value: any) => handleChangeSelectTwo('business_name_compare_id', value?.value)}
                            isClearable={true}
                            placeholder="Seleccione M. Pago"
                            size="small"
                        />
                    </div>
                </div>
            </div>


        </>
    );
};

export default InternalSettlementFilter;
