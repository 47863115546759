import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BatchType, BatchTypeFilters } from '../../models/BatchType';

const useBatchTypeService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBatchTypes, setFetchingGetBatchTypes] = useState(false);
    const [fetchingShowBatchType, setFetchingShowBatchType] = useState(false);
    const [fetchingStoreBatchType, setFetchingStoreBatchType] = useState(false);
    const [fetchingEditBatchType, setFetchingEditBatchType] = useState(false);
    const [fetchingCreateBatchType, setFetchingCreateBatchType] = useState(false);
    const [fetchingUpdateBatchType, setFetchingUpdateBatchType] = useState(false);
    const [fetchingDeleteBatchType, setFetchingDeleteBatchType] = useState(false);
    const [fetchingActiveBatchType, setFetchingActiveBatchType] = useState(false);

    const getBatchTypes = (batchTypeFilters: BatchTypeFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(batchTypeFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BATCH_TYPE.GET_BATCH_TYPES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBatchTypes
        });
    };

    const showBatchType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH_TYPE.SHOW_BATCH_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingShowBatchType
        });
    };

    const storeBatchType = (batchType: BatchType, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BATCH_TYPE.STORE_BATCH_TYPE,
            body: batchType,
            setFetching: setFetchingStoreBatchType
        });
    };

    const editBatchType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH_TYPE.EDIT_BATCH_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingEditBatchType
        });
    };

    const createBatchType = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH_TYPE.CREATE_BATCH_TYPE,
            setFetching: setFetchingCreateBatchType
        });
    };

    const updateBatchType = (id: number, batchType: BatchType, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BATCH_TYPE.UPDATE_BATCH_TYPE.replace(':id', id.toString()),
            body: batchType,
            setFetching: setFetchingUpdateBatchType
        });
    };

    const deleteBatchType = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BATCH_TYPE.DELETE_BATCH_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBatchType
        });
    };

    const activeBatchType = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BATCH_TYPE.ACTIVE_BATCH_TYPE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBatchType
        });
    };

    return {
        fetchingGetBatchTypes,
        fetchingShowBatchType,
        fetchingStoreBatchType,
        fetchingEditBatchType,
        fetchingUpdateBatchType,
        fetchingDeleteBatchType,
        fetchingActiveBatchType,
        fetchingCreateBatchType,
        getBatchTypes,
        showBatchType,
        storeBatchType,
        createBatchType,
        editBatchType,
        updateBatchType,
        deleteBatchType,
        activeBatchType
    };
};

export default useBatchTypeService;
