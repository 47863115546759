import React from 'react';
import { momentParseDate } from '../../../../helpers';
import { PurchaseOrderFilters } from '../../../../app/models/PurchaseOrder';

type Props = {
    filter: PurchaseOrderFilters;
    setFilter: (filter: PurchaseOrderFilters) => void;
};

const PurchaseOrderFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="society">
                            Sociedad
                        </label>
                        <input
                            title="Sociedad"
                            type="text"
                            id="society"
                            name="society"
                            className="form-control form-control-sm"
                            value={filter.society}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider">
                            Proveedor
                        </label>
                        <input
                            title="Proveedor"
                            type="text"
                            id="provider"
                            name="provider"
                            className="form-control form-control-sm"
                            value={filter.provider}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                <label htmlFor="selectGrande" className="mb-1 font-size-10 ">Estado</label>
                <select
                            
                            name="status"
                            className="form-select form-select-sm"
                            value={filter.status}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'DRAFT'} value="DRAFT">
                            Borrador
                            </option>
                            <option key={'PENDING'} value="PENDING">
                            Por confirmar
                            </option>
                            <option key={'CONFIRMED'} value="CONFIRMED">
                            Confirmado
                            </option>
                        </select>
                </div>
                
            </div>
            <div className='row'>
            <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_from">
                            Fecha de creación desde
                        </label>
                        <input
                            title="Fecha de creación desde"
                            type="date"
                            id="created_at_from"
                            name="created_at_from"
                            className="form-control form-control-sm"
                            value={filter.created_at_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_until">
                            Fecha de creación hasta
                        </label>
                        <input
                            title="Fecha de creación hasta"
                            type="date"
                            id="created_at_until"
                            name="created_at_until"
                            className="form-control form-control-sm"
                            value={filter.created_at_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default PurchaseOrderFilter;
