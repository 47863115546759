import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BusinessNameDataForm, BusinessNameFilters } from '../../models/BusinessName';

const useBusinessNamesService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBusinessNames, setFetchingGetBusinessNames] = useState(false);
    const [fetchingShowBusinessName, setFetchingShowBusinessName] = useState(false);
    const [fetchingStoreBusinessName, setFetchingStoreBusinessName] = useState(false);
    const [fetchingEditBusinessName, setFetchingEditBusinessName] = useState(false);
    const [fetchingCreateBusinessName, setFetchingCreateBusinessName] = useState(false);
    const [fetchingUpdateBusinessName, setFetchingUpdateBusinessName] = useState(false);
    const [fetchingDeleteBusinessName, setFetchingDeleteBusinessName] = useState(false);
    const [fetchingActiveBusinessName, setFetchingActiveBusinessName] = useState(false);
    const [fetchingGetBusinessNameList, setFetchingGetBusinessNameList] = useState(false);

    const getBusinessNames = (BusinessNameFilters: BusinessNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(BusinessNameFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BUSINESS_NAMES.GET_BUSINESS_NAMES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBusinessNames
        });
    };

    const showBusinessName = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAMES.SHOW_BUSINESS_NAME.replace(':id', id.toString()),
            setFetching: setFetchingShowBusinessName
        });
    };

    const createBusinessName = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAMES.CREATE_BUSINESS_NAME,
            setFetching: setFetchingCreateBusinessName
        });
    };

    const storeBusinessName = (businessName: BusinessNameDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BUSINESS_NAMES.STORE_BUSINESS_NAME,
            body: businessName,
            setFetching: setFetchingStoreBusinessName
        });
    };

    const editBusinessName = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAMES.EDIT_BUSINESS_NAME.replace(':id', id.toString()),
            setFetching: setFetchingEditBusinessName
        });
    };

    const updateBusinessName = (id: number, businessName: BusinessNameDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BUSINESS_NAMES.UPDATE_BUSINESS_NAME.replace(':id', id.toString()),
            body: businessName,
            setFetching: setFetchingUpdateBusinessName
        });
    };

    const deleteBusinessName = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BUSINESS_NAMES.DELETE_BUSINESS_NAME.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBusinessName
        });
    };

    const activeBusinessName = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BUSINESS_NAMES.ACTIVE_BUSINESS_NAME.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBusinessName
        });
    };

    const getBusinessNameList = (entity: string, events: ServiceEvents = {}) => {

        const url = `${EndPoints.BUSINESS_NAMES.GET_BUSINESS_NAMES_BY_ENTITY}`;
        doGet({
            ...events,
            url: url.replace(':entity', entity),
            setFetching: setFetchingGetBusinessNameList
        });
    };


    return {
        fetchingGetBusinessNames,
        fetchingShowBusinessName,
        fetchingStoreBusinessName,
        fetchingEditBusinessName,
        fetchingUpdateBusinessName,
        fetchingDeleteBusinessName,
        fetchingActiveBusinessName,
        fetchingCreateBusinessName,
        fetchingGetBusinessNameList,
        getBusinessNames,
        showBusinessName,
        storeBusinessName,
        createBusinessName,
        editBusinessName,
        updateBusinessName,
        deleteBusinessName,
        activeBusinessName,
        getBusinessNameList,
    };
};

export default useBusinessNamesService;
