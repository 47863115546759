import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { Product, ProductDataForm } from '../../../app/models/Product';
import useProductService from '../../../app/services/hooks/useProductService';
import ProductFormContainer from './ProductFormContainer';
import { Unit } from '../../../app/models/Unit';
import { PackingForm } from '../../../app/models/PackingForm';
import { Sector } from '../../../app/models/Sector';
import { OriginArea } from '../../../app/models/OriginArea';
import { Currency } from '../../../app/models/Currency';
import { Tax } from '../../../app/models/Tax';
import { ItemCategory } from '../../../app/models/ItemCategory';

interface Props {
    productId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProductEdit = ({ productId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditProduct, editProduct, fetchingUpdateProduct, updateProduct } =
        useProductService();

    const [product, setProduct] = useState<ProductDataForm>({ name: '', description: '' });
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [priceUnits, setPriceUnits] = useState<Unit[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [packingForms, setPackingForms] = useState<PackingForm[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [originAreas, setOriginAreas] = useState<OriginArea[]>([]);
    const [itemCategories, setItemCategories] = useState<ItemCategory[]>([]);
    const [taxes, setTaxes] = useState<Tax[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando producto...');
        editProduct(productId, {
            onSuccess: (response: ServiceResponse) => {
                setCurrencies(response.data.currencies);
                setPriceUnits(response.data.price_units);
                setUnits(response.data.units);
                setPackingForms(response.data.packing_forms);
                setSectors(response.data.sectors);
                setOriginAreas(response.data.origin_areas);
                setItemCategories(response.data.item_categories);
                setTaxes(response.data.taxes);
                const _product = response.data.product;
                _product.taxes = response.data.product.taxes.map(
                    (tax: any) => {
                        return {
                            value: tax.id,
                            label: tax.name,
                         
                        };
                    }
                );
            
                setProduct(_product);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando producto...');
        updateProduct(productId, product, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditProduct ? (
        <LazyLoading height="300" />
    ) : (
        <ProductFormContainer
            fetching={fetchingUpdateProduct}
            action={update}
            cancel={cancel}
            product={product}
            setProduct={setProduct}
            packingForms={packingForms}
            sectors={sectors}
            originAreas={originAreas}
            itemCategories={itemCategories}
            currencies={currencies}
            priceUnits={priceUnits}
            units={units}
            taxesData={taxes}
            errorFields={errorFields}
        />
    );
};

export default ProductEdit;
