import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ProviderContactDataForm, ProviderContactFilters } from '../../models/ProviderContact';

const useProviderContactService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetProviderContactsByProvider, setFetchingGetProviderContactsByProvider] = useState(false);
    const [fetchingShowProviderContact, setFetchingShowProviderContact] = useState(false);
    const [fetchingCreateProviderContact, setFetchingCreateProviderContact] = useState(false);
    const [fetchingStoreProviderContact, setFetchingStoreProviderContact] = useState(false);
    const [fetchingEditProviderContact, setFetchingEditProviderContact] = useState(false);
    const [fetchingUpdateProviderContact, setFetchingUpdateProviderContact] = useState(false);
    const [fetchingDeleteProviderContact, setFetchingDeleteProviderContact] = useState(false);
    const [fetchingActiveProviderContact, setFetchingActiveProviderContact] = useState(false);
    const [fetchingGetProviderContactByProvider, setFetchingGetProviderContactByProvider] = useState(false);


    const getProviderContactsByProvider = (filter: ProviderContactFilters, provider_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PROVIDER_CONTACT.GET_PROVIDER_CONTACTS_BY_PROVIDER}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':provider_id', provider_id.toString()),
            setFetching: setFetchingGetProviderContactsByProvider
        });
    };

    const showProviderContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.SHOW_PROVIDER_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingShowProviderContact
        });
    };

    const createProviderContact = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.CREATE_PROVIDER_CONTACT,
            setFetching: setFetchingCreateProviderContact
        });
    };

    const storeProviderContact = (ProviderContact: ProviderContactDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.STORE_PROVIDER_CONTACT,
            body: ProviderContact,
            setFetching: setFetchingStoreProviderContact
        });
    };

    const editProviderContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.EDIT_PROVIDER_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingEditProviderContact
        });
    };

    const updateProviderContact = (id: number, ProviderContact: ProviderContactDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.UPDATE_PROVIDER_CONTACT.replace(':id', id.toString()),
            body: ProviderContact,
            setFetching: setFetchingUpdateProviderContact
        });
    };

    const deleteProviderContact = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.DELETE_PROVIDER_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteProviderContact
        });
    };

    const activeProviderContact = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.ACTIVE_PROVIDER_CONTACT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveProviderContact
        });
    };

    const getProviderContactByProvider = (provider_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_CONTACT.GET_PROVIDER_CONTACT_BY_PROVIDER.replace(':provider_id', provider_id.toString()),
            setFetching: setFetchingGetProviderContactByProvider
        });
    };

    return {
        fetchingGetProviderContactsByProvider,
        fetchingShowProviderContact,
        fetchingCreateProviderContact,
        fetchingStoreProviderContact,
        fetchingEditProviderContact,
        fetchingUpdateProviderContact,
        fetchingDeleteProviderContact,
        fetchingActiveProviderContact,
        fetchingGetProviderContactByProvider,
        getProviderContactsByProvider,
        showProviderContact,
        createProviderContact,
        storeProviderContact,
        editProviderContact,
        updateProviderContact,
        deleteProviderContact,
        activeProviderContact,
        getProviderContactByProvider
    };
};

export default useProviderContactService;
