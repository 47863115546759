import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import {
    ServiceManifestDetailDataForm,
    serviceManifestDetailFilters
} from '../../models/ServiceManifestDetail';
import { BatchDetailDataForm, BatchDetailFilters } from '../../models/BatchDetail';

const useServiceManifestDetailService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetServiceManifestDetailsByServiceManifest,
        setfetchingGetServiceManifestDetailsByServiceManifest
    ] = useState(false);
    const [fetchingShowServiceManifestDetail, setFetchingShowServiceManifestDetail] =
        useState(false);
    const [fetchingCreateServiceManifestDetail, setFetchingCreateServiceManifestDetail] =
        useState(false);
    const [fetchingStoreServiceManifestDetail, setFetchingStoreServiceManifestDetail] =
        useState(false);
    const [fetchingEditServiceManifestDetail, setFetchingEditServiceManifestDetail] =
        useState(false);
    const [fetchingUpdateServiceManifestDetail, setFetchingUpdateServiceManifestDetail] =
        useState(false);
    const [fetchingDeleteServiceManifestDetail, setFetchingDeleteServiceManifestDetail] =
        useState(false);
    const [
        fetchingChangeStatusServiceManifestDetail,
        setFetchingChangeStatusServiceManifestDetail
    ] = useState(false);
    const [fetchingHasFinishedStatuses, setFetchingHasFinishedStatuses] = useState(false);

    const getServiceManifestDetailsByServiceManifest = (
        serviceManifestDetailFilters: serviceManifestDetailFilters,
        service_manifest_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(serviceManifestDetailFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SERVICE_MANIFEST_DETAIL.GET_SERVICE_MANIFEST_DETAILS_BY_SERVICE_MANIFEST}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':service_manifest_id', service_manifest_id.toString()),
            setFetching: setfetchingGetServiceManifestDetailsByServiceManifest
        });
    };

    const showServiceManifestDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.SHOW_SERVICE_MANIFEST_DETAIL.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowServiceManifestDetail
        });
    };

    const createServiceManifestDetail = (
        service_manifest_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.CREATE_SERVICE_MANIFEST_DETAIL.replace(
                ':service_manifest_id',
                service_manifest_id.toString()
            ),
            setFetching: setFetchingCreateServiceManifestDetail
        });
    };

    const storeServiceManifestDetail = (
        serviceManifestDetail: ServiceManifestDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.STORE_SERVICE_MANIFEST_DETAIL,
            body: serviceManifestDetail,
            setFetching: setFetchingStoreServiceManifestDetail
        });
    };

    const editServiceManifestDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.EDIT_SERVICE_MANIFEST_DETAIL.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditServiceManifestDetail
        });
    };

    const updateServiceManifestDetail = (
        id: number,
        serviceManifestDetail: ServiceManifestDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.UPDATE_SERVICE_MANIFEST_DETAIL.replace(
                ':id',
                id.toString()
            ),
            body: serviceManifestDetail,
            setFetching: setFetchingUpdateServiceManifestDetail
        });
    };

    const deleteServiceManifestDetail = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.DELETE_SERVICE_MANIFEST_DETAIL.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingDeleteServiceManifestDetail
        });
    };

    const hasAllFinishedStatuses = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.HAS_ALL_FINISHED_STATUSES.replace(
                ':id',
                id.toString()
            ).replace(':service_manifest_detail_status', status),
            setFetching: setFetchingHasFinishedStatuses
        });
    };

    const changeStatusServiceManifestDetail = (
        id: number,
        status: string,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DETAIL.CHANGE_STATUS_SERVICE_MANIFEST_DETAIL.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusServiceManifestDetail
        });
    };

    return {
        fetchingGetServiceManifestDetailsByServiceManifest,
        fetchingShowServiceManifestDetail,
        fetchingCreateServiceManifestDetail,
        fetchingStoreServiceManifestDetail,
        fetchingEditServiceManifestDetail,
        fetchingUpdateServiceManifestDetail,
        fetchingDeleteServiceManifestDetail,
        fetchingHasFinishedStatuses,
        fetchingChangeStatusServiceManifestDetail,
        getServiceManifestDetailsByServiceManifest,
        showServiceManifestDetail,
        createServiceManifestDetail,
        storeServiceManifestDetail,
        editServiceManifestDetail,
        updateServiceManifestDetail,
        deleteServiceManifestDetail,
        hasAllFinishedStatuses,
        changeStatusServiceManifestDetail
    };
};

export default useServiceManifestDetailService;
