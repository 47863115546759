import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { DepotDataForm } from '../../../../app/models/Depot';
import { Center } from '../../../../app/models/Center';
import { User } from '../../../../app/models/User';

type Props = {
    depotDataForm: DepotDataForm;
    setDepotDataForm: (depotDataForm: DepotDataForm) => void;
    centers: Center[];
    supervisors: User[];
    errorFields?: any;
};

const DepotForm = ({
    depotDataForm,
    setDepotDataForm,
    centers,
    supervisors,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setDepotDataForm({ ...depotDataForm, [name]: value });
    };

    const handleReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setDepotDataForm({ ...depotDataForm, is_owner: checked });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setDepotDataForm({ ...depotDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={depotDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row align-items-center">
                <div className="col-md-12">
                    <label htmlFor="is_owner" className="col-md-3 col-form-label">
                        Descripción
                    </label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={depotDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Dimensiones m3</label>
                    <input
                        name="dimension"
                        id="dimension"
                        type="number"
                        className={`form-control ${fieldHasError('dimension')}`}
                        value={depotDataForm.dimension}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('dimension')}
                        placeholder="Ingrese dimensión m3"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('dimension')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row align-items-center">
                <label htmlFor="is_owner" className="col-md-3 col-form-label">
                    Es propio
                </label>
                <div className="col-3">
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            style={{ height: '20px', width: '35px' }}
                            type="checkbox"
                            name="is_owner"
                            id="is_owner"
                            className={`form-check-input ${depotDataForm.is_owner ? 'active' : ''}`}
                            checked={depotDataForm.is_owner}
                            onChange={handleReportChange}
                        />
                        {fieldErrorMessage('is_owner')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Centro</label>
                    <SelectTwo
                        name="center_id"
                        id="center_id"
                        inputValue={depotDataForm.center_id}
                        onChange={(value: any) => handleChangeSelectTwo('center_id', value?.value)}
                        options={SelectTwoMapperOptions(centers ?? [])}
                        hasError={fieldHasError('center_id') !== ''}
                        placeholder="Seleccione centro"
                        onFocus={() => onFocusRemove('center_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('center_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Supervisor</label>
                    <SelectTwo
                        name="supervisor_id"
                        id="supervisor_id"
                        inputValue={depotDataForm.supervisor_id}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('supervisor_id', value?.value)
                        }
                        options={SelectTwoMapperOptions(supervisors ?? [])}
                        hasError={fieldHasError('supervisor_id') !== ''}
                        placeholder="Seleccione supervisor"
                        onFocus={() => onFocusRemove('supervisor_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('supervisor_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DepotForm;
