import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BatchDetailDataForm, BatchDetailFilters } from '../../models/BatchDetail';

const useBatchDetailService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBatchDetailsByBatch, setFetchingGetBatchDetailsByBatch] = useState(false);
    const [fetchingShowBatchDetail, setFetchingShowBatchDetail] = useState(false);
    const [fetchingCreateBatchDetail, setFetchingCreateBatchDetail] = useState(false);
    const [fetchingStoreBatchDetail, setFetchingStoreBatchDetail] = useState(false);
    const [fetchingEditBatchDetail, setFetchingEditBatchDetail] = useState(false);
    const [fetchingUpdateBatchDetail, setFetchingUpdateBatchDetail] = useState(false);
    const [fetchingDeleteBatchDetail, setFetchingDeleteBatchDetail] = useState(false);
    const [fetchingGetBatchDetailAccountingsByBatch, setFetchingGetBatchDetailAccountingsByBatch] =
        useState(false);

    const getBatchDetailsByBatch = (
        batchDetailFilters: BatchDetailFilters,
        batch_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(batchDetailFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BATCH_DETAIL.GET_BATCH_DETAILS_BY_BATCH}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':batch_id', batch_id.toString()),
            setFetching: setFetchingGetBatchDetailsByBatch
        });
    };

    const getBatchDetailAccountingsByBatch = (
        filter: BatchDetailFilters,
        batch_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BATCH_DETAIL.GET_BATCH_DETAIL_ACCOUNTINGS_BY_BATCH}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':batch_id', batch_id.toString()),
            setFetching: setFetchingGetBatchDetailAccountingsByBatch
        });
    };

    const showBatchDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH_DETAIL.SHOW_BATCH_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingShowBatchDetail
        });
    };

    const createBatchDetail = (batch_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH_DETAIL.CREATE_BATCH_DETAIL.replace(
                ':batch_id',
                batch_id.toString()
            ),
            setFetching: setFetchingCreateBatchDetail
        });
    };

    const storeBatchDetail = (batchDetail: BatchDetailDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BATCH_DETAIL.STORE_BATCH_DETAIL,
            body: batchDetail,
            setFetching: setFetchingStoreBatchDetail
        });
    };

    const editBatchDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BATCH_DETAIL.EDIT_BATCH_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingEditBatchDetail
        });
    };

    const updateBatchDetail = (
        id: number,
        batchDetail: BatchDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.BATCH_DETAIL.UPDATE_BATCH_DETAIL.replace(':id', id.toString()),
            body: batchDetail,
            setFetching: setFetchingUpdateBatchDetail
        });
    };

    const deleteBatchDetail = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BATCH_DETAIL.DELETE_BATCH_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBatchDetail
        });
    };

    return {
        fetchingGetBatchDetailsByBatch,
        fetchingGetBatchDetailAccountingsByBatch,
        fetchingShowBatchDetail,
        fetchingCreateBatchDetail,
        fetchingStoreBatchDetail,
        fetchingEditBatchDetail,
        fetchingUpdateBatchDetail,
        fetchingDeleteBatchDetail,
        getBatchDetailsByBatch,
        getBatchDetailAccountingsByBatch,
        showBatchDetail,
        createBatchDetail,
        storeBatchDetail,
        editBatchDetail,
        updateBatchDetail,
        deleteBatchDetail
    };
};

export default useBatchDetailService;
