import React, { useEffect } from 'react';
import { DailyCarrier } from '../../../app/models/DailyCarrier';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/SelectTwo';
import { Carrier } from '../../../app/models/Carrier';
import { Truck } from '../../../app/models/Truck';
import { Provider } from '../../../app/models/Provider';

type Props = {
    dailyCarrier: DailyCarrier;
    setDailyCarrier: (dailyCarrier: DailyCarrier) => void;
    errorFields?: any;
    carriers: Carrier[];
    trucks: Truck[];
    hoppers: Truck[];
    providers: Provider[];
};

const DailyCarrierForm = ({
    dailyCarrier,
    setDailyCarrier,
    errorFields,
    carriers,
    trucks,
    hoppers,
    providers
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setDailyCarrier({ ...dailyCarrier, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Proveedor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={dailyCarrier.provider_id}
                        options={SelectTwoMapperOptions(providers)}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder={'Seleccione un proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Conductor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="carrier_id"
                        id="carrier_id"
                        inputValue={dailyCarrier.carrier_id}
                        options={SelectTwoMapperOptions(carriers)}
                        hasError={fieldHasError('carrier_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('carrier_id', value.value)}
                        placeholder={'Seleccione un conductor'}
                        onFocus={() => onFocusRemove('carrier_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('carrier_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Camiones</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="truck_id"
                        id="truck_id"
                        inputValue={dailyCarrier.truck_id}
                        options={SelectTwoMapperOptions(trucks)}
                        hasError={fieldHasError('truck_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('truck_id', value.value)}
                        placeholder={'Seleccione un camion'}
                        onFocus={() => onFocusRemove('truck_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('truck_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tolvas</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="hopper_id"
                        id="hopper_id"
                        inputValue={dailyCarrier.hopper_id}
                        options={SelectTwoMapperOptions(hoppers)}
                        hasError={fieldHasError('hopper_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('hopper_id', value.value)}
                        placeholder={'Seleccione una tolva'}
                        onFocus={() => onFocusRemove('hopper_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('hopper_id')}
                    </div>
                </div>
            </div>

            {/*<div className="mb-2 row">*/}
            {/*    <label className="col-md-3 col-form-label">Día</label>*/}
            {/*    <div className="col-md-9">*/}
            {/*        <input*/}
            {/*            type="date"*/}
            {/*            className={`form-control ${fieldHasError('day')}`}*/}
            {/*            name="day"*/}
            {/*            id="day"*/}
            {/*            value={dailyCarrier.day}*/}
            {/*            onChange={handleChange}*/}
            {/*            placeholder="Ingrese un día"*/}
            {/*            onFocus={setCleanInputError}*/}
            {/*        />*/}
            {/*        <div className="invalid-feedback" style={{ display: 'flex' }}>*/}
            {/*            {fieldErrorMessage('day')}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default DailyCarrierForm;
