import { PlaceType } from './PlaceType';

export interface Place {
    id?: number;
    name: string;
    latitude?: number;
    longitude?: number;
    isActive?: boolean;
    placeTypes?: PlaceType[];
}

export const defaultPlace: Place = {
    name: '',
    latitude: 0,
    longitude: 0
};

export interface PlaceFilters {
    name?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
