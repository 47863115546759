import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BatchHistoryFilters } from '../../models/BatchHistory';

const useBatchHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBatchHistoriesByBatch, setFetchingGetBatchHistoriesByBatch] = useState(false);

    const getBatchHistoriesByBatch = (
        filter: BatchHistoryFilters,
        batch_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BATCH_HOSTORY.GET_BATCH_HOSTORIES_BY_BATCH}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':batch_id', batch_id.toString()),
            setFetching: setFetchingGetBatchHistoriesByBatch
        });
    };

    return {
        fetchingGetBatchHistoriesByBatch,
        getBatchHistoriesByBatch
    };
};

export default useBatchHistoryService;
