import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { ServiceDataForm } from '../../../../app/models/Service';
import { Sector } from '../../../../app/models/Sector';
import { Currency } from '../../../../app/models/Currency';
import { Unit } from '../../../../app/models/Unit';
import { Tax } from '../../../../app/models/Tax';
import { ItemCategory } from '../../../../app/models/ItemCategory';

type Props = {
    serviceDataForm: ServiceDataForm;
    setServiceDataForm: (serviceDataForm: ServiceDataForm) => void;
    sectors: Sector[];
    currencies: Currency[];
    units: Unit[];
    priceUnits: Unit[];
    itemCategories: ItemCategory[];
    taxesData: Tax[];
    errorFields?: any;
};

const ServiceForm = ({
    serviceDataForm,
    setServiceDataForm,
    currencies,
    priceUnits,
    sectors,
    units,
    itemCategories,
    taxesData,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setServiceDataForm({ ...serviceDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setServiceDataForm({ ...serviceDataForm, ...obj });
    };

    const changeMultipleSelectTwo = (taxesSelect: any) => {
        const obj = { taxes: taxesSelect };
        
        setServiceDataForm({
            ...serviceDataForm,
            ...obj
        });
       
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={serviceDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sector</label>
                    <SelectTwo
                        name="sector_id"
                        id="sector_id"
                        inputValue={serviceDataForm.sector_id}
                        onChange={(value: any) => handleChangeSelectTwo('sector_id', value?.value)}
                        options={SelectTwoMapperOptions(sectors ?? [])}
                        hasError={fieldHasError('sector_id') !== ''}
                        placeholder="Seleccione sector"
                        onFocus={() => onFocusRemove('sector_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sector_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Categoría</label>
                    <SelectTwo
                        name="item_category_id"
                        id="item_category_id"
                        inputValue={serviceDataForm.item_category_id}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('item_category_id', value?.value)
                        }
                        options={SelectTwoMapperOptions(itemCategories ?? [])}
                        hasError={fieldHasError('item_category_id') !== ''}
                        placeholder="Seleccione categoría"
                        onFocus={() => onFocusRemove('item_category_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('item_category_id')}
                    </div>
                </div>
            </div>
             <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Precio</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-4">
                            <input
                                name="price"
                                id="price"
                                type="number"
                                className={`form-control ${fieldHasError('price')}`}
                                value={serviceDataForm.price}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('price')}
                                placeholder="Ingrese precio"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('price')}
                            </div>
                        </div>

                        <div className="col-4">
                            <SelectTwo
                                name="currency_price_id"
                                id="currency_price_id"
                                inputValue={serviceDataForm.currency_price_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_price_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_price_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_price_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_price_id')}
                            </div>
                        </div>

                        <div className="col-4">
                            <SelectTwo
                                name="unit_price_id"
                                id="unit_price_id"
                                inputValue={serviceDataForm.unit_price_id}
                                options={SelectTwoMapperOptions(priceUnits)}
                                hasError={fieldHasError('unit_price_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_price_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_price_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_price_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Unidad</label>
                    <SelectTwo
                        name="unit_id"
                        inputValue={serviceDataForm.unit_id}
                        onChange={(value: any) => handleChangeSelectTwo('unit_id', value?.value)}
                        options={SelectTwoMapperOptions(units ?? [])}
                        placeholder="Seleccione unidad"
                        onFocus={() => onFocusRemove('unit_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('unit_id')}
                    </div>
                 </div>
            </div>




            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Impuestos</label>

                    <SelectTwo
                        name="taxes"
                        id="taxes"
                        inputValue={serviceDataForm.taxes}
                        options={SelectTwoMapperOptions(taxesData)}
                        hasError={fieldHasError('taxes') !== ''}
                        onChange={changeMultipleSelectTwo}
                        isMulti={true}
                        placeholder={'Seleccione impuesto(s)'}
                        onFocus={() => onFocusRemove('taxes')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('taxes')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row align-items-center">
                <div className="col-md-12">
                    <label htmlFor="is_owner" className="col-md-3 col-form-label">
                        Descripción
                    </label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={serviceDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>


        </>
    );
};

export default ServiceForm;
