import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { DepotDataForm } from '../../../app/models/Depot';
import { Center } from '../../../app/models/Center';
import { User } from '../../../app/models/User';
import DepotForm from './forms/DepotForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    depot: DepotDataForm;
    setDepot: (depot: DepotDataForm) => void;
    centers: Center[];
    supervisors: User[];
    errorFields?: any;
}

const DepotFormContainer = ({
    fetching,
    action,
    cancel,
    depot,
    setDepot,
    centers,
    supervisors,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <DepotForm
                        depotDataForm={depot}
                        setDepotDataForm={setDepot}
                        errorFields={errorFields}
                        centers={centers}
                        supervisors={supervisors}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default DepotFormContainer;
