import { useHistory } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { useContext, useEffect, useState } from 'react';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import DefaultModal from '../../components/default/DefaultModal';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useServiceManifestService from '../../app/services/hooks/useServiceManifestService';
import { ServiceManifestDataForm, ServiceManifestFilters } from '../../app/models/ServiceManifest';
import ServiceManifestCreate from './components/ServiceManifestCreate';
import useNavigationPage from '../../hooks/useNavigationPage';
import ServiceManifestDataTable from './components/tables/ServiceManifestDataTable';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'hoja de entrada',
        url: '/service-manifests',
        isActive: true
    }
];

const ServiceManifests = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetServiceManifests, getServiceManifests, deleteServiceManifest } =
        useServiceManifestService();
    const [serviceManifests, setServiceManifests] = useState<ServiceManifestDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [showingCreate, setShowingCreate] = useState(false);
    const [filter, setFilter] = useState<ServiceManifestFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getServiceManifests(filter, {
            onSuccess: (response: ServiceResponse) => {
                setServiceManifests(response.data.service_manifests);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (id: number) => {
        console.log("aaaa")
        navigationPage(`/service-manifests/${id}/edit`);
    };

    const showShow = (id: number) => {
        navigationPage(`/service-manifests/${id}/show`);
    };

    const destroy = (ServiceManifestId: number) => {
        const _text = 'Está a punto de eliminar la hoja de entrada #' + ServiceManifestId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando hoja de entrada...');
                        deleteServiceManifest(ServiceManifestId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La hoja de entrada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="hoja de entrada" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showCreate}
                                    title="Nueva hoja de entrada"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <ServiceManifestDataTable
                            serviceManifests={serviceManifests}
                            totalRows={totalRows}
                            loading={fetchingGetServiceManifests}
                            destroy={destroy}
                            edit={showEdit}
                            filter={filter}
                            setFilter={setFilter}
                            show={showShow}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear hoja de entrada"
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestCreate
                        onSaved={() => {
                            reloadTable();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ServiceManifests;
