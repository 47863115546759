import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import usePurchaseOrderService from '../../../app/services/hooks/usePurchaseOrderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import {
    ServiceManifestDataForm,
    defaultServiceManifestDataForm
} from '../../../app/models/ServiceManifest';
import { defaultServiceDataForm } from '../../../app/models/Service';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';
import ServiceManifestShowForm from '../components/forms/ServiceManifestShowForm';
import ServiceManifestDetailContainer from '../components/ServiceManifestDetailContainer';
import { Tab, Tabs } from 'react-bootstrap';
import ServiceManifestDocumentContainer from '../components/ServiceManifestDocumentContainer';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { ServiceManifestDetailDataForm } from '../../../app/models/ServiceManifestDetail';

const ServiceManifestShow = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const smdIdParam = !Number.isNaN(params.get('smd_id')) ? Number(params.get('smd_id')) : null;

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'hoja de entrada',
            url: '/service-manifests',
            isActive: false
        },
        {
            name: 'Ver hoja de entrada #' + id,
            url: '/service-manifests/' + id + '/show',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const { showServiceManifest, fetchingShowServiceManifest } = useServiceManifestService();

    const [serviceManifest, setServiceManifest] = useState<ServiceManifestDataForm>(
        defaultServiceManifestDataForm
    );
    const [serviceManifestDetails, setServiceManifestDetails] = useState<
        ServiceManifestDetailDataForm[]
    >([]);

    useEffect(() => {
        reloadModule();
    }, []);

    const show = (id: number) => {
        showServiceManifest(id, {
            onSuccess: (response: ServiceResponse) => {
                setServiceManifest(response.data.service_manifest);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/service-manifests');
                }
            }
        });
    };

    const reloadModule = () => {
        show(parseInt(id));
    };

    return (
        <>
            <Breadcrumbs
                pageSection={`Información hoja de entrada #${id}`}
                breadcrumbs={breadcrumbs}
            />
            <Tabs defaultActiveKey="general-data" id="service-manifest-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {!fetchingShowServiceManifest ? (
                                    <>
                                        <ServiceManifestShowForm
                                            serviceManifestDataForm={serviceManifest}
                                        />
                                    </>
                                ) : (
                                    <LazyLoading />
                                )}
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="documents-data" title="Documentos">
                    <ServiceManifestDocumentContainer
                        serviceManifestId={parseInt(id)}
                        isShow={true}
                    />
                </Tab>
            </Tabs>
            <>
                <ServiceManifestDetailContainer
                    serviceManifestId={parseInt(id)}
                    isShow={true}
                    smdIdParam={smdIdParam}
                    setServiceManifestDetails={setServiceManifestDetails}
                    serviceManifestDetails={serviceManifestDetails}
                    reloadDetail={reloadModule}
                />
            </>
        </>
    );
};

export default ServiceManifestShow;
