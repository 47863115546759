import React from 'react';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { QuoteStatusRequestDataForm } from '../../../app/models/Quote';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';

interface Props {
    quoteId: number;
    statuses: QuoteStatusRequestDataForm[];
    selectedStatus: QuoteStatusRequestDataForm;
    callbackSuccess?: () => void;
}

const QuoteChangeStatus = ({ quoteId, statuses, selectedStatus, callbackSuccess }: Props) => {
    const [selected, setSelected] = React.useState<QuoteStatusRequestDataForm>(selectedStatus);

    const { confirmQuote, rejectQuote, operationReviewQuote, clientReviewQuote, preparationQuote } =
        useQuoteService();

    // useEffect(() => {
    //     if (status === ResponseStatus.SUCCESS) {
    //         if (callbackSuccess) {
    //             callbackSuccess();
    //         }
    //         useSweetAlert().successAlert({
    //             title: 'Éxito',
    //             text: 'Se ha cambiado el estado de la cotización #' + quoteId
    //         });
    //     }
    // }, [status]);

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            useSweetAlert().successAlert({
                title: 'Éxito',
                text: 'Se ha cambiado el estado de la cotización #' + quoteId
            });
        },
        onError: (response: ServiceResponse) => {
            useSweetAlert().closeSpinnerAlert();
            if (callbackSuccess) {
                callbackSuccess();
            }
            toast.error(response.message);
        }
    };

    const changeStatus = (value: string) => {
        if (value == selected.id) return null;

        const _status = statuses.find((status: QuoteStatusRequestDataForm) => status.id == value);

        setSelected(_status!);

        const _text =
            'Está a punto de cambiar el estado de la cotización #' +
            quoteId +
            ' a ' +
            (_status ? _status.name : '');

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                switch (value) {
                    case 'PREPARACION':
                        preparationQuote(quoteId, eventChangeStatus);
                        break;
                    case 'REVISION_OPERACION':
                        operationReviewQuote(quoteId, eventChangeStatus);
                        break;
                    case 'REVISION_CLIENTE':
                        clientReviewQuote(quoteId, eventChangeStatus);
                        break;
                    case 'RECHAZADA':
                        rejectQuote(quoteId, eventChangeStatus);
                        break;
                    case 'CONFIRMADA':
                        confirmQuote(quoteId, eventChangeStatus);
                        break;
                    default:
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'Opción no encontrada.'
                        });
                        setSelected(selectedStatus);
                        break;
                }
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado de la cotización no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => {
                return (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                );
            })}
        </select>
    );
};

export default QuoteChangeStatus;
