import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Center, CenterDataForm, CenterFilters } from '../../models/Center';

const useCenterService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCenters, setFetchingGetCenters] = useState(false);
    const [fetchingShowCenter, setFetchingShowCenter] = useState(false);
    const [fetchingStoreCenter, setFetchingStoreCenter] = useState(false);
    const [fetchingEditCenter, setFetchingEditCenter] = useState(false);
    const [fetchingCreateCenter, setFetchingCreateCenter] = useState(false);
    const [fetchingUpdateCenter, setFetchingUpdateCenter] = useState(false);
    const [fetchingDeleteCenter, setFetchingDeleteCenter] = useState(false);
    const [fetchingActiveCenter, setFetchingActiveCenter] = useState(false);

    const getCenters = (centerFilters: CenterFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(centerFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CENTER.GET_CENTERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCenters
        });
    };

    const showCenter = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CENTER.SHOW_CENTER.replace(':id', id.toString()),
            setFetching: setFetchingShowCenter
        });
    };

    const storeCenter = (center: CenterDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CENTER.STORE_CENTER,
            body: center,
            setFetching: setFetchingStoreCenter
        });
    };

    const editCenter = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CENTER.EDIT_CENTER.replace(':id', id.toString()),
            setFetching: setFetchingEditCenter
        });
    };

    const createCenter = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CENTER.CREATE_CENTER,
            setFetching: setFetchingCreateCenter
        });
    };

    const updateCenter = (id: number, center: CenterDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CENTER.UPDATE_CENTER.replace(':id', id.toString()),
            body: center,
            setFetching: setFetchingUpdateCenter
        });
    };

    const deleteCenter = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CENTER.DELETE_CENTER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCenter
        });
    };

    const activeCenter = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CENTER.ACTIVE_CENTER.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveCenter
        });
    };

    return {
        fetchingGetCenters,
        fetchingShowCenter,
        fetchingStoreCenter,
        fetchingEditCenter,
        fetchingUpdateCenter,
        fetchingDeleteCenter,
        fetchingActiveCenter,
        fetchingCreateCenter,
        getCenters,
        showCenter,
        storeCenter,
        createCenter,
        editCenter,
        updateCenter,
        deleteCenter,
        activeCenter
    };
};

export default useCenterService;
