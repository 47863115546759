import React from 'react';
import { ClientFilters } from '../../../app/models/Client';

type Props = {
    filter: ClientFilters;
    setFilter: (filter: ClientFilters) => void;
};

const ClientFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="rut">
                        Rut
                    </label>
                    <input
                        type="text"
                        id="rut"
                        name="rut"
                        className="form-control form-control-sm"
                        value={filter.rut}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="rut_contact">
                        Rut de contacto
                    </label>
                    <input
                        type="text"
                        id="rut_contact"
                        name="rut_contact"
                        className="form-control form-control-sm"
                        value={filter.rut_contact}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control form-control-sm"
                        value={filter.email}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="name">
                        Razón Social
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control form-control-sm"
                        value={filter.name}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClientFilter;
