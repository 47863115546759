import { LOCAL_STORAGE } from '../../../config';

export const ResponseStatus = {
    UNKNOWN: 'unknown',
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    FIELDS_VALIDATION: 'fields_validation'
};

export const fetchDefaultHeaders = {
    // Accept: 'application/json',
    // 'Content-Type': 'application/json',
    Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN)}`
};

export const getFetchDefaultHeaders = () => {
    const fetchDefaultHeaders = {
        Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN)}`
    };

    return fetchDefaultHeaders;
};
