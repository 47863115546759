export interface Client {
    id?: number;
    name?: string;
    email?: string;
    rut?: string;
    rut_contact?: string;
    phone?: string;
    phone_code?: string;
    address?: string;
    logo?: File;
    is_national?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    business_name_id?: number;
    account_number?: string;
}

export interface ClientFilters {
    id?: number | null;
    rut?: string | '';
    rut_contact?: string | '';
    name?: string | '';
    email?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultClient: Client = {
    name: '',
    email: '',
    rut: '',
    rut_contact: '',
    phone: '',
    phone_code: '+56',
    address: '',
    created_at: '',
    updated_at: '',
    deleted_at: ''
};
