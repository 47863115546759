import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import { BusinessNameBankAccountDataForm } from '../../../app/models/BusinessNameBankAccount';
import { ContactType } from '../../../app/models/ContactType';
import BusinessNameBankAccountForm from './forms/BusinessNameBankAccountForm';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Bank } from '../../../app/models/Bank';

interface Props {
    businessNameId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BusinessNameBankAccountCreate = ({ onSaved, onCancel, onError, businessNameId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createBusinessNameBankAccount,
        storeBusinessNameBankAccount,
        fetchingCreateBusinessNameBankAccount,
        fetchingStoreBusinessNameBankAccount
    } = useBusinessNameBankAccountService();

    const [businessNameBankAccount, setBusinessNameBankAccount] = useState<BusinessNameBankAccountDataForm>(
        {
            business_name_id: businessNameId,
            bank_id: 0,
            bank_account_reason_id: 0,
            account_type_id: 0,
            account_number: '',
        }
    );


    const [errorFields, setErrorFields] = useState<any>();

    const [bankAccountReasons, setBankAccountReasons] = useState<BankAccountReason[]>([])
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([])
    const [banks, setBanks] = useState<Bank[]>([])

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createBusinessNameBankAccount({
            onSuccess: (response: ServiceResponse) => {
         
                setBankAccountReasons(response.data.bank_account_reasons);
                setBankAccountTypes(response.data.account_types	);
                setBanks(response.data.banks);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);


    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando cuenta bancaria...');
        storeBusinessNameBankAccount(businessNameBankAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateBusinessNameBankAccount ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BusinessNameBankAccountForm
                        businessNameBankAccountDataForm={businessNameBankAccount}
                        setBusinessNameBankAccountDataForm={setBusinessNameBankAccount}
                        errorFields={errorFields}
                        bankAccountReasons={bankAccountReasons} 
                        bankAccountTypes={bankAccountTypes} 
                        banks={banks} 
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreBusinessNameBankAccount}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreBusinessNameBankAccount} />
                </div>
            </div>
        </>
    );
};

export default BusinessNameBankAccountCreate;

