import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BankAccountType, BankAccountTypeFilters } from '../../models/BankAccountType';

const useBankAccountTypeService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBankAccountTypes, setFetchingGetBankAccountTypes] = useState(false);
    const [fetchingShowBankAccountType, setFetchingShowBankAccountType] = useState(false);
    const [fetchingStoreBankAccountType, setFetchingStoreBankAccountType] = useState(false);
    const [fetchingEditBankAccountType, setFetchingEditBankAccountType] = useState(false);
    const [fetchingUpdateBankAccountType, setFetchingUpdateBankAccountType] = useState(false);
    const [fetchingDeleteBankAccountType, setFetchingDeleteBankAccountType] = useState(false);
    const [fetchingActiveBankAccountType, setFetchingActiveBankAccountType] = useState(false);
    const [fetchingCreateBankAccountType, setFetchingCreateBankAccountType] = useState(false);


    const getBankAccountTypes = (
        bankAccountTypeFilters: BankAccountTypeFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(bankAccountTypeFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_TYPES.GET_BANK_ACCOUNT_TYPES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBankAccountTypes
        });
    };

    const showBankAccountType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.SHOW_BANK_ACCOUNT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingShowBankAccountType
        });
    };

    const createBankAccountType = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.CREATE_BANK_ACCOUNT_TYPE,
            setFetching: setFetchingCreateBankAccountType
        });
    };

    const storeBankAccountType = (bankAccountType: BankAccountType, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.STORE_BANK_ACCOUNT_TYPE,
            body: bankAccountType,
            setFetching: setFetchingStoreBankAccountType
        });
    };

    const editBankAccountType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.EDIT_BANK_ACCOUNT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingEditBankAccountType
        });
    };

    const updateBankAccountType = (
        id: number,
        bankAccountType: BankAccountType,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.UPDATE_BANK_ACCOUNT_TYPE.replace(
                ':id',
                id.toString()
            ),
            body: bankAccountType,
            setFetching: setFetchingUpdateBankAccountType
        });
    };

    const deleteBankAccountType = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.DELETE_BANK_ACCOUNT_TYPE.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingDeleteBankAccountType
        });
    };

    const activeBankAccountType = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT_TYPES.ACTIVE_BANK_ACCOUNT_TYPE.replace(
                ':id',
                id.toString()
            ),
            body: { active: active },
            setFetching: setFetchingActiveBankAccountType
        });
    };

    return {
        fetchingGetBankAccountTypes,
        fetchingShowBankAccountType,
        fetchingStoreBankAccountType,
        fetchingEditBankAccountType,
        fetchingUpdateBankAccountType,
        fetchingDeleteBankAccountType,
        fetchingActiveBankAccountType,
        fetchingCreateBankAccountType,
        getBankAccountTypes,
        showBankAccountType,
        storeBankAccountType,
        editBankAccountType,
        updateBankAccountType,
        deleteBankAccountType,
        activeBankAccountType,
        createBankAccountType,
    };
};

export default useBankAccountTypeService;
