import React, { useContext, useEffect, useRef, useState } from 'react';

import {
    defaultTransportationOrderDataForm,
    TransportationOrderDataForm
} from '../../../app/models/TransportationOrder';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import TransportationOrderForm from './forms/TransportationOrderForm';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import { Currency } from '../../../app/models/Currency';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';
import { Quote, QuoteReferenceDataForm } from '../../../app/models/Quote';
import { Unit } from '../../../app/models/Unit';
import { useHistory } from 'react-router-dom';
import { TransportationQuoteDetail } from '../../../app/models/TransportationQuoteDetail';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useClientService from '../../../app/services/hooks/useClientService';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TransportationOrderCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [quotes, setQuotes] = useState<TransportationQuoteDetail[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [references, setReferences] = useState<QuoteReferenceDataForm[]>([]);
    const [errorGetData, setErrorGetData] = useState('');
    const history = useHistory();
    const { navigationPage } = useNavigationPage();

    const {
        fetchingStoreTransportationOrder,
        storeTransportationOrder,
        fetchingCreateTransportationOrder,
        createTransportationOrder,
        fetchingShowQuoteTransportationOrder,
        showQuoteTransportationOrder
    } = useTransportationOrderService();

    const {
        getClientsBySociety,
        fetchingGetClientBySociety
    } = useClientService()

    const [transportationOrder, setTransportationOrder] = useState<TransportationOrderDataForm>(
        defaultTransportationOrderDataForm
    );
    const [societyAndClientDisabled, setSocietyAndClientDisabled] = useState<boolean>(false);


    const transportationOrderFromTqd = useRef<TransportationOrderDataForm|undefined>();
    const clientsFromTqd = useRef<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);
    
    const [errorFields, setErrorFields] = useState<any>();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    useEffect(() => {
        createTransportationOrder({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                
                setPaymentMethods(response.data.payment_methods);
                setPaymentsConditions(response.data.payment_conditions);
                setCurrencies(response.data.currencies);
                setQuantityUnits(response.data.quantity_units);
                setQuotes(response.data.quotes);
                setReferences(response.data.references);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (transportationOrder.transportation_quote_detail_id) {
            showQuoteTransportationOrder(transportationOrder.transportation_quote_detail_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    if(response.data.quote.society_id){
                        societyIdSelected.current = Number(response.data.quote.society_id) ?? 0;
                    }
                  
                    setClients(response.data.clients);
                    clientsFromTqd.current = response.data.clients;
                    setTransportationOrder({
                        ...transportationOrder,
                        ...response.data.quote
                    });

                    transportationOrderFromTqd.current = {
                        ...transportationOrder,
                        ...response.data.quote
                    }

                    setSocietyAndClientDisabled(true)
                },
                onError: (response: ServiceResponse) => {
                    setTransportationOrder({
                        ...transportationOrder,
                        transportation_quote_detail_id: transportationOrderFromTqd.current?.transportation_quote_detail_id ?? undefined
                    });
                }
            });
        }
        if(transportationOrder.transportation_quote_detail_id === undefined){
            setSocietyAndClientDisabled(false)
        }
    }, [transportationOrder.transportation_quote_detail_id]);

    useEffect(() => {

        if (transportationOrder.society_id == undefined) {
            societyIdSelected.current = undefined;
        }

        if (transportationOrder.society_id && transportationOrder.society_id != societyIdSelected.current) {

            if((transportationOrderFromTqd.current && 
                transportationOrderFromTqd.current.transportation_quote_detail_id &&
                transportationOrderFromTqd.current.society_id != transportationOrder.society_id) ||
                (!transportationOrderFromTqd.current)
            ){

                    getClientsBySociety(Number(transportationOrder.society_id), {
                        onSuccess: (response: ServiceResponse) => {
                            societyIdSelected.current = Number(transportationOrder.society_id) ?? 0;
                            
                            setClients(response.data.clients);
                            setTransportationOrder(
                                {
                                    ...transportationOrder,
                                    client_id: 0
                                }
                            )
                        },
                        onError: (response: ServiceResponse) => {
                            setTransportationOrder({
                                ...transportationOrder,
                                society_id: Number(societyIdSelected.current)
                            });
                        }
                    })
                    
                }
            else if(
                (transportationOrderFromTqd.current && 
                    transportationOrderFromTqd.current.transportation_quote_detail_id &&
                    transportationOrderFromTqd.current.society_id == transportationOrder.society_id)
            ){
                societyIdSelected.current = Number(transportationOrder.society_id) ?? 0;
                setClients(clientsFromTqd.current);
                setTransportationOrder(
                    {
                        ...transportationOrder,
                        client_id: transportationOrderFromTqd.current.client_id
                    }
                )

            }
             
        }
     
    }, [transportationOrder.society_id]);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando pedido...');
        storeTransportationOrder(transportationOrder, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                navigationPage(
                    '/transportation-orders/' + response.data.transportation_order_id + '/edit'
                );

                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateTransportationOrder || fetchingShowQuoteTransportationOrder ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TransportationOrderForm
                        transportationOrderForm={transportationOrder}
                        setTransportationOrderForm={setTransportationOrder}
                        societies={societies}
                        clients={clients}
                        paymentConditions={paymentConditions}
                        paymentMethods={paymentMethods}
                        currencies={currencies}
                        quantityUnits={quantityUnits}
                        references={references}
                        errorFields={errorFields}
                        quotes={quotes}
                        isCreated={true}
                        loadingClient={fetchingGetClientBySociety}
                        societyAndClientDisabled={societyAndClientDisabled}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTransportationOrder}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreTransportationOrder}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderCreate;
