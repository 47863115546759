import React from 'react';
import LazyLoading from '../../../components/LazyLoading';
import { PurchaseOrderStatus } from '../../../app/models/PurchaseOrder';
import StickyBox from "react-sticky-box";
import { PurchaseOrderStatuses } from '../../../app/shared/enums';

interface Props {
    requestChangeStatus: (status: string) => void;
    fetchingChangeStatus: boolean;
    statuses: PurchaseOrderStatus[];
    status: string;
}

const PurchaseOrderChangeStatus = ({ 
    requestChangeStatus, 
    fetchingChangeStatus, 
    statuses, 
    status 
}: Props) => {

    const parseStatusToName = (state: string) => {
        
        const statusObj = statuses.find((status) => status.id === state);

        return statusObj ? statusObj.name : '';
    };

    const nextStatus = (state: string) => {
     
        const transitionObj = statuses.find((status) => status.id === state);

        return transitionObj ? String(transitionObj.next) : '';
    };

    const prevStatus = (state: string) => {
      
        const transitionObj = statuses.find((status) => status.id === state);

        return transitionObj ? String(transitionObj.prev) : '';
    };

    return fetchingChangeStatus ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row justify-content-center align-items-center">
                <div className="row sticky-status">
                    <div className="col-4 text-center">
                        {parseStatusToName(prevStatus(String(status))) !== '' && (
                            <>
                                {/*<div className="h3 mt-0 mb-2 card-title">Anterior Estado</div>*/}
                                <button
                                    className="btn btn-primary  mt-2"
                                    onClick={() => {
                                        requestChangeStatus(prevStatus(String(status)));
                                    }}
                                >
                                    {parseStatusToName(prevStatus(String(status)))}
                                </button>
                            </>
                        )}
                    </div>

                    <div className="col-4 text-center">
                        <div className="h3 mt-0 mb-2 card-title text-center">Estado actual</div>

                        <div className="h5 mt-0 mb-2 card-title text-center">
                            <strong>{parseStatusToName(String(status))}</strong>
                        </div>
                    </div>

                    <div className="col-4 float-end text-center">
                        {parseStatusToName(nextStatus(String(status))) !== '' && (
                            <>
                                {/* <div className="h3 mt-0 mb-2 card-title text-center">
                                    Siguiente Estado
                                </div> */}

                                <button
                                    className={`btn btn-${nextStatus(String(status))!== PurchaseOrderStatuses.ANNULLED ? 'primary' : 'danger'} text-center mt-2`}
                                    onClick={() => {
                                        requestChangeStatus(nextStatus(String(status)));
                                    }}
                                >
                                    {parseStatusToName(nextStatus(String(status)))}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseOrderChangeStatus;
