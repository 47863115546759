import React from 'react';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentFilters
} from '../../../../app/models/ServiceManifestDocument';
import ServiceManifestDocumentFilter from './ServicemanifestDocumentFilter';

interface Props {
    serviceManifestDocuments: ServiceManifestDocumentDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (purchaseOrderDetailId: number, position: number) => void;
    destroy?: (purchaseOrderDetailId: number, position: number) => void;
    show?: (purchaseOrderDetailId: number, position: number) => void;
    filter: ServiceManifestDocumentFilters;
    setFilter: (filter: ServiceManifestDocumentFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const ServiceManifestDocumentDataTable = ({
    serviceManifestDocuments,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'Posición #',
            selector: (row: any) => row?.position,
            sortable: true,
            sortField: 'position',
            cell: (row: ServiceManifestDocumentDataForm, index: any, column: any) => (
                <span className="">{row?.position}</span>
            )
        },
        {
            name: 'Nombre',
            selector: (row: any) => row?.name,
            sortable: true,
            sortField: 'name',
            cell: (row: ServiceManifestDocumentDataForm, index: any, column: any) => (
                <span className="">{row?.name}</span>
            )
        },
        {
            name: 'Tipo de documento',
            selector: (row: any) => row?.document_type,
            sortable: true,
            sortField: 'document_type',
            cell: (row: ServiceManifestDocumentDataForm, index: any, column: any) => (
                <span className="">{row?.document_type}</span>
            )
        },
        {
            name: 'Documento',
            selector: (row: any) => row?.file,
            sortable: true,
            sortField: 'file',
            width: '120px',
            cell: (row: ServiceManifestDocumentDataForm, index: any, column: any) => (
                <>
                    {row.file_url ? (
                        <span className="text-info">
                            <a
                                href={row.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success btn-sm"
                                role="button"
                                aria-pressed="true"
                            >
                                Documento
                            </a>
                        </span>
                    ) : (
                        <span className="text-danger">
                            <p>No tiene documento</p>
                        </span>
                    )}
                </>
            )
        },
        {
            name: 'Creado',
            selector: (row: any) => row?.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: ServiceManifestDocumentDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.created_at)}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: ServiceManifestDocumentDataForm, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}

                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro'}
                            title={'Eliminar'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro'}
                            title={'Ver'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <ServiceManifestDocumentFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={serviceManifestDocuments}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default ServiceManifestDocumentDataTable;
