import { LOADING, LOGIN, LOGOUT, UPDATE_AUTH, UPDATE_AUTH_TOKEN } from './types';

import { AuthContextStateInterface } from './interfaces';
import { ReducerActionInterface } from '../Shared/interfaces';

const reducerAuthCustomer = (state: AuthContextStateInterface, action: ReducerActionInterface) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                auth: action.payload,
                authToken: action.payload.authToken,
                logged: true
            };

        case LOGOUT:
            return {
                ...state,
                auth: null,
                authToken: null,
                logged: false
            };

        case UPDATE_AUTH:
            return {
                ...state,
                auth: action.payload
            };

        case UPDATE_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload
            };

        case LOADING:
            return {
                ...state,
                loading: action.payload
            };

        default:
            return state;
    }
};

export default reducerAuthCustomer;
