import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';

const useCountryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetRegionsByCountry, setFetchingGetRegionsByCountry] = useState(false);

    const getRegionsByCountry = (country_id: number, events: ServiceEvents = {}) => {
        const url = EndPoints.REGIONS.GET_REGIONS_BY_COUNTRY;
        console.log(url.replace(':country_id', country_id.toString()))
        doGet({
            ...events,
            url: url.replace(':country_id', country_id.toString()),
            setFetching: setFetchingGetRegionsByCountry
        });
    };

    return {
        fetchingGetRegionsByCountry,
        getRegionsByCountry
    };
};

export default useCountryService;
