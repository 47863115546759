import { Client } from "./Client";
import { Provider } from "./Provider";
import { ProviderType } from "./ProviderType";

export interface BusinessName{
    id?: number;
    rut?: string | null;
    name?: string;
    email?: string;
    phone_code?: string;
    phone?: string;
    address?: string;
    logo?: File,
    logo_url?: string;
    is_national?: boolean;
    is_active?: boolean;
    provider_type?: ProviderType;
    provider_type_id?: number;
    clients?: BusinessName[];
    providers?: BusinessName[];
}

export interface BusinessNameDataForm{
    id?: number;
    rut?: string | null;
    name?: string;
    email?: string;
    phone_code?: string;
    phone?: string;
    address?: string;
    logo?: File,
    logo_url?: string;
    is_national?: boolean;
    is_active?: boolean;
    provider_type?: string;
    provider_type_id?: number;
    has_society?: boolean;
    has_provider?: boolean;
    has_client?: boolean
}

export const defaultBusinessNameForm: BusinessNameDataForm = {
    rut: '',
    name: '',
    email: '',
    phone_code: '',
    phone: '',
    address: '',
};

export interface BusinessNameFilters {
    id?: number | null;
    name?: string | '';
    email?: string | '';
    is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}