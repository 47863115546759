import React, { useState } from 'react';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useAuthService from '../../../app/services/hooks/useAuthService';
import { toast } from 'react-toastify';

type Props = {
    setSendEmail: (showChangePasswordForm: boolean) => void;
};

const recoveryPasswordForm = ({ setSendEmail }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [email, setEmail] = useState('');
    const { sendCodeRecoveryPassword } = useAuthService();

    const onClickSendCodeRecoveryPassword = () => {
        sendCodeRecoveryPassword(email, {
            onSuccess: (response: ServiceResponse) => {
                setSendEmail(true);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            },
            onFieldError: (response: ServiceResponse) => {
                setErrors(response.data);
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <p>
                        Para recuperar tu contraseña, ingresa tu correo electrónico y te enviaremos
                        un código de verificación.
                    </p>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <input
                        name="email"
                        id="email"
                        type="email"
                        className={`form-control ${fieldHasError('email')}`}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese un correo electrónico"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button
                        onClick={onClickSendCodeRecoveryPassword}
                        type="submit"
                        className="btn btn-primary waves-effect waves-light w-100"
                    >
                        Enviar código
                    </button>
                </div>
            </div>
        </>
    );
};

export default recoveryPasswordForm;
