import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import { Tax } from '../../../../app/models/Tax';
import LazyLoading from '../../../../components/LazyLoading';
import { PurchaseOrderDetail } from '../../../../app/models/PurchaseOrderDetail';
import { Items, ServiceType } from '../../../../app/shared/enums';
import { ServiceManifestDetailDataForm } from '../../../../app/models/ServiceManifestDetail';
import { Batch } from '../../../../app/models/Batch';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { Provider } from '../../../../app/models/Provider';
import DispatchProgramItemForm from '../../../DispatchProgram/components/forms/DispatchProgramItemDailyCarrierForm';
import { Carrier } from '../../../../app/models/Carrier';
import { Truck } from '../../../../app/models/Truck';
import { Hopper } from '../../../../app/models/Hopper';
import DispatchProgramItemGuideForm from '../../../DispatchProgram/components/forms/DispatchProgramItemGuideForm';

interface Props {
    serviceManifestDetailDataForm: ServiceManifestDetailDataForm;
    setServiceManifestDetailDataForm: (
        serviceManifestDetailDataForm: ServiceManifestDetailDataForm
    ) => void;
    purchaseOrderDetails: PurchaseOrderDetail[];
    itemType: string;
    batches: Batch[];
    providers: Provider[];
    carriers: Carrier[];
    trucks: Truck[];
    hoppers: Hopper[];
    purchaseOrderRef: number;
    errorFields?: any;
    itemLoading: boolean;
    resultValue: number;
    setResultValue: (value: number) => void;
    referenceQuantity: number;
    setReferenceQuantity: (value: number) => void;
}

const ServiceManifestDetailForm = ({
    serviceManifestDetailDataForm,
    setServiceManifestDetailDataForm,
    purchaseOrderDetails,
    itemType,
    batches,
    purchaseOrderRef,
    errorFields,
    itemLoading,
    resultValue,
    setResultValue,
    referenceQuantity,
    setReferenceQuantity,
    providers,
    carriers,
    trucks,
    hoppers
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    // const [resultValue, setResultValue] = useState<number>(0);
    // const referenceQuantity = useRef<number>(0);
    // const [quantityPurchaseOrderDetail, setQuantityPurchaseOrderDetail] = useState<number>(0);
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    // useEffect(() => {
    //     console.log("aaaaaa")
    //     setResultValue(serviceManifestDetailDataForm.suggested_quantity ?? 0);
    //     referenceQuantity.current = serviceManifestDetailDataForm.quantity ?? 0;
    //     // setQuantityPurchaseOrderDetail(
    //     //     serviceManifestDetailDataForm.quantity_purchase_order_detail ?? 0
    //     // );
    // }, [serviceManifestDetailDataForm.suggested_quantity]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        if (name == 'quantity') {
            let inputNumber = parseFloat(value);
            
            if (isNaN(inputNumber)) {
                setServiceManifestDetailDataForm({
                    ...serviceManifestDetailDataForm,
                    quantity: undefined
                });
                inputNumber = 0;
                const result = inputNumber - referenceQuantity;
                setResultValue(resultValue - result);
                setReferenceQuantity(inputNumber)
                
                return;
            } else if (inputNumber < 0) {
                inputNumber = -1 * inputNumber;
            }
            setServiceManifestDetailDataForm({
                ...serviceManifestDetailDataForm,
                quantity: inputNumber
            });

            const result = inputNumber - referenceQuantity;
            setResultValue(Math.round((resultValue - result) * 100) / 100);
            setReferenceQuantity(inputNumber)
            
        } else {
            setServiceManifestDetailDataForm({ ...serviceManifestDetailDataForm, [name]: value });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'purchase_order_detail_id' && value == undefined) {
            const purchase_order_detail = purchaseOrderDetails.find((p) => p.id === value);
            if (purchase_order_detail) {
                obj.price = 0;
                obj.quantity = 0;
                obj.product = '';
                obj.currency_price = '';
                obj.unit_price = '';
                obj.unit = '';
                obj.json_tax = null;
                obj.batch_id = 0;
            }

            setServiceManifestDetailDataForm({ ...serviceManifestDetailDataForm, ...obj });
        } else {
            setServiceManifestDetailDataForm({ ...serviceManifestDetailDataForm, ...obj });
        }
    };


    if (!serviceManifestDetailDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                        Posición orden de compra #{purchaseOrderRef}
                    </label>
                    <SelectTwo
                        name="purchase_order_detail_id"
                        id="purchase_order_detail_id"
                        inputValue={serviceManifestDetailDataForm.purchase_order_detail_id}
                        options={SelectTwoMapperOptions(purchaseOrderDetails, 'id', ['name'])}
                        hasError={fieldHasError('purchase_order_detail_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('purchase_order_detail_id', value.value)
                        }
                        placeholder={'Seleccione posición'}
                        onFocus={() => onFocusRemove('purchase_order_detail_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('purchase_order_detail_id')}
                    </div>
                </div>
            </div>

            {(itemType == Items.PRODUCT || itemType == Items.SERVICE) && (
                <>
                    {itemType == Items.PRODUCT ? (
                        <>
                            
                            <div className="mb-2 row">
                                <div className="col-md-12">
                                    <label className="col-form-label">Producto</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="product"
                                        id="product"
                                        value={serviceManifestDetailDataForm.item_name}
                                        disabled={true}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('product_id')}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <div className="col-md-12">
                                    <label className="col-form-label">Lote</label>
                                    <SelectTwo
                                        name="batch_id"
                                        id="batch_id"
                                        inputValue={serviceManifestDetailDataForm.batch_id}
                                        options={SelectTwoMapperOptions(batches ?? [], 'id', [
                                            'name'
                                        ])}
                                        hasError={fieldHasError('batch_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('batch_id', value.value)
                                        }
                                        placeholder={'Seleccione lote'}
                                        onFocus={() => onFocusRemove('batch_id')}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('batch_id')}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="mb-2 row">
                            <div className="col-md-12">
                                <label className="col-form-label">Servicio</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="service"
                                    id="service"
                                    value={serviceManifestDetailDataForm.item_name}
                                    disabled={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('service_id')}
                                </div>
                            </div>
                        </div>
                    )}

                    {itemLoading ? (
                        <LazyLoading height="300" />
                    ) : (
                        <>
                            <div className="mb-2 row">
                                <label className="col-md-12 col-form-label">Precio</label>
                                <div className="col-md-12">
                                    <div className="row g-1">
                                        <div className="col-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="price"
                                                id="price"
                                                value={serviceManifestDetailDataForm.price}
                                                disabled={true}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('price')}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="currency_price"
                                                id="currency_price"
                                                value={serviceManifestDetailDataForm.currency_price}
                                                disabled={true}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('currency_price_id')}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="unit_price"
                                                id="unit_price"
                                                value={serviceManifestDetailDataForm.unit_price}
                                                disabled={true}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('unit_price_id')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-2 row">
                                    <label className="col-md-12 col-form-label">
                                        Cantidad{' '}
                                        <span
                                            className={
                                                resultValue >= 0 ? 'text-info' : 'text-danger'
                                            }
                                        >
                                            faltan: {resultValue}/{serviceManifestDetailDataForm.quantity_purchase_order_detail} (
                                            {( Math.round((resultValue / serviceManifestDetailDataForm.quantity_purchase_order_detail!) * 100) / 100) * 100}%)
                                        </span>
                                    </label>
                                    <div className="col-md-12">
                                        <div className="row g-1">
                                            <div className="col-6">
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    id="quantity"
                                                    className={`form-control ${fieldHasError(
                                                        'quantity'
                                                    )}`}
                                                    value={serviceManifestDetailDataForm.quantity}
                                                    onChange={handleChange}
                                                    placeholder="Ingrese cantidad"
                                                    onFocus={() => onFocusRemove('quantity')}
                                                />
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: 'flex' }}
                                                >
                                                    {fieldErrorMessage('quantity')}
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="unit"
                                                    id="unit"
                                                    value={serviceManifestDetailDataForm.unit}
                                                    disabled={true}
                                                />
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: 'flex' }}
                                                >
                                                    {fieldErrorMessage('unit_id')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <div className="mb-2 row">
                                <div className="col-md-12">
                                    <div>
                                        <label className="col-form-label">Impuestos</label>

                                        {serviceManifestDetailDataForm.json_tax ? (
                                            <div className="fs-4">
                                                {serviceManifestDetailDataForm.json_tax.map(
                                                    (tax: Tax) => (
                                                        <span
                                                            key={tax.id}
                                                            className="badge bg-info mx-1"
                                                        >
                                                            {formatMoney(
                                                                Number(
                                                                    serviceManifestDetailDataForm.price
                                                                ) * Number(tax.value)
                                                            )}{' '}
                                                            ({tax.name})
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <div className="fs-6">
                                                No tiene impuestos asignados.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {
                                serviceManifestDetailDataForm.service_type === ServiceType.TRANSPORT &&
                                
                                    <div className='mt-3'>
                                        <hr/>
                                        <h5 className="mb-3">Datos Despacho</h5>
                                        <div className="mb-4 row">
                                       
                                        <label className="col-md-3 col-form-label">Fecha despacho</label>
                                        <div className="col-md-9">
                                            <input
                                                className={`form-control ${fieldHasError('dispatch_date')}`}
                                                name="dispatch_date"
                                                id="dispatch_date"
                                                type="date"
                                                value={serviceManifestDetailDataForm.dispatch_date}
                                                onChange={handleChange}
                                                onFocus={() => onFocusRemove('dispatch_date')}
                                            />
                                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                                {fieldErrorMessage('dispatch_date')}
                                            </div>
                                        </div>
                                        </div>
                                    <DispatchProgramItemForm 
                                        dailyCarrier={serviceManifestDetailDataForm}
                                        setDailyCarrier={setServiceManifestDetailDataForm}
                                        carriers={carriers}
                                        providers={providers}
                                        trucks={trucks}
                                        hoppers={hoppers}
                                        errorFields={errorFields}
                                    />
                                    <DispatchProgramItemGuideForm
                                        guide={serviceManifestDetailDataForm}
                                        setGuide={setServiceManifestDetailDataForm}
                                        errorFields={errorFields}
                                        labelDate='Fecha Emisión Guía'

                                    />
                                </div>
                                
                            }
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ServiceManifestDetailForm;
