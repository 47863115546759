import React, { useEffect, useState } from 'react';
import { defaultProvider, Provider } from '../../../app/models/Provider';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import useSweetAlert from '../../../hooks/useSweetAlert';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import {
    RouteSegment,
    RouteSegmentDataForm,
    defaultRouteSegmentDataForm
} from '../../../app/models/RouteSegment';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';
import RouteSegmentFormContainer from './RouteSegmentFormContainer';
import { toast } from 'react-toastify';
import QuoteRouteSegmentFormContainer from './QuoteRouteSegmentFormContainer';
import {
    QuoteRouteSegmentDataForm,
    defaultQuoteRouteSegmentDataForm
} from '../../../app/models/QuoteRouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';

interface Props {
    quoteRouteSegmentIdEdit: number;
    quoteId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const QuoteRouteSegmentEdit = ({
    quoteRouteSegmentIdEdit,
    quoteId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        fetchingEditQuoteRouteSegment,
        editQuoteRouteSegment,
        fetchingUpdateQuoteRouteSegment,
        updateQuoteRouteSegment,
        fetchingShowRsToQuoteRouteSegment,
        showRsToQuoteRouteSegment
    } = useQuoteRouteSegmentService();

    const [units, setUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [errorGetData, setErrorGetData] = useState('');
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);

    const [quoteRouteSegment, setQuoteRouteSegment] = useState<QuoteRouteSegmentDataForm>({
        ...defaultQuoteRouteSegmentDataForm,
        ['quote_id']: quoteId,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0
    });

    const [quoteRouteSegmentEdit, setQuoteRouteSegmentEdit] = useState<QuoteRouteSegmentDataForm>({
        ...defaultQuoteRouteSegmentDataForm,
        ['quote_id']: quoteId,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0
    });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editQuoteRouteSegment(quoteRouteSegmentIdEdit, {
            onSuccess: (response: ServiceResponse) => {
                setUnits(response.data.units);
                setCurrencies(response.data.currencies);
                setRouteSegments(response.data.route_segments);
                setQuoteRouteSegment(response.data.quote_route_segment);
                setQuoteRouteSegmentEdit(response.data.quote_route_segment);
            },
            onError: (response: ServiceResponse) => {
                setErrorGetData(response.message);
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (
            quoteRouteSegment.route_segment_id &&
            quoteRouteSegment.route_segment_id == quoteRouteSegmentEdit.route_segment_id
        ) {
            console.log(quoteRouteSegmentEdit);
            setQuoteRouteSegment({
                ...quoteRouteSegment,
                ...defaultQuoteRouteSegmentDataForm,
                ...quoteRouteSegmentEdit
            });
        } else if (
            quoteRouteSegment.route_segment_id &&
            quoteRouteSegment.route_segment_id != quoteRouteSegmentEdit.route_segment_id
        ) {
            showRsToQuoteRouteSegment(quoteRouteSegment.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    setQuoteRouteSegment({
                        ...quoteRouteSegment,
                        ...defaultQuoteRouteSegmentDataForm,
                        ...response.data.route_segment
                    });
                }
            });
        }
    }, [quoteRouteSegment.route_segment_id]);

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando tramo...');
        updateQuoteRouteSegment(quoteRouteSegmentIdEdit, quoteRouteSegment, {
            onSuccess: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditQuoteRouteSegment || fetchingShowRsToQuoteRouteSegment ? (
        <LazyLoading height="300" />
    ) : (
        <QuoteRouteSegmentFormContainer
            fetching={fetchingUpdateQuoteRouteSegment}
            action={update}
            cancel={cancel}
            quoteRouteSegmentForm={quoteRouteSegment}
            setQuoteRouteSegmentForm={setQuoteRouteSegment}
            routeSegments={routeSegments}
            currencies={currencies}
            units={units}
            errorFields={errorFields}
        />
    );
};

export default QuoteRouteSegmentEdit;
