import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useClientContactService from '../../../app/services/hooks/useClientContactService';
import { ClientContactDataForm, ClientContactFilters } from '../../../app/models/ClientContact';
import DefaultToolBar from '../../../components/default/DefaultToolBar';

import ClientContactCreate from './ClientContactCreate';
import ClientContactEdit from './ClientContactEdit';
import ClientContactDataTable from './tables/ClientContactDataTable';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';
import BusinessNameContactDataTable from '../../BusinessName/components/tables/BusinessNameContactDataTable';
import { BusinessNameContactDataForm } from '../../../app/models/BusinessNameContact';

interface Props {
    clientId: number;
    isShow?: boolean;
}

const ClientContactContainer = ({ clientId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllBusinessNameContactsByClient,
        fetchingGetAllBusinessNameContactsByClient
    } = useBusinessNameContactService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [clientContactIdEdit, setClientContactIdEdit] = useState<number>(-1);
    const [clientContactIdShow, setClientContactIdShow] = useState<number>(-1);
    
    const [clientContacts, setClientContacts] = useState<BusinessNameContactDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<ClientContactFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (clientContactId: number) => {
        setShowingEdit(true);
        setClientContactIdEdit(clientContactId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setClientContactIdEdit(-1);
    };

    const showShow = (clientContactId: number) => {
       
        setShowingShow(true);
        setClientContactIdShow(clientContactId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setClientContactIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);


    const reloadTable = () => {
        getAllBusinessNameContactsByClient(filter, clientId, {
            onSuccess: (response: ServiceResponse) => {
                setClientContacts(response.data.client_contacts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Contactos cliente</div>
                <div className="row">
                   
                    <div className="col-12">
                            <BusinessNameContactDataTable
                                contacts={clientContacts}
                                loading={fetchingGetAllBusinessNameContactsByClient}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                            />
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear contacto cliente"
                    backdrop={true}
                    showFooter={false}
                >
                    <ClientContactCreate
                        clientId={clientId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title='Editar contacto cliente'
                    backdrop={true}
                    showFooter={false}
                >
                    <ClientContactEdit
                        clientContactId={clientContactIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        
                        onCancel={hideEdit}
                        onError={hideEdit}
                        
                    />
                </DefaultModal>
            ) : null} 

            {/* {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title='Ver contacto cliente'
                    backdrop={true}
                    showFooter={false}
                >
                    <ClientContactShow
                        clientContactId={clientContactIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}  */}
        </>
    );
};

export default ClientContactContainer;
