import React from 'react';

import SocietySignatureForm from './form/SocietySignatureForm';
import { SocietyDataForm } from '../../../app/models/Society';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Society } from '../../../app/models/Society';
import { SocietySignatureDataForm } from '../../../app/models/SocietySignature';
import { User } from '../../../app/models/User';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    societySignatureForm: SocietySignatureDataForm;
    setSocietySignatureForm: (society: SocietySignatureDataForm) => void;
    societies: Society[];
    users: User[];
    errorFields?: any;
    setErrorFields: (errors: any) => void;
}

const SocietySignatureFormContainer = ({
    fetching,
    action,
    cancel,
    societySignatureForm,
    setSocietySignatureForm,
    societies,
    users,
    errorFields = null,
    setErrorFields
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <SocietySignatureForm
                        societySignatureForm={societySignatureForm}
                        setSocietySignatureDataForm={setSocietySignatureForm}
                        societies={societies}
                        users={users}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default SocietySignatureFormContainer;
