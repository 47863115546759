import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { SalesInvoiceHistoryFilters } from '../../models/SalesInvoiceHistory';

const useSalesInvoiceHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetSalesInvoiceHistoriesByInvoice,
        setFetchingGetSalesInvoiceHistoriesByInvoice
    ] = useState(false);

    const getAllSalesInvoiceHistoriesByInvoice = (
        salesInvoiceId: number,
        filter: SalesInvoiceHistoryFilters,
        events: ServiceEvents = {}
    ) => {

        const queryString = Object.entries(filter)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
        const url = `${EndPoints.SALES_INVOICE_HISTORY.GET_SALES_INVOICE_HISTORIES_BY_INVOICE}?${queryString}`;

        doGet({
            ...events,
            url: url.replace(
                ':sales_invoice_id',
                salesInvoiceId.toString()
            ),
            setFetching: setFetchingGetSalesInvoiceHistoriesByInvoice
        });
    };

    return {
        getAllSalesInvoiceHistoriesByInvoice,
        fetchingGetSalesInvoiceHistoriesByInvoice
    };
};

export default useSalesInvoiceHistoryService;
