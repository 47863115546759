import { CenterDataForm, CenterFilters } from '../../../../app/models/Center';
import { SocietyDataForm, SocietyFilters } from '../../../../app/models/Society';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import CenterSwitchChange from '../CenterSwitchChange';
import CenterFilter from './CenterFilter';

interface Props {
    centers: CenterDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: CenterFilters;
    setFilter: (filter: CenterFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const CenterDataTable = ({
    centers,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: CenterDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.business_name,
            sortable: true,
            sortField: 'name',
            cell: (row: CenterDataForm, index: any, column: any) => (
                <span className="">{row.name}</span>
            )
        },
        {
            name: 'País',
            selector: (row: any) => row.business_name,
            sortable: true,
            sortField: 'name',
            cell: (row: CenterDataForm, index: any, column: any) => (
                <span className="">{row.country}</span>
            )
        },
        {
            name: 'Comuna',
            selector: (row: any) => row.commune,
            sortable: true,
            sortField: 'commune',
            cell: (row: CenterDataForm, index: any, column: any) => (
                <span className="">{row.commune}</span>
            )
        },
        {
            name: 'Es propio',
            selector: (row: any) => row.business_name,
            sortable: true,
            sortField: 'name',
            cell: (row: CenterDataForm, index: any, column: any) => (
                <span className="">{row.is_owner}</span>
            )
        },

        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: CenterDataForm, index: any, column: any) => (
                <CenterSwitchChange
                    checkValue={row.is_active ?? true}
                    centerId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <CenterFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={centers}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default CenterDataTable;
