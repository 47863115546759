import React from 'react';

const Dashboard = () => {
    return (
        <div className="row">
            {/*<iframe title='El Origen - Duplicado de Página 2' width='1024' height='804'*/}
            {/*src='https://app.powerbi.com/view?r=eyJrIjoiZDNmNjIwNDEtMDFkZi00Nzg1LTk0MzItZjdmZTIzZDVmZmY0IiwidCI6IjM1ZjU5YTcwLTZlMzctNGUwOS1iMTJhLTg4YTU4NDQ1ZGVhZSJ9'*/}
            {/*frameBorder='0' />*/}

            <iframe
                title="Logistica y Despacho - Duplicado de Duplicado de Duplicado de Duplicado de Despachos"
                width="1920"
                height="1080"
                src="https://app.powerbi.com/view?r=eyJrIjoiOTg4NTBhNDgtMDgyZS00YzRkLTg1ZWQtZjhiMTRlNjhmMGRiIiwidCI6IjM1ZjU5YTcwLTZlMzctNGUwOS1iMTJhLTg4YTU4NDQ1ZGVhZSJ9"
                frameBorder="0"
            ></iframe>
        </div>
    );
};
export default Dashboard;
