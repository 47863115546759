import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ProductDataForm } from '../../../app/models/Product';
import ProductForm from './forms/ProductForm';
import { Unit } from '../../../app/models/Unit';
import { PackingForm } from '../../../app/models/PackingForm';
import { OriginArea } from '../../../app/models/OriginArea';
import { Sector } from '../../../app/models/Sector';
import { Currency } from '../../../app/models/Currency';
import { Tax } from '../../../app/models/Tax';
import { ItemCategory } from '../../../app/models/ItemCategory';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    product: ProductDataForm;
    setProduct: (product: ProductDataForm) => void;
    currencies: Currency[];
    priceUnits: Unit[];
    units: Unit[];
    packingForms: PackingForm[];
    originAreas: OriginArea[];
    sectors: Sector[];
    itemCategories: ItemCategory[];
    taxesData: Tax[];
    errorFields?: any;
}

const ProductFormContainer = ({
    fetching,
    action,
    cancel,
    product,
    setProduct,
    currencies,
    priceUnits,
    units,
    packingForms,
    originAreas,
    sectors,
    itemCategories,
    taxesData,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ProductForm
                        productDataForm={product}
                        setProductDataForm={setProduct}
                        packingForms={packingForms}
                        sectors={sectors}
                        originAreas={originAreas}
                        itemCategories={itemCategories}
                        currencies={currencies}
                        priceUnits={priceUnits}
                        units={units}
                        taxesData={taxesData}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ProductFormContainer;
