import React, { useContext, useEffect, useState } from 'react';

import { defaultTruck, Truck } from '../../../app/models/Truck';
import useTruckService from '../../../app/services/hooks/useTruckService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import TruckFormContainer from './TruckFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useSweetAlert from '../../../hooks/useSweetAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TruckCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { fetchingStoreTruck, storeTruck, createTruck, fetchingCreateTruck } = useTruckService();

    const [truck, setTruck] = useState<Truck>(defaultTruck);

    const [errorFields, setErrorFields] = useState<any>();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createTruck({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        let idsHopper = [];
        if (truck.hoppers_assigned) {
            idsHopper = truck.hoppers_assigned.map((x: any) => x.value);
        }
        storeTruck(truck, idsHopper, {
            onSuccess: (response: ServiceResponse) => {
                if (onSaved) onSaved();
            },
            onError: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateTruck ? (
        <LazyLoading height={300} />
    ) : (
        <TruckFormContainer
            fetching={fetchingStoreTruck}
            action={store}
            cancel={cancel}
            truck={truck}
            setTruck={setTruck}
            errorFields={errorFields}
        />
    );
};

export default TruckCreate;
