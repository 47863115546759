import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { BusinessNameDataForm, defaultBusinessNameForm } from '../../../app/models/BusinessName';
import useBusinessNamesService from '../../../app/services/hooks/useBusinessService';
import { ProviderType } from '../../../app/models/ProviderType';
import BusinessNameForm from './forms/BusinessNameForm';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BusinessNameCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const { fetchingStoreBusinessName, storeBusinessName, fetchingCreateBusinessName, createBusinessName } =
        useBusinessNamesService();

    const [businessName, setbusinessName] = useState<BusinessNameDataForm>(defaultBusinessNameForm);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    
        

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createBusinessName({
            onSuccess: (response: ServiceResponse) => {
          
                setProviderTypes(response.data.provider_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
   
        if (showLoading) showLoading('loading', 'Guardando razón social...');
        storeBusinessName(businessName, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateBusinessName ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BusinessNameForm
                        businessNameDataForm={businessName}
                        setBusinessNameDataForm={setbusinessName}
                        providerTypes={providerTypes}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreBusinessName} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreBusinessName} />
                </div>
            </div>
        </>
    );
};

export default BusinessNameCreate;
