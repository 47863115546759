import { FocusEvent } from 'react';
import * as animationIcon from '../utils/lottieAnimations';



export const nationalityOptions = [
    { value: 'Afgana', label: 'Afgana' },
    { value: 'Albanesa', label: 'Albanesa' },
    { value: 'Alemana', label: 'Alemana' },
    { value: 'Andorrana', label: 'Andorrana' },
    { value: 'Angoleña', label: 'Angoleña' },
    { value: 'Antiguana', label: 'Antiguana' },
    { value: 'Argelina', label: 'Argelina' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Australiana', label: 'Australiana' },
    { value: 'Austríaca', label: 'Austríaca' },
    { value: 'Azerbaiyana', label: 'Azerbaiyana' },
    { value: 'Bahameña', label: 'Bahameña' },
    { value: 'Bangladesí', label: 'Bangladesí' },
    { value: 'Barbadense', label: 'Barbadense' },
    { value: 'Belga', label: 'Belga' },
    { value: 'Beliceña', label: 'Beliceña' },
    { value: 'Beninesa', label: 'Beninesa' },
    { value: 'Birmana', label: 'Birmana' },
    { value: 'Boliviana', label: 'Boliviana' },
    { value: 'Bosnia', label: 'Bosnia' },
    { value: 'Botsuana', label: 'Botsuana' },
    { value: 'Brasileña', label: 'Brasileña' },
    { value: 'Británica', label: 'Británica' },
    { value: 'Bruneana', label: 'Bruneana' },
    { value: 'Búlgara', label: 'Búlgara' },
    { value: 'Burkinesa', label: 'Burkinesa' },
    { value: 'Burundesa', label: 'Burundesa' },
    { value: 'Butanesa', label: 'Butanesa' },
    { value: 'Caboverdiana', label: 'Caboverdiana' },
    { value: 'Camboyana', label: 'Camboyana' },
    { value: 'Camerunesa', label: 'Camerunesa' },
    { value: 'Canadiense', label: 'Canadiense' },
    { value: 'Catarí', label: 'Catarí' },
    { value: 'Chadiana', label: 'Chadiana' },
    { value: 'Chilena', label: 'Chilena' },
    { value: 'China', label: 'China' },
    { value: 'Chipriota', label: 'Chipriota' },
    { value: 'Colombiana', label: 'Colombiana' },
    { value: 'Comorense', label: 'Comorense' },
    { value: 'Congoleña', label: 'Congoleña' },
    { value: 'Costarricense', label: 'Costarricense' },
    { value: 'Croata', label: 'Croata' },
    { value: 'Cubana', label: 'Cubana' },
    { value: 'Danesa', label: 'Danesa' },
    { value: 'Dominiquesa', label: 'Dominiquesa' },
    { value: 'Ecuatoriana', label: 'Ecuatoriana' },
    { value: 'Egipcia', label: 'Egipcia' },
    { value: 'Emiratí', label: 'Emiratí' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Escocesa', label: 'Escocesa' },
    { value: 'Eslovaca', label: 'Eslovaca' },
    { value: 'Eslovena', label: 'Eslovena' },
    { value: 'Española', label: 'Española' },
    { value: 'Estadounidense', label: 'Estadounidense' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Etíope', label: 'Etíope' },
    { value: 'Feroesa', label: 'Feroesa' },
    { value: 'Fiyiana', label: 'Fiyiana' },
    { value: 'Filipina', label: 'Filipina' },
    { value: 'Finlandesa', label: 'Finlandesa' },
    { value: 'Francesa', label: 'Francesa' },
    { value: 'Gabonesa', label: 'Gabonesa' },
    { value: 'Gambiana', label: 'Gambiana' },
    { value: 'Georgiana', label: 'Georgiana' },
    { value: 'Ghanesa', label: 'Ghanesa' },
    { value: 'Gibraltareña', label: 'Gibraltareña' },
    { value: 'Granadina', label: 'Granadina' },
    { value: 'Griega', label: 'Griega' },
    { value: 'Groenlandesa', label: 'Groenlandesa' },
    { value: 'Guatemalteca', label: 'Guatemalteca' },
    { value: 'Guineana', label: 'Guineana' },
    { value: 'Guyanesa', label: 'Guyanesa' },
    { value: 'Haitiana', label: 'Haitiana' },
    { value: 'Hondureña', label: 'Hondureña' },
    { value: 'Húngara', label: 'Húngara' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iraquí', label: 'Iraquí' },
    { value: 'Irlandesa', label: 'Irlandesa' },
    { value: 'Iraní', label: 'Iraní' },
    { value: 'Islandesa', label: 'Islandesa' },
    { value: 'Israelí', label: 'Israelí' },
    { value: 'Italiana', label: 'Italiana' },
    { value: 'Jamaiquina', label: 'Jamaiquina' },
    { value: 'Japonesa', label: 'Japonesa' },
    { value: 'Jordana', label: 'Jordana' },
    { value: 'Kazaja', label: 'Kazaja' },
    { value: 'Keniata', label: 'Keniata' },
    { value: 'Kirguís', label: 'Kirguís' },
    { value: 'Kiribatiana', label: 'Kiribatiana' },
    { value: 'Kuwaití', label: 'Kuwaití' },
    { value: 'Laosiana', label: 'Laosiana' },
    { value: 'Lesotense', label: 'Lesotense' },
    { value: 'Letona', label: 'Letona' },
    { value: 'Libanesa', label: 'Libanesa' },
    { value: 'Liberiana', label: 'Liberiana' },
    { value: 'Libia', label: 'Libia' },
    { value: 'Liechtensteiniana', label: 'Liechtensteiniana' },
    { value: 'Lituana', label: 'Lituana' },
    { value: 'Luxemburguesa', label: 'Luxemburguesa' },
    { value: 'Macedonia', label: 'Macedonia' },
    { value: 'Malagasy', label: 'Malagasy' },
    { value: 'Malasia', label: 'Malasia' },
    { value: 'Malaui', label: 'Malaui' },
    { value: 'Maldiva', label: 'Maldiva' },
    { value: 'Maliense', label: 'Maliense' },
    { value: 'Maltesa', label: 'Maltesa' },
    { value: 'Marroquí', label: 'Marroquí' },
    { value: 'Mauriciana', label: 'Mauriciana' },
    { value: 'Mauritana', label: 'Mauritana' },
    { value: 'Mexicana', label: 'Mexicana' },
    { value: 'Micronesia', label: 'Micronesia' },
    { value: 'Moldava', label: 'Moldava' },
    { value: 'Monegasca', label: 'Monegasca' },
    { value: 'Mongola', label: 'Mongola' },
    { value: 'Montenegrina', label: 'Montenegrina' },
    { value: 'Mozambiqueña', label: 'Mozambiqueña' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauruana', label: 'Nauruana' },
    { value: 'Nepalí', label: 'Nepalí' },
    { value: 'Nicaragüense', label: 'Nicaragüense' },
    { value: 'Nigeriana', label: 'Nigeriana' },
    { value: 'Noruega', label: 'Noruega' },
    { value: 'Neozelandesa', label: 'Neozelandesa' },
    { value: 'Omaní', label: 'Omaní' },
    { value: 'Neerlandesa', label: 'Neerlandesa' }, // También conocida como 'Holandesa'
    { value: 'Pakistana', label: 'Pakistana' },
    { value: 'Palauana', label: 'Palauana' },
    { value: 'Panameña', label: 'Panameña' },
    { value: 'Papú', label: 'Papú' }, // De Papúa Nueva Guinea
    { value: 'Paraguaya', label: 'Paraguaya' },
    { value: 'Peruana', label: 'Peruana' },
    { value: 'Polaca', label: 'Polaca' },
    { value: 'Portuguesa', label: 'Portuguesa' },
    { value: 'Ruandesa', label: 'Ruandesa' },
    { value: 'Rumana', label: 'Rumana' },
    { value: 'Rusa', label: 'Rusa' },
    { value: 'Salvadoreña', label: 'Salvadoreña' },
    { value: 'Samoana', label: 'Samoana' },
    { value: 'Sanmarinense', label: 'Sanmarinense' },
    { value: 'Santotomense', label: 'Santotomense' },
    { value: 'Saudí', label: 'Saudí' },
    { value: 'Senegalesa', label: 'Senegalesa' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychellense', label: 'Seychellense' },
    { value: 'Sierraleonesa', label: 'Sierraleonesa' },
    { value: 'Singapurense', label: 'Singapurense' },
    { value: 'Siria', label: 'Siria' },
    { value: 'Somalí', label: 'Somalí' },
    { value: 'Srilanquesa', label: 'Srilanquesa' },
    { value: 'Suazi', label: 'Suazi' }, // o Swazi
    { value: 'Sudafricana', label: 'Sudafricana' },
    { value: 'Sudanesa', label: 'Sudanesa' },
    { value: 'Sueca', label: 'Sueca' },
    { value: 'Suiza', label: 'Suiza' },
    { value: 'Surinamesa', label: 'Surinamesa' },
    { value: 'Tailandesa', label: 'Tailandesa' },
    { value: 'Taiwanesa', label: 'Taiwanesa' },
    { value: 'Tanzana', label: 'Tanzana' },
    { value: 'Tayika', label: 'Tayika' },
    { value: 'Timorense', label: 'Timorense' },
    { value: 'Togolesa', label: 'Togolesa' },
    { value: 'Tongana', label: 'Tongana' },
    { value: 'Trinitense', label: 'Trinitense' },
    { value: 'Tunecina', label: 'Tunecina' },
    { value: 'Turcomana', label: 'Turcomana' },
    { value: 'Turca', label: 'Turca' },
    { value: 'Tuvaluana', label: 'Tuvaluana' },
    { value: 'Ucraniana', label: 'Ucraniana' },
    { value: 'Ugandesa', label: 'Ugandesa' },
    { value: 'Uruguaya', label: 'Uruguaya' },
    { value: 'Uzbeka', label: 'Uzbeka' },
    { value: 'Vanuatuense', label: 'Vanuatuense' },
    { value: 'Vaticana', label: 'Vaticana' },
    { value: 'Venezolana', label: 'Venezolana' },
    { value: 'Vietnamita', label: 'Vietnamita' },
    { value: 'Yemení', label: 'Yemení' },
    { value: 'Yibutiana', label: 'Yibutiana' },
    { value: 'Zambiana', label: 'Zambiana' },
    { value: 'Zimbabuense', label: 'Zimbabuense' }
];

export const countriesOptions = [
    { value: 'Perú', label: 'Perú' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Chile', label: 'Chile' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'México', label: 'México' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Brasil', label: 'Brasil' },
    { value: 'Panamá', label: 'Panamá' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'República Dominicana', label: 'República Dominicana' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'España', label: 'España' },
    { value: 'Estados Unidos', label: 'Estados Unidos' }
];

type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export const setInputError = (input: string, message: string) => {
    const element = document.getElementById(input);
    if (element) {
        const formGroup = element.parentElement!;
        element.classList.add('is-invalid');
        formGroup.querySelector('.invalid-feedback')!.innerHTML = message;
    }
};

//Se dividió en 3 para que no se queje con los tipos ya que uno es select y otro input y un textarea
export const setCleanInputError = (e: FocusEvent<HTMLInputElement>) => {
    setCleanError(e.target.id);
};

export const setCleanTextAreaError = (e: FocusEvent<HTMLTextAreaElement>) => {
    setCleanError(e.target.id);
};

export const setCleanSelectError = (e: FocusEvent<HTMLSelectElement>) => {
    setCleanError(e.target.id);
};

export const setCleanSelectTwoError = (e: FocusEvent<HTMLInputElement>) => {
    const id = e.currentTarget.parentNode?.parentNode?.parentNode?.parentElement?.id;
    setCleanError(String(id));
};

function setCleanError(id: string) {
    const element = document.getElementById(id);
    if (element) {
        const formGroup = element.parentElement!;
        element.classList.remove('is-invalid');
        formGroup.querySelector('.invalid-feedback')!.innerHTML = '';
    }
}


function isObject(value: any): value is object {
    return typeof value === 'object' && value !== null;
}

export function nullToUndefinedRecursive<T>(obj: Nullable<T>): T {
    const result: any = {};

    for (const key in obj) {
        if (obj[key] === null) {
            result[key] = undefined;
        } else if (isObject(obj[key])) {
            result[key] = nullToUndefinedRecursive(obj[key] as Nullable<any>);
        } else {
            result[key] = obj[key];
        }
    }

    return result;
}

export const formatAmount = (number: number) => {
    return new Intl.NumberFormat('de-DE').format(number);
};

export const formatNumber = (number: number) => {
    return new Intl.NumberFormat('de-DE').format(number);
};

export const formatMoney = (number: number, currency = 'CLP') => {
    if (!number) {
        number = 0;
    }

    if (currency == 'USD') {
        const formattedNumber = number.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return '$' + formattedNumber;
    }
    // Por defecto siempre formato CLP
    const formattedNumber = number.toLocaleString('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return '$' + formattedNumber;

    // return '$' + new Intl.NumberFormat('de-DE').format(number);
};

export const formatPhone = (number: string) => {
    const onlyNumbers = /^[0-9]+$/;

    if (number.match(onlyNumbers) && number.length <= 9) {
        return true;
    } else if (number == '') {
        return true;
    }

    return false;
};

export const phoneLength = (number: string) => {
    if (number.length <= 12) {
        return true;
    } else if (number == '') {
        return true;
    }
    return false;
};

export const capitalizeFirstLetterOfEachWord = (value: string, isSpace: boolean) => {
    if (isSpace) {
        let string = value
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(' ');

        string = string.replace(/[^a-zA-ZáéíóúÁÉÍÓÚ´ñÑ ]/g, '');

        return string;
    } else {
        let string = value
            .toLowerCase()
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(' ');

        string = string.replace(/[^a-zA-ZáéíóúÁÉÍÓÚ´ñÑ ]/g, '');

        return string;
    }
};

export const getShortSpanishDayName = (day: number) => {
    switch (day) {
        case 0:
            return 'DOM';
        case 1:
            return 'LUN';
        case 2:
            return 'MAR';
        case 3:
            return 'MIE';
        case 4:
            return 'JUE';
        case 5:
            return 'VIE';
        case 6:
            return 'SAB';
    }
};

export const reactSelectAdapter = (array: []) => {
    let data: any = [];
    array.map((item: any) => {
        data = [
            ...data,
            {
                value: item.id,
                label: item.name ? item.name : item.label
            }
        ];
    });
    return data;
};

export const getAnimationIcon = (name = 'loading') => {
    let animation: any = {};

    switch (name) {
        case 'loading':
            animation = animationIcon.loadingDefault;
            break;
        case 'truck':
            animation = animationIcon.truckAnimation;
            break;
        case 'routes':
            animation = animationIcon.routes;
            break;
        case 'download':
            animation = animationIcon.download;
            break;
        case 'downloadSuccess':
            animation = animationIcon.downloadSuccess;
            break;
        case 'deleteSuccess':
            animation = animationIcon.deleteSuccess;
            break;
        case 'success':
            animation = animationIcon.success;
            break;
        case 'warning':
            animation = animationIcon.warning;
            break;
        case 'info':
            animation = animationIcon.info;
            break;
        case 'error':
            animation = animationIcon.error;
            break;

        default:
            animation = animationIcon.loadingDefault;
            break;
    }

    return animation;
};
