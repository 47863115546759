import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { Items } from '../../../app/shared/enums';
import usePaymentStatusDetailService from '../../../app/services/hooks/usePaymentStatusDetailService';
import { PaymentStatusDetailDataForm, PaymentStatusDetailFilters } from '../../../app/models/PaymentStatusDetail';
import PaymentStatusDetailDataTable from './tables/PaymentiStatusDetailTable';


interface Props {
    paymentStatusId: number;
    isShow?: boolean;
}

const PaymentStatusDetailContainer = ({
    paymentStatusId,
    isShow = false,
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getPaymentStatusDetailsByPaymentStatus,
        fetchingGetPaymentStatusDetailByPaymentStatus,
    } = usePaymentStatusDetailService();

    const [showingShow, setShowingShow] = useState(false);
    
    const [PaymentStatusDetailIdShow, setPaymentStatusDetailIdShow] = useState<number>(-1);

    const [paymentStatusDetails, setPaymentStatusDetails] = useState<PaymentStatusDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    
    const [filter, setFilter] = useState<PaymentStatusDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getPaymentStatusDetailsByPaymentStatus(filter, paymentStatusId, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentStatusDetails(response.data.payment_status_details);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };


    const showShow = (PaymentStatusDetailId: number, position: number) => {
        setShowingShow(true);
        setPaymentStatusDetailIdShow(PaymentStatusDetailId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setPaymentStatusDetailIdShow(-1);
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
           
                    <div className="col-12 mb-2">
                    
                    </div>
              
                    <div className="col-12">
                
                        <PaymentStatusDetailDataTable
                            paymentStatusDetails={paymentStatusDetails}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            loading={fetchingGetPaymentStatusDetailByPaymentStatus}
                        />
           
                        
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default PaymentStatusDetailContainer;
