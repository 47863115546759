import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ProviderType, ProviderTypeFilters } from '../../models/ProviderType';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useProviderTypeService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetProviderTypes, setFetchingGetProviderTypes] = useState(false);
    const [fetchingShowProviderType, setFetchingShowProviderType] = useState(false);
    const [fetchingCreateProviderType, setFetchingCreateProviderType] = useState(false);
    const [fetchingStoreProviderType, setFetchingStoreProviderType] = useState(false);
    const [fetchingEditProviderType, setFetchingEditProviderType] = useState(false);
    const [fetchingUpdateProviderType, setFetchingUpdateProviderType] = useState(false);
    const [fetchingDeleteProviderType, setFetchingDeleteProviderType] = useState(false);

    const getProviderTypes = (placeFilter: ProviderTypeFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(placeFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.PROVIDER_TYPES.GET_PROVIDER_TYPES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetProviderTypes
        });
    };

    const showProviderType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_TYPES.GET_PROVIDER_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingShowProviderType
        });
    };

    const createProviderType = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_TYPES.CREATE_PROVIDER_TYPE,
            setFetching: setFetchingCreateProviderType
        });
    };

    const storeProviderType = (providerType: ProviderType, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PROVIDER_TYPES.STORE_PROVIDER_TYPE,
            body: providerType,
            setFetching: setFetchingStoreProviderType
        });
    };

    const editProviderType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDER_TYPES.EDIT_PROVIDER_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingEditProviderType
        });
    };

    const updateProviderType = (
        id: number,
        providerType: ProviderType,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.PROVIDER_TYPES.UPDATE_PROVIDER_TYPE.replace(':id', id.toString()),
            body: providerType,
            setFetching: setFetchingUpdateProviderType
        });
    };

    const deleteProviderType = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PROVIDER_TYPES.DELETE_PROVIDER_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteProviderType
        });
    };

    return {
        fetchingGetProviderTypes,
        fetchingShowProviderType,
        fetchingCreateProviderType,
        fetchingStoreProviderType,
        fetchingEditProviderType,
        fetchingUpdateProviderType,
        fetchingDeleteProviderType,
        getProviderTypes,
        showProviderType,
        createProviderType,
        storeProviderType,
        editProviderType,
        updateProviderType,
        deleteProviderType
    };
};

export default useProviderTypeService;
