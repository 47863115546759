import React, { useContext } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import ActiveControl from '../../../components/ActiveControl';
import useItemCategoryService from '../../../app/services/hooks/useItemCategoryService';

interface Props {
    itemCategoryId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const ItemCategorySwitchChange = ({ itemCategoryId, checkValue, callbackSuccess }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [checked, setChecked] = React.useState<boolean>(checkValue);

    const { activeItemCategory, fetchingActiveItemCategory } = useItemCategoryService();

    const active = (value: boolean) => {
        setChecked(value);

        activeItemCategory(itemCategoryId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={itemCategoryId} callback={active} />;
};

export default ItemCategorySwitchChange;
