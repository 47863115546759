import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { getAnimationIcon } from '../../utils/utils';
import { LoadingData } from '../../contexts/Shared/interfaces';
import { DEFAULT, PROGRESSIVE } from '../../contexts/AppContext/loadingTypes';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface Props {
    loading: boolean;
    loadingData: LoadingData;
    hideLoading: () => void;
}

interface DataLoading {
    animation?: any;
    message?: string;
}

const DefaultLoadingAnimation = ({
    loading,
    loadingData = {
        nameLoading: 'loading',
        messageLoading: 'Cargando...',
        finishAnimation: false,
        loadingType: DEFAULT,
        progressPercentage: 0
    },
    hideLoading
}: Props) => {
    const [animationData, setAnimationData] = useState<DataLoading>({});

    useEffect(() => {
        if (loadingData.loadingType == DEFAULT) {
            setAnimationData({
                animation: getAnimationIcon(loadingData.nameLoading),
                message: loadingData.messageLoading
            });
        }
    }, [loadingData.nameLoading]);

    const handleAnimationComplete = () => {
        let timeout = 0;
        if (loadingData.finishAnimation) {
            if (loadingData.nameLoading == 'error' || loadingData.nameLoading == 'success') {
                timeout = 1500;
            }
            setTimeout(() => {
                hideLoading();
            }, timeout);
        }
    };

    if (!loading) {
        return null;
    }

    return (
        <>
            {loadingData.loadingType == DEFAULT && (
                <div className="loading-all">
                    <div className="m-auto px-3 text-center w-75">
                        <div className="text-primary" role="status">
                            {/* <span className="sr-only">Loading...</span> */}
                            <div className="spinner-container">
                                <div className="spinner-body">
                                    <div className="spinner-loading">
                                        <>
                                            <Lottie
                                                animationData={animationData.animation}
                                                loop={true}
                                                style={{ width: '250px', height: '150px' }}
                                                onLoopComplete={handleAnimationComplete}
                                            />
                                            <div
                                                style={{
                                                    fontSize: '16px',
                                                    overflowWrap: 'break-word'
                                                }}
                                            >
                                                {animationData.message}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <p className="pt-3">{text ? text : 'Cargando...'}</p> */}
                    </div>
                </div>
            )}

            {loadingData.loadingType == PROGRESSIVE && (
                <div className="loading-all">
                    <div className="m-auto px-3 text-center w-75">
                        <div className="text-primary" role="status">
                            {/* <span className="sr-only">Loading...</span> */}
                            <div className="spinner-container">
                                <div className="spinner-body">
                                    <div className="mb-2 fs-3">{loadingData.nameLoading}</div>
                                    <div>
                                        {/* <ProgressBar style={{'height': '20px'}} striped variant="success" now={loadingData.progressPercentage} key={1} animated min={0} max={100} /> */}
                                        <ProgressBar style={{ height: '20px' }}>
                                            <ProgressBar
                                                animated
                                                striped
                                                variant="success"
                                                now={loadingData.progressPercentage}
                                                key={1}
                                                min={0}
                                                max={100}
                                            />
                                            <ProgressBar
                                                variant="warning"
                                                now={100 - loadingData.progressPercentage!}
                                                key={2}
                                            />
                                        </ProgressBar>
                                    </div>
                                    <div className="mt-2 text-center fw-bold fs-3">
                                        {loadingData.progressPercentage} %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DefaultLoadingAnimation;
