import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BusinessNameContactDataForm, BusinessNameContactFilters } from '../../models/BusinessNameContact';

const useBusinessNameContactService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetAllBusinessNameContacts, setFetchingGetAllBusinessNameContacts] = useState(false);
    const [fetchingGetAllBusinessNameContactsByBusinessName, setFetchingGetAllBusinessNameContactsByBusinessName] = useState(false);
    const [fetchingGetAllBusinessNameContactsByClient, setFetchingGetAllBusinessNameContactsByClient] = useState(false);
    const [fetchingGetAllBusinessNameContactsByProvider, setFetchingGetAllBusinessNameContactsByProvider] = useState(false);
    const [fetchingGetAllBusinessNameContactsBySociety, setFetchingGetAllBusinessNameContactsBySociety] = useState(false);
    const [fetchingShowBusinessNameContact, setFetchingShowBusinessNameContact] = useState(false);
    const [fetchingCreateBusinessNameContact, setFetchingCreateBusinessNameContact] = useState(false);
    const [fetchingStoreBusinessNameContact, setFetchingStoreBusinessNameContact] = useState(false);
    const [fetchingEditBusinessNameContact, setFetchingEditBusinessNameContact] = useState(false);
    const [fetchingUpdateBusinessNameContact, setFetchingUpdateBusinessNameContact] = useState(false);
    const [fetchingDeleteBusinessNameContact, setFetchingDeleteBusinessNameContact] = useState(false);
    const [fetchingActiveBusinessNameContact, setFetchingActiveBusinessNameContact] = useState(false);
    const [fetchingGetBusinessNameContactsByClient, setFetchingGetBusinessNameContactByClient] = useState(false);
    const [fetchingGetBusinessNameContactsByProvider, setFetchingGetBusinessNameContactByProvider] = useState(false);

    const getAllBusinessNameContacts = (filter: BusinessNameContactFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BUSINESS_NAME_CONTACTS.GET_ALL_BUSINESS_NAME_CONTACTS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetAllBusinessNameContacts
        });
    };

    const getAllBusinessNameContactsByBusinessName = (filter: BusinessNameContactFilters, business_name_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BUSINESS_NAME_CONTACTS.GET_ALL_BUSINESS_NAME_CONTACTS_BY_BUSINESS_NAME}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':business_name_id', business_name_id.toString()),
            setFetching: setFetchingGetAllBusinessNameContactsByBusinessName
        });
    };

    const getAllBusinessNameContactsByClient = (filter: BusinessNameContactFilters, client_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BUSINESS_NAME_CONTACTS.GET_ALL_BUSINESS_NAME_CONTACTS_BY_CLIENT}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':client_id', client_id.toString()),
            setFetching: setFetchingGetAllBusinessNameContactsByClient
        });
    };

    const getAllBusinessNameContactsByProvider = (filter: BusinessNameContactFilters, provider_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BUSINESS_NAME_CONTACTS.GET_ALL_BUSINESS_NAME_CONTACTS_BY_PROVIDER}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':provider_id', provider_id.toString()),
            setFetching: setFetchingGetAllBusinessNameContactsByProvider
        });
    };

    const getAllBusinessNameContactsBySociety = (filter: BusinessNameContactFilters, society_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BUSINESS_NAME_CONTACTS.GET_ALL_BUSINESS_NAME_CONTACTS_BY_SOCIETY}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':society_id', society_id.toString()),
            setFetching: setFetchingGetAllBusinessNameContactsBySociety
        });
    };

    const showBusinessNameContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.SHOW_BUSINESS_NAME_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingShowBusinessNameContact
        });
    };

    const createBusinessNameContact = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.CREATE_BUSINESS_NAME_CONTACT,
            setFetching: setFetchingCreateBusinessNameContact
        });
    };

    const storeBusinessNameContact = (businessNameContact: BusinessNameContactDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.STORE_BUSINESS_NAME_CONTACT,
            body: businessNameContact,
            setFetching: setFetchingStoreBusinessNameContact
        });
    };

    const editBusinessNameContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.EDIT_BUSINESS_NAME_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingEditBusinessNameContact
        });
    };

    const updateBusinessNameContact = (id: number, businessNameContact: BusinessNameContactDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.UPDATE_BUSINESS_NAME_CONTACT.replace(':id', id.toString()),
            body: businessNameContact,
            setFetching: setFetchingUpdateBusinessNameContact
        });
    };

    const deleteBusinessNameContact = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.DELETE_BUSINESS_NAME_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBusinessNameContact
        });
    };

    const activeBusinessNameContact = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.ACTIVE_BUSINESS_NAME_CONTACT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBusinessNameContact
        });
    };

    const getBusinessNameContactByClient = (client_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.GET_BUSINESS_NAME_CONTACT_BY_CLIENT.replace(':client_id', client_id.toString()),
            setFetching: setFetchingGetBusinessNameContactByClient
        });
    };

    const getBusinessNameContactByProvider = (provider_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BUSINESS_NAME_CONTACTS.GET_BUSINESS_NAME_CONTACT_BY_PROVIDER.replace(':provider_id', provider_id.toString()),
            setFetching: setFetchingGetBusinessNameContactByProvider
        });
    };

    return {
        fetchingGetAllBusinessNameContacts,
        fetchingGetAllBusinessNameContactsByBusinessName,
        fetchingGetAllBusinessNameContactsByClient,
        fetchingGetAllBusinessNameContactsByProvider,
        fetchingGetAllBusinessNameContactsBySociety,
        fetchingShowBusinessNameContact,
        fetchingCreateBusinessNameContact,
        fetchingStoreBusinessNameContact,
        fetchingEditBusinessNameContact,
        fetchingUpdateBusinessNameContact,
        fetchingDeleteBusinessNameContact,
        fetchingActiveBusinessNameContact,
        fetchingGetBusinessNameContactsByClient,
        fetchingGetBusinessNameContactsByProvider,
        getAllBusinessNameContacts,
        getAllBusinessNameContactsByBusinessName,
        getAllBusinessNameContactsByClient, 
        getAllBusinessNameContactsByProvider,
        getAllBusinessNameContactsBySociety,
        showBusinessNameContact,
        createBusinessNameContact,
        storeBusinessNameContact,
        editBusinessNameContact,
        updateBusinessNameContact,
        deleteBusinessNameContact,
        activeBusinessNameContact,
        getBusinessNameContactByClient,
        getBusinessNameContactByProvider
    };
};

export default useBusinessNameContactService;
