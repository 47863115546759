import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Country } from '../../../app/models/Country';
import { Region } from '../../../app/models/Region';
import { Commune } from '../../../app/models/Commune';
import { CenterDataForm } from '../../../app/models/Center';
import CenterForm from './forms/CenterForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    center: CenterDataForm;
    setCenter: (center: CenterDataForm) => void;
    countries: Country[];
    regions: Region[];
    communes: Commune[];
    setCommunes: (communes: Commune[]) => void;
    setRegions: (regions: Region[]) => void;
    fetchingRegions?: boolean;
    fetchingCommunes?: boolean;
    errorFields?: any;
}

const CenterFormContainer = ({
    fetching,
    action,
    cancel,
    center,
    setCenter,
    countries,
    regions,
    communes,
    setCommunes,
    setRegions,
    fetchingCommunes,
    fetchingRegions,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CenterForm
                        centerDataForm={center}
                        setCenterDataForm={setCenter}
                        countries={countries}
                        regions={regions}
                        communes={communes}
                        setCommunes={setCommunes}
                        setRegions={setRegions}
                        errorFields={errorFields}
                        fetchingCommunes={fetchingCommunes}
                        fetchingRegions={fetchingRegions}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default CenterFormContainer;
