import React, { useEffect, useState } from 'react';
import { Clasification } from '../../../../app/models/Clasification';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { setCleanTextAreaError } from '../../../../utils/utils';

interface ClasificationFormProps {
    clasificationDataForm: Clasification;
    setClasificationsDataForm: (clasification: Clasification) => void;
    errorFields?: any;
    type: number; // Asegurarse de que el prop type esté presente
}

const ClasificationForm: React.FC<ClasificationFormProps> = ({
    clasificationDataForm,
    setClasificationsDataForm,
    errorFields,
    type
}) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [editableDigit, setEditableDigit] = useState<string>(''); // Para el dígito editable
    const [accountNumberBase, setAccountNumberBase] = useState<string>(''); // Para la parte fija del número de cuenta

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);

    useEffect(() => {
        // Asegurarse de que el type se pase en el formulario
        setClasificationsDataForm({
            ...clasificationDataForm,
            type
        });
    }, [type, setClasificationsDataForm, clasificationDataForm]);

    useEffect(() => {
        if (clasificationDataForm.account_number) {
            const parts = clasificationDataForm.account_number.split('-');
            if (parts.length > 1) {
                setEditableDigit(parts[0]);
                setAccountNumberBase(parts.slice(1).join('-'));
            } else {
                setAccountNumberBase('0-000-000');
            }
        } else {
            setAccountNumberBase('0-000-000');
        }
    }, [clasificationDataForm.account_number]);

    const handleEditableDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^\d]/g, ''); // Asegurarse de que solo haya dígitos
        setEditableDigit(value);
        const updatedAccountNumber = value ? `${value}-${accountNumberBase}` : `0-${accountNumberBase}`;
        setClasificationsDataForm({
            ...clasificationDataForm,
            account_number: updatedAccountNumber,
            type // Añadir el type al estado del formulario
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setClasificationsDataForm({
            ...clasificationDataForm,
            [name]: value,
            type // Añadir el type al estado del formulario
        });
    };

    const isFormValid = () => {
        const { account_number } = clasificationDataForm;
        if (!account_number || account_number.length === 0) return false;
        const firstSegment = account_number.split('-')[0];
        return firstSegment !== '0' && firstSegment !== '';
    };

    const getValidationError = () => {
        const { account_number } = clasificationDataForm;
        if (!account_number) return null;
        const firstSegment = account_number.split('-')[0];
        if (firstSegment === '0') return 'El valor no puede ser 0.';
        if (firstSegment === '') return 'Debe agregar un valor antes del primer guion.';
        return null;
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre cuenta</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name') ? 'is-invalid' : ''}`}
                        value={clasificationDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Código de la Cuenta</label>
                    <div className="input-group">
                        <input
                            name="editable_digit"
                            id="editable_digit"
                            type="text"
                            className={`form-control ${fieldHasError('account_number') ? 'is-invalid' : ''}`}
                            value={editableDigit}
                            onChange={handleEditableDigitChange}
                            onFocus={() => onFocusRemove('account_number')}
                            placeholder="Ingrese número de cuenta"
                            maxLength={1}
                        />
                        <input
                            name="accountNumberBase"
                            id="accountNumberBase"
                            type="text"
                            className="input-group-text"
                            value={`-${accountNumberBase}`}
                            readOnly
                         />
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account_number')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description') ? 'is-invalid' : ''}`}
                        name="description"
                        id="description"
                        value={clasificationDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClasificationForm;
