import React from 'react';
import { Client, ClientFilters } from '../../../app/models/Client';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';
import ClientFilter from './ClientFilter';

interface Props {
    clients: Client[];
    totalRows: number;
    loading?: boolean;
    edit?: (clientId: number) => void;
    show?: (clientId: number) => void;
    destroy?: (clientId: number) => void;
    filter: ClientFilters;
    setFilter: (filter: ClientFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const ClientDataTable = ({
    clients,
    totalRows,
    loading = false,
    edit,
    show,
    destroy,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Client, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Rut Cliente',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'rut',
            cell: (row: Client, index: any, column: any) => <span className="">{row.rut}</span>
        },
        {
            name: 'Razón Social',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: Client, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Email',
            selector: (row: any) => row.email,
            sortable: true,
            sortField: 'email',
            cell: (row: Client, index: any, column: any) => <span className="">{row.email}</span>
        },
        {
            name: 'Teléfono',
            selector: (row: any) => row.phone,
            sortable: true,
            sortField: 'phone',
            cell: (row: Client, index: any, column: any) => <span className="">{row.phone}</span>
        },
        {
            name: 'Dirección',
            selector: (row: any) => row.address,
            sortable: true,
            sortField: 'address',
            cell: (row: Client, index: any, column: any) => <span className="">{row.address}</span>
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: Client, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (

            <>
            <div className="row mt-3">
            <div className="col">
                <ClientFilter filter={filter} setFilter={setFilter} />
            </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={clients}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            </>
  
    );
};

export default ClientDataTable;
