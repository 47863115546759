export interface PaymentMethod {
    id?: number;
    name: string;
    is_active?: boolean;
}

export interface PaymentMethodFilters {
    name?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultPaymentMethod: PaymentMethod = {
    name: ''
};