import React, { createContext, useReducer } from 'react';
import {
    HIDE_LOADING,
    HIDE_MAIN_MENU,
    SHOW_LOADING,
    SHOW_MAIN_MENU,
    CHANGE_ANIMATION,
    CHANGE_MAIN_MENU,
    CHANGE_EVENT_CLICK
} from './types';
import { DEFAULT, PROGRESSIVE } from './loadingTypes';

import { AppContextStateInterface } from './interfaces';
import reducerApp from './reducer';

const initialState: AppContextStateInterface = {
    showingLoading: false,
    showingMainMenu: undefined,
    loadingData: {
        nameLoading: '',
        messageLoading: 'Cargando...',
        finishAnimation: false,
        loadingType: DEFAULT,
        progressPercentage: 0
    },
    eventClick: undefined
};

export const AppContext = createContext<AppContextStateInterface>(initialState);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducerApp, initialState);

    const showLoading = (
        name = 'loading',
        message = 'Cargando...',
        progressPercentage = 0,
        loadingType = DEFAULT
    ) => {
        dispatch({
            type: SHOW_LOADING,
            payload: { name, message, progressPercentage, loadingType }
        });
    };

    const showProgressiveLoading = (
        name = 'Cargando datos',
        progressPercentage = 0,
        loadingType = PROGRESSIVE
    ) => {
        dispatch({ type: SHOW_LOADING, payload: { name, progressPercentage, loadingType } });
    };

    const changeAnimation = (name = '', message = '', finishAnimation = false) => {
        dispatch({ type: CHANGE_ANIMATION, payload: { name, message, finishAnimation } });
    };
    const changeEventClick = (eventClick: any) => {
        dispatch({ type: CHANGE_EVENT_CLICK, payload: { eventClick: eventClick } });
    };
    const hideLoading = () => {
        dispatch({ type: HIDE_LOADING });
    };

    const hideProgressiveLoading = () => {
        dispatch({ type: HIDE_LOADING });
    };

    const showMainMenu = () => {
        dispatch({ type: SHOW_MAIN_MENU });
    };

    const hideMainMenu = () => {
        dispatch({ type: HIDE_MAIN_MENU });
    };

    const changeMainMenu = () => {
        dispatch({ type: CHANGE_MAIN_MENU });
    };

    return (
        <AppContext.Provider
            value={{
                showingLoading: state.showingLoading,
                loadingData: state.loadingData,
                showLoading,
                showProgressiveLoading,
                hideLoading,
                changeAnimation,
                showingMainMenu: state.showingMainMenu,
                showMainMenu,
                hideMainMenu,
                changeMainMenu,
                eventClick: state.eventClick,
                changeEventClick
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
