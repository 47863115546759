import React from 'react';
import DailyCarrierForm from './DailyCarrierForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { DailyCarrier } from '../../../app/models/DailyCarrier';
import { Carrier } from '../../../app/models/Carrier';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { Provider } from '../../../app/models/Provider';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    dailyCarrier: DailyCarrier;
    setDailyCarrier: (DailyCarrier: DailyCarrier) => void;
    errorFields?: any;
    carriers: Carrier[];
    trucks: Truck[];
    hoppers: Hopper[];
    providers: Provider[];
}

const DailyCarrierFormContainer = ({
    fetching,
    action,
    cancel,
    dailyCarrier,
    setDailyCarrier,
    errorFields = null,
    carriers,
    trucks,
    hoppers,
    providers
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <DailyCarrierForm
                        dailyCarrier={dailyCarrier}
                        setDailyCarrier={setDailyCarrier}
                        errorFields={errorFields}
                        carriers={carriers}
                        trucks={trucks}
                        hoppers={hoppers}
                        providers={providers}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default DailyCarrierFormContainer;
