import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useServiceManifestDocumentService from '../../../app/services/hooks/useServiceManifestDocumentService';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentType,
    defaultServiceManifestDocumentDataForm
} from '../../../app/models/ServiceManifestDocument';
import ServiceManifestDocumentForm from './forms/ServiceManifestDocumentForm';

interface Props {
    serviceManifestDocumentId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestDocumentEdit = ({
    serviceManifestDocumentId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditServiceManifestDocument,
        editServiceManifestDocument,
        fetchingUpdateServiceManifestDocument,
        updateServiceManifestDocument
    } = useServiceManifestDocumentService();

    const [serviceManifestDocument, setServiceManifestDocument] =
        useState<ServiceManifestDocumentDataForm>({
            ...defaultServiceManifestDocumentDataForm
        });

    const [documentTypes, setDocumentTypes] = useState<ServiceManifestDocumentType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando documento de hoja de entrada...');
        editServiceManifestDocument(serviceManifestDocumentId, {
            onSuccess: (response: ServiceResponse) => {
                setDocumentTypes(response.data.document_types);
                setServiceManifestDocument(response.data.service_manifest_document);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        // useSweetAlert().spinnerAlert("Actualizando PurchaseOrderDocumente...");
        if (showLoading) showLoading('loading', 'Actualizando documento de hoja de entrada...');
        updateServiceManifestDocument(serviceManifestDocumentId, serviceManifestDocument, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditServiceManifestDocument ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestDocumentForm
                        serviceManifestDocumentDataForm={serviceManifestDocument}
                        setServiceManifestDocumentDataForm={setServiceManifestDocument}
                        documentTypes={documentTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateServiceManifestDocument}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateServiceManifestDocument}
                    />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDocumentEdit;
