import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import SocietySignatureEdit from './components/SocietySignatureEdit';
import useSocietySignatureService from '../../app/services/hooks/useSocietySignatureService';
import { SocietySignature, SocietySignatureDataForm, SocietySignatureFilters, defaultSocietySignatureDataForm } from '../../app/models/SocietySignature';
import SocietySignatureDataTable from './components/tables/SocietySignatureDataTable';
import SocietySignatureCreate from './components/SocietySignatureCreate';
import useNavigationPage from '../../hooks/useNavigationPage';
import SocietySignatureForm from './components/form/SocietySignatureForm';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Firmas de Sociedad',
        url: '/societies-signatures',
        isActive: true
    }
];

const SocietySignatures = () => {
    const { 
        fetchingGetSocietySignature,
        getSocietySignatures,
        storeSocietySignature,
        updateSocietySignature,
        deleteSocietySignature
    } = useSocietySignatureService();

    const [societySignatures, setSocietySignatures] = useState<SocietySignature[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [societySignatureIdEdit, setSocietySignatureIdEdit] = useState<number>(-1);
    const { navigationPage } = useNavigationPage();
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [selectedSignatureId, setSelectedSignatureId] = useState<number | null>(null);
    
    
    const [filter, setFilter] = useState<SocietySignatureFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = async () => {
            getSocietySignatures(filter, {
            onSuccess: (response: ServiceResponse) => {
                setSocietySignatures(response.data.society_signatures);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showCreateModal = () => setShowingCreate(true);
    const hideCreateModal = () => setShowingCreate(false);
    const showEditModal = (id: number) => {
        setSelectedSignatureId(id);
        setShowingEdit(true);
    };
    const hideEditModal = () => {
        setSelectedSignatureId(null);
        setShowingEdit(false);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setSocietySignatureIdEdit(-1);
    };

    const showEdit = (SocietySignatureId: number) => {
        setShowingEdit(true);
        setSocietySignatureIdEdit(SocietySignatureId);
    };

    const onError = () => {
        setShowingEdit(false);
        setSocietySignatureIdEdit(-1);
    };


    const destroy = (societySignatureId: number) => {
        const _text = 'Está a punto de eliminar la firma #' + societySignatureId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando firma...');
                        deleteSocietySignature(societySignatureId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                // useSweetAlert().successAlert({
                                //     title: 'Éxito',
                                //     text: response.message

                                // });
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                // if(changeAnimation)changeAnimation('success', response.message, true);
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La firma no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    


    return (
        <>
            <Breadcrumbs pageSection="Firmas de Sociedad" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreateModal} title="Crear Firma" />}
                />
                <SocietySignatureDataTable
                    societySignatures={societySignatures}
                    loading={fetchingGetSocietySignature}
                    edit={showEdit}
                    destroy={destroy}
                    totalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                    callbackSuccess={reloadTable}

                />
            </DefaultCard>


            

            {showingCreate && (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreateModal}
                    title="Crear Firma de Sociedad"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietySignatureCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreateModal();
                        }}
                        onCancel={hideCreateModal}
                        onError={hideCreateModal}
                    />
                </DefaultModal>
            )}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Sociedad"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietySignatureEdit
                        societySignatureId={societySignatureIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}


        </>
    );
};

export default SocietySignatures;
