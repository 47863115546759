import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Sector, SectorDataForm, SectorFilters } from '../../models/Sector';

const useSectorService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSectors, setFetchingGetSectors] = useState(false);
    const [fetchingShowSector, setFetchingShowSector] = useState(false);
    const [fetchingStoreSector, setFetchingStoreSector] = useState(false);
    const [fetchingEditSector, setFetchingEditSector] = useState(false);
    const [fetchingCreateSector, setFetchingCreateSector] = useState(false);
    const [fetchingUpdateSector, setFetchingUpdateSector] = useState(false);
    const [fetchingDeleteSector, setFetchingDeleteSector] = useState(false);
    const [fetchingActiveSector, setFetchingActiveSector] = useState(false);

    const getSectors = (sectorFilter: SectorFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(sectorFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SECTOR.GET_SECTORS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSectors
        });
    };

    const showSector = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SECTOR.SHOW_SECTOR.replace(':id', id.toString()),
            setFetching: setFetchingShowSector
        });
    };


    const storeSector = (cector: SectorDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SECTOR.STORE_SECTOR,
            body: cector,
            setFetching: setFetchingStoreSector
        });
    };

    const editSector = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SECTOR.EDIT_SECTOR.replace(':id', id.toString()),
            setFetching: setFetchingEditSector
        });
    };

    const createSector = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SECTOR.CREATE_SECTOR,
            setFetching: setFetchingEditSector
        });
    };

    const updateSector = (id: number, sector: SectorDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SECTOR.UPDATE_SECTOR.replace(':id', id.toString()),
            body: sector,
            setFetching: setFetchingUpdateSector
        });
    };

    const deleteSector = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SECTOR.DELETE_SECTOR.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSector
        });
    };

    const activeSector = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SECTOR.ACTIVE_SECTOR.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveSector
        });
    };

    return {
        fetchingGetSectors,
        fetchingShowSector,
        fetchingStoreSector,
        fetchingEditSector,
        fetchingUpdateSector,
        fetchingDeleteSector,
        fetchingActiveSector,
        fetchingCreateSector,
        getSectors,
        showSector,
        storeSector,
        createSector,
        editSector,
        updateSector,
        deleteSector,
        activeSector
    };
};

export default useSectorService;
