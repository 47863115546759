import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { AccountingDataForm, AccountingFilters } from '../../models/Accounting';

const useAccountingService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetAccountingByServiceManifest,
        setfetchingGetServiceManifestDetailsByServiceManifest
    ] = useState(false);
    const [fetchingShowAccounting, setFetchingShowAccounting] = useState(false);
    const [fetchingStoreAccounting, setFetchingStoreAccounting] = useState(false);
    const [fetchingEditAccounting, setFetchingEditAccounting] = useState(false);
    const [fetchingUpdateAccounting, setFetchingUpdateAccounting] = useState(false);
    const [fetchingDeleteAccounting, setFetchingDeleteAccounting] = useState(false);

    const getAccountingByServiceManifestDetail = (
        filter: AccountingFilters,
        service_manifest_detail_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.ACCOUNTING.GET_ACCOUNTINGS_BY_SERVICE_MANIFEST_DETAIL}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':service_manifest_detail_id', service_manifest_detail_id.toString()),
            setFetching: setfetchingGetServiceManifestDetailsByServiceManifest
        });
    };

    const showAccounting = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACCOUNTING.SHOW_ACCOUNTING.replace(':id', id.toString()),
            setFetching: setFetchingShowAccounting
        });
    };

    const storeAccounting = (accounting: AccountingDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.ACCOUNTING.STORE_ACCOUNTING,
            body: accounting,
            setFetching: setFetchingStoreAccounting
        });
    };

    const editAccounting = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACCOUNTING.EDIT_ACCOUNTING.replace(':id', id.toString()),
            setFetching: setFetchingEditAccounting
        });
    };

    const updateAccounting = (
        id: number,
        accounting: AccountingDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.ACCOUNTING.UPDATE_ACCOUNTING.replace(':id', id.toString()),
            body: accounting,
            setFetching: setFetchingUpdateAccounting
        });
    };

    const deleteAccounting = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.ACCOUNTING.DELETE_ACCOUNTING.replace(':id', id.toString()),
            setFetching: setFetchingDeleteAccounting
        });
    };

    return {
        fetchingGetAccountingByServiceManifest,
        fetchingShowAccounting,
        fetchingStoreAccounting,
        fetchingEditAccounting,
        fetchingUpdateAccounting,
        fetchingDeleteAccounting,
        getAccountingByServiceManifestDetail,
        showAccounting,
        storeAccounting,
        editAccounting,
        updateAccounting,
        deleteAccounting
    };
};

export default useAccountingService;
