import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import { PurchaseOrderDetailDataForm, PurchaseOrderDetailFilters } from '../../../app/models/PurchaseOrderDetail';
import PurchaseOrderDetailCreate from './PurchaseOrderDetailCreate';
import PurchaseOrderDetailDataTable from './tables/PurchaseOrderDetailDataTable';
import PurchaseOrderDetailEdit from './PurchaseOrderDetailEdit';
import PurchaseOrderDetailShow from './PurchaseOrderDetailShow';
import { Items } from '../../../app/shared/enums';

interface Props {
    purchaseOrderId: number;
    updateReference?: boolean;
    setUpdateReference?: (reference:boolean) => void;
    isShow?: boolean;
}

const PurchaseOrderDetailContainer = ({ purchaseOrderId, setUpdateReference, updateReference=false, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getPurchaseOrderDetailsByPurchaseOrder,
        fetchingGetPurchaseOrderDetailsByPurchaseOrder,
        deletePurchaseOrderDetail,
        fetchingDeletePurchaseOrderDetail,
   
    } = usePurchaseOrderDetailService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    
    const [showingCreateProduct, setShowingCreateProduct] = useState(false);
    const [showingCreateService, setShowingCreateService] = useState(false);

    const [purchaseOrderDetailIdEdit, setPurchaseOrderDetailIdEdit] = useState<number>(-1);
    const [purchaseOrderDetailIdShow, setPurchaseOrderDetailIdShow] = useState<number>(-1);
    const [position, setPosition] = useState<number>(0);
    const [PurchaseOrderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<PurchaseOrderDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });



    const showCreateProduct = () => {
        setShowingCreateProduct(true);
    };

    const showCreateService = () => {
        setShowingCreateService(true);
    };

    const hideCreateProduct = () => {
        setShowingCreateProduct(false);
    };

    const hideCreateService = () => {
        setShowingCreateService(false);
    };

    const showEdit = (PurchaseOrderDetailId: number, position: number) => {
        setShowingEdit(true);
        setPurchaseOrderDetailIdEdit(PurchaseOrderDetailId);
        setPosition(position);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setPurchaseOrderDetailIdEdit(-1);
        setPosition(0);
    };

    const showShow = (PurchaseOrderDetailId: number, position: number) => {
       
        setShowingShow(true);
        setPurchaseOrderDetailIdShow(PurchaseOrderDetailId);
        setPosition(position);
    };

    const hideShow = () => {
        setShowingShow(false);
        setPurchaseOrderDetailIdShow(-1);
        setPosition(0);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

    useEffect(() => {
        if(updateReference){
            
            reloadTable();
           if(setUpdateReference) setUpdateReference(false);

        }
    }, [updateReference]);

    const destroy = (purchaseOrderDetailId: number, position: number) => {
        const _text = 'Está a punto de eliminar la posición de orden de compra #' + position;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando posición de orden de compra...');
                        deletePurchaseOrderDetail(purchaseOrderDetailId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La posición de orden de compra no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const reloadTable = () => {
        getPurchaseOrderDetailsByPurchaseOrder(filter, purchaseOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setPurchaseOrderDetails(response.data.purchase_order_details);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='row'>
                           <div className='col-auto'>
                           {!isShow && (
                            <ButtonCreate
                                title="Nuevo producto"
                                callbackCreate={showCreateProduct}
                            />
                            )}
                           </div>
                           <div className='col-auto'>
                           {!isShow && (
                            <ButtonCreate
                                title="Nuevo servicio"
                                callbackCreate={showCreateService}
                            />
                            )}
                           </div>
                        </div>
                        
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <PurchaseOrderDetailDataTable
                                purchaseOrderDetails={PurchaseOrderDetails}
                                loading={fetchingGetPurchaseOrderDetailsByPurchaseOrder}
                                totalRows={totalRows}
                                show={showShow}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        ) : (
                            <PurchaseOrderDetailDataTable
                                purchaseOrderDetails={PurchaseOrderDetails}
                                edit={showEdit}
                                destroy={destroy}
                                show={showShow}
                                loading={fetchingGetPurchaseOrderDetailsByPurchaseOrder}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            {showingCreateProduct ? (
                <DefaultModal
                    show={showingCreateProduct}
                    handleClose={hideCreateProduct}
                    title="Crear posición"
                    backdrop={true}
                    showFooter={false}
                >
                    <PurchaseOrderDetailCreate
                        purchaseOrderId={purchaseOrderId}
                        itemType={Items.PRODUCT}
                        onSaved={() => {
                            reloadTable();
                            hideCreateProduct();
                        }}
                        onCancel={hideCreateProduct}
                        onError={hideCreateProduct}
                    />
                </DefaultModal>
            ) : null}

            {showingCreateService ? (
                <DefaultModal
                    show={showingCreateService}
                    handleClose={hideCreateService}
                    title="Crear posición"
                    backdrop={true}
                    showFooter={false}
                >
                    <PurchaseOrderDetailCreate
                        purchaseOrderId={purchaseOrderId}
                        itemType={Items.SERVICE}
                        onSaved={() => {
                            reloadTable();
                            hideCreateService();
                        }}
                        onCancel={hideCreateService}
                        onError={hideCreateService}
                    />
                </DefaultModal>
            ) : null}
          
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title={`Editar posición # ${position}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <PurchaseOrderDetailEdit
                        purchaseOrderId={purchaseOrderId}
                        purchaseOrderDetailId={purchaseOrderDetailIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        
                        onCancel={hideEdit}
                        onError={hideEdit}
                        
                    />
                </DefaultModal>
            ) : null} 

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title={`Ver posición # ${position}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <PurchaseOrderDetailShow
                        purchaseOrderDetailId={purchaseOrderDetailIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null} 
        </>
    );
};

export default PurchaseOrderDetailContainer;
