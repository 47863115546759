import { NOTIFICATIONS } from './types';

import { NotificationContextStateInterface } from './interfaces';
import { ReducerActionInterface } from '../Shared/interfaces';

const reducerAuthCustomer = (
    state: NotificationContextStateInterface,
    action: ReducerActionInterface
) => {
    switch (action.type) {
        case NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        default:
            return state;
    }
};

export default reducerAuthCustomer;
