import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useServiceManifestDocumentService from '../../../app/services/hooks/useServiceManifestDocumentService';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentType,
    defaultServiceManifestDocumentDataForm
} from '../../../app/models/ServiceManifestDocument';
import ServiceManifestDocumentForm from './forms/ServiceManifestDocumentForm';

interface Props {
    serviceManifestId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestDocumentCreate = ({
    onSaved,
    onCancel,
    onError,
    serviceManifestId
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createServiceManifestDocument,
        storeServiceManifestDocument,
        fetchingCreateServiceManifestDocument,
        fetchingStoreServiceManifestDocument
    } = useServiceManifestDocumentService();

    const [serviceManifestDocument, setServiceManifestDocument] =
        useState<ServiceManifestDocumentDataForm>({
            ...defaultServiceManifestDocumentDataForm,
            service_manifest_id: serviceManifestId
        });

    const [documentTypes, setDocumentTypes] = useState<ServiceManifestDocumentType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createServiceManifestDocument({
            onSuccess: (response: ServiceResponse) => {
                setDocumentTypes(response.data.document_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando documento...');
        storeServiceManifestDocument(serviceManifestDocument, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                setErrorFields(undefined);
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateServiceManifestDocument ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestDocumentForm
                        serviceManifestDocumentDataForm={serviceManifestDocument}
                        setServiceManifestDocumentDataForm={setServiceManifestDocument}
                        documentTypes={documentTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreServiceManifestDocument}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreServiceManifestDocument}
                    />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDocumentCreate;
