import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import useAuthService from '../../app/services/hooks/useAuthService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import PasswordProfile from '../../pages/Profile/EditPassword';

type Props = {
    children: React.ReactNode;
};

const PrivateMiddleware: React.FC<Props> = ({ children }) => {
    const { auth, updateAuth, logout, loading, logged } = useContext(AuthContext);
    const [showingPasswordEdit, SetShowingPasswordEdit] = useState(false);
    const { validateTokenAuth } = useAuthService();

    useEffect(() => {
        if (logged != undefined) {
            validateTokenAuth({
                onSuccess: (response: ServiceResponse) => {
                    if (auth && updateAuth) {
                        updateAuth({
                            ...auth,
                            id: response.data.id,
                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            email: response.data.email,
                            avatar: response.data.avatar,
                            roles: response.data.roles,
                            permissions: response.data.permissions,
                            hasAllPermissions: response.data.hasAllPermissions
                        });
                        console.log('111', response.data.is_updated_password)
                        if (!response.data.is_updated_password) {
                            showPasswordEdit();
                        }
                    }
                },
                onError: () => {
                    if (logout) {
                        logout();
                    }
                }
            });
        }
    }, [logged]);

    useEffect(() => {
        if (!loading && !auth && logout) logout();
    }, [auth, loading]);

    const showPasswordEdit = () => {
        SetShowingPasswordEdit(true);
    };

    const hidePasswordEdit = () => {
        SetShowingPasswordEdit(false);
    };

    return !auth ? null : (
        <>
            {children}
            {showingPasswordEdit && (
                <PasswordProfile
                    showingPasswordEdit={showingPasswordEdit}
                    hidePasswordEdit={hidePasswordEdit}
                    title="Debe actualizar su contraseña"
                    backdrop={false}
                />
            )}
        </>
    );
};

export default PrivateMiddleware;
