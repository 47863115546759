import React, { useEffect, useState } from 'react';
import {
    DispatchProgramDocument,
    defaultDispatchProgramDocument
} from '../../../app/models/DispatchProgramDocument';
import useDispatchProgramDocumentService from '../../../app/services/hooks/useDispatchProgramDocumentService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import DispatchProgramDocumentForm from './forms/DispatchProgramDocumentForm';

interface Props {
    dispatchProgramDocumentId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DispatchProgramDocumentUpload = ({
    dispatchProgramDocumentId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        fetchingGetDispatchProgramDocument,
        getDispatchProgramDocument,
        fetchingUpdateDispatchProgramDocument,
        updateDispatchProgramDocument
    } = useDispatchProgramDocumentService();

    const [dispatchProgramDocument, setDispatchProgramDocument] = useState<
        DispatchProgramDocument[]
    >([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        get();
    }, []);

    const get = () => {
        getDispatchProgramDocument(dispatchProgramDocumentId, {
            onSuccess: (response: ServiceResponse) => {
                setDispatchProgramDocument(response.data.dispatch_program_documents);
            },
            onError: () => {
                if (onError) onError();
            }
        });
    };

    const update = () => {
        updateDispatchProgramDocument(dispatchProgramDocumentId, dispatchProgramDocument, {
            onSuccess: () => {
                if (onSaved) onSaved();
            },
            onError: () => {
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingGetDispatchProgramDocument ? (
        <LazyLoading height="300" />
    ) : (
        <DispatchProgramDocumentForm
            fetching={fetchingUpdateDispatchProgramDocument}
            onAction={update}
            onCancel={cancel}
            dispatchProgramDocument={dispatchProgramDocument}
            setDispatchProgramDocument={setDispatchProgramDocument}
            dispatchProgramDocumentId={dispatchProgramDocumentId}
            errorFields={errorFields}
        />
    );
};

export default DispatchProgramDocumentUpload;
