import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ForeignExchanges,ForeignExchangeFilters } from '../../models/ForeignExchange';

const useForeingExchangeService = () => {
    const { doGet} = useFetch();

    const [fetchingGetForeignExchanges, setfetchingGetForeignExchange] = useState(false);


    const getForeignExchange = (currencyFilters: ForeignExchangeFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(currencyFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.FOREIGN_EXCHANGE.GET_FOREIGN_EXCHANGE}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setfetchingGetForeignExchange
        });

        return {
            getForeignExchange,
            fetchingGetForeignExchanges
        };
    };

    return {
        fetchingGetForeignExchanges,
        getForeignExchange
    };

}

export default useForeingExchangeService;
