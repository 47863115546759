import { PaymentMethod } from './PaymentMethod';
import { PaymentCondition } from './PaymentCondition';
import { Client } from './Client';
import { Currency } from './Currency';
import { Society } from './Society';
import { Quote } from './Quote';
import { TransportationOrderStatus } from './TransportationOrderStatus';
import { Unit } from './Unit';
import { TransportationQuoteDetail } from './TransportationQuoteDetail';
export interface TransportationOrder {
    id?: number;
    contactEmail?: string;
    status?: number | string;
    statusSelected?: TransportationOrderStatus;
    statusChoices?: TransportationOrderStatus[];
    expiredAt?: Date | string;
    clientId?: number;
    client?: Client;
    quoteId?: number;
    quote?: Quote;
    paymentMethodId?: number;
    paymentMethod?: PaymentMethod;
    paymentConditionId?: number;
    paymentCondition?: PaymentCondition;
    currencyId?: number;
    currency?: Currency;
    societyId?: number;
    society?: Society;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    // transportationOrderItems?: TransportationOrderItems[];
}

export interface TransportationOrderFilters {
    transportation_order_status?: string | null;
    start_date?: string;
    end_date?: string;
    transportation_order_id?: number;
    client?: string;
    quote_id?: number;

    page?: number;
    per_page?: number;
    sort?: string;
    order?: string;
}

export interface TransportationOrderDataForm {
    id?: number;
    contact_email: string;
    expired_at: string;
    expired_in_days?: number;
    client_id: number | string;
    transportation_quote_detail_id?: number;
    transportation_quote_detail?: TransportationQuoteDetail;
    payment_method_id: number | string;
    payment_method?: string;
    payment_condition_id: number | string;
    currency?: Currency | string;
    client?: string;
    society?: string;
    payment_condition?: string;
    reference?: string;
    days_count?: number;
    currency_id: number | string;
    society_id: number | string;
    status?: string;
    status_selected?: TransportationOrderStatus;
    status_choices?: TransportationOrderStatusRequestDataForm[];
    transportation_order_details?: TransportationOrderDetailDataForm[];
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
    rate_runner?: number;
    currency_rate_runner?: Currency;
    currency_rate_runner_id?: number;
    quantity_unit_rate_runner?: Unit;
    quantity_unit_rate_runner_id?: number;
    rate_transporter?: number;
    currency_rate_transporter?: Currency;
    currency_rate_transporter_id?: number;
    quantity_unit_rate_transporter?: Unit;
    quantity_unit_rate_transporter_id?: number;
}

export interface TransportationOrderDetailDataForm {
    transportation_order_id?: number;
    id?: number;
    description?: string;
    first_dispatch_date: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number | string;
    rate?: number | string;
    currency?: string;
    service?: string;
    service_id?: number | string;
    destiny_id?: number | string;
    origin_id?: number | string;
    product_id?: number | string;
    quantity_unit_id?: number | string;
    currency_id?: number | string;
    created_at?: string;
    updated_at?: string;
}

export interface TransportationOrderDetailShowDataForm {
    transportation_order_id?: number;
    id?: number;
    description?: string;
    first_dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number | string;
    quantity_unit?: number | string;
    rate?: number | string;
    service?: string;
    currency?: string;
    destiny?: number | string;
    origin?: number | string;
    created_at?: string;
    updated_at?: string;
}

export interface TransportationOrderRatesDataForm {
    rate_runner?: number | string;
    currency_rate_runner?: string;
    quantity_unit_rate_runner?: string;
    rate_transporter?: number | string;
    currency_rate_transporter?: string;
    quantity_unit_rate_transporter?: string;
}

export interface TransportationOrderStatusRequestDataForm {
    id: number | string;
    name: number | string;
}

export const defaultTransportationOrderDataForm: TransportationOrderDataForm = {
    contact_email: '',
    expired_at: '',
    expired_in_days: 0,
    client_id: 0,
    payment_method_id: 0,
    payment_condition_id: 0,
    reference: '',
    days_count: 0,
    currency_id: 0,
    society_id: 0,
    status: ''
};

export const defaultTransportationOrderRateDataForm: TransportationOrderRatesDataForm = {
    rate_runner: 0,
    currency_rate_runner: '',
    quantity_unit_rate_runner: '',
    rate_transporter: 0,
    currency_rate_transporter: '',
    quantity_unit_rate_transporter: ''
};
