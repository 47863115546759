import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';

const useNotificationService = () => {
    const { doGet, doPost } = useFetch();

    const [fetchingNotificationsNotViewed, setFetchingNotificationsNotViewed] = useState(false);
    const [fetchingNotificationsByUserRange, setFetchingNotificationsByUserRange] = useState(false);
    const [fetchingNotificationSetAsViewed, setFetchingNotificationSetAsViewed] = useState(false);

    const getNotificationsNotViewed = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.NOTIFICATIONS.GET_NOT_VIEWED,
            setFetching: setFetchingNotificationsNotViewed
        });
    };

    const getAllNotificationsByUserRange = (
        idNotification: number,
        quantity: number,
        events: ServiceEvents = {}
    ) => {
        const url = new URL(EndPoints.NOTIFICATIONS.GET_ALL_BY_USER_RANGE);
        url.searchParams.append('id', idNotification.toString());
        url.searchParams.append('quantity', quantity.toString());
        doGet({
            ...events,
            url: url.href,
            setFetching: setFetchingNotificationsByUserRange
        });
    };

    const setNotificationAsViewed = (idNotification: number, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.NOTIFICATIONS.SET_AS_VIEWED.replace(':id', idNotification.toString()),
            setFetching: setFetchingNotificationSetAsViewed
        });
    };
    return {
        getNotificationsNotViewed,
        fetchingNotificationsNotViewed,
        getAllNotificationsByUserRange,
        fetchingNotificationsByUserRange,
        setNotificationAsViewed,
        fetchingNotificationSetAsViewed
    };
};

export default useNotificationService;
