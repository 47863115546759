import React from 'react';
import DefaultLoadingAnimation from '../../../components/default/DefaultLoadingAnimation';

import { LoadingData } from '../../../contexts/Shared/interfaces';

interface Props {
    loadingData: LoadingData;
    hideLoading: () => void;
}
const FullScreenLoading = ({ loadingData, hideLoading }: Props) => {
    return (
        <DefaultLoadingAnimation
            loading={true}
            loadingData={loadingData}
            hideLoading={hideLoading}
        />
    );
};

export default FullScreenLoading;
