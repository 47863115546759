import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useBankAccountProviderService from '../../../app/services/hooks/useBankAccountProviderService';
import { BankAccountProviderDataForm, BankAccountProviderFilters } from '../../../app/models/BankAccountProvider';
import DefaultToolBar from '../../../components/default/DefaultToolBar';

import BankAccountProviderCreate from './BankAccountProviderCreate';
import BankAccountProviderEdit from './BankAccountProviderEdit';
import BankAccountProviderDataTable from './tables/BankAccountProviderDataTable';
import BusinessNameBankAccountDataTable from '../../BusinessName/components/tables/BusinessNameBankAccountDataTable';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import { BusinessNameBankAccountDataForm } from '../../../app/models/BusinessNameBankAccount';

interface Props {
    providerId: number;
    isShow?: boolean;
}

const BankAccountProviderContainer = ({ providerId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllBusinessNameBankAccountsByProvider,
        fetchingGetAllBusinessNameBankAccountsByProvider
    } = useBusinessNameBankAccountService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [bankAccountProviderIdEdit, setBankAccountProviderIdEdit] = useState<number>(-1);
    const [bankAccountProviderIdShow, setBankAccountProviderIdShow] = useState<number>(-1);
    
    const [bankAccountProviders, setBankAccountProviders] = useState<BusinessNameBankAccountDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BankAccountProviderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (bankAccountProviderId: number) => {
        setShowingEdit(true);
        setBankAccountProviderIdEdit(bankAccountProviderId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setBankAccountProviderIdEdit(-1);
    };

    const showShow = (bankAccountProviderId: number) => {
       
        setShowingShow(true);
        setBankAccountProviderIdShow(bankAccountProviderId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setBankAccountProviderIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

  
   

    const reloadTable = () => {
        getAllBusinessNameBankAccountsByProvider(filter, providerId, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccountProviders(response.data.bank_accounts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Cuentas bancarias</div>
                <div className="row">
                    <div className="col-12">
                        <BusinessNameBankAccountDataTable
                            businessNamebankAccounts={bankAccountProviders}
                            loading={fetchingGetAllBusinessNameBankAccountsByProvider}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default BankAccountProviderContainer;
