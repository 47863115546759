import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { SocietySignature,SocietySignatureDataForm, defaultSocietySignatureDataForm } from '../../../app/models/SocietySignature';
import SocietySignatureForm from './form/SocietySignatureForm'; // Update the form component import
import useSocietySignatureService from '../../../app/services/hooks/useSocietySignatureService';
import { Society } from '../../../app/models/Society';
import { User } from '../../../app/models/User';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietySignatureCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    const [societySignature, setSocietySignature] = useState<SocietySignature>(defaultSocietySignatureDataForm);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreSocietySignature, storeSocietySignature, fetchingCreateSocietySignature, createSocietySignature } = useSocietySignatureService(); // Update the hook and method names

    useEffect(() => {
        createSocietySignature({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setUsers(response.data.users);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);
    
    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando firma...');
        storeSocietySignature(societySignature, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };



    return fetchingCreateSocietySignature ? (
        <LazyLoading height={300} />  
    ) : (  
        <>
            <SocietySignatureForm
                societySignatureForm={societySignature}
                setSocietySignatureDataForm={setSocietySignature}
                societies={societies} 
                errorFields={errorFields}
                setErrorFields={setErrorFields}
                users={users}
            />
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreSocietySignature}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreSocietySignature} />
                </div>
            </div>
        </>
    );
};

export default SocietySignatureCreate;
