import React, { useState } from 'react';
import { TransportationQuoteDetailShowDataForm } from '../../../../app/models/Quote';
import { formatMoney } from '../../../../utils/utils';
import { Button, Collapse } from 'react-bootstrap';

interface QuoteDetailsProps {
    quoteRequestDetail: TransportationQuoteDetailShowDataForm;
}

const QuoteDetailShowForm = ({ quoteRequestDetail }: QuoteDetailsProps) => {
    const [openRates, setOpenRates] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    if (!quoteRequestDetail) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Producto</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="productName"
                        className="form-control"
                        readOnly={true}
                        value={quoteRequestDetail.product_name}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Servicio</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="service"
                        className="form-control"
                        readOnly={true}
                        value={quoteRequestDetail.service}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                <div className="col-md-9">
                    <input
                        type="date"
                        className="form-control"
                        name="first_dispatch_date"
                        id="first_dispatch_date"
                        value={quoteRequestDetail.first_dispatch_date}
                        readOnly={true}
                    />
                    <div className="invalid-feedback" />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Descripción</label>
                <div className="col-md-9">
                    <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        value={quoteRequestDetail.description}
                        readOnly={true}
                    />
                    <div className="invalid-feedback" />
                </div>
            </div>

            <hr />

            <>
                <Button
                    onClick={() => setOpenInfo(!openInfo)}
                    aria-controls="transportation-quote-detail-info"
                    aria-expanded={openInfo}
                    className="container text-center mb-4"
                >
                    Información ruta
                </Button>
                <Collapse in={openInfo}>
                    <div id="transportation-quote-detail-info">
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">n° Opción ruta</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="quote_route_segment"
                                    className="form-control"
                                    readOnly={true}
                                    value={quoteRequestDetail.quote_route_segment_id}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">n° Tramo</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="route_segment"
                                    className="form-control"
                                    readOnly={true}
                                    value={quoteRequestDetail.route_segment_id}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Origen</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="origin"
                                    className="form-control"
                                    readOnly={true}
                                    value={quoteRequestDetail.origin}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Destino</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="destiny"
                                    className="form-control"
                                    readOnly={true}
                                    value={quoteRequestDetail.destiny}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Tarifa de venta</label>
                            <div className="col-md-9 ">
                                <div className="row g-1">
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="rate"
                                            className="form-control"
                                            readOnly={true}
                                            value={formatMoney(
                                                quoteRequestDetail.rate ?? 0,
                                                quoteRequestDetail.currency ?? undefined
                                            )}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="rate"
                                            className="form-control"
                                            readOnly={true}
                                            value={quoteRequestDetail.currency}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Cantidad</label>
                            <div className="col-md-9">
                                <div className="row g-1">
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            name="quantity"
                                            className="form-control"
                                            readOnly={true}
                                            value={quoteRequestDetail.quantity}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="quantity_unit"
                                            className="form-control"
                                            readOnly={true}
                                            value={quoteRequestDetail.quantity_unit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Distancia acordada Km</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="agreed_distance"
                                    className="form-control"
                                    readOnly={true}
                                    value={quoteRequestDetail.agreed_distance}
                                />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </>

            <hr />
            <>
                <Button
                    onClick={() => setOpenRates(!openRates)}
                    aria-controls="rates"
                    aria-expanded={openRates}
                    className="container text-center mb-4"
                >
                    Ver tarifas
                </Button>
                <Collapse in={openRates}>
                    <div id="rates">
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Tarifa Runner</label>
                            <div className="col-md-9">
                                <div className="row g-1">
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="rate_runner"
                                            className="form-control"
                                            readOnly={true}
                                            value={formatMoney(
                                                quoteRequestDetail.rate_runner ?? 0,
                                                quoteRequestDetail.currency_rate_runner ?? undefined
                                            )}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="currency_rate_runner"
                                            className="form-control"
                                            readOnly={true}
                                            value={quoteRequestDetail.currency_rate_runner}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="quantity_unit_rate_runner"
                                            className="form-control"
                                            readOnly={true}
                                            value={quoteRequestDetail.quantity_unit_rate_runner}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Tarifa Transportista</label>
                            <div className="col-md-9">
                                <div className="row g-1">
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="rate_transporter"
                                            className="form-control"
                                            readOnly={true}
                                            value={formatMoney(
                                                quoteRequestDetail.rate_transporter ?? 0,
                                                quoteRequestDetail.currency_rate_transporter ??
                                                    undefined
                                            )}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="currency_rate_transporter"
                                            className="form-control"
                                            readOnly={true}
                                            value={quoteRequestDetail.currency_rate_transporter}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="quantity_unit_rate_transporter"
                                            className="form-control"
                                            readOnly={true}
                                            value={
                                                quoteRequestDetail.quantity_unit_rate_transporter
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </>

            <hr />
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Creación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="created_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            quoteRequestDetail.created_at
                                ? new Date(quoteRequestDetail.created_at).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Modificación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="updated_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            quoteRequestDetail.updated_at
                                ? new Date(quoteRequestDetail.updated_at).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default QuoteDetailShowForm;
