import React from 'react';
import { PaymentMethod, PaymentMethodFilters } from '../../../../app/models/PaymentMethod';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import PaymentMethodSwitchChange from '../PaymentMethodSwitchChange';

interface Props {
    paymentMethods: PaymentMethod[];
    edit?: (PlaceId: number) => void;
    destroy?: (PlaceId: number) => void;
    show?: (DailyPlaceId: number) => void;
    loading?: boolean;
    filter: PaymentMethodFilters;
    setFilter: (filter: PaymentMethodFilters) => void;
    totalRows: number;
    callbackSuccess: () => void;
}


const PaymentMethodDataTable = ({
    paymentMethods,
    edit,
    destroy,
    loading = false,
    show,
    filter,
    setFilter,
    totalRows,
    callbackSuccess
}: Props) => {

    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id'
        },
        {
            name: 'Nombre',
            selector: (row: any) => row?.name,
            sortable: true,
            sortField: 'name'
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <PaymentMethodSwitchChange
                    checkValue={row.is_active ?? true}
                    paymentMethodId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="nowrap-cell nowrap-cell-no-min">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <DefaultDataTable
                columns={columns}
                data={paymentMethods}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    )
}

export default PaymentMethodDataTable
