import { Currency } from './Currency';
import { Place } from './Place';
import { RouteSegment } from './RouteSegment';
import { Unit } from './Unit';

export interface QuoteRouteSegment {
    id?: number;
    quote_id?: number;
    route_segment_id?: number;
    distance?: number | string;
    rate?: number | string;
    currencyId?: number;
    currency?: Currency;
    unitId?: number;
    unit?: Unit;
    agreed_distance?: number | string;
    origin?: Place;
    destiny?: Place;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    in_quote_detail?: boolean;
    name?: string;
}

export interface QuoteRouteSegmentFilters {
    id?: number;
    route_segment_id?: number | null;
    distance?: number | string;
    rate?: number | string;
    unitId?: number | string;
    agreed_distance?: number | string;
    rate_runner?: number | string;
    rate_transporter?: number | string;
    unit?: Unit;
    origin?: string | null;
    destiny?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface QuoteRouteSegmentDataForm {
    id?: number;
    quote_id?: number;
    route_segment_id?: number;

    distance?: number | string;
    rate?: number | string;
    currency_id?: number | string;
    currency?: Currency;

    unit_id?: number | string;
    unit?: Unit;
    agreed_distance?: number | string;

    rate_runner?: number | string;
    currency_rate_runner_id?: number | string;
    quantity_unit_rate_runner_id?: number | string;
    rate_transporter?: number | string;
    currency_rate_transporter_id?: number | string;
    quantity_unit_rate_transporter_id?: number | string;

    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
}

export interface QuoteRouteSegmentShowDataForm {
    id?: number;
    origin?: Place;
    destiny?: Place;
    distance?: number;
    agreed_distance?: number;
    currency?: string;
    unit?: string;
    rate?: number;
    routeSegment?: RouteSegment;
    route_segment_id?: number;
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;

    rate_runner?: number;
    currency_rate_runner?: string;
    quantity_unit_rate_runner?: string;
    rate_transporter?: number;
    currency_rate_transporter?: string;
    quantity_unit_rate_transporter?: string;
}

export const defaultQuoteRouteSegmentDataForm: QuoteRouteSegmentDataForm = {
    distance: 0,
    rate: 0,
    unit_id: undefined,
    currency_id: undefined,

    rate_runner: 0,
    currency_rate_runner_id: undefined,
    quantity_unit_rate_runner_id: undefined,
    rate_transporter: 0,
    currency_rate_transporter_id: undefined,
    quantity_unit_rate_transporter_id: undefined
};
