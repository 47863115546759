import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';
import { Currency } from '../../../app/models/Currency';
import {
    defaultTransportationOrderDataForm,
    TransportationOrderDataForm
} from '../../../app/models/TransportationOrder';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCard from '../../../components/default/DefaultCard';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import TransportationOrderForm from '../components/forms/TransportationOrderForm';
import { Quote, QuoteDataForm, QuoteReferenceDataForm } from '../../../app/models/Quote';
import TransportationOrderShowForm from '../components/forms/TransportationOrderShowForm';
import TransportationOrderItemsContainer from '../components/TransportationOrderItemsContainer';
import { RouterParams } from '../../../app/shared/interfaces';
import TransportationOrderRatesForm from '../components/forms/TransportationOrderRatesForm';
import { Unit } from '../../../app/models/Unit';
import { Roles } from '../../../app/shared/enums';
import { AuthContext } from '../../../contexts/AuthContext';
import { TransportationQuoteDetail } from '../../../app/models/TransportationQuoteDetail';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useClientService from '../../../app/services/hooks/useClientService';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import TransportationOrderChangeStatusEdit from '../components/TransportationOrderChangeStatusEdit';

const TransportationOrderEdit = () => {

    const { showLoading, hideLoading } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Pedidos de Transporte',
            url: '/transportation-orders',
            isActive: false
        },
        {
            name: 'Editar pedido de transporte #' + id,
            url: '/transportation-orders/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editTransportationOrder,
        fetchingEditTransportationOrder,
        fetchingShowTransportationOrder,
        updateTransportationOrder,
        showTransportationOrder,
        showQuoteTransportationOrder,
        updateTransportationOrderRateRunner,
        canChangeStatusTransportationOrder,
        fetchingCanChangeStatusTransportationOrder,
        changeStatusTransportationOrder,
        fetchingChangeStatusTransportationOrder
    } = useTransportationOrderService();

  
    const {
        getClientsBySociety,
        fetchingGetClientBySociety
    } = useClientService();

    const [transportationOrderForm, setTransportationOrderForm] =
        useState<TransportationOrderDataForm>(defaultTransportationOrderDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    const [canBeEdited, setCanBeEdited] = useState(false);

    const [quotes, setQuotes] = useState<TransportationQuoteDetail[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [references, setReferences] = useState<QuoteReferenceDataForm[]>([]);
    const [disabledRunner, setDisabledRunner] = useState<boolean>(false);
    const transportationOrderFromTqd = useRef<TransportationOrderDataForm|undefined>();
    const clientsFromTqd = useRef<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);
    const [statuses, setStatuses] = useState<TransportationOrderStatus[]>([]);

    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        setCanBeEdited(false);
        editTransportationOrder(id, {
            onSuccess: (response: ServiceResponse) => {
                if (
                    response.data.transportation_order.transportation_quote_detail_id == '' ||
                    response.data.transportation_order.transportation_quote_detail_id == null
                ) {
                    setCanBeEdited(true);
                }

                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setPaymentMethods(response.data.payment_methods);
                setPaymentsConditions(response.data.payment_conditions);
                setCurrencies(response.data.currencies);
                setQuantityUnits(response.data.quantity_units);
                setQuotes(response.data.quotes);
                setReferences(response.data.references);
                setStatuses(response.data.statuses);
                societyIdSelected.current = response.data.transportation_order.society_id;
                setTransportationOrderForm(response.data.transportation_order);

                if(response.data.transportation_order.transportation_quote_detail_id){

                    clientsFromTqd.current = response.data.clients;
                    transportationOrderFromTqd.current = response.data.transportation_order;
                    
                }
                
            }
        });
    };

    useEffect(() => {

        if (transportationOrderForm.society_id == undefined) {
            societyIdSelected.current = undefined;
        }

        if (transportationOrderForm.society_id && transportationOrderForm.society_id != societyIdSelected.current) {

            if((transportationOrderFromTqd.current && 
                transportationOrderFromTqd.current.transportation_quote_detail_id &&
                transportationOrderFromTqd.current.society_id != transportationOrderForm.society_id) ||
                (!transportationOrderFromTqd.current)
            ){

                    getClientsBySociety(Number(transportationOrderForm.society_id), {
                        onSuccess: (response: ServiceResponse) => {
                            societyIdSelected.current = Number(transportationOrderForm.society_id) ?? 0;
                            
                            setClients(response.data.clients);
                            setTransportationOrderForm(
                                {
                                    ...transportationOrderForm,
                                    client_id: 0
                                }
                            )
                        },
                        onError: (response: ServiceResponse) => {
                            setTransportationOrderForm({
                                ...transportationOrderForm,
                                society_id: Number(societyIdSelected.current)
                            });
                        }
                    })
                    
                }
            else if(
                (transportationOrderFromTqd.current && 
                    transportationOrderFromTqd.current.transportation_quote_detail_id &&
                    transportationOrderFromTqd.current.society_id == transportationOrderForm.society_id)
            ){
                societyIdSelected.current = Number(transportationOrderForm.society_id) ?? 0;
                setClients(clientsFromTqd.current);
                setTransportationOrderForm(
                    {
                        ...transportationOrderForm,
                        client_id: transportationOrderFromTqd.current.client_id
                    }
                )

            }
             
        }
     
    }, [transportationOrderForm.society_id]);

    const updateRateRunner = () => {
        useSweetAlert().spinnerAlert('Actualizando tarifas...');
        updateTransportationOrderRateRunner(transportationOrderForm.id!, transportationOrderForm, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                setErrorFields(undefined);
                reloadModule();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const reloadModule = () => {
        edit(parseInt(id));
        // show(parseInt(id));
    };

    // useEffect(() => {
    //     if (transportationOrderForm.transportation_quote_detail_id) {
    //         console.log("viene quote detail id")
    //         showQuoteTransportationOrder(transportationOrderForm.transportation_quote_detail_id, {
    //             onSuccess: (response: ServiceResponse) => {
    //                 const quote: QuoteDataForm = response.data.quote;
    //                 console.log(quote)
    //                 setClients(response.data.clients);
    //                 clientsFromTqd.current = response.data.clients;
    //                 setTransportationOrderForm({
    //                     ...transportationOrderForm,
    //                     contact_email: quote.contact_email,
    //                     expired_at: quote.expired_at,
    //                     society_id: quote.society_id,
    //                     client_id: quote.client_id,
    //                     payment_condition_id: quote.payment_condition_id,
    //                     payment_method_id: quote.payment_method_id,
    //                     currency_id: quote.currency_id,
    //                     transportation_quote_detail_id:
    //                         transportationOrderForm.transportation_quote_detail_id,
    //                     days_count:quote.days_count
    //                 });
    //                 transportationOrderFromTqd.current = {
    //                    ...transportationOrder,
    //                    ...response.data.quote
    //                 }
    //             },
    //             onError: (response: ServiceResponse) => {
    //                setTransportationOrder({
    //                   ...transportationOrder,
    //                   transportation_quote_detail_id: transportationOrderFromTqd.current?.transportation_quote_detail_id ?? undefined
    //                });
    // }
    //         });
    //     }
    // }, [transportationOrderForm.transportation_quote_detail_id]);

    // const show = (id: number) => {
    //     showTransportationOrder(id, {
    //         onSuccess: (response: ServiceResponse) => {
    //             setTransportationOrderForm(response.data.transportation_order);
    //             setCurrencies(response.data.currencies);
    //             setQuantityUnits(response.data.quantity_units);
    //         }
    //     });
    // };

    // const update = () => {
    //     updateTransportationOrder(transportationOrderForm.id!, transportationOrderForm, {
    //         onSuccess: (response: ServiceResponse) => {
    //             toast.success(response.message);
    //             reloadModule();
    //         },
    //         onError: (response: ServiceResponse) => {
    //             toast.error(response.message);
    //         },
    //         onFieldError: (errorFields: ServiceResponse) => {
    //             setErrorFields(errorFields.data);
    //         }
    //     });
    // };

    const requestChangeStatus = (status: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');
        canChangeStatusTransportationOrder(parseInt(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(status, response.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data?.message_list ?? []
                });
                toast.error(response.message);
            }
        });
    };

    const changeStatus = (status: string, message: string) => {
        const _text = `Está a punto de cambiar el estado del pedido de transporte #${id}.${message ?? ''}`;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusTransportationOrder(Number(id), status, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                reloadModule();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El pedido de transporte no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs
                pageSection={`Editar pedido de transporte #${id}`}
                breadcrumbs={breadcrumbs}
            />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditTransportationOrder ? (
                            // canBeEdited ? (
                            //     <>
                            //         <TransportationOrderForm
                            //             transportationOrderForm={transportationOrderForm}
                            //             setTransportationOrderForm={setTransportationOrderForm}
                            //             societies={societies}
                            //             clients={clients}
                            //             paymentConditions={paymentConditions}
                            //             paymentMethods={paymentMethods}
                            //             quantityUnits={quantityUnits}
                            //             currencies={currencies}
                            //             errorFields={errorFields}
                            //             quotes={quotes}
                            //         />
                            //         <div className="row justify-content-end">
                            //             <div className="col-auto">
                            //                 <ButtonSaveForm callbackSave={update} />
                            //             </div>
                            //         </div>
                            //     </>
                            // ) : (
                            <>
                                <TransportationOrderShowForm
                                    transportationOrderDataForm={transportationOrderForm}
                                    quotes={quotes}
                                />
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                            {!fetchingEditTransportationOrder ? (
                                canBeEdited ? (
                                    <>
                                        <DefaultCreatedModifiedUserForm
                                            createdBy={transportationOrderForm.created_user}
                                            createdAt={transportationOrderForm.created_at}
                                            updatedBy={transportationOrderForm.last_modified_user}
                                            updatedAt={transportationOrderForm.updated_at}
                                            status={transportationOrderForm.status}
                                        />
                                    </>
                                ) : (
                                    <DefaultCreatedModifiedUserForm
                                        createdBy={transportationOrderForm.created_user}
                                        createdAt={transportationOrderForm.created_at}
                                        updatedBy={transportationOrderForm.last_modified_user}
                                        updatedAt={transportationOrderForm.updated_at}
                                        status={transportationOrderForm.status}
                                        // status={
                                        //     transportationOrderForm.status_selected
                                        //         ? transportationOrderForm.status_selected.name.toString()
                                        //         : ''
                                        // }
                                    />
                                )
                            ) : (
                                <LazyLoading />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {!fetchingEditTransportationOrder && !fetchingShowTransportationOrder ? (
                <>
                    <TransportationOrderItemsContainer
                        transportationOrderId={parseInt(id)}
                        canBeEdited={canBeEdited}
                        currencyId={transportationOrderForm.currency_id}
                        transportationOrderForm={transportationOrderForm}
                        setTransportationOrderForm={setTransportationOrderForm}
                        reloadModule={reloadModule}
                    />
                    <TransportationOrderChangeStatusEdit
                        status={String(transportationOrderForm?.status)}
                        statuses={statuses}
                        requestChangeStatus={requestChangeStatus}
                        fetchingChangeStatus={fetchingChangeStatusTransportationOrder}
                    />
                </>
            ) : null}
        </>
    );
};

export default TransportationOrderEdit;
