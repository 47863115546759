export interface Truck {
    id?: number;
    patent?: string;
    transport_company_id?: number;
    hoppers_assigned?: [];
    documents?: TruckDocument[];
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface TruckFilters {
    id?: number | null;
    patent?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultTruck: Truck = {
    patent: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    documents: []
};

export interface TruckDocument {
    id?: number;
    file?: File;
    expire_date?: string;
    type?: string;
}
