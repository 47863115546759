import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useServiceManifestDocumentService from '../../../app/services/hooks/useServiceManifestDocumentService';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentFilters
} from '../../../app/models/ServiceManifestDocument';
import ServiceManifestDocumentDataTable from './tables/ServiceManifestDocumentDataTable';

import ServiceManifestDocumentCreate from './ServiceManifestDocumentCreate';
import ServiceManifestDocumentEdit from './ServiceManifestDocumentEdit';
import ServiceManifestDocumentShow from './ServiceManifestDocumentShow';

interface Props {
    serviceManifestId: number;
    isShow?: boolean;
}

const ServiceManifestDocumentContainer = ({ serviceManifestId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getServiceManifestDocumentsByServiceManifest,
        fetchingGetServiceManifestDocumentsByServiceManifest,
        deleteServiceManifestDocument,
        fetchingDeleteServiceManifestDocument
    } = useServiceManifestDocumentService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);

    const [showingCreate, setShowingCreate] = useState(false);

    const [serviceManifestDocumentIdEdit, setServiceManifestDocumentIdEdit] = useState<number>(-1);
    const [serviceManifestDocumentIdShow, setServiceManifestDocumentIdShow] = useState<number>(-1);
    const [serviceManifestDocuments, setServiceManifestDocuments] = useState<
        ServiceManifestDocumentDataForm[]
    >([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [position, setPosition] = useState<number>(0);
    const [filter, setFilter] = useState<ServiceManifestDocumentFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });

    const reloadTable = () => {
        getServiceManifestDocumentsByServiceManifest(filter, serviceManifestId, {
            onSuccess: (response: ServiceResponse) => {
                setServiceManifestDocuments(response.data.service_manifest_documents);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (serviceManifestDocumentId: number, position: number) => {
        setShowingEdit(true);
        setServiceManifestDocumentIdEdit(serviceManifestDocumentId);
        setPosition(position);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setServiceManifestDocumentIdEdit(-1);
        setPosition(0);
    };

    const showShow = (serviceManifestDocumentId: number, position: number) => {
        setShowingShow(true);
        setServiceManifestDocumentIdShow(serviceManifestDocumentId);
        setPosition(position);
    };

    const hideShow = () => {
        setShowingShow(false);
        setServiceManifestDocumentIdShow(-1);
        setPosition(0);
    };

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const destroy = (serviceManifestDocumentId: number, position: number) => {
        const _text = 'Está a punto de eliminar el documento en la hoja de entrada #' + position;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando documento de la hoja de entrada...');
                        deleteServiceManifestDocument(serviceManifestDocumentId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El documento en la hoja de entrada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    {!isShow && (
                        <div className="col-12 mb-2">
                            <div className="row">
                                <div className="col-auto">
                                    <ButtonCreate
                                        title="Nuevo documento"
                                        callbackCreate={showCreate}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-12">
                        {isShow ? (
                            <ServiceManifestDocumentDataTable
                                serviceManifestDocuments={serviceManifestDocuments}
                                loading={fetchingGetServiceManifestDocumentsByServiceManifest}
                                totalRows={totalRows}
                                show={showShow}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        ) : (
                            <ServiceManifestDocumentDataTable
                                serviceManifestDocuments={serviceManifestDocuments}
                                edit={showEdit}
                                destroy={destroy}
                                show={showShow}
                                loading={fetchingGetServiceManifestDocumentsByServiceManifest}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDocumentCreate
                        serviceManifestId={serviceManifestId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title={`Editar documento # ${position}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDocumentEdit
                        serviceManifestDocumentId={serviceManifestDocumentIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title={`Ver documento # ${position}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDocumentShow
                        serviceManifestDocumentId={serviceManifestDocumentIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ServiceManifestDocumentContainer;
