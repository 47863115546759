import React, { useEffect } from 'react';
import { RouteSegment } from '../../../../app/models/RouteSegment';
import { Unit } from '../../../../app/models/Unit';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Currency } from '../../../../app/models/Currency';
import { QuoteRouteSegmentDataForm } from '../../../../app/models/QuoteRouteSegment';

interface GeneralRouteSegmentProps {
    quoteRouteSegmentForm: QuoteRouteSegmentDataForm;
    setQuoteRouteSegmentForm: (quoteRouteSegment: QuoteRouteSegmentDataForm) => void;
    routeSegments: RouteSegment[];
    currencies: Currency[];
    units: Unit[];
    errorFields?: any;
}

const quoteRouteSegmentForm = ({
    quoteRouteSegmentForm,
    setQuoteRouteSegmentForm,
    routeSegments,
    currencies,
    units,
    errorFields
}: GeneralRouteSegmentProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setQuoteRouteSegmentForm({ ...quoteRouteSegmentForm, [name]: value });
    };

    // const handleChangeSelectTwo = (name: string, value: number | string | null) => {
    //     setquoteRouteSegmentForm({ ...quoteRouteSegmentForm, [name]: value });
    // };

    // const handleRouteSegments = (routeSegments: RouteSegment[]) => {
    //     console.log(routeSegments)
    //     const newoptions:any = []
    //     routeSegments.map((option : any) => {

    //         option.name=`${option.name} | ${formatMoney(option.rate, option.currency.name)} ${option.currency.name}/ ${option.unit.name}`

    //     });

    //     return SelectTwoMapperOptions(routeSegments);

    // }

    const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (String(name) == 'rate') {
            const foundCurrency = currencies.find(
                (item) => item.id === quoteRouteSegmentForm.currency_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setQuoteRouteSegmentForm({
                    ...quoteRouteSegmentForm,
                    [name]: value.replace(/\D/g, '')
                });

                return;
            }
        } else if (String(name) == 'rate_runner') {
            const foundCurrency = currencies.find(
                (item) => item.id === quoteRouteSegmentForm.currency_rate_runner_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setQuoteRouteSegmentForm({
                    ...quoteRouteSegmentForm,
                    [name]: value.replace(/\D/g, '')
                });

                return;
            }
        } else if (String(name) == 'rate_transporter') {
            const foundCurrency = currencies.find(
                (item) => item.id === quoteRouteSegmentForm.currency_rate_transporter_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setQuoteRouteSegmentForm({
                    ...quoteRouteSegmentForm,
                    [name]: value.replace(/\D/g, '')
                });

                return;
            }
        }

        setQuoteRouteSegmentForm({ ...quoteRouteSegmentForm, [name]: value });
    };

    // const handleChangeSelectTwo = (name: string, value: number | string | null) => {
    //     setTransportationOrderForm({ ...transportationOrderForm, [name]: value });
    // };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name == 'currency_id') {
            obj.rate = format_currency(quoteRouteSegmentForm.rate, value);
        } else if (name == 'currency_rate_runner_id') {
            obj.rate_runner = format_currency(quoteRouteSegmentForm.rate_runner, value);
        } else if (name == 'currency_rate_transporter_id') {
            obj.rate_transporter = format_currency(quoteRouteSegmentForm.rate_transporter, value);
        }

        setQuoteRouteSegmentForm({ ...quoteRouteSegmentForm, ...obj });
    };

    const format_currency = (currency_rate: any, value: any) => {
        let rate = String(currency_rate);

        const foundCurrency = currencies.find((item) => item.id === value);
        const currency = foundCurrency ? foundCurrency.name : '';

        if (currency_rate && currency === 'CLP') {
            const indice = rate.search(/\D/);

            if (indice !== -1) {
                rate = rate.substring(0, indice);
            }
        }

        return rate;
    };

    if (!quoteRouteSegmentForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tramo</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="route_segment_id"
                        id="route_segment_id"
                        inputValue={quoteRouteSegmentForm.route_segment_id}
                        hasError={fieldHasError('route_segment_id') !== ''}
                        options={SelectTwoMapperOptions(routeSegments)}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('route_segment_id', value.value)
                        }
                        placeholder={'Seleccione tramo'}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('route_segment_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Distancia KM</label>
                <div className="col-md-9">
                    <input
                        name="distance"
                        id="distance"
                        type="number"
                        className={`form-control ${fieldHasError('distance')}`}
                        value={quoteRouteSegmentForm.distance}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('distance')}
                        placeholder="Ingrese distancia del tramo"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('distance')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa de venta</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                name="rate"
                                id="rate"
                                type="number"
                                className={`form-control ${fieldHasError('rate')}`}
                                value={quoteRouteSegmentForm.rate}
                                onChange={handleRateChange}
                                onFocus={() => onFocusRemove('rate')}
                                placeholder="Ingrese tarifa"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_id"
                                id="currency_id"
                                inputValue={quoteRouteSegmentForm.currency_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_id')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="unit_id"
                                id="unit_id"
                                inputValue={quoteRouteSegmentForm.unit_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('unit_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Distancia acordada KM</label>
                <div className="col-md-9">
                    <input
                        name="agreed_distance"
                        id="agreed_distance"
                        type="number"
                        className={`form-control ${fieldHasError('agreed_distance')}`}
                        value={quoteRouteSegmentForm.agreed_distance}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('agreed_distance')}
                        placeholder="distancia acordada del tramo"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('agreed_distance')}
                    </div>
                </div>
            </div>

            <hr />

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa Runner</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="rate_runner"
                                className={`form-control ${fieldHasError('rate_runner')}`}
                                id="rate_runner"
                                onFocus={() => onFocusRemove('rate_runner')}
                                value={quoteRouteSegmentForm.rate_runner}
                                onChange={handleRateChange}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate_runner')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_rate_runner_id"
                                id="currency_rate_runner_id"
                                inputValue={quoteRouteSegmentForm.currency_rate_runner_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_rate_runner_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_rate_runner_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_rate_runner_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_rate_runner_id')}
                            </div>
                        </div>
                        <div className="col-3">
                            <SelectTwo
                                name="quantity_unit_rate_runner_id"
                                id="quantity_unit_rate_runner_id"
                                inputValue={quoteRouteSegmentForm.quantity_unit_rate_runner_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('quantity_unit_rate_runner_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'quantity_unit_rate_runner_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('quantity_unit_rate_runner_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity_unit_rate_runner_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa Transportista</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="rate_transporter"
                                className={`form-control ${fieldHasError('rate_transporter')}`}
                                id="rate_transporter"
                                onFocus={() => onFocusRemove('rate_transporter')}
                                value={quoteRouteSegmentForm.rate_transporter}
                                onChange={handleRateChange}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate_transporter')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_rate_transporter_id"
                                id="currency_rate_transporter_id"
                                inputValue={quoteRouteSegmentForm.currency_rate_transporter_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_rate_transporter_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'currency_rate_transporter_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('currency_rate_transporter_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_rate_transporter_id')}
                            </div>
                        </div>
                        <div className="col-3">
                            <SelectTwo
                                name="quantity_unit_rate_transporter_id"
                                id="quantity_unit_rate_transporter_id"
                                inputValue={quoteRouteSegmentForm.quantity_unit_rate_transporter_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('quantity_unit_rate_transporter_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'quantity_unit_rate_transporter_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('quantity_unit_rate_transporter_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity_unit_rate_transporter_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default quoteRouteSegmentForm;
