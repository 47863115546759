import { SalesInvoiceDetail } from '../../../../app/models/SalesInvoiceDetail';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import SalesInvoiceFilter from './SalesInvoiceFilter';
import { SalesInvoiceDetailFilters } from '../../../../app/models/SalesInvoiceDetail';
import { PaymentStatusDataForm, PaymentStatusesStatus } from '../../../../app/models/PaymentStatus';
import PaymentStatusChangeStatus from '../../../PaymentStatus/components/PaymentStatusChangeStatus';

interface Props {
    salesInvoiceDetail: PaymentStatusDataForm[];
    totalRows: number;
    loading?: boolean;
    filter: SalesInvoiceDetailFilters;
    setFilter: (filter: SalesInvoiceDetailFilters) => void;
    edit?: (id: number) => void;
    destroy?: (id: number) => void;
    show?: (id: number) => void;
    callbackSuccess: () => void;
    statuses: PaymentStatusesStatus[];

}

const SalesInvoiceDetailDataTable = ({
    salesInvoiceDetail,
    totalRows,
    loading = false,
    filter,
    setFilter,
    edit,
    destroy,
    show,
    callbackSuccess,
    statuses,
}: Props) => {

    const columns = [
        // {
        //     name: 'Pos',
        //     selector: (row: SalesInvoiceDetail) => row.id,
        //     sortable: true,
        //     sortField: 'id',
        //     cell: (row: SalesInvoiceDetail) => <span>{row.id}</span>
        // },
        {
            name: 'Glosa',
            selector: (row: SalesInvoiceDetail) => row.payment_status_id,
            sortable: true,
            sortField: 'payment_status_id',
            cell: (row: SalesInvoiceDetail) => <span> {"EDP #"+row.id}</span>
        },
        {
            name: 'Valor Un',
            selector: (row: SalesInvoiceDetail) => row.total_neto,
            sortable: true,
            sortField: 'total_neto',
            cell: (row: SalesInvoiceDetail) => <span>{row.total_neto}</span>
        },
        {
            name: 'Neto',
            selector: (row: SalesInvoiceDetail) => row.total_neto,
            sortable: true,
            sortField: 'total_neto',
            cell: (row: SalesInvoiceDetail) => <span>{row.total_neto}</span>
        },
        {
            name: 'Impuesto',
            selector: (row: SalesInvoiceDetail) => row.total_tax,
            sortable: true,
            sortField: 'total_tax',
            cell: (row: SalesInvoiceDetail) => <span>{row.total_tax}</span>
        },
        {
            name: 'Total',
            selector: (row: any) => row.total,
            sortable: true,
            sortField: 'total',
            cell: (row: any) => <span>{row.total}</span>
        },

        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: any) => (
                <div>
                    <PaymentStatusChangeStatus
                        paymentStatusId={row.id!}
                        statuses={row.statuses}
                        selectedStatus={row.status}
                        callbackSuccess={callbackSuccess}
                    />
                </div>
            ),
        },
        {
            name: 'Acciones',
            selector: (row: SalesInvoiceDetail) => row.id,
            sortable: false,
            cell: (row: SalesInvoiceDetail) => (
                <div>
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            {/* <div className="row mt-3">
                <div className="col">
                    <SalesInvoiceFilter filter={filter} setFilter={setFilter} />
                </div>
            </div> */}

            <DefaultDataTable
                columns={columns}
                data={salesInvoiceDetail}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SalesInvoiceDetailDataTable;
