import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useServiceManifestDocumentService from '../../../app/services/hooks/useServiceManifestDocumentService';

import ServiceManifestDocumentFormShow from './forms/ServiceManifestDocumentFormShow';
import { ServiceManifestDocumentDataForm } from '../../../app/models/ServiceManifestDocument';

interface Props {
    serviceManifestDocumentId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestDocumentShow = ({
    serviceManifestDocumentId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingShowServiceManifestDocument, showServiceManifestDocument } =
        useServiceManifestDocumentService();

    const [serviceManifestDocument, setServiceManifestDocument] =
        useState<ServiceManifestDocumentDataForm>({});

    useEffect(() => {
        show();
    }, []);

    const show = () => {
        if (showLoading) showLoading('loading', 'Cargando documento de hoja de entrada...');
        showServiceManifestDocument(serviceManifestDocumentId, {
            onSuccess: (response: ServiceResponse) => {
                setServiceManifestDocument(response.data.service_manifest_document);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingShowServiceManifestDocument ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestDocumentFormShow
                        serviceManifestDocumentDataForm={serviceManifestDocument}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={cancel} title="Cerrar" />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDocumentShow;
