import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Currency, CurrencyFilters } from '../../models/Currency';

const useCurrencyService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCurrencies, setFetchingGetCurrencies] = useState(false);
    const [fetchingShowCurrency, setFetchingShowCurrency] = useState(false);
    const [fetchingStoreCurrency, setFetchingStoreCurrency] = useState(false);
    const [fetchingEditCurrency, setFetchingEditCurrency] = useState(false);
    const [fetchingUpdateCurrency, setFetchingUpdateCurrency] = useState(false);
    const [fetchingDeleteCurrency, setFetchingDeleteCurrency] = useState(false);
    const [fetchingActiveCurrency, setFetchingActiveCurrency] = useState(false);
    const [fetchingCreateCurrency, setFetchingCreateCurrency] = useState(false);


    const getCurrencies = (currencyFilters: CurrencyFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(currencyFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CURRENCIES.GET_CURRENCIES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCurrencies
        });
    };

    const showCurrency = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CURRENCIES.SHOW_CURRENCY.replace(':id', id.toString()),
            setFetching: setFetchingShowCurrency
        });
    };

    const storeCurrency = (currency: Currency, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CURRENCIES.STORE_CURRENCY,
            body: currency,
            setFetching: setFetchingStoreCurrency
        });
    };

    const createCurrency = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CURRENCIES.CREATE_CURRENCY,
            setFetching: setFetchingCreateCurrency
        });
    };

    const editCurrency = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CURRENCIES.EDIT_CURRENCY.replace(':id', id.toString()),
            setFetching: setFetchingEditCurrency
        });
    };

    const updateCurrency = (id: number, currency: Currency, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CURRENCIES.UPDATE_CURRENCY.replace(':id', id.toString()),
            body: currency,
            setFetching: setFetchingUpdateCurrency
        });
    };

    const deleteCurrency = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CURRENCIES.DELETE_CURRENCY.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCurrency
        });
    };

    const activeCurrency = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CURRENCIES.ACTIVE_CURRENCY.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveCurrency
        });
    };

    return {
        fetchingGetCurrencies,
        fetchingShowCurrency,
        fetchingStoreCurrency,
        fetchingEditCurrency,
        fetchingUpdateCurrency,
        fetchingDeleteCurrency,
        fetchingActiveCurrency,
        fetchingCreateCurrency,
        getCurrencies,
        showCurrency,
        createCurrency,
        storeCurrency,
        editCurrency,
        updateCurrency,
        deleteCurrency,
        activeCurrency
    };
};

export default useCurrencyService;
