import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { useHistory } from 'react-router-dom';
import useClasificationService from '../../app/services/hooks/useClasificationService';
import { ClasificationDataForm, ClasificationFilters } from '../../app/models/Clasification';
import ClasificationDataTable from './components/tables/ClasificationDataTable';
import ClasificationCreate from './components/ClasificationCreate';
import useNavigationPage from '../../hooks/useNavigationPage';
import ClasificationEdit from './components/ClasificationEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Clasificaciones',
        url: '/clasifications',
        isActive: true
    }
];

const Clasifications: React.FC = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetClasifications, getClasifications, activeClasification,deleteClasification } = useClasificationService();
    const [clasifications, setClasifications] = useState<ClasificationDataForm[]>([]);
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingCreateTwo, setShowingCreateTwo] = useState(false);
    const [showingCreateThree, setShowingCreateThree] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [clasificationIdEdit, setClasificationIdEdit] = useState<number>(-1);



    const [totalRows, setTotalRows] = useState<number>(0);

    const [clasificationFilter, setClasificationFilter] = useState<ClasificationFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        type: ''
    });

    useEffect(() => {
        reloadTable();
    }, [clasificationFilter]);

    const getAllClasifications = () => {
        getClasifications(clasificationFilter, {
            onSuccess: (response: ServiceResponse) => {
                setClasifications(response.data.clasifications);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };
    
    const showCreateTwo = () => {
        setShowingCreateTwo(true);
    };

    const hideCreateTwo = () => {
        setShowingCreateTwo(false);
    };
    
    const showCreateThree = () => {
        setShowingCreateThree(true);
    };

    const hideCreateThree = () => {
        setShowingCreateThree(false);
    };

    const showEdit = (clasificationId: number) => {
        setShowingEdit(true);
        setClasificationIdEdit(clasificationId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setClasificationIdEdit(-1);
    };

    const reloadTable = () => {
        getAllClasifications();
    };

    const active = (clasificationId: number, active: boolean) => {
        activeClasification(clasificationId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const destroy = (clasificationId: number) => {
        const _text = 'Está a punto de eliminar la clasificación #' + clasificationId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando clasificación...');
                        deleteClasification(clasificationId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La clasificación no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Clasificaciones" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={[
                        <div key="create1" className="d-block d-md-inline-block m-2 mb-0">
                            <ButtonCreate callbackCreate={showCreate} title="Crear clasificación tipo 1" />
                        </div>,
                        <div key="create2" className="d-block d-md-inline-block m-2 mb-0">
                            <ButtonCreate callbackCreate={showCreateTwo} title="Crear clasificación tipo 2" />
                        </div>,
                        <div key="create3" className="d-block d-md-inline-block m-2 mb-0">
                            <ButtonCreate callbackCreate={showCreateThree} title="Crear clasificación tipo 3" />
                        </div>
                    ]}
                />
                <ClasificationDataTable
                    clasifications={clasifications}
                    loading={fetchingGetClasifications}
                    filter={clasificationFilter}
                    active={active}
                    edit={showEdit}
                    destroy={destroy}
                    setFilter={setClasificationFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}

                />
            </DefaultCard>

            {showingCreate && (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear clasificación"
                    backdrop={true}
                    showFooter={false}
                >
                    <ClasificationCreate
                        type={1}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            )}
            
            {showingCreateTwo && (
                <DefaultModal
                    show={showingCreateTwo}
                    handleClose={hideCreateTwo}
                    title="Crear clasificación tipo 2"
                    backdrop={true}
                    showFooter={false}
                >
                    <ClasificationCreate
                        type={2}
                        onSaved={() => {
                            reloadTable();
                            hideCreateTwo();
                        }}
                        onCancel={hideCreateTwo}
                        onError={hideCreateTwo}
                    />
                </DefaultModal>
            )}
            
            {showingCreateThree && (
                <DefaultModal
                    show={showingCreateThree}
                    handleClose={hideCreateThree}
                    title="Crear clasificación tipo 3"
                    backdrop={true}
                    showFooter={false}
                >
                    <ClasificationCreate
                        type={3}
                        onSaved={() => {
                            reloadTable();
                            hideCreateThree();
                        }}
                        onCancel={hideCreateThree}
                        onError={hideCreateThree}
                    />
                </DefaultModal>
            )}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Clasificacion"
                    backdrop={true}
                    showFooter={false}
                >
                    <ClasificationEdit
                        clasificationId={clasificationIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Clasifications;
