import React from 'react';
import { PurchaseOrderDetailFilters } from '../../../../app/models/PurchaseOrderDetail';

type Props = {
    filter: PurchaseOrderDetailFilters;
    setFilter: (filter: PurchaseOrderDetailFilters) => void;
};

const PurchaseOrderDetailFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="item_name">
                            Glosa
                        </label>
                        <input
                            title="Item"
                            type="text"
                            id="item_name"
                            name="item_name"
                            className="form-control form-control-sm"
                            value={filter.item_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
               
                <div className="col-md-auto">
                <label htmlFor="selectGrande" className="mb-1 font-size-10 ">Tipo de item</label>
                <select
                            
                            name="item_type"
                            className="form-select form-select-sm"
                            value={filter.item_type}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'PRODUCT'} value="PRODUCT">
                            Producto
                            </option>
                            <option key={'SERVICE'} value="SERVICE">
                            Servicio
                            </option>
                          
                        </select>
                </div>
                
            </div>
            <div className='row'>
            <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_from">
                            Fecha de creación desde
                        </label>
                        <input
                            title="Fecha de creación desde"
                            type="date"
                            id="created_at_from"
                            name="created_at_from"
                            className="form-control form-control-sm"
                            value={filter.created_at_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_until">
                            Fecha de creación hasta
                        </label>
                        <input
                            title="Fecha de creación hasta"
                            type="date"
                            id="created_at_until"
                            name="created_at_until"
                            className="form-control form-control-sm"
                            value={filter.created_at_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default PurchaseOrderDetailFilter;
