import React, { useContext } from 'react';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { DispatchProgramStatus } from '../../../app/models/DispatchProgram';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { label } from 'yet-another-react-lightbox/*';

interface Props {
    dispatchProgramId: number;
    statuses: DispatchProgramStatus[];
    selectedStatus: DispatchProgramStatus;
    callbackSuccess?: () => void;
}

const DispatchProgramChangeStatus = ({
    dispatchProgramId,
    statuses,
    selectedStatus,
    callbackSuccess
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [ selected, setSelected ] = React.useState(selectedStatus);

    const { canChangeStatusDispatchProgram, changeStatusDispatchProgram } = useDispatchProgramService();

    const requestChangeStatus = (valueStatus: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');
        
        if (valueStatus == selected.id){
            if (hideLoading) hideLoading();
            return null;
        }

        const _status = statuses.find((status: DispatchProgramStatus) => status.id === valueStatus);

        if(!_status){
            if (hideLoading) hideLoading();
            return null;
        }
        setSelected(_status);

        canChangeStatusDispatchProgram(dispatchProgramId, valueStatus, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(valueStatus, response.message);
            },
            onError: (response: ServiceResponse) => {
                setSelected(selectedStatus);
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data?.message_list ?? []
                });
                toast.error(response.message);
            }
        })
        
    }

    const changeStatus = (value: string, message: string) => {
        const _text = `Está a punto de cambiar el estado del despacho #${dispatchProgramId}.${message ?? ''}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusDispatchProgram(dispatchProgramId, value, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                if(callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                setSelected(selectedStatus);
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        })
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            setSelected(selectedStatus);
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El despacho no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                },
            },
            onClickOutsideFunction: () => {
                setSelected(selectedStatus);
            }
        })
    
    
    
        // useSweetAlert().requestConfirmation({
        //     title: '¿Está seguro?',
        //     text: _text,
        //     confirmButtonText: 'Si, cambiar estado',
        //     cancelButtonText: 'Cancelar',
        //     onConfirm: () => {
        //         switch (value) {
        //             case 'PREPARACION':
        //                 preparationDispatchProgram(dispatchProgramId, eventChangeStatus);
        //                 break;
        //             case 'REVISION_OPERACION':
        //                 operationReviewDispatchProgram(dispatchProgramId, eventChangeStatus);
        //                 break;
        //             case 'REVISION_CLIENTE':
        //                 clientReviewDispatchProgram(dispatchProgramId, eventChangeStatus);
        //                 break;
        //             case 'RECHAZADA':
        //                 rejectDispatchProgram(dispatchProgramId, eventChangeStatus);
        //                 break;
        //             case 'CONFIRMADA':
        //                 confirmDispatchProgram(dispatchProgramId, eventChangeStatus);
        //                 break;
        //             default:
        //                 useSweetAlert().errorAlert({
        //                     title: 'Error',
        //                     text: 'Opción no encontrada.'
        //                 });
        //                 setSelected(selectedStatus);
        //                 break;
        //         }
        //     },
        //     onCancel: () => {
        //         useSweetAlert().infoAlert({
        //             title: 'Cancelado',
        //             text: 'El estado de la cotización no se ha cambiado.'
        //         });
        //         setSelected(selectedStatus);
        //     }
        // });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => requestChangeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses
                .filter((status) => status.id !== selectedStatus.id) 
                .map((status) => {
                    return (
                        <option key={status.id} value={status.id}>
                            {status.name}
                        </option>
                    );
                })}
        </select>
    );
};

export default DispatchProgramChangeStatus;
