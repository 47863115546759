import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BankAccountReason, BankAccountReasonFilters } from '../../models/BankAccountReason';

const useBankAccountReasonService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBankAccountReasons, setFetchingGetBankAccountReasons] = useState(false);
    const [fetchingShowBankAccountReason, setFetchingShowBankAccountReason] = useState(false);
    const [fetchingStoreBankAccountReason, setFetchingStoreBankAccountReason] = useState(false);
    const [fetchingEditBankAccountReason, setFetchingEditBankAccountReason] = useState(false);
    const [fetchingUpdateBankAccountReason, setFetchingUpdateBankAccountReason] = useState(false);
    const [fetchingDeleteBankAccountReason, setFetchingDeleteBankAccountReason] = useState(false);
    const [fetchingActiveBankAccountReason, setFetchingActiveBankAccountReason] = useState(false);

    const getBankAccountReasons = (bankFilters: BankAccountReasonFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(bankFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_REASONS.GET_BANK_ACCOUNT_REASONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBankAccountReasons
        });
    };

    const showBankAccountReason = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_REASONS.SHOW_BANK_ACCOUNT_REASON.replace(':id', id.toString()),
            setFetching: setFetchingShowBankAccountReason
        });
    };

    const storeBankAccountReason = (bank: BankAccountReason, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT_REASONS.STORE_BANK_ACCOUNT_REASON,
            body: bank,
            setFetching: setFetchingStoreBankAccountReason
        });
    };

    const editBankAccountReason = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_REASONS.EDIT_BANK_ACCOUNT_REASON.replace(':id', id.toString()),
            setFetching: setFetchingEditBankAccountReason
        });
    };

    const updateBankAccountReason = (id: number, bank: BankAccountReason, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BANK_ACCOUNT_REASONS.UPDATE_BANK_ACCOUNT_REASON.replace(':id', id.toString()),
            body: bank,
            setFetching: setFetchingUpdateBankAccountReason
        });
    };

    const deleteBankAccountReason = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BANK_ACCOUNT_REASONS.DELETE_BANK_ACCOUNT_REASON.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBankAccountReason
        });
    };

    const activeBankAccountReason = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT_REASONS.ACTIVE_BANK_ACCOUNT_REASON.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBankAccountReason
        });
    };

    return {
        fetchingGetBankAccountReasons,
        fetchingShowBankAccountReason,
        fetchingStoreBankAccountReason,
        fetchingEditBankAccountReason,
        fetchingUpdateBankAccountReason,
        fetchingDeleteBankAccountReason,
        fetchingActiveBankAccountReason,
        getBankAccountReasons,
        showBankAccountReason,
        storeBankAccountReason,
        editBankAccountReason,
        updateBankAccountReason,
        deleteBankAccountReason,
        activeBankAccountReason
    };
};

export default useBankAccountReasonService;
