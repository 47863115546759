import { EndPoints } from '../EndPoints';
import {
    DispatchProgramDailyCarrierGuide,
    DispatchProgramItem,
    DispatchProgramItemFilters,
    MassiveStatusForm
} from '../../models/DispatchProgramItem';
import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { fetchDefaultHeaders, getFetchDefaultHeaders } from '../shared/vars';
import { MassiveLoad } from '../../models/MassiveLoad';

const useDispatchProgramItemService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [
        fetchingGetDispatchProgramItemsByDispatch,
        setFetchingGetDispatchProgramItemsByDispatch
    ] = useState(false);

    const [
        fetchingGetDispatchProgramItemsByTransportationSubOrderItem,
        setFetchingGetDispatchProgramItemsByTransportationSubOrderItem
    ] = useState(false);

    const [fetchingStoreDispatchProgramItem, setFetchingStoreDispatchProgramItem] = useState(false);
    const [fetchingEditDispatchProgramItem, setFetchingEditDispatchProgramItem] = useState(false);
    const [fetchingUpdateDispatchProgramItem, setFetchingUpdateDispatchProgramItem] =
        useState(false);
    const [fetchingDeleteDispatchProgramItem, setFetchingDeleteDispatchProgramItem] =
        useState(false);
    const [fetchingCreateDispatchProgramItem, setFetchingCreateDispatchProgramItem] =
        useState(false);
    const [fetchingShowDispatchProgramItem, setFetchingShowDispatchProgramItem] = useState(false);

    const [fetchingPreparationDispatchProgramItem, setFetchingPreparationDispatchProgramItem] =
        useState(false);
    const [fetchingPlanningDispatchProgramItem, setFetchingPlanningDispatchProgramItem] =
        useState(false);
    const [fetchingDispatchDispatchProgramItem, setFetchingDispatchDispatchProgramItem] =
        useState(false);
    const [fetchingFinishDispatchProgramItem, setFetchingFinishDispatchProgramItem] =
        useState(false);
    const [fetchingGetDispatchProgramItemGuide, setFetchingGetDispatchProgramItemGuide] =
        useState(false);

    const [
        fetchingGetDispatchProgramItemDailyCarriersAvailable,
        setFetchingGetDispatchProgramItemDailyCarriersAvailable
    ] = useState(false);

    const [fetchingSyncDispatchProgramDailyCarriers, setFetchingSyncDispatchProgramDailyCarriers] =
        useState(false);

    const [
        fetchingHasAllFinishedDispatchProgramItems,
        setFetchingHasAllFinishedDispatchProgramItems
    ] = useState(false);

    const [fetchingExcelLoadBulk, setFetchingExcelLoadBulk] = useState(false);
    const [fetchingGetExcelDispatchProgramItems, setFetchingGetExcelDispatchProgramItems] =
        useState(false);

    const [
        fetchingChangeStatusesRowsToDispatchDispatchProgramItem,
        setFetchingChangeStatusesRowsToDispatchDispatchProgramItem
    ] = useState(false);

    const [
        fetchingMasiveDeleteDispatchRows,
        setFetchingMasiveDeleteDispatchRows
    ] = useState(false);

    const getAllDispatchProgramItemsByDispatch = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.GET_DISPATCH_PROGRAM_ITEMS_BY_DISPATCH.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetDispatchProgramItemsByDispatch
        });
    };

    const getAllDispatchProgramItemsByTransportationSubOrderItem = (
        dispatchProgramItemFilter: DispatchProgramItemFilters,
        dispatchProgramId: number,
        id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(dispatchProgramItemFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.DISPATCH_PROGRAMS.GET_DISPATCH_PROGRAM_ITEMS_BY_TRANSPORTATION_SUB_ORDER_ITEM}?dispatch_program_id=${dispatchProgramId}&${queryString}`;

        doGet({
            ...events,
            url: url.replace(':id', id.toString()),
            setFetching: setFetchingGetDispatchProgramItemsByTransportationSubOrderItem
        });
    };

    const excelLoadBulk = (massiveLoad: MassiveLoad, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.LOAD_BULK,
            body: massiveLoad,
            requestType: 'multipart',
            setFetching: setFetchingExcelLoadBulk
        });
    };

    const getExcelAllDispatchProgramItemsByTransportationSubOrderItem = (
        dispatchProgramItemFilter: DispatchProgramItemFilters,
        dispatchProgramId: number,
        id: number,
        events: ServiceEventsDocuments = {}
    ) => {
        const queryString = Object.entries(dispatchProgramItemFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.DISPATCH_PROGRAMS.GET_EXCEL_DISPATCH_PROGRAM_ITEMS_BY_TRANSPORTATION_SUB_ORDER_ITEM}?dispatch_program_id=${dispatchProgramId}&${queryString}`;

        doGetDocument({
            ...events,
            url: url.replace(':id', id.toString()),
            successData: {
                nameDocument: `Despacho N°${dispatchProgramId}.xlsx`,
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetExcelDispatchProgramItems
        });
    };

    const createDispatchProgramItem = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.CREATE_DISPATCH_PROGRAM_ITEM,
            setFetching: setFetchingCreateDispatchProgramItem
        });
    };

    const storeDispatchProgramItem = (
        dispatchProgramItem: DispatchProgramItem,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.STORE_DISPATCH_PROGRAM_ITEM,
            body: dispatchProgramItem,
            setFetching: setFetchingStoreDispatchProgramItem
        });
    };

    const editDispatchProgramItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.EDIT_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditDispatchProgramItem
        });
    };

    const showDispatchProgramItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.SHOW_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowDispatchProgramItem
        });
    };

    const updateDispatchProgramItem = (
        id: number,
        dispatchProgramItem: DispatchProgramItem,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.UPDATE_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            body: dispatchProgramItem,
            setFetching: setFetchingUpdateDispatchProgramItem
        });
    };

    const deleteDispatchProgramItem = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.DELETE_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingDeleteDispatchProgramItem
        });
    };

    const preparationDispatchProgramItem = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.PREPARATION_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingPreparationDispatchProgramItem
        });
    };

    const preparationDispatchProgramRows = (ids: number[], events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.PREPARATION_DISPATCH_PROGRAM_ROWS,
            body: { ids },
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...fetchDefaultHeaders
            },
            setFetching: setFetchingPreparationDispatchProgramItem
        });
    };

    const planningDispatchProgramItem = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.PLANNING_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingPlanningDispatchProgramItem
        });
    };

    const planningDispatchProgramRows = (ids: number[], events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.PLANNING_DISPATCH_PROGRAM_ROWS,
            body: { ids },
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...fetchDefaultHeaders
            },
            setFetching: setFetchingPlanningDispatchProgramItem
        });
    };

    const dispatchDispatchProgramItem = (
        id: number,
        // data: DispatchProgramItemChangeStatusDispatch,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.DISPATCH_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            // body: data,
            setFetching: setFetchingDispatchDispatchProgramItem
        });
    };

    const dispatchDispatchProgramRows = (
        ids: number[],
        // data: DispatchProgramItemChangeStatusDispatch,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.DISPATCH_DISPATCH_PROGRAM_ROWS,
            body: { ids },
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...fetchDefaultHeaders
            },
            setFetching: setFetchingDispatchDispatchProgramItem
        });
    };

    const finishDispatchProgramItem = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.FINISH_DISPATCH_PROGRAM_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingFinishDispatchProgramItem
        });
    };

    const finishDispatchProgramRows = (ids: number[], events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.FINISH_DISPATCH_PROGRAM_ROWS,
            body: { ids },
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...fetchDefaultHeaders
            },
            setFetching: setFetchingFinishDispatchProgramItem
        });
    };

    const getDispatchProgramItemDailyCarriersAvailable = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.GET_DISPATCH_PROGRAM_ITEM_DAILY_CARRIERS_AVAILABLE.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetDispatchProgramItemDailyCarriersAvailable
        });
    };

    const getDispatchProgramItemGuide = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.GET_DISPATCH_PROGRAM_ITEM_GUIDE.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetDispatchProgramItemGuide
        });
    };

    const updateDispatchProgramCarrierGuide = (
        dispatchProgramDailyCarrierSync: DispatchProgramDailyCarrierGuide,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.SYNC_DISPATCH_PROGRAM_DAILY_CARRIERS,
            body: dispatchProgramDailyCarrierSync,
            requestType: 'multipart',
            setFetching: setFetchingSyncDispatchProgramDailyCarriers
        });
    };

    const getUrlDispatchProgramItemFilters = (
        url: URL,
        dispatch_program_item_status: string | null = null,
        start_date: string | null = null,
        end_date: string | null = null
    ) => {
        if (dispatch_program_item_status && dispatch_program_item_status !== 'TODOS') {
            url.searchParams.append('status', dispatch_program_item_status);
        }
        if (start_date) {
            url.searchParams.append('start_date', start_date);
        }
        if (end_date) {
            url.searchParams.append('end_date', end_date);
        }
        return url;
    };

    const hasAllFinishedDispatchProgramItems = (
        id: number,
        status_detail_dpi: string,
        events: ServiceEvents = {}
    ) => {
        return doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.HAS_ALL_FINISHED_DISPATCH_PROGRAM_ITEMS.replace(
                ':id',
                id.toString()
            ).replace(':status_detail_dpi', status_detail_dpi.toString()),

            setFetching: setFetchingHasAllFinishedDispatchProgramItems
        });
    };

    const hasAllFinishedDispatchProgramRows = (
        ids: number[],
        status_detail_dpi: string,
        events: ServiceEvents = {}
    ) => {
        return doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.HAS_ALL_FINISHED_DISPATCH_PROGRAM_ROWS,
            body: {
                dispatch_program_ids: ids,
                status_detail_dpi: status_detail_dpi
            },
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...fetchDefaultHeaders
            },
            setFetching: setFetchingHasAllFinishedDispatchProgramItems
        });
    };

    
    const changeStatusesRowsToDispatchDispatchProgramItem = (
        massiveStatusForm: MassiveStatusForm,
        events: ServiceEvents = {}
    ) => {
        return doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.CHANGE_STATUSES_ROWS_TO_DISPATCH_PROGRAM_ITEM,
            body: massiveStatusForm,
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...getFetchDefaultHeaders()
            },
            setFetching: setFetchingChangeStatusesRowsToDispatchDispatchProgramItem
        });
    };
    
    const masiveDeleteDispatchRows = (
        massiveStatusForm: MassiveStatusForm,
        events: ServiceEvents = {}
    ) => {
        return doDelete({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.MASIVE_DELETE_ROWS_DISPATCH_PROGRAM_ITEMS,
            body: massiveStatusForm,
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...getFetchDefaultHeaders()
            },
            setFetching: setFetchingMasiveDeleteDispatchRows
        });
    };

    return {
        getAllDispatchProgramItemsByDispatch,
        getAllDispatchProgramItemsByTransportationSubOrderItem,
        createDispatchProgramItem,
        storeDispatchProgramItem,
        showDispatchProgramItem,
        editDispatchProgramItem,
        hasAllFinishedDispatchProgramItems,
        hasAllFinishedDispatchProgramRows,
        updateDispatchProgramItem,
        deleteDispatchProgramItem,
        preparationDispatchProgramItem,
        preparationDispatchProgramRows,
        planningDispatchProgramItem,
        planningDispatchProgramRows,
        dispatchDispatchProgramItem,
        dispatchDispatchProgramRows,
        finishDispatchProgramItem,
        finishDispatchProgramRows,
        getDispatchProgramItemGuide,
        getDispatchProgramItemDailyCarriersAvailable,
        updateDispatchProgramCarrierGuide,
        getExcelAllDispatchProgramItemsByTransportationSubOrderItem,
        excelLoadBulk,
        changeStatusesRowsToDispatchDispatchProgramItem,
        masiveDeleteDispatchRows,
        fetchingHasAllFinishedDispatchProgramItems,
        fetchingExcelLoadBulk,
        fetchingGetDispatchProgramItemGuide,
        fetchingGetDispatchProgramItemsByDispatch,
        fetchingGetDispatchProgramItemsByTransportationSubOrderItem,
        fetchingStoreDispatchProgramItem,
        fetchingShowDispatchProgramItem,
        fetchingEditDispatchProgramItem,
        fetchingUpdateDispatchProgramItem,
        fetchingDeleteDispatchProgramItem,
        fetchingCreateDispatchProgramItem,
        fetchingPreparationDispatchProgramItem,
        fetchingPlanningDispatchProgramItem,
        fetchingDispatchDispatchProgramItem,
        fetchingFinishDispatchProgramItem,
        fetchingGetDispatchProgramItemDailyCarriersAvailable,
        fetchingSyncDispatchProgramDailyCarriers,
        fetchingChangeStatusesRowsToDispatchDispatchProgramItem,
        fetchingMasiveDeleteDispatchRows,

    };
};

export default useDispatchProgramItemService;
