import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useProviderContactService from '../../../app/services/hooks/useProviderContactService';
import { ProviderContactDataForm } from '../../../app/models/ProviderContact';
import { ContactType } from '../../../app/models/ContactType';
import ProviderContactForm from './forms/ProviderContactForm';

interface Props {
    providerId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderContactCreate = ({ onSaved, onCancel, onError, providerId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createProviderContact,
        storeProviderContact,
        fetchingCreateProviderContact,
        fetchingStoreProviderContact
    } = useProviderContactService();

    const [providerContact, setProviderContact] = useState<ProviderContactDataForm>(
        {
            provider_id: providerId,
            name: '',
            email: '',
            contact_type_id: 0
        }
    );

    const [contactTypes, setContactTypes] = useState<ContactType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();
  
    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createProviderContact({
            onSuccess: (response: ServiceResponse) => {
         
                setContactTypes(response.data.contact_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);


    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando contacto proveedor...');
        storeProviderContact(providerContact, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateProviderContact ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ProviderContactForm
                        providerContactDataForm={providerContact}
                        setProviderContactDataForm={setProviderContact}
                        contactTypes={contactTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreProviderContact}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreProviderContact} />
                </div>
            </div>
        </>
    );
};

export default ProviderContactCreate;
