export const APP_CONFIG = {
    APP_NAME: '',
    APP_TITLE: ''
};
export const LOCAL_STORAGE = {
    AUTH: '_TICSA_ORIGEN_PWA_AT_ec82cccc1563b15bda8748b13eba5576',
    AUTH_TOKEN: '_TICSA_ORIGEN_PWA_ATK_667e297dbf1a3b67f0adc256ad07e041'
};

interface SelectSize {
    [key: string]: {
        control: {
            minHeight: number;
            borderRadius: string;
            borderColor: string;
        };
        valueContainer: {
            minHeight: number;
            padding?: string;
        };
        dropdownIndicator: {
            minHeight: number;
            padding: string;
        };
        multiValue: {
            backgroundColor: string;
            color: string;
        };
        multiValueLabel: {
            background: string;
            color: string;
        };
    };
}

export interface StyleCondition {

    key: string;
    properties: {
        option: {
            color: {
                normal: string,
                hover: string
            },
            background: {
                normal: string,
                hover: string,
               
            },
            fontWeight:{
                normal: string,
                hover: string,
            }
        }
    }
    
}

export const sizes: SelectSize = {
    small: {
        control: {
            minHeight: 24,
            borderRadius: '2px',
            borderColor: '1px solid #ced4da'
        },
        valueContainer: {
            minHeight: 24,
            padding: '0 6px'
        },
        dropdownIndicator: {
            minHeight: 24,
            padding: '0 8px'
        },
        multiValue: {
            backgroundColor: '#1b7ee0',
            color: 'white'
        },
        multiValueLabel: {
            background: '#1b7ee0',
            color: 'white'
        }
    },
    medium: {
        control: {
            minHeight: 35,
            borderRadius: '3px',
            borderColor: '1px solid #ced4da'
        },
        valueContainer: {
            minHeight: 35
            // padding: '0 8px'
        },
        dropdownIndicator: {
            minHeight: 35,
            padding: '5px'
        },
        multiValue: {
            backgroundColor: '#1b7ee0',
            color: 'white'
        },
        multiValueLabel: {
            background: '#1b7ee0',
            color: 'white'
        }
    },
    large: {
        control: {
            minHeight: 200,
            borderRadius: '2px',
            borderColor: '1px solid #ced4da'
        },
        valueContainer: {
            minHeight: 35
            // padding: '0 8px'
        },
        dropdownIndicator: {
            minHeight: 35,
            padding: '5px'
        },
        multiValue: {
            backgroundColor: '#1b7ee0',
            color: 'white'
        },
        multiValueLabel: {
            background: '#1b7ee0',
            color: 'white'
        }
    }
};

const getStyles = (size: string) => {
    if (size in sizes) {
        return sizes[size];
    } else {
        return sizes['medium'];
    }
};

export const select2Config = (hasError = false, size: string) => {

    const stylesSelect = getStyles(size);
    return {
    
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: stylesSelect.control.minHeight,
        borderRadius: stylesSelect.control.borderRadius,
        border: hasError ? '1px solid red' : stylesSelect.control.borderColor
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        minHeight: stylesSelect.valueContainer.minHeight,
        padding: stylesSelect.valueContainer.padding || provided.padding
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        minHeight: stylesSelect.dropdownIndicator.minHeight,
        padding: stylesSelect.dropdownIndicator.padding
    }),
    option: (provided:any, state:any) => {

        let optionStyle = undefined;

        if(state.data.condition){
           
            if (Object.prototype.hasOwnProperty.call(state.data, state.data.condition.key)) {

                optionStyle = {
                    value: state.data[state.data.condition.key],
                    ...state.data.condition
                };
                
            }
       
        }
        return {
            ...provided,
            color: state.isSelected
                ? 'white'
                : (optionStyle && optionStyle?.value)
                    ? optionStyle.properties?.option?.color.normal
                    : '', 
            background: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.background.normal: '',
            fontWeight: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.fontWeight.normal: '',
            '&:hover': {
                color: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.color.hover : '', 
                background: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.background.hover: ''
            },
        };
    },
    multiValue: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: stylesSelect.multiValue.backgroundColor,
        color: stylesSelect.multiValue.color
    }),
    multiValueLabel: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: stylesSelect.multiValueLabel.background,
        color: stylesSelect.multiValueLabel.color
    })
};
};

export const defaultSwalStyleButtons = {
    customClass: {
        confirmButton: 'waves-effect btn btn-sm btn-success px-3',
        cancelButton: 'waves-effect btn btn-outline-light btn-sm px-3'
    },
    buttonsStyling: false
};

export const defaultSwalStyleTwoButtons = {
    customClass: {
        confirmButton: 'waves-effect btn btn-sm btn-success px-3',
        denyButton: 'waves-effect btn btn-sm btn-warning px-3',
        cancelButton: 'waves-effect btn btn-outline-light btn-sm px-3'
    },
    buttonsStyling: false
};
