import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ClientContactDataForm, ClientContactFilters } from '../../models/ClientContact';

const useClientContactService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetClientContactsByClient, setFetchingGetClientContactsByClient] = useState(false);
    const [fetchingShowClientContact, setFetchingShowClientContact] = useState(false);
    const [fetchingCreateClientContact, setFetchingCreateClientContact] = useState(false);
    const [fetchingStoreClientContact, setFetchingStoreClientContact] = useState(false);
    const [fetchingEditClientContact, setFetchingEditClientContact] = useState(false);
    const [fetchingUpdateClientContact, setFetchingUpdateClientContact] = useState(false);
    const [fetchingDeleteClientContact, setFetchingDeleteClientContact] = useState(false);
    const [fetchingActiveClientContact, setFetchingActiveClientContact] = useState(false);
    const [fetchingGetClientContactByClient, setFetchingGetClientContactByClient] = useState(false);

    const getClientContactsByClient = (filter: ClientContactFilters, client_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CLIENT_CONTACT.GET_CLIENT_CONTACTS_BY_CLIENT}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':client_id', client_id.toString()),
            setFetching: setFetchingGetClientContactsByClient
        });
    };

    const showClientContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENT_CONTACT.SHOW_CLIENT_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingShowClientContact
        });
    };

    const createClientContact = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENT_CONTACT.CREATE_CLIENT_CONTACT,
            setFetching: setFetchingCreateClientContact
        });
    };

    const storeClientContact = (clientContact: ClientContactDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CLIENT_CONTACT.STORE_CLIENT_CONTACT,
            body: clientContact,
            setFetching: setFetchingStoreClientContact
        });
    };

    const editClientContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENT_CONTACT.EDIT_CLIENT_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingEditClientContact
        });
    };

    const updateClientContact = (id: number, clientContact: ClientContactDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CLIENT_CONTACT.UPDATE_CLIENT_CONTACT.replace(':id', id.toString()),
            body: clientContact,
            setFetching: setFetchingUpdateClientContact
        });
    };

    const deleteClientContact = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CLIENT_CONTACT.DELETE_CLIENT_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteClientContact
        });
    };

    const activeClientContact = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CLIENT_CONTACT.ACTIVE_CLIENT_CONTACT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveClientContact
        });
    };

    const getClientContactByClient = (client_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENT_CONTACT.GET_CLIENT_CONTACT_BY_CLIENT.replace(':client_id', client_id.toString()),
            setFetching: setFetchingGetClientContactByClient
        });
    };

    return {
        fetchingGetClientContactsByClient,
        fetchingShowClientContact,
        fetchingCreateClientContact,
        fetchingStoreClientContact,
        fetchingEditClientContact,
        fetchingUpdateClientContact,
        fetchingDeleteClientContact,
        fetchingActiveClientContact,
        fetchingGetClientContactByClient,
        activeClientContact,
        getClientContactsByClient,
        showClientContact,
        createClientContact,
        storeClientContact,
        editClientContact,
        updateClientContact,
        deleteClientContact,
        getClientContactByClient
        
    };
};

export default useClientContactService;
