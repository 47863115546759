import { Currency } from "./Currency";
import { Incoterm } from "./Incoterm";
import { PaymentCondition } from "./PaymentCondition";
import { PaymentMethod } from "./PaymentMethod";
import { PaymentType } from "./PaymentType";
import { Provider } from "./Provider";
import { Society } from "./Society";
import { Tax } from "./Tax";

export interface PurchaseOrder{
    id?: number;
    society?: Society;
    society_id?: number;
    society_rut?: string;
    society_business_name?: string;
    society_address?: string;
    provider?: Provider;
    provider_id?: number;
    provider_rut?: string;
    provider_business_name?: string;
    provider_address?: string;
    provider_name_contact?: string;
    provider_phone_code_contact?: string;
    provider_phone_contact?: string;
    provider_email_contact?: string;
    destiny?: string;
    place_id?: number;

    name_contact?: string;
    email_contact?: string;
    phone_code_contact?: string;
    phone_contact?: string;
    incoterm?: Incoterm;
    incoterm_id?: number;
    status?: string;
    currency?: Currency;
    currency_id?: number;
    payment_method?: PaymentMethod;
    payment_method_id?: number;
    payment_condition?: PaymentCondition;
    payment_condition_selected?: string;
    days_count?: number;
}

export interface PurchaseorderDataForm{
    id?: number;
    society?: string;
    society_id?: number;
    society_rut?: string;
    society_name?: string;
    society_address?: string;
    provider?: string;
    provider_id?: number;
    provider_rut?: string;
    provider_name?: string;
    provider_address?: string;
    provider_name_contact?: string;
    provider_contact_phone?: string;
    provider_phone_contact?: string;
    provider_email_contact?: string;
    destiny?: string;
    place_id?: number;
    name_contact?: string;
    email_contact?: string;
    phone_code_contact?: string;
    phone_contact?: string;
    incoterm?: string;
    incoterm_id?: number;
    status?: string;
    currency?: string;
    currency_id?: number;
    payment_method_id?: number;
    payment_method?: string;
    payment_condition?: string;
    days_count?: number;
    created_at?: string;
    gross_total?: number;
    taxes?: number;
    tax_total?: number;
    society_business_name?: string;
    provider_business_name?: string;
}


export interface PurchaseOrderFilters {
    id?: number | null;
    society?: string | '';
    provider?: string | '';
    status?: string | '';
    payment_method?: string | '';
    payment_condition?: string;
    created_at_from?: string | '';
    created_at_until?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultPurchaseOrderDataForm: PurchaseorderDataForm = {

    society_id: 0,
    provider_id: 0,
    destiny: '',
    incoterm_id: 0,
    place_id: 0,
    payment_method_id: 0,
    currency_id: 0,
    payment_condition: ''

}

export interface PurchaseOrderStatus{

    id?: string;
    name?: string;
    prev?: number | string;
    next?: number | string;
}