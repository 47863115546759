import React, { useEffect, useState } from 'react';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import {
    defaultTransportationOrderItemRatesDataForm,
    RunnerAndTransporterRate,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Place } from '../../../app/models/Place';
import { Service } from '../../../app/models/Service';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import TransportationOrderItemForm from './forms/TransportationOrderItemForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import { QuoteRouteSegment } from '../../../app/models/QuoteRouteSegment';
import { RouteSegment, Routes } from '../../../app/models/RouteSegment';
import TransportationOrderRatesForm from './forms/TransportationOrderRatesForm';

interface Props {
    canBeEdited: boolean;
    transportationOrderItemId: number;
    transportationOrderForm: TransportationOrderDataForm;
    setTransportationOrderForm?: (transportationOrderForm: TransportationOrderDataForm) => void;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationOrderItemEditRates = ({
    canBeEdited,
    transportationOrderItemId,
    transportationOrderForm,
    setTransportationOrderForm,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        fetchingUpdateRatesTransportationOrderItem,
        fetchingGetRatesTransportationOrderItem,
        getRatesTransportationOrderItem,
        updateRatesTransportationOrderItem
    } = useTransportationOrderItemService();

    const [errorFields, setErrorFields] = useState<any>();

    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [runnerAndTransporterRates, setRunnerAndTransporterRates] = useState<
        RunnerAndTransporterRate[]
    >([]);
    const [disabledInputRates, setDisabledInputRates] = useState<boolean>(false);

    const [transportationOrderitemRatesForm, setTransportationOrderItemRatesForm] =
        useState<TransportationOrderItemDataForm>(defaultTransportationOrderItemRatesDataForm);

    useEffect(() => {
        if (transportationOrderItemId) {
            edit();
        }
    }, [transportationOrderItemId]);

    const edit = () => {
        getRatesTransportationOrderItem(transportationOrderItemId, {
            onSuccess: (response: ServiceResponse) => {
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setRunnerAndTransporterRates(response.data.all_rates);
                setTransportationOrderItemRatesForm(response.data.transportation_order_item);
                if (response.data.disabled_input_rates) {
                    setDisabledInputRates(true);
                }
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando tarifas...', 400);
        updateRatesTransportationOrderItem(
            transportationOrderItemId,
            transportationOrderitemRatesForm,
            {
                onSuccess: (response: ServiceResponse) => {
                    useSweetAlert().closeSpinnerAlert();
                    toast.success(response.message);
                    if (setTransportationOrderForm !== undefined) {
                        if (response.data?.update_user_date) {
                            setTransportationOrderForm({
                                ...transportationOrderForm,
                                ...response.data.update_user_date
                            });
                        }
                    }

                    if (onSaved) onSaved();
                },
                onError: (response: ServiceResponse) => {
                    useSweetAlert().closeSpinnerAlert();
                    if (onError) onError(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    useSweetAlert().closeSpinnerAlert();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingGetRatesTransportationOrderItem ? (
                        <TransportationOrderRatesForm
                            currencies={currencies}
                            quantityUnits={quantityUnits}
                            transportationOrderitemForm={transportationOrderitemRatesForm}
                            setTransportationOrderItemForm={setTransportationOrderItemRatesForm}
                            runnerAndTransporterRates={runnerAndTransporterRates}
                            errorFields={errorFields}
                            disabledInputRates={disabledInputRates}
                        />
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateRatesTransportationOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateRatesTransportationOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemEditRates;
