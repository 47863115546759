import React, { useContext, useState } from 'react';

import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { formatAmount, formatMoney } from '../../../../utils/utils';
import { PurchaseOrderDetailDataForm, PurchaseOrderDetailFilters } from '../../../../app/models/PurchaseOrderDetail';
import { Link } from 'react-router-dom';
import { ServiceManifestDataForm } from '../../../../app/models/ServiceManifest';

interface Props {
    form: ServiceManifestDataForm;
    setForm: (form: ServiceManifestDataForm) => void;
    purchaseOrderDetails: PurchaseOrderDetailDataForm[];
    totalRows: number;
    filter: PurchaseOrderDetailFilters;
    setFilter: (filter: PurchaseOrderDetailFilters) => void;
    loading: boolean;
    toggleCleared: boolean;
    resetContextActions: () => void;
}

const PurchaseOrderDetailOnDispatchProgramDataTable = ({
    form,
    setForm,
    purchaseOrderDetails,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    toggleCleared,
    resetContextActions
}: Props) => {
    const columns = [
        {
            name: 'id',
            selector: (row: PurchaseOrderDetailDataForm) => row?.id,
            sortable: true,
            sortField: 'id'
        },
        {
            name: <div dangerouslySetInnerHTML={{ __html: 'Orden<br />de<br />compra' }} />,
            selector: (row: PurchaseOrderDetailDataForm) => row?.purchase_order_id,
            sortable: true,
            sortField: 'purchase_order_id',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.purchase_order_id ? (
                        <Link to={'/purchase-orders/' + row?.purchase_order_id + '/show'}>
                            <span className="text-primary">#{row.purchase_order_id}</span>
                        </Link>
                    ) : (
                        ''
                    )}
                </span>
            )
            
        },
        {
            name: 'Posición',
            selector: (row: PurchaseOrderDetailDataForm) => row?.position,
            sortable: true,
            sortField: 'position'
        },
        
        {
            name: 'Origen',
            selector: (row: any) => row?.origin,
            sortable: true,
            sortField: 'origin',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {(row.route_segment && row.route_segment.origin) ? row.route_segment.origin.name:''}
                </span>
            )
        },
        {
            name: 'Destino',
            selector: (row: any) => row?.destiny,
            sortable: true,
            sortField: 'destiny',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {(row.route_segment && row.route_segment.destiny) ? row.route_segment.destiny.name:''}
                </span>
            )
        },
        {
            name: 'Tarifa',
            selector: (row: any) => row?.price,
            sortable: true,
            sortField: 'rate',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {row.price !== null &&
                            `${formatMoney(row.price ?? 0, row.currency_price)} ${
                                row.currency_price
                            } / ${row.unit_price}`}
                    </span>
                </>
            )
        },
       
        {
            name: 'Cantidad',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            width: '120px',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatAmount(row.quantity??0)} {row.unit}
                </span>
            )
        },
        {
            name: <div dangerouslySetInnerHTML={{ __html: 'Total<br />repartido' }} />,
            selector: (row: any) => row?.total_service_quantity,
            sortable: true,
            sortField: 'total_service_quantity',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatAmount(row.total_service_quantity??0)} {row.unit}
                </span>
            )
        }
        
    ];
    
    const handleChange = ({allSelected, selectedCount, selectedRows}: any) => {
       
        setForm(
            {
                ...form,
                purchase_order_detail_id: selectedRows[0] ? selectedRows[0].id : undefined
            }
        )
    
	}

    return (
        <>
          
            <DefaultDataTable
                columns={columns}
                data={purchaseOrderDetails}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                selectableRows={true}
                onSelectedRowsChange={handleChange}
                selectableRowsHighlight={true}
                clearSelectedRows={toggleCleared}
                resetContextActions={resetContextActions}
                selectableRowsSingle={true}
            />
        </>
    );
};

export default PurchaseOrderDetailOnDispatchProgramDataTable;
