import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import { useHistory } from 'react-router-dom';
import { PackingForm, PackingFormFilters } from '../../app/models/PackingForm';
import usePackingFormService from '../../app/services/hooks/usePackingFormService';
import PackingFormDataTable from './components/tables/PackingFormDataTable';
import PackingFormCreate from './components/PackingFormCreate';
import PackingFormEdit from './components/PackingFormEdit';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Formas de empaque',
        url: '/packing-forms',
        isActive: true
    }
];

const PackingForms = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetPackingForms, getPackingForms, deletePackingForm, activePackingForm } =
        usePackingFormService();
    const [packingForms, setPackingForms] = useState<PackingForm[]>([]);

    const [packingFormIdEdit, setPackingFormIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [PackingFormFilter, setPackingFormFilter] = useState<PackingFormFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    useEffect(() => {
        reloadTable();
    }, [PackingFormFilter]);

    const getAllPackingForms = () => {
        getPackingForms(PackingFormFilter, {
            onSuccess: (response: ServiceResponse) => {
                setPackingForms(response.data.packing_forms);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (packingFormId: number) => {
        setShowingEdit(true);
        setPackingFormIdEdit(packingFormId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setPackingFormIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllPackingForms();
    };

    const destroy = (packingFormId: number) => {
        const _text = 'Está a punto de eliminar la forma de empaque #' + packingFormId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando forma de empaque...');
                        deletePackingForm(packingFormId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La forma de empaque no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Formas de empaque" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate callbackCreate={showCreate} title="Nueva forma de empaque" />
                    }
                />
                <PackingFormDataTable
                    packingForms={packingForms}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetPackingForms}
                    filter={PackingFormFilter}
                    setFilter={setPackingFormFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear forma de empaque"
                    backdrop={true}
                    showFooter={false}
                >
                    <PackingFormCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar forma de empaque"
                    backdrop={true}
                    showFooter={false}
                >
                    <PackingFormEdit
                        packingFormId={packingFormIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default PackingForms;
