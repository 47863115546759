import React from 'react';
import { momentParseDate } from '../../../../helpers';
import { ServiceManifestFilters } from '../../../../app/models/ServiceManifest';

type Props = {
    filter: ServiceManifestFilters;
    setFilter: (filter: ServiceManifestFilters) => void;
};

const ServiceManifestFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="purchase_order_id">
                            Orden de compra
                        </label>
                        <input
                            title="Orden de compra"
                            type="text"
                            id="purchase_order_id"
                            name="purchase_order_id"
                            className="form-control form-control-sm"
                            value={filter.purchase_order_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="society">
                            Sociedad
                        </label>
                        <input
                            title="Sociedad"
                            type="text"
                            id="society"
                            name="society"
                            className="form-control form-control-sm"
                            value={filter.society}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider">
                            Proveedor
                        </label>
                        <input
                            title="Proveedor"
                            type="text"
                            id="provider"
                            name="provider"
                            className="form-control form-control-sm"
                            value={filter.provider}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <label htmlFor="selectGrande" className="mb-1 font-size-10 ">
                        Estado
                    </label>
                    <select
                        name="status"
                        className="form-select form-select-sm"
                        value={filter.status}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option key={''} value="">
                            Todos
                        </option>
                        <option key={'DRAFT'} value="DRAFT">
                            Borrador
                        </option>
                        <option key={'CONFIRMED'} value="CONFIRMED">
                            Confirmado
                        </option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_from">
                            Fecha de creación desde
                        </label>
                        <input
                            title="Fecha de creación desde"
                            type="date"
                            id="created_at_from"
                            name="created_at_from"
                            className="form-control form-control-sm"
                            value={filter.created_at_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_at_until">
                            Fecha de creación hasta
                        </label>
                        <input
                            title="Fecha de creación hasta"
                            type="date"
                            id="created_at_until"
                            name="created_at_until"
                            className="form-control form-control-sm"
                            value={filter.created_at_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_from">
                            Fecha emisión factura desde
                        </label>
                        <input
                            title="Fecha emisión factura desde"
                            type="date"
                            id="issue_date_from"
                            name="issue_date_from"
                            className="form-control form-control-sm"
                            value={filter.issue_date_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_until">
                            Fecha emisión factura hasta
                        </label>
                        <input
                            title="Fecha emisión factura hasta"
                            type="date"
                            id="issue_date_until"
                            name="issue_date_until"
                            className="form-control form-control-sm"
                            value={filter.issue_date_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceManifestFilter;
