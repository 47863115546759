import { Center } from './Center';
import { User } from './User';

export interface Depot {
    id?: number;
    name?: string;
    description?: string;
    dimension?: number;
    is_owner?: boolean;
    center?: Center;
    center_id?: number;
    supervisor?: User;
    supervisor_id?: number;
    is_active?: boolean;
}

export interface DepotDataForm {
    id?: number;
    name?: string;
    description?: string;
    dimension?: number;
    is_owner?: boolean;
    center?: string;
    center_id?: number;
    supervisor?: string;
    supervisor_id?: number;
    is_active?: boolean;
}

export const defaultDepotDataForm: DepotDataForm = {
    name: '',
    description: '',
    center_id: 0,
    supervisor_id: 0,
    is_owner: false
};

export interface DepotFilters {
    id?: number | null;
    name?: string | '';
    is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
