import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import { AppContext } from '../../../contexts/AppContext';
import { SalesInvoiceHistoryDataForm, SalesInvoiceHistoryFilters } from '../../../app/models/SalesInvoiceHistory';
import SalesInvoiceHistoryDataTable from './tables/SalesInvoiceHistoryDataTable';
import useSalesInvoiceHistoryService from '../../../app/services/hooks/useSalesInvoiceHistoryService';

interface Props {
    salesInvoiceId: number;
    salesInvoiceHistory: SalesInvoiceHistoryDataForm[]
    totalRowsHistory: number;
    filterHistory: SalesInvoiceHistoryFilters;
    setFilterHistory: (filter: SalesInvoiceHistoryFilters) => void;
    setSalesInvoiceHistoryTable?: (salesInvoiceHistory: SalesInvoiceHistoryDataForm[]) => void;
    reloadSalesInvoiceHistoryTable: () => void;
}

const SalesInvoiceHistoryContainer = ({ 
    salesInvoiceId,
    salesInvoiceHistory,
    totalRowsHistory,
    filterHistory,
    setFilterHistory,
    setSalesInvoiceHistoryTable,
    reloadSalesInvoiceHistoryTable,

}: Props) => {

    const { getAllSalesInvoiceHistoriesByInvoice, fetchingGetSalesInvoiceHistoriesByInvoice } = useSalesInvoiceHistoryService();

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <SalesInvoiceHistoryDataTable
                            salesInvoiceId={salesInvoiceId}
                            salesInvoiceHistories={salesInvoiceHistory}
                            loading={fetchingGetSalesInvoiceHistoriesByInvoice}
                            totalRows={totalRowsHistory}
                            filter={filterHistory}
                            setFilter={setFilterHistory}
                            callbackSuccess={reloadSalesInvoiceHistoryTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default SalesInvoiceHistoryContainer;
