import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import { BusinessNameContactDataForm, BusinessNameContactFilters } from '../../../app/models/BusinessNameContact';
import BusinessNameContactDataTable from './tables/BusinessNameContactDataTable';
import BusinessNameContactCreate from './BusinessNameContactCreate';
import BusinessNameContactEdit from './BusinessNameContactEdit';


interface Props {
    businessNameId: number;
    isShow?: boolean;
}

const BusinessNameContactContainer = ({ businessNameId, isShow = false }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllBusinessNameContactsByBusinessName,
        fetchingGetAllBusinessNameContactsByBusinessName,
        deleteBusinessNameContact,
        fetchingDeleteBusinessNameContact,
    } = useBusinessNameContactService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
  
    const [businessNameContactIdEdit, setBusinessNameContactIdEdit] = useState<number>(-1);
    const [businessNameContactIdShow, setbusinessNameContactIdShow] = useState<number>(-1);
    
    const [businessNameContacts, setBusinessNameContacts] = useState<BusinessNameContactDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BusinessNameContactFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    const showEdit = (businessNameContactId: number) => {
        setShowingEdit(true);
        setBusinessNameContactIdEdit(businessNameContactId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setBusinessNameContactIdEdit(-1);
    };

    const showShow = (businessNameContactId: number) => {
       
        setShowingShow(true);
        setbusinessNameContactIdShow(businessNameContactId);
     };

    const hideShow = () => {
        setShowingShow(false);
        setbusinessNameContactIdShow(-1);
    };


    useEffect(() => {
      
        reloadTable();
  
    }, [filter]);

  
    const destroy = (businessNameContactId: number) => {
        const _text = 'Está a punto de eliminar el contacto #' + businessNameContactId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando contacto...');
                        deleteBusinessNameContact(businessNameContactId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El contacto no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const reloadTable = () => {
        getAllBusinessNameContactsByBusinessName(filter, businessNameId, {
            onSuccess: (response: ServiceResponse) => {
                setBusinessNameContacts(response.data.contacts);
                setTotalRows(response.data.total_rows);
               
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Contactos</div>
                <div className="row">
                    <div className="col-12">
                        {!isShow ? (
                            <DefaultToolBar left={<ButtonCreate callbackCreate={showCreate} />} />
                        ) : null}
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <BusinessNameContactDataTable
                                contacts={businessNameContacts}
                                loading={fetchingGetAllBusinessNameContactsByBusinessName}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                                notActions={false}
                            />
                        ) : (
                            <BusinessNameContactDataTable
                                contacts={businessNameContacts}
                                edit={showEdit}
                                destroy={destroy}
                                loading={fetchingGetAllBusinessNameContactsByBusinessName}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                                notActions={false}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear contacto"
                    backdrop={true}
                    showFooter={false}
                >
                    <BusinessNameContactCreate
                        businessNameId={businessNameId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title='Editar contacto'
                    backdrop={true}
                    showFooter={false}
                >
                    <BusinessNameContactEdit
                        businessNameContactId={businessNameContactIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        
                        onCancel={hideEdit}
                        onError={hideEdit}
                        
                    />
                </DefaultModal>
            ) : null} 

            {/* {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title='Ver contacto cliente'
                    backdrop={true}
                    showFooter={false}
                >
                    <BusinessNameContactShow
                        businessNameContactId={businessNameContactIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}  */}
        </>
    );
};

export default BusinessNameContactContainer;
