import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { Society } from '../../../app/models/Society';
import { Provider } from '../../../app/models/Provider';
import { useHistory } from 'react-router-dom';
import usePurchaseOrderService from '../../../app/services/hooks/usePurchaseOrderService';
import {
    PurchaseorderDataForm,
    defaultPurchaseOrderDataForm
} from '../../../app/models/PurchaseOrder';

import {
    ServiceManifestDataForm,
    defaultServiceManifestDataForm
} from '../../../app/models/ServiceManifest';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';
import ServiceManifestForm from './forms/ServiceManifestForm';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        createServiceManifest,
        storeServiceManifest,
        fetchingCreateServiceManifest,
        fetchingStoreServiceManifest
    } = useServiceManifestService();

    const { getPurchaseOrderBySocietyAndProvider, fetchingGetPurchaseOrdersBySocietyAndProvider } =
        usePurchaseOrderService();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);

    const [serviceManifest, setServiceManifest] = useState<ServiceManifestDataForm>(
        defaultServiceManifestDataForm
    );
    const [purchaseOrders, setPurcharseOrders] = useState<PurchaseorderDataForm[]>([]);

    const [errorFields, setErrorFields] = useState<any>();
    const societyIdSelected = useRef<number | undefined>(0);
    const providerIdSelected = useRef<number | undefined>(0);
    const serviceManifestRef = useRef<number>(0);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createServiceManifest({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setProviders(response.data.providers);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (serviceManifest.society_id == undefined) {
            societyIdSelected.current = undefined;
        }

        if (serviceManifest.provider_id == undefined) {
            providerIdSelected.current = undefined;
        }

        if (serviceManifest.society_id && serviceManifest.provider_id) {
            getPurchaseOrderBySocietyAndProvider(
                serviceManifest.society_id,
                serviceManifest.provider_id,
                serviceManifestRef.current,
                {
                    onSuccess: (response: ServiceResponse) => {
                        societyIdSelected.current = serviceManifest.society_id ?? 0;
                        providerIdSelected.current = serviceManifest.provider_id ?? 0;
                        setErrorFields(undefined);
                        setPurcharseOrders(response.data.purchase_orders);
                        setServiceManifest({
                            ...serviceManifest,
                            society_rut: '',
                            society_business_name: '',
                            provider_rut: '',
                            provider_business_name: '',
                            incoterm: '',
                            destiny: '',
                            payment_method: '',
                            payment_condition: '',
                            purchase_order_id: 0
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        setServiceManifest({
                            ...serviceManifest,
                            society_id: societyIdSelected.current,
                            provider_id: providerIdSelected.current
                        });
                    }
                }
            );
        }
    }, [serviceManifest.society_id, serviceManifest.provider_id]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando hoja de entrada...');
        storeServiceManifest(serviceManifest, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                navigationPage('/service-manifests/' + response.data.service_manifest_id + '/edit');
                
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateServiceManifest ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestForm
                        serviceManifestDataForm={serviceManifest}
                        setServiceManifestDataForm={setServiceManifest}
                        societies={societies}
                        providers={providers}
                        bill={false}
                        purchaseOrders={purchaseOrders}
                        errorFields={errorFields}
                        purchaseOrdersLoading={fetchingGetPurchaseOrdersBySocietyAndProvider}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreServiceManifest}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreServiceManifest} />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestCreate;
