import { SalesInvoiceDataForm, SalesInvoiceFilters } from '../../../../app/models/SalesInvoice';
import { SalesInvoiceStatus } from '../../../../app/models/SalesInvoiceStatus';
import { SalesInvoiceStatusEnum } from '../../../../app/shared/enums';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import SalesInvoiceChangeStatus from '../SalesInvoiceChangeStatus';
import SalesInvoiceSwitchChange from '../SalesInvoiceSwitchChange';
import SalesInvoiceFilter from './SalesInvoiceFilter';

interface Props {
    salesInvoices: SalesInvoiceDataForm[];
    statuses : SalesInvoiceStatus[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: SalesInvoiceFilters;
    setFilter: (filter: SalesInvoiceFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const SalesInvoiceDataTable = ({
    salesInvoices,
    statuses,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Nro de factura',
            selector: (row: any) => row.invoice_number,
            sortable: true,
            sortField: 'invoice_number',
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
                <span className="">{row.invoice_number}</span>
            )
        },
        {
            name: 'Neto',
            selector: (row: any) => row.total_neto,
            sortable: true,
            sortField: 'total_neto',
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
                <span className="">{row.total_neto ?? 0}</span>
            )
        },
        {
            name: 'Impuesto',
            selector: (row: any) => row.total_tax,
            sortable: true,
            sortField: 'total_tax',
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
                <span className="">{row.total_tax ?? 0}</span>
            )
        },
        {
            name: 'Total Venta',
            selector: (row: any) => row.total_sale,
            sortable: true,
            sortField: 'total_sale',
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
                <span className="">{row.total_sale ?? 0}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            width:'185px',
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => {
                const status = statuses.find((salesInvoiceStatus) => salesInvoiceStatus.id === row.status);
                if (statuses.length == 0) {
                    return '';
                }
                return (
                    <SalesInvoiceChangeStatus
                        callbackSuccess={callbackSuccess}
                        statuses={row.status_choices! ?? []}
                        selectedStatus={status!}
                        salesInvoiceId={row.id!}
                    />
                );
            }
        },

        // se comenta mientras se define el uso del active
        // {
        //     name: 'Activo',
        //     selector: (row: any) => row.is_active,
        //     sortable: true,
        //     sortField: 'is_active',
        //     cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
        //         <SalesInvoiceSwitchChange
        //             checkValue={row.is_active ?? true}
        //             salesInvoiceId={row.id!}
        //             callbackSuccess={callbackSuccess!}
        //         />
        //     )
        // },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: SalesInvoiceDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && row.status !== SalesInvoiceStatusEnum.ANULADA && row.status !== SalesInvoiceStatusEnum.CONTABILIZADA && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <SalesInvoiceFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={salesInvoices}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SalesInvoiceDataTable;
