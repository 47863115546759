import UserFilter from './UserFilter';
import { UserDataForm, UserFilters } from '../../../../app/models/User';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import UserSwitchChange from '../UserSwitchChange';
import default_avatar from '../../../../assets/images/default-avatar.png';

interface Props {
    users: UserDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    editPassword?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: UserFilters;
    setFilter: (filter: UserFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const UserDataTable = ({
    users,
    totalRows,
    loading = false,
    edit,
    editPassword,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        // {
        //     name: 'usuario',
        //     selector: (row: any) => row.id,
        //     sortable: true,
        //     sortField: 'id',
        //     cell: (row: UserDataForm, index: any, column: any) => (
        //         <span className="">
        //             {' '}
        //             {/* Agrega la clase de Bootstrap que desees */}
        //             {row.id}
        //         </span>
        //     )
        // },
        {
            name: 'Imagen',
            selector: (row: any) => row.avatar_url,
            sortable: true,
            sortField: 'avatar_url',
            center: true,
            cell: (row: UserDataForm, index: any, column: any) => (
                <span>
                    <img
                        className="rounded-circle pointer bg-white my-1"
                        style={{
                            height: '50px',
                            width: '50px',
                            objectFit: 'cover',
                            border: '2px solid #ccc'
                        }}
                        alt={row.avatar_url ?? default_avatar}
                        src={row.avatar_url ?? default_avatar}
                    />
                </span>
            )
        },
        {
            name: 'RUT',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'rut',
            minWidth: '120px',
            cell: (row: UserDataForm, index: any, column: any) => (
                <span className="">{row.rut}</span>
            )
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.first_name,
            sortable: true,
            sortField: 'first_name',
            cell: (row: UserDataForm, index: any, column: any) => (
                <span className="">{row?.first_name}</span>
            )
        },
        {
            name: 'Apellido Paterno',
            selector: (row: any) => row.last_name,
            sortable: true,
            sortField: 'last_name',
            cell: (row: UserDataForm, index: any, column: any) => (
                <span className="">{row?.last_name}</span>
            )
        },
        {
            name: 'Apellido Materno',
            selector: (row: any) => row.second_last_name,
            sortable: true,
            sortField: 'second_last_name',
            cell: (row: UserDataForm, index: any, column: any) => (
                <span className="">{row.second_last_name}</span>
            )
        },
        {
            name: 'Email',
            selector: (row: any) => row.email,
            sortable: true,
            sortField: 'email',
            minWidth: '210px',
            cell: (row: UserDataForm, index: any, column: any) => (
                <span className="">{row?.email}</span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: UserDataForm, index: any, column: any) => (
                <UserSwitchChange
                    checkValue={row.is_active ?? true}
                    userId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: UserDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {editPassword && (
                        <ButtonTableAction
                            callbackFunction={() => editPassword(row.id ?? -1)}
                            classIcon={'mdi mdi-account-lock'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar contraseña'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <UserFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={users}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default UserDataTable;
