import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Service, ServiceDataForm, ServiceFilters } from '../../models/Service';

const useServiceService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetServices, setFetchingGetServices] = useState(false);
    const [fetchingShowService, setFetchingShowService] = useState(false);
    const [fetchingStoreService, setFetchingStoreService] = useState(false);
    const [fetchingEditService, setFetchingEditService] = useState(false);
    const [fetchingCreateService, setFetchingCreateService] = useState(false);
    const [fetchingUpdateService, setFetchingUpdateService] = useState(false);
    const [fetchingDeleteService, setFetchingDeleteService] = useState(false);
    const [fetchingActiveService, setFetchingActiveService] = useState(false);
    const [fetchingGetService, setFetchingGetService] = useState(false);
    const [fetchingGetServiceWithPurchaseOrder, setFetchingGetServiceWithPurchaseOrder] = useState(false);

    const getServices = (ServiceFilters: ServiceFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(ServiceFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SERVICE.GET_SERVICES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetServices
        });
    };

    const showService = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE.SHOW_SERVICE.replace(':id', id.toString()),
            setFetching: setFetchingShowService
        });
    };

    const storeService = (service: ServiceDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SERVICE.STORE_SERVICE,
            body: service,
            setFetching: setFetchingStoreService
        });
    };

    const editService = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE.EDIT_SERVICE.replace(':id', id.toString()),
            setFetching: setFetchingEditService
        });
    };

    const createService = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE.CREATE_SERVICE,
            setFetching: setFetchingCreateService
        });
    };

    const updateService = (id: number, service: ServiceDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SERVICE.UPDATE_SERVICE.replace(':id', id.toString()),
            body: service,
            setFetching: setFetchingUpdateService
        });
    };

    const deleteService = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SERVICE.DELETE_SERVICE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteService
        });
    };

    const activeService = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SERVICE.ACTIVE_SERVICE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveService
        });
    };

    const getService = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE.GET_SERVICE.replace(':id', id.toString()),
            setFetching: setFetchingGetService
        });
    };

    const getServiceWithPurchaseOrder = (id: number, purchase_order_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE.GET_SERVICE_WITH_PURCHASE_ORDER
            .replace(':id', id.toString())
            .replace(':purchase_order_id', purchase_order_id.toString()),
            setFetching: setFetchingGetServiceWithPurchaseOrder
        });
    };

    return {
        fetchingGetServices,
        fetchingShowService,
        fetchingStoreService,
        fetchingEditService,
        fetchingUpdateService,
        fetchingDeleteService,
        fetchingActiveService,
        fetchingCreateService,
        fetchingGetService,
        fetchingGetServiceWithPurchaseOrder,
        getServices,
        showService,
        storeService,
        createService,
        editService,
        updateService,
        deleteService,
        activeService,
        getService,
        getServiceWithPurchaseOrder
    };
};

export default useServiceService;
