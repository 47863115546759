import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { SocietyClientDataForm, SocietyClientFilters } from '../../models/SocietyClient';

const useSocietyClientService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSocietyClientsBySociety, setFetchingGetSocietyClientsBySociety] = useState(false);
    const [fetchingShowSocietyClient, setFetchingShowSocietyClient] = useState(false);
    const [fetchingStoreSocietyClient, setFetchingStoreSocietyClient] = useState(false);
    const [fetchingEditSocietyClient, setFetchingEditSocietyClient] = useState(false);
    const [fetchingCreateSocietyClient, setFetchingCreateSocietyClient] = useState(false);
    const [fetchingUpdateSocietyClient, setFetchingUpdateSocietyClient] = useState(false);
    const [fetchingDeleteSocietyClient, setFetchingDeleteSocietyClient] = useState(false);
    const [fetchingActiveSocietyClient, setFetchingActiveSocietyClient] = useState(false);
 
    const getSocietyClientsBySociety = (filter: SocietyClientFilters, society_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SOCIETY_CLIENTS.GET_SOCIETY_CLIENTS_BY_SOCIETY}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':society_id', society_id.toString()),
            setFetching: setFetchingGetSocietyClientsBySociety
        });
    };

    const showSocietyClient = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.SHOW_SOCIETY_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingShowSocietyClient
        });
    };

    const storeSocietyClient = (societyClient: SocietyClientDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.STORE_SOCIETY_CLIENT,
            body: societyClient,
            setFetching: setFetchingStoreSocietyClient
        });
    };

    const editSocietyClient = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.EDIT_SOCIETY_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingEditSocietyClient
        });
    };

    const createSocietyClient = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.CREATE_SOCIETY_CLIENT,
            setFetching: setFetchingCreateSocietyClient
        });
    };

    const updateSocietyClient = (id: number, societyClient: SocietyClientDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.UPDATE_SOCIETY_CLIENT.replace(':id', id.toString()),
            body: societyClient,
            setFetching: setFetchingUpdateSocietyClient
        });
    };

    const deleteSocietyClient = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.DELETE_SOCIETY_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSocietyClient
        });
    };

    const activeSocietyClient = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETY_CLIENTS.ACTIVE_SOCIETY_CLIENT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveSocietyClient
        });
    };

  

    return {
        fetchingGetSocietyClientsBySociety,
        fetchingShowSocietyClient,
        fetchingStoreSocietyClient,
        fetchingEditSocietyClient,
        fetchingUpdateSocietyClient,
        fetchingDeleteSocietyClient,
        fetchingActiveSocietyClient,
        fetchingCreateSocietyClient,
        getSocietyClientsBySociety,
        showSocietyClient,
        storeSocietyClient,
        createSocietyClient,
        editSocietyClient,
        updateSocietyClient,
        deleteSocietyClient,
        activeSocietyClient,
    };
};

export default useSocietyClientService;
