import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { SocietySignatureDataForm, defaultSocietySignatureDataForm } from '../../../app/models/SocietySignature';
import useSocietySignatureService from '../../../app/services/hooks/useSocietySignatureService';
// import SocietyFormContainer from './SocietyFormContainer';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import SocietySignatureFormContainer from './SocietySignatureFormcontainer';
import { Society } from '../../../app/models/Society';
import { User } from '../../../app/models/User';


interface Props {
    societySignatureId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietySignatureEdit = ({ societySignatureId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {fetchingEditSocietySignature, editSocietySignature, fetchingUpdateSocietySignature, updateSocietySignature} = useSocietySignatureService();

    const [societySignature, setSocietySignature] = useState<SocietySignatureDataForm>(defaultSocietySignatureDataForm);
    const[societies, setSocieties] = useState<Society[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando firma sociedad...');
        editSocietySignature(societySignatureId, {
            onSuccess: (response: ServiceResponse) => {
                setSocietySignature(response.data.society_signature);
                setSocieties(response.data.societies);
                setUsers(response.data.users)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }

        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando firma sociedad...');
        updateSocietySignature(societySignatureId, societySignature, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                // useSweetAlert().closeSpinnerAlert();
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditSocietySignature ? (
        <LazyLoading height="300" />
    ) : (
        <SocietySignatureFormContainer
            fetching={fetchingUpdateSocietySignature}
            action={update}
            cancel={cancel}
            societySignatureForm={societySignature}
            setSocietySignatureForm={setSocietySignature}
            societies={societies}
            users={users}
            errorFields={errorFields}
            setErrorFields={setErrorFields}
        />
    );
};

export default SocietySignatureEdit;
