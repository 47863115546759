import React from 'react';
import { DispatchProgramItemFilters } from '../../../../app/models/DispatchProgramItem';

type Props = {
    filter: DispatchProgramItemFilters;
    setFilter: (filter: DispatchProgramItemFilters) => void;
};

const DispatchProgramItemFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="start_date">
                        Desde
                    </label>
                    <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        className="form-control form-control-sm"
                        value={filter.start_date}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="end_date">
                        Hasta
                    </label>
                    <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        className="form-control form-control-sm"
                        value={filter.end_date}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="type">
                        Estatus
                    </label>
                    <select
                        id="dispatch_program_item_status"
                        name="dispatch_program_item_status"
                        className="form-control form-select-sm"
                        value={filter.dispatch_program_item_status}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option value="">TODOS</option>
                        <option value="PREPARACION">Preparacion</option>
                        <option value="PLANIFICACION">Planificacion</option>
                        <option value="DESPACHO">Despacho</option>
                        <option value="FINALIZADO">Finalizado</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default DispatchProgramItemFilter;
