import React, { useEffect, useState } from 'react';
import { Place } from '../../../app/models/Place';
import { useHistory } from 'react-router-dom';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';
import {
    RouteSegment,
    RouteSegmentDataForm,
    defaultRouteSegmentDataForm
} from '../../../app/models/RouteSegment';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import RouteSegmentForm from './forms/RouteSegmentForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import {
    QuoteRouteSegmentDataForm,
    defaultQuoteRouteSegmentDataForm
} from '../../../app/models/QuoteRouteSegment';
import QuoteRouteSegmentForm from './forms/QuoteRouteSegmentForm';

interface Props {
    quoteId?: number;
    // currencyId?: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const QuoteRouteSegmentCreate = ({
    quoteId,
    //   currencyId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const [units, setUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [errorGetData, setErrorGetData] = useState('');
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [quoteRouteSegment, setQuoteRouteSegment] = useState<QuoteRouteSegmentDataForm>({
        ...defaultQuoteRouteSegmentDataForm,
        ['quote_id']: quoteId,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0
    });

    const [errorFields, setErrorFields] = useState<any>();

    const {
        fetchingStoreQuoteRouteSegment,
        storeQuoteRouteSegment,
        fetchingCreateQuoteRouteSegment,
        createQuoteRouteSegment,
        fetchingShowRsToQuoteRouteSegment,
        showRsToQuoteRouteSegment
    } = useQuoteRouteSegmentService();

    useEffect(() => {
        createQuoteRouteSegment({
            onSuccess: (response: ServiceResponse) => {
                setUnits(response.data.units);
                setCurrencies(response.data.currencies);
                setRouteSegments(response.data.route_segments);
            },
            onError: (response: ServiceResponse) => {
                setErrorGetData(response.message);
            }
        });
    }, []);

    useEffect(() => {
        if (quoteRouteSegment.route_segment_id) {
            console.log(quoteRouteSegment.route_segment_id);
            showRsToQuoteRouteSegment(quoteRouteSegment.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    setQuoteRouteSegment({
                        ...quoteRouteSegment,
                        ...defaultQuoteRouteSegmentDataForm,
                        ...response.data.route_segment
                    });
                }
            });
        }
    }, [quoteRouteSegment.route_segment_id]);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando opción de tramo...', 400);
        storeQuoteRouteSegment(quoteRouteSegment, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();

                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateQuoteRouteSegment || fetchingShowRsToQuoteRouteSegment ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteRouteSegmentForm
                        quoteRouteSegmentForm={quoteRouteSegment}
                        setQuoteRouteSegmentForm={setQuoteRouteSegment}
                        routeSegments={routeSegments}
                        currencies={currencies}
                        units={units}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreQuoteRouteSegment}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreQuoteRouteSegment} />
                </div>
            </div>
        </>
    );
};

export default QuoteRouteSegmentCreate;
