import React, { useEffect } from 'react';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';
import BatchDetailFilter from './BatchDetailFilter';
import { Link } from 'react-router-dom';
import { BatchDetailDataForm, BatchDetailFilters } from '../../../../app/models/BatchDetail';

interface Props {
    batchDetails: BatchDetailDataForm[];
    totalRows: number;
    loading?: boolean;
    show?: (batchDetailId: number) => void;
    destroy?: (batchDetailId: number) => void;
    filter: BatchDetailFilters;
    setFilter: (filter: BatchDetailFilters) => void;
    ready?: boolean;
    paginationRowsPerPageOptions?: any[];
    expandableRows?: boolean;
    expandableIcon?: any;
    expandableRowsComponent?: React.FC<{ data: any }>;
    expandableRowsComponentProps?: any;
}

const BatchDetailDataTable = ({
    batchDetails,
    totalRows,
    loading = false,
    destroy,
    show,
    filter,
    setFilter,
    ready = false,
    expandableRows,
    expandableIcon,
    expandableRowsComponent,
    expandableRowsComponentProps
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            omit: ready,
            cell: (row: BatchDetailDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },

        {
            name: 'Contabilizando',
            selector: (row: any) => row?.last_quantity_by_smd,
            sortable: true,
            sortField: 'last_quantity_by_smd',
            omit: ready,
            cell: (row: BatchDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.last_quantity_by_smd} {row.unit_quantity}
                </span>
            )
        },

        {
            name: 'Total en lote',
            selector: (row: any) => row?.total_quantity,
            omit: !ready,
            cell: (row: BatchDetailDataForm, index: any, column: any) => (
                <span className="">{row.total_quantity}</span>
            )
        },

        {
            name: 'Hoja entrada',
            selector: (row: any) => row?.service_manifest,
            sortable: true,
            sortField: 'service_manifest',
            omit: ready,
            cell: (row: BatchDetailDataForm, index: any, column: any) => (
                <Link
                    to={
                        '/service-manifests/' +
                        row?.service_manifest_id +
                        '/show?smd_id=' +
                        row.service_manifest_detail_id
                    }
                >
                    <span className="text-primary">{row.service_manifest}</span>
                </Link>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            omit: ready,
            cell: (row: BatchDetailDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <BatchDetailFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={batchDetails}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                expandableRows={expandableRows}
                expandableRowsComponent={expandableRowsComponent}
                expandableRowsComponentProps={expandableRowsComponentProps}
                expandableIcon={expandableIcon}
            />
        </>
    );
};

export default BatchDetailDataTable;
