import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ContactTypeDataForm, ContactTypeFilters } from '../../models/ContactType';

const useContactTypeService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetContactTypes, setFetchingGetContactTypes] = useState(false);
    const [fetchingShowContactType, setFetchingShowContactType] = useState(false);
    const [fetchingCreateContactType, setFetchingCreateContactType] = useState(false);
    const [fetchingStoreContactType, setFetchingStoreContactType] = useState(false);
    const [fetchingEditContactType, setFetchingEditContactType] = useState(false);
    const [fetchingUpdateContactType, setFetchingUpdateContactType] = useState(false);
    const [fetchingDeleteContactType, setFetchingDeleteContactType] = useState(false);
    const [fetchingActiveContactType, setFetchingActiveContactType] = useState(false);

    const getContactTypes = (contactTypeFilters: ContactTypeFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(contactTypeFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CONTACT_TYPE.GET_CONTACT_TYPES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetContactTypes
        });
    };

    const showContactType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CONTACT_TYPE.SHOW_CONTACT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingShowContactType
        });
    };

    const createContactType = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CONTACT_TYPE.CREATE_CONTACT_TYPE,
            setFetching: setFetchingCreateContactType
        });
    };

    const storeContactType = (contactType: ContactTypeDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CONTACT_TYPE.STORE_CONTACT_TYPE,
            body: contactType,
            setFetching: setFetchingStoreContactType
        });
    };

    const editContactType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CONTACT_TYPE.EDIT_CONTACT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingEditContactType
        });
    };

    const updateContactType = (id: number, contactType: ContactTypeDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CONTACT_TYPE.UPDATE_CONTACT_TYPE.replace(':id', id.toString()),
            body: contactType,
            setFetching: setFetchingUpdateContactType
        });
    };

    const deleteContactType = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CONTACT_TYPE.DELETE_CONTACT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteContactType
        });
    };

    const activeContactType = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CONTACT_TYPE.ACTIVE_CONTACT_TYPE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveContactType
        });
    };

    return {
        fetchingGetContactTypes,
        fetchingShowContactType,
        fetchingCreateContactType,
        fetchingStoreContactType,
        fetchingEditContactType,
        fetchingUpdateContactType,
        fetchingDeleteContactType,
        fetchingActiveContactType,
        getContactTypes,
        showContactType,
        createContactType,
        storeContactType,
        editContactType,
        updateContactType,
        deleteContactType,
        activeContactType
    };
};

export default useContactTypeService;
