import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useDepotService from '../../../app/services/hooks/useDepotService';
import { DepotDataForm, defaultDepotDataForm } from '../../../app/models/Depot';
import { User } from '../../../app/models/User';
import { Center } from '../../../app/models/Center';
import DepotFormContainer from './DepotFormContainer';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DepotCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingCreateDepot, createDepot, fetchingStoreDepot, storeDepot } = useDepotService();
    const [errorGetData, setErrorGetData] = useState('');
    const [depot, setDepot] = useState<DepotDataForm>(defaultDepotDataForm);
    const [supervisors, setSupervisors] = useState<User[]>([]);
    const [centers, setCenters] = useState<Center[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createDepot({
            onSuccess: (response: ServiceResponse) => {
                setCenters(response.data.centers);
                setSupervisors(response.data.supervisors);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando almacén...');
        storeDepot(depot, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <DepotFormContainer
            action={store}
            cancel={cancel}
            depot={depot}
            setDepot={setDepot}
            errorFields={errorFields}
            centers={centers}
            supervisors={supervisors}
            fetching={fetchingStoreDepot}
        />
    );
};

export default DepotCreate;
