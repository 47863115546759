import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';
import useServiceService from '../../../app/services/hooks/useServiceService';
import { ServiceDataForm, defaultServiceDataForm } from '../../../app/models/Service';
import { Sector } from '../../../app/models/Sector';
import ServiceFormContainer from './ServiceFormContainer';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import { Tax } from '../../../app/models/Tax';
import { ItemCategory } from '../../../app/models/ItemCategory';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [service, setService] = useState<ServiceDataForm>(defaultServiceDataForm);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [itemCategories, setItemCategories] = useState<ItemCategory[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [priceUnits, setPriceUnits] = useState<Unit[]>([]);
    const[units,setUnits] =useState<Unit[]>([]);
    const [taxes, setTaxes] = useState<Tax[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreService, storeService, createService, fetchingCreateService } =
        useServiceService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createService({
            onSuccess: (response: ServiceResponse) => {
                setSectors(response.data.sectors);
                setCurrencies(response.data.currencies);
                setPriceUnits(response.data.price_units);
                setItemCategories(response.data.item_categories);
                setUnits(response.data.units);
                setTaxes(response.data.taxes);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando servicio...');
        storeService(service, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateService ? (
        <LazyLoading height="300" />
    ) : (
        <ServiceFormContainer
            fetching={fetchingStoreService}
            sectors={sectors}
            itemCategories={itemCategories}
            currencies={currencies}
            priceUnits={priceUnits}
            units={units}
            taxesData={taxes}
            action={store}
            cancel={cancel}
            service={service}
            setService={setService}
            errorFields={errorFields}
        />
    );
};

export default ServiceCreate;
