import React, { useEffect, useState } from 'react';
import { BusinessNameBankAccountDataForm } from '../../../../app/models/BusinessNameBankAccount';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { ContactType } from '../../../../app/models/ContactType';
import { Bank } from '../../../../app/models/Bank';
import { BankAccountReason } from '../../../../app/models/BankAccountReason';
import { BankAccountType } from '../../../../app/models/BankAccountType';


type Props = {
    businessNameBankAccountDataForm: BusinessNameBankAccountDataForm;
    setBusinessNameBankAccountDataForm: (client: BusinessNameBankAccountDataForm) => void;
    banks: Bank[];
    bankAccountTypes: BankAccountType[];
    bankAccountReasons: BankAccountReason[];
    errorFields?: any;
};

const BusinessNameBankAccountForm = ({ businessNameBankAccountDataForm, setBusinessNameBankAccountDataForm, errorFields, banks, bankAccountReasons, bankAccountTypes }: Props) => {
    
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
       
        setBusinessNameBankAccountDataForm({ ...businessNameBankAccountDataForm, [name]: value });
        
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setBusinessNameBankAccountDataForm({ ...businessNameBankAccountDataForm, ...obj });
    };

    return (
        <>
            <div className="row">
                        <div className="col-md-12">
                            <label className="col-form-label">Banco</label>
                            <SelectTwo
                                name="bank_id"
                                id="bank_id"
                                inputValue={businessNameBankAccountDataForm.bank_id}
                                options={SelectTwoMapperOptions(banks)}
                                hasError={fieldHasError(`bank_id`) !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('bank_id', value?.value)
                                }
                                placeholder={'Seleccione banco'}
                                onFocus={() => onFocusRemove(`bank_id`)}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage(`bank_id`)}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label className="col-form-label">Motivo de Cuenta</label>
                            <SelectTwo
                                name="account_reason_id"
                                id="account_reason_id"
                                inputValue={
                                    businessNameBankAccountDataForm.bank_account_reason_id
                                }
                                options={SelectTwoMapperOptions(bankAccountReasons)}
                                hasError={fieldHasError(`bank_account_reason_id`) !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'bank_account_reason_id',
                                        value?.value
                                    )
                                }
                                placeholder={'Seleccione motivo'}
                                onFocus={() => onFocusRemove(`bank_account_reason_id`)}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage(`banck_account_reason_id`)}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Número de Cuenta</label>
                            <input
                                name="account_number"
                                id="account_number"
                                type="number"
                                className={`form-control ${fieldHasError(
                                    `account_number`
                                )}`}
                                value={businessNameBankAccountDataForm.account_number}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove(`account_number`)}
                                placeholder="Ingrese el número de cuenta"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage(`account_number`)}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label className="col-form-label">Tipo de Cuenta</label>
                            <SelectTwo
                                name="account_type_id"
                                id="account_type_id"
                                inputValue={businessNameBankAccountDataForm.account_type_id}
                                options={SelectTwoMapperOptions(bankAccountTypes)}
                                hasError={fieldHasError(`account_type_id`) !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('account_type_id', value?.value)
                                }
                                isClearable={true}
                                placeholder={'Seleccione tipo de cuenta'}
                                onFocus={() => onFocusRemove(`account_type_id`)}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage(`account_type_id`)}
                            </div>
                        </div>
                    </div>

        </>
    );
};

export default BusinessNameBankAccountForm;
