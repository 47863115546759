import { Tax } from './Tax';

export interface Service {
    id?: number;
    name: string;
    isActive: boolean;
}

export interface ServiceDataForm {
    id?: number;
    name?: string;
    sector?: string;
    currency_price?: string;
    currency_price_id?: number;
    unit_price?: string;
    unit_price_id?: number;
    unit?: string;
    unit_id?: number;
    sector_id?: number;
    item_category?: string;
    item_category_id?: number;
    price?: number;
    description?: string;
    is_active?: boolean;
    taxes?: Tax[];
}

export const defaultServiceDataForm: ServiceDataForm = {
    name: '',
    sector_id: 0,
    item_category_id: 0,
    description: ''
};

export interface ServiceFilters {
    id?: number | null;
    name?: string | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}