import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { SalesInvoiceDataForm, SalesInvoiceFilters } from '../../models/SalesInvoice';
import { ServiceEvents } from '../shared/interfaces';

const useSalesInvoiceService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSalesInvoices, setFetchingGetSalesInvoices] = useState(false);
    const [fetchingShowSalesInvoice, setFetchingShowSalesInvoice] = useState(false);
    const [fetchingStoreSalesInvoice, setFetchingStoreSalesInvoice] = useState(false);
    const [fetchingEditSalesInvoice, setFetchingEditSalesInvoice] = useState(false);
    const [fetchingCreateSalesInvoice, setFetchingCreateSalesInvoice] = useState(false);
    const [fetchingUpdateSalesInvoice, setFetchingUpdateSalesInvoice] = useState(false);
    const [fetchingDeleteSalesInvoice, setFetchingDeleteSalesInvoice] = useState(false);
    const [fetchingActiveSalesInvoice, setFetchingActiveSalesInvoice] = useState(false);
    const [fetchingGetSalesInvoice, setFetchingGetSalesInvoice] = useState(false);
    const [fetchingReviewSalesInvoice, setFetchingReviewSalesInvoice] = useState(false);
    const [fetchingAnulledSalesInvoice, setFetchingAnulledSalesInvoice] = useState(false);
    const [fetchingAccountedSalesInvoice, setFetchingAccountedSalesInvoice] = useState(false);
    const [fetchingGetSalesInvoiceDetails, setFetchingGetSalesInvoiceDetails] = useState(false);

    const [fetchingGetPaymentStatusesByClientSociety, setFetchingGetPaymentStatusesByClientSociety] = useState(false);
    const getSalesInvoices = (filters: SalesInvoiceFilters,  events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SALES_INVOICE.GET_SALES_INVOICES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSalesInvoices
        });
    };

    const showSalesInvoice = (id: number,  events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SALES_INVOICE.SHOW_SALES_INVOICE.replace(':id', id.toString()),
            setFetching: setFetchingShowSalesInvoice
        });
    };

    const storeSalesInvoice = (salesInvoice: SalesInvoiceDataForm,  events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SALES_INVOICE.STORE_SALES_INVOICE,
            body: salesInvoice,
            setFetching: setFetchingStoreSalesInvoice
        });
    };

    const editSalesInvoice = (id: number,  events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SALES_INVOICE.EDIT_SALES_INVOICE.replace(':id', id.toString()),
            setFetching: setFetchingEditSalesInvoice
        });
    };

    const createSalesInvoice = ( events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SALES_INVOICE.CREATE_SALES_INVOICE,
            setFetching: setFetchingCreateSalesInvoice
        });
    };

    const updateSalesInvoice = (
        id: number,
        salesInvoice: SalesInvoiceDataForm,
        events: ServiceEvents = {},
        isFolioAndDateOnly: boolean  // Se agrega este parámetro opcional
    ) => {
        doPut({
            ...events,
            url: EndPoints.SALES_INVOICE.UPDATE_SALES_INVOICE.replace(':id', id.toString()),
            body: { ...salesInvoice, isFolioAndDateOnly }, // Agregar `isFolioAndDateOnly` al cuerpo de la solicitud
            setFetching: setFetchingUpdateSalesInvoice
        });
    };

    const deleteSalesInvoice = (id: number,  events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SALES_INVOICE.DELETE_SALES_INVOICE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSalesInvoice
        });
    };

    const activeSalesInvoice = (id: number, active: boolean,  events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SALES_INVOICE.ACTIVE_SALES_INVOICE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveSalesInvoice
        });
    };

    const getSalesInvoice = (id: number,  events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SALES_INVOICE.GET_SALES_INVOICE.replace(':id', id.toString()),
            setFetching: setFetchingGetSalesInvoice
        });
    };


    const salesInvoiceReview = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SALES_INVOICE.REVIEW.replace(':id', id.toString()),
            setFetching: setFetchingReviewSalesInvoice
        });
    };

    const salesInvoiceAccounted = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SALES_INVOICE.ACCOUNTED.replace(':id', id.toString()),
            setFetching: setFetchingAccountedSalesInvoice
        });
    };

    const salesInvoiceAnnulled = (id: number, data: { reason: string }, events: ServiceEvents = {}) => {
        // Hacemos un PUT enviando la razón de anulación en el cuerpo de la solicitud
        doPut({
            ...events,
            url: EndPoints.SALES_INVOICE.ANNULLED.replace(':id', id.toString()),
            body: data,  // Enviamos el objeto completo
            setFetching: setFetchingAnulledSalesInvoice
        });
    };

    const getSalesInvoiceDetails = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SALES_INVOICE.GET_SALES_INVOICE_DETAILS.replace(':id', id.toString()),
            setFetching: setFetchingGetSalesInvoiceDetails,
        });
    };

    const getPaymentStatusesByClientSociety = (societyId: number, clientId: number, events: ServiceEvents = {}) => {
        // const url = `${EndPoints.SALES_INVOICE.GET_PAYMENT_STATUSES_BY_CLIENT_SOCIETY}?society_id=${societyId}&client_id=${clientId}`;
        const url = `${EndPoints.SALES_INVOICE.GET_PAYMENT_STATUSES_BY_CLIENT_SOCIETY.replace(':society_id', societyId.toString()).replace(':client_id', clientId.toString())}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPaymentStatusesByClientSociety
        });
    };


    return {
        fetchingGetSalesInvoices,
        fetchingShowSalesInvoice,
        fetchingStoreSalesInvoice,
        fetchingEditSalesInvoice,
        fetchingUpdateSalesInvoice,
        fetchingDeleteSalesInvoice,
        fetchingActiveSalesInvoice,
        fetchingCreateSalesInvoice,
        fetchingGetSalesInvoice,
        fetchingReviewSalesInvoice,
        fetchingAnulledSalesInvoice,
        fetchingAccountedSalesInvoice,
        fetchingGetSalesInvoiceDetails,
        fetchingGetPaymentStatusesByClientSociety,
        getSalesInvoices,
        showSalesInvoice,
        storeSalesInvoice,
        createSalesInvoice,
        editSalesInvoice,
        updateSalesInvoice,
        deleteSalesInvoice,
        activeSalesInvoice,
        getSalesInvoice,
        salesInvoiceReview,
        salesInvoiceAnnulled,
        salesInvoiceAccounted,
        getSalesInvoiceDetails,
        getPaymentStatusesByClientSociety
    };
};

export default useSalesInvoiceService;
