import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { Incoterm, IncotermFilters } from '../../../../app/models/Incoterm';
import { AccountDataForm } from '../../../../app/models/Account';
import AccountFilter from './AccountFilter';

interface Props {
    accounts: AccountDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: IncotermFilters;
    setFilter: (filter: IncotermFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const AccountDataTable = ({
    accounts,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        // {
        //     name: 'Id',
        //     selector: (row: any) => row.id,
        //     sortable: true,
        //     sortField: 'id',
        //     cell: (row: AccountDataForm, index: any, column: any) => <span className="">{row.id}</span>
        // },

        {
            name: 'Primera Clasificación',
            selector: (row: any) => row.clasif,
            sortable: true,
            sortField: 'clasif',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasif?.name}</span>
            )
        },
        {
            name: 'Primera Cuenta',
            selector: (row: any) => row.cta,
            sortable: true,
            sortField: 'cta',
            width: '135px',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasif?.account_number}</span>
            )
        },
        {
            name: 'Segunda Clasificación',
            selector: (row: any) => row.clasif_two,
            sortable: true,
            sortField: 'clasif_two',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasif_two?.name}</span>
            )
        },
        {
            name: 'Segunda Cuenta',
            selector: (row: any) => row.cta_two,
            sortable: true,
            sortField: 'cta_two',
            width: '135px',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasif_two?.account_number}</span>
            )
        },
        {
            name: 'Tercera Clasificación',
            selector: (row: any) => row.clasif_three,
            sortable: true,
            sortField: 'clasif_three',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasif_three?.name}</span>
            )
        },
        {
            name: 'Tercera Cuenta',
            selector: (row: any) => row.cta_two,
            sortable: true,
            sortField: 'cta_three',
            width: '135px',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasif_three?.account_number}</span>
            )
        },

        {
            name: 'Nombre cuenta',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.name}</span>
            )
        },
        {
            name: 'Código de la Cuenta',
            selector: (row: any) => row.account,
            sortable: true,
            sortField: 'account',
            width: '135px',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.account}</span>
            )
        },
        {
            name: 'Agrupador adicional ',
            selector: (row: any) => row.clasif_three,
            sortable: true,
            sortField: 'clasification',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <span className="">{row.clasification}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            width: '120px',
            cell: (row: AccountDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <AccountFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={accounts}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default AccountDataTable;
