import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useNavigationPage from '../../hooks/useNavigationPage';

import { Brand, BrandFilters } from '../../app/models/Brand';
import BrandDataTable from './components/tables/BrandDataTable';
import BrandCreate from './components/BrandCreate';
import BrandEdit from './components/BrandEdit';
import useBrandService from '../../app/services/hooks/useBrandService';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Marcas',
        url: '/brands',
        isActive: true
    }
];

const Brands = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetBrands, getBrands, deleteBrand, activeBrand } = useBrandService();
    const [brands, setBrands] = useState<Brand[]>([]);

    const [brandIdEdit, setBrandIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [brandsFilter, setBrandsFilter] = useState<BrandFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [brandsFilter]);

    const getAllBrands = () => {
        getBrands(brandsFilter, {
            onSuccess: (response: ServiceResponse) => {
                setBrands(response.data.brands);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (brandId: number) => {
        setShowingEdit(true);
        setBrandIdEdit(brandId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setBrandIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllBrands();
    };

    const destroy = (brandId: number) => {
        const _text = 'Está a punto de eliminar la marca #' + brandId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando marca...');
                        deleteBrand(brandId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La marca no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Marcas" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva marca" />}
                />
                <BrandDataTable
                    brands={brands}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetBrands}
                    filter={brandsFilter}
                    setFilter={setBrandsFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear marca"
                    backdrop={true}
                    showFooter={false}
                >
                    <BrandCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar marca"
                    backdrop={true}
                    showFooter={false}
                >
                    <BrandEdit
                        brandId={brandIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Brands;
