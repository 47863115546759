import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { PackingForm, PackingFormFilters } from '../../models/PackingForm';

const usePackingFormService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetPackingForms, setFetchingGetPackingForms] = useState(false);
    const [fetchingShowPackingForm, setFetchingShowPackingForm] = useState(false);
    const [fetchingStorePackingForm, setFetchingStorePackingForm] = useState(false);
    const [fetchingEditPackingForm, setFetchingEditPackingForm] = useState(false);
    const [fetchingUpdatePackingForm, setFetchingUpdatePackingForm] = useState(false);
    const [fetchingDeletePackingForm, setFetchingDeletePackingForm] = useState(false);
    const [fetchingActivePackingForm, setFetchingActivePackingForm] = useState(false);
    const [fetchingCreatePackingForm, setFetchingCreatePackingForm] = useState(false);


    const getPackingForms = (
        packingFormFilters: PackingFormFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(packingFormFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PACKING_FORMS.GET_PACKING_FORMS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPackingForms
        });
    };

    const showPackingForm = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PACKING_FORMS.SHOW_PACKING_FORM.replace(':id', id.toString()),
            setFetching: setFetchingShowPackingForm
        });
    };

    const createPackingForm = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PACKING_FORMS.CREATE_PACKING_FORM,
            setFetching: setFetchingCreatePackingForm
        });
    };

    const storePackingForm = (packingForm: PackingForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PACKING_FORMS.STORE_PACKING_FORM,
            body: packingForm,
            setFetching: setFetchingStorePackingForm
        });
    };

    const editPackingForm = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PACKING_FORMS.EDIT_PACKING_FORM.replace(':id', id.toString()),
            setFetching: setFetchingEditPackingForm
        });
    };

    const updatePackingForm = (
        id: number,
        packingForm: PackingForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.PACKING_FORMS.UPDATE_PACKING_FORM.replace(':id', id.toString()),
            body: packingForm,
            setFetching: setFetchingUpdatePackingForm
        });
    };

    const deletePackingForm = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PACKING_FORMS.DELETE_PACKING_FORM.replace(':id', id.toString()),
            setFetching: setFetchingDeletePackingForm
        });
    };

    const activePackingForm = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PACKING_FORMS.ACTIVE_PACKING_FORM.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActivePackingForm
        });
    };

    return {
        fetchingGetPackingForms,
        fetchingShowPackingForm,
        fetchingStorePackingForm,
        fetchingEditPackingForm,
        fetchingUpdatePackingForm,
        fetchingDeletePackingForm,
        fetchingActivePackingForm,
        fetchingCreatePackingForm,
        getPackingForms,
        showPackingForm,
        storePackingForm,
        editPackingForm,
        updatePackingForm,
        deletePackingForm,
        activePackingForm,
        createPackingForm
    };
};

export default usePackingFormService;
