import React from 'react';
import LazyLoading from '../../../../components/LazyLoading';
import { BatchDataForm } from '../../../../app/models/Batch';

interface Props {
    batchDataForm: BatchDataForm;
}

const BatchFormShow = ({ batchDataForm }: Props) => {
    if (!batchDataForm) return null;
   
    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedad</label>
                    <input
                        name="society"
                        id="society"
                        type="text"
                        value={batchDataForm.society}
                        className="form-control"
                        disabled={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Código</label>
                    <input
                        name="code"
                        id="code"
                        type="text"
                        value={batchDataForm.code}
                        className="form-control"
                        disabled={true}
                    />
                </div>

                <div className="col-md-6">
                    <label className="col-form-label">Tipo lote</label>
                    <input
                        name="batch_type"
                        id="batch_type"
                        type="text"
                        value={batchDataForm.batch_type}
                        className="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Producto etiqueta</label>
                    <input
                        name="product_label"
                        id="product_label"
                        type="text"
                        value={batchDataForm.product_label}
                        className="form-control"
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Almacén</label>
                    <input
                        name="depot"
                        id="depot"
                        type="text"
                        value={batchDataForm.depot}
                        className="form-control"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Capacidad</label>
                    <input
                        name="capacity"
                        id="capacity"
                        type="number"
                        value={batchDataForm.capacity}
                        className="form-control"
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Unidad capacidad</label>
                    <input
                        name="unit_capacity"
                        id="unit_capacity"
                        type="text"
                        value={batchDataForm.unit_capacity}
                        className="form-control"
                        disabled={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        name="description"
                        id="description"
                        className="form-control"
                        value={batchDataForm.description}
                        disabled={true}
                    />
                </div>
            </div>
        </>
    );
};

export default BatchFormShow;
