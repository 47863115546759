import React, { useContext, useEffect, useState } from 'react';

import { Client, defaultClient } from '../../../app/models/Client';
import useClientService from '../../../app/services/hooks/useClientService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ClientFormContainer from './ClientFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { AppContext } from '../../../contexts/AppContext';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ClientCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);


    const { fetchingStoreClient,fetchingCreateClient,createClient ,storeClient } = useClientService();

    const [client, setClient] = useState<Client>(defaultClient);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createClient({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                 setLoading(false);

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        useSweetAlert().spinnerAlert('Agregando cliente...');
        storeClient(client, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return loading ? (
        <LazyLoading height={300} />
    ) : (
        <ClientFormContainer
            fetching={fetchingStoreClient}
            action={store}
            cancel={cancel}
            client={client}
            setClient={setClient}
            errorFields={errorFields}
        />
    );
};

export default ClientCreate;
