import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import {
    CheckingAccount,
    CheckingAccountDataForm,
    CheckingAccountFilters
} from '../../../../app/models/CheckingAccount';
import CheckingAccountFilter from './CheckingAccountFilter';
import { momentParseDate } from '../../../../helpers';
import { formatNumber } from '../../../../utils/utils';

interface Props {
    checkingAccounts: CheckingAccountDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: CheckingAccountFilters;
    setFilter: (filter: CheckingAccountFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const CheckingAccountDataTable = ({
    checkingAccounts,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: CheckingAccount, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            width: '130px',
            cell: (row: CheckingAccount, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Entidad emisor',
            selector: (row: any) => row.sender_business_entity,
            sortable: true,
            sortField: 'sender_business_entity',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.sender_business_entity}</span>
            )
        },
        {
            name: 'Razón social emisor',
            selector: (row: any) => row.sender_business_name,
            sortable: true,
            sortField: 'sender_business_name',
            width: '130px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.sender_business_name ? String(row.sender_business_name) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Banco',
            selector: (row: any) => row.sender_bank,
            sortable: true,
            sortField: 'sender_bank',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.sender_bank ? String(row.sender_bank) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'N° cuenta emisor',
            selector: (row: any) => row.sender_account_number,
            sortable: true,
            sortField: 'sender_account_number',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.sender_account_number}</span>
            )
        },
        {
            name: 'Entidad receptor',
            selector: (row: any) => row.receiver_business_entity,
            sortable: true,
            sortField: 'receiver_business_entity',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.receiver_business_entity}</span>
            )
        },
        {
            name: 'Razón social receptor',
            selector: (row: any) => row.receiver_business_name,
            sortable: true,
            sortField: 'receiver_business_name',
            width: '130px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.receiver_business_name ? String(row.receiver_business_name) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Banco',
            selector: (row: any) => row.receiver_bank,
            sortable: true,
            sortField: 'receiver_bank',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.receiver_bank ? String(row.receiver_bank) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'N° cuenta receptor',
            selector: (row: any) => row.receiver_account_number,
            sortable: true,
            sortField: 'receiver_account_number',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.receiver_account_number}</span>
            )
        },
        {
            name: 'Monto',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.amount ? row.amount : 0)} {row.currency}
                    </span>
                </>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <CheckingAccountFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={checkingAccounts}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default CheckingAccountDataTable;
