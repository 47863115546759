import React from 'react';

import UserForm from './forms/UserForm';
import { UserDataForm } from '../../../app/models/User';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Group } from '../../../app/models/Group';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    userForm: UserDataForm;
    setUserForm: (user: UserDataForm) => void;
    groups: Group[];
    errorFields?: any;
    isDisabled?: boolean;
}

const UserFormContainer = ({
    fetching,
    action,
    cancel,
    userForm,
    setUserForm,
    groups,
    errorFields = null,
    isDisabled = false
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <UserForm
                        userForm={userForm}
                        setUserForm={setUserForm}
                        groups={groups}
                        errorFields={errorFields}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default UserFormContainer;
