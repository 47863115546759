import React, { useEffect } from 'react';
import { Product } from '../../../../app/models/Product';
import { Place } from '../../../../app/models/Place';
import { Unit } from '../../../../app/models/Unit';
import { Service } from '../../../../app/models/Service';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanInputError, setCleanTextAreaError } from '../../../../utils/utils';
import { Currency } from '../../../../app/models/Currency';
import { DispatchProgramItem } from '../../../../app/models/DispatchProgramItem';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

interface DispatchProgramItemProps {
    canBeEdited: boolean;
    dispatchProgramItemForm: DispatchProgramItem;
    setDispatchProgramItemForm: (dispatchProgramItemData: DispatchProgramItem) => void;
    products: Product[];
    quantityUnits: Unit[];
    currencies: Currency[];
    origins: Place[];
    destinies: Place[];
    services: Service[];
    errorFields?: any;
}

const DispatchProgramItemForm = ({
    canBeEdited,
    dispatchProgramItemForm,
    setDispatchProgramItemForm,
    products,
    quantityUnits,
    currencies,
    origins,
    destinies,
    services,
    errorFields
}: DispatchProgramItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setDispatchProgramItemForm({ ...dispatchProgramItemForm, [name]: value });
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setDispatchProgramItemForm({ ...dispatchProgramItemForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setDispatchProgramItemForm({ ...dispatchProgramItemForm, [name]: value });
    };

    if (!dispatchProgramItemForm) return null;

    return (
        <>
            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Servicio</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="service_id"
                            id="service_id"
                            inputValue={dispatchProgramItemForm.service_id}
                            hasError={fieldHasError('service_id') !== ''}
                            options={SelectTwoMapperOptions(services)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('service_id', value.value)
                            }
                            placeholder={'Seleccione un servicio'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('service_id')}
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Producto</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="product_id"
                            id="product_id"
                            inputValue={dispatchProgramItemForm.product_id}
                            hasError={fieldHasError('product_id') !== ''}
                            options={SelectTwoMapperOptions(products)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('product_id', value.value)
                            }
                            placeholder={'Seleccione un producto'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('product_id')}
                        </div>
                    </div>
                </div>
            )}
            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Cantidad</label>
                    <div className="col-md-9">
                        <div className="row g-1">
                            <div className="col-6">
                                <input
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    onFocus={setCleanInputError}
                                    className={`form-control ${fieldHasError('quantity')}`}
                                    value={dispatchProgramItemForm.quantity}
                                    onChange={handleChange}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('quantity')}
                                </div>
                            </div>

                            <div className="col-6">
                                <SelectTwo
                                    name="quantity_unit_id"
                                    id="quantity_unit_id"
                                    hasError={fieldHasError('quantity_unit_id') !== ''}
                                    inputValue={dispatchProgramItemForm.quantity_unit_id}
                                    options={SelectTwoMapperOptions(quantityUnits)}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('quantity_unit_id', value.value)
                                    }
                                    placeholder={'medida'}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('quantity_unit_id')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Tarifa de venta</label>
                    <div className="col-md-9 ">
                        <div className="row g-1">
                            <div className="col-6">
                                <input
                                    type="number"
                                    name="rate"
                                    className={`form-control ${fieldHasError('rate')}`}
                                    id="rate"
                                    onFocus={setCleanInputError}
                                    value={dispatchProgramItemForm.rate}
                                    onChange={handleChange}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('rate')}
                                </div>
                            </div>

                            <div className="col-6">
                                <SelectTwo
                                    name="currency_id"
                                    id="currency_id"
                                    inputValue={dispatchProgramItemForm.currency_id}
                                    options={SelectTwoMapperOptions(currencies)}
                                    hasError={fieldHasError('currency_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('currency_id', value.value)
                                    }
                                    placeholder={'moneda'}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('currency_id')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Origen</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="origin_id"
                            id="origin_id"
                            inputValue={dispatchProgramItemForm.origin_id}
                            hasError={fieldHasError('origin_id') !== ''}
                            options={SelectTwoMapperOptions(origins)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('origin_id', value.value)
                            }
                            placeholder={'Seleccione origen'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('origin_id')}
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Destino</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="destiny_id"
                            id="destiny_id"
                            hasError={fieldHasError('destiny_id') !== ''}
                            inputValue={dispatchProgramItemForm.destiny_id}
                            options={SelectTwoMapperOptions(destinies)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('destiny_id', value.value)
                            }
                            placeholder={'Seleccione destino'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('destiny_id')}
                        </div>
                    </div>
                </div>
            )}

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                <div className="col-md-9">
                    <input
                        type="datetime-local"
                        className={`form-control ${fieldHasError('dispatch_date')}`}
                        name="dispatch_date"
                        id="dispatch_date"
                        value={
                            dispatchProgramItemForm.dispatch_date.length > 20
                                ? dispatchProgramItemForm.dispatch_date.substring(
                                      0,
                                      dispatchProgramItemForm.dispatch_date.length - 6
                                  )
                                : dispatchProgramItemForm.dispatch_date
                        }
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de despacho"
                        onFocus={setCleanInputError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('dispatch_date')}
                    </div>
                </div>
            </div>

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Descripción</label>
                    <div className="col-md-9">
                        <textarea
                            className={`form-control ${fieldHasError('description')}`}
                            name="description"
                            id="description"
                            value={dispatchProgramItemForm.description}
                            onChange={handleChangeText}
                            placeholder="Ingrese una descripción"
                            onFocus={setCleanTextAreaError}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('description')}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DispatchProgramItemForm;
