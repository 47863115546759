import React, { useContext, useEffect, useState } from 'react';

import { Place } from '../../app/models/Place';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useCurrencyService from '../../app/services/hooks/useCurrencyService';
import { Currency, CurrencyFilters } from '../../app/models/Currency';

import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';
import { BusinessNameDataForm, BusinessNameFilters } from '../../app/models/BusinessName';
import useBusinessNamesService from '../../app/services/hooks/useBusinessService';
import BusinessNameDataTable from './components/tables/BusinessNameDataTable';
import BusinessNameCreate from './components/BusinessNameCreate';
import BusinessNameEdit from './components/BusinessNameEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Razones Sociales',
        url: '/business-names',
        isActive: true
    }
];

const BusinessNames = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetBusinessNames, getBusinessNames, deleteBusinessName, activeBusinessName } =
        useBusinessNamesService();
    const [businessNames, setBusinessNames] = useState<BusinessNameDataForm[]>([]);
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<BusinessNameFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const getAllBusinessNames = () => {
        getBusinessNames(filter, {
            onSuccess: (response: ServiceResponse) => {
                setBusinessNames(response.data.business_names);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };


   
    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllBusinessNames();
    };

    const showEdit = (id: number) => {
        navigationPage(`/business-names/${id}/edit`);
       
    };

    const showShow = (id: number) => {
        navigationPage(`/business-names/${id}/show`)
       
    };

    const destroy = (businessNameId: number) => {
        const _text = 'Está a punto de eliminar la razón social #' + businessNameId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando razón social...');
                        deleteBusinessName(businessNameId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La razón social no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    
    return (
        <>
            <Breadcrumbs pageSection="Razones Sociales" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva razón social" />}
                />
                <BusinessNameDataTable
                    businessNames={businessNames}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetBusinessNames}
                    filter={filter}
                    setFilter={setFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear razón social"
                    backdrop={true}
                    showFooter={false}
                >
                    <BusinessNameCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default BusinessNames;
