import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useBankAccountReasonService from '../../../app/services/hooks/useBankAccountReasonService';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import BankAccountReasonFormContainer from './BankAccountReasonFormContainer';

interface Props {
    bankAccountReasonId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BankAccountReasonEdit = ({ bankAccountReasonId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditBankAccountReason, editBankAccountReason, fetchingUpdateBankAccountReason, updateBankAccountReason } = useBankAccountReasonService();

    const [bankAccountReason, setBankAccountReason] = useState<BankAccountReason>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando motivo de cuenta...');
        editBankAccountReason(bankAccountReasonId, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccountReason(response.data.bank_account_reason);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando motivo de cuenta...');
        updateBankAccountReason(bankAccountReasonId, bankAccountReason, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditBankAccountReason ? (
        <LazyLoading height="300" />
    ) : (
        <BankAccountReasonFormContainer
            fetching={fetchingUpdateBankAccountReason}
            action={update}
            cancel={cancel}
            bankAccountReason={bankAccountReason}
            setBankAccountReason={setBankAccountReason}
            errorFields={errorFields}
        />
    );
};

export default BankAccountReasonEdit;
