import React, { useEffect, useState } from 'react';

import { Place, PlaceFilters } from '../../app/models/Place';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import usePlaceService from '../../app/services/hooks/usePlaceService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import PlaceCreate from './components/PlaceCreate';
import PlaceEdit from './components/PlaceEdit';
import PlaceDataTable from './components/PlaceDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Origenes y destinos',
        url: '/Places',
        isActive: true
    }
];

const Places = () => {
    const { navigationPage } = useNavigationPage();

    const { fetchingGetPlaces, getPlaces, deletePlace } = usePlaceService();
    const [places, setPlaces] = useState<Place[]>([]);

    const [placeIdEdit, setPlaceIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [totalRows, setTotalRows] = useState<number>(0);
    const [filter, setFilter] = useState<PlaceFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllPlaces();
    }, [filter]);

    const getAllPlaces = () => {
        getPlaces(filter, {
            onSuccess: (response: ServiceResponse) => {
                setPlaces(response.data.places);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showEdit = (PlaceId: number) => {
        setShowingEdit(true);
        setPlaceIdEdit(PlaceId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setPlaceIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllPlaces();
    };

    const destroy = (PlaceId: number) => {
        const _text = 'Está a punto de eliminar el origen/destino #' + PlaceId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando origen/destino...', 400);
                deletePlace(PlaceId, {
                    onSuccess: () => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el origen/destino #' + PlaceId
                        });
                        reloadTable();
                    },
                    onError: () => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'No se ha podido el origen/destino #' + PlaceId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El origen/destino no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Origenes y destinos" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo origen/destino" />}
                />
                {/*<PlaceTable
                    places={places}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetPlaces}
    />*/}
                <PlaceDataTable
                    places={places}
                    loading={fetchingGetPlaces}
                    edit={showEdit}
                    destroy={destroy}
                    totalRows={totalRows}
                    setFilter={setFilter}
                    filter={filter}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear origen/destino"
                    backdrop={true}
                    showFooter={false}
                >
                    <PlaceCreate
                        onSaved={() => {
                            toast.success('Origen/Destino Registrado');
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Origen/Destino"
                    backdrop={true}
                    showFooter={false}
                >
                    <PlaceEdit
                        placeId={placeIdEdit}
                        onSaved={() => {
                            toast.success('Origen/Destino Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}

                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Places;
