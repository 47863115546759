import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Carrier } from '../../../app/models/Carrier';
import { Currency } from '../../../app/models/Currency';
import CheckingAccountFormContainer from './CheckingAccountFormContainer';
import moment from 'moment';
import useCheckingAccountService from '../../../app/services/hooks/useCheckingAccount';
import { CheckingAccountDataForm, defaultCheckingAccountDataForm } from '../../../app/models/CheckingAccount';
import { Bank } from '../../../app/models/Bank';
import { BusinessName } from '../../../app/models/BusinessName';
import { BusinessEntity } from '../../../app/models/BusinessEntity';
import useBusinessNamesService from '../../../app/services/hooks/useBusinessService';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import { BusinessNameBankAccount } from '../../../app/models/BusinessNameBankAccount';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import { TransferTypes } from '../../../app/shared/enums';


interface Props {
    checkingAccountId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    transferType: string;
    setTransferTypeEdit: (transferType: string) => void;
}

const CheckingAccountEdit = ({ checkingAccountId, onSaved, onCancel, onError, transferType, setTransferTypeEdit }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditCheckingAccount,
        editCheckingAccount,
        fetchingUpdateCheckingAccount,
        updateCheckingAccount
    } = useCheckingAccountService();

    const [checkingAccount, setCheckingAccount] = useState<CheckingAccountDataForm>(defaultCheckingAccountDataForm);
    const [checkingAccountEdit, setCheckingAccountEdit] = useState<CheckingAccountDataForm>(defaultCheckingAccountDataForm);
    const [senderBusinessNames, setSenderBusinessNames] = useState<BusinessName[]>([]);
    const [senderBusinessEntities, setSenderBusinessEntities] = useState<BusinessEntity[]>([]);
    const [senderBankAccountReasons, setSenderBankAccountReasons] = useState<BankAccountReason[]>([]);
    const [senderBankAccounts, setSenderBankAccounts] = useState<BusinessNameBankAccount[]>([]);
    const [senderBanks, setSenderBanks] = useState<Bank[]>([]);
    const [senderBankAccountTypes, setSenderBankAccountTypes] = useState<BankAccountType[]>([]);
    
    const [receiverBusinessNames, setReceiverBusinessNames] = useState<BusinessName[]>([]);
    const [receiverBusinessEntities, setReceiverBusinessEntities] = useState<BusinessEntity[]>([]);
    const [receiverBankAccountReasons, setReceiverBankAccountReasons] = useState<BankAccountReason[]>([]);
    const [receiverBankAccounts, setReceiverBankAccounts] = useState<BusinessNameBankAccount[]>([]);
    const [receiverBanks, setReceiverBanks] = useState<Bank[]>([]);
    const [receiverBankAccountTypes, setReceiverBankAccountTypes] = useState<BankAccountType[]>([]);

    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const senderBusinessEntitySelected = useRef<string | undefined>('');
    const senderBusinessNameIdSelected = useRef<number | undefined>(0);
    const senderBankAccountReasonIdSelected = useRef<number | undefined>(0);

    const receiverBusinessEntitySelected = useRef<string | undefined>('');
    const receiverBusinessNameIdSelected = useRef<number | undefined>(0);
    const receiverBankAccountReasonIdSelected = useRef<number | undefined>(0);

    const [errorFields, setErrorFields] = useState<any>();

    const {
        getBusinessNameList,
        fetchingGetBusinessNameList
    } = useBusinessNamesService();

    const {
        getBankAccountsByBusinessNameAndBankAccountReason,
        fetchingGetBankAccountsByBusinessNameAndBankAccountReason
    } = useBusinessNameBankAccountService()

    const [loadingSenderBusinessName, setLoadingSenderBusinessName] = useState<boolean>(fetchingGetBusinessNameList);
    const [loadingSenderBankAccount, setLoadingSenderBankAccount] = useState<boolean>(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
    const [loadingReceiverBusinessName, setLoadingReceiverBusinessName] = useState<boolean>(fetchingGetBusinessNameList);
    const [loadingReceiverBankAccount, setLoadingReceiverBankAccount] = useState<boolean>(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
  
    useEffect(() => {
        edit();
    }, []);

 
    const edit = () => {
        if (showLoading) showLoading('loading', `Cargando ${transferType === TransferTypes.PROVIDER_PAY ? "pago a proveedor" : "pago de cliente"}...`);
        editCheckingAccount(checkingAccountId, {
            onSuccess: (response: ServiceResponse) => {

                setSenderBusinessNames(response.data.sender_business_names);
                setSenderBusinessEntities(response.data.business_entities);
                setSenderBankAccountReasons(response.data.sender_bank_account_reasons);
                setSenderBankAccounts(response.data.sender_bank_accounts);
                setSenderBanks(response.data.sender_banks);
                setSenderBankAccountTypes(response.data.sender_bank_account_types);
                setReceiverBusinessNames(response.data.receiver_business_names);
                setReceiverBusinessEntities(response.data.business_entities);
                setReceiverBankAccountReasons(response.data.receiver_bank_account_reasons);
                setReceiverBankAccounts(response.data.receiver_bank_accounts);
                setReceiverBanks(response.data.receiver_banks);
                setReceiverBankAccountTypes(response.data.receiver_bank_account_types);
                setCurrencies(response.data.currencies);

                senderBusinessEntitySelected.current = response.data.checking_account.sender_business_entity;
                senderBusinessNameIdSelected.current = response.data.checking_account.sender_business_name_id;
                senderBankAccountReasonIdSelected.current = response.data.checking_account.sender_bank_account_reason_id;
                receiverBusinessEntitySelected.current = response.data.checking_account.receiver_business_entity;
                receiverBusinessNameIdSelected.current = response.data.checking_account.receiver_business_name_id;
                receiverBankAccountReasonIdSelected.current = response.data.checking_account.receiver_bank_account_reason_id;
                setTransferTypeEdit(response.data.checking_account.transfer_type);
                setCheckingAccount(response.data.checking_account);
                setCheckingAccountEdit(response.data.checking_account);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    
    // useEffect(() => {

    //     if(transferType === TransferTypes.PAY){
    //         if (checkingAccount.sender_business_entity == undefined) {
    //             senderBusinessEntitySelected.current = undefined;
    //         }
    //         if (checkingAccount.sender_business_entity && checkingAccount.sender_business_entity != senderBusinessEntitySelected.current) {
    //             setLoadingSenderBusinessName(true);
    //             getBusinessNameList(checkingAccount.sender_business_entity, {
    //                 onSuccess: (response: ServiceResponse) => {
    
    //                     senderBusinessEntitySelected.current = checkingAccount.sender_business_entity ?? '';
    //                     setErrorFields(undefined);
    //                     setSenderBusinessNames(response.data.business_names);
    //                     setLoadingSenderBusinessName(fetchingGetBusinessNameList);
    //                     if(checkingAccount.sender_business_entity === checkingAccountEdit.sender_business_entity){
    
    //                         setCheckingAccount(
    //                             {
    //                                 ...checkingAccount,
    //                                 sender_business_name_id: checkingAccountEdit.sender_business_name_id,
    //                                 sender_bank_account_reason_id: checkingAccountEdit.sender_bank_account_reason_id,
    //                                 sender_bank_account_id: checkingAccountEdit.sender_bank_account_id,
    //                                 sender_bank_id: checkingAccountEdit.sender_bank_id,
    //                                 sender_account_type_id: checkingAccountEdit.sender_account_type_id,
    //                                 sender_account_number: checkingAccountEdit.sender_account_number
    //                             }
    //                         )
    
    //                     }else{
    //                         setCheckingAccount(
    //                             {
    //                                 ...checkingAccount,
    //                                 sender_business_name_id: 0,
    //                                 sender_bank_account_reason_id: 0,
    //                                 sender_bank_account_id: 0,
    //                                 sender_bank_id: 0,
    //                                 sender_account_type_id: 0,
    //                                 sender_account_number: ''
    //                             }
    //                         )
    //                     }
                        
                        
    //                 },
    //                 onError: (response: ServiceResponse) => {
    //                     setCheckingAccount({
    //                         ...checkingAccount,
    //                         sender_business_entity: senderBusinessEntitySelected.current
    //                     });
    //                     setLoadingSenderBusinessName(fetchingGetBusinessNameList);
    //                 }
    //             });
    //         }
    //     }
        
    // }, [checkingAccount.sender_business_entity]);

    // useEffect(() => {

    //     if(transferType === TransferTypes.TRANSFER){
    //         if (checkingAccount.receiver_business_entity == undefined) {
    //             receiverBusinessEntitySelected.current = undefined;
    //         }
    //         if (checkingAccount.receiver_business_entity && checkingAccount.receiver_business_entity != receiverBusinessEntitySelected.current) {
    //             setLoadingReceiverBusinessName(true);
    //             getBusinessNameList(checkingAccount.receiver_business_entity, {
    //                 onSuccess: (response: ServiceResponse) => {
    
    //                     receiverBusinessEntitySelected.current = checkingAccount.receiver_business_entity ?? '';
    //                     setErrorFields(undefined);
    //                     setReceiverBusinessNames(response.data.business_names);
    //                     if(checkingAccount.receiver_business_entity === checkingAccountEdit.receiver_business_entity){
    //                         setCheckingAccount(
    //                             {
    //                                 ...checkingAccount,
    //                                 receiver_business_name_id: checkingAccountEdit.receiver_business_name_id,
    //                                 receiver_bank_account_reason_id: checkingAccountEdit.receiver_bank_account_reason_id,
    //                                 receiver_bank_account_id: checkingAccountEdit.receiver_bank_account_id,
    //                                 receiver_bank_id: checkingAccountEdit.receiver_bank_id,
    //                                 receiver_account_type_id: checkingAccountEdit.receiver_account_type_id,
    //                                 receiver_account_number: checkingAccountEdit.receiver_account_number
    //                             }
    //                         )
    //                     }
    //                     else{
    //                         setCheckingAccount(
    //                             {
    //                                 ...checkingAccount,
    //                                 receiver_business_name_id: 0,
    //                                 receiver_bank_account_reason_id: 0,
    //                                 receiver_bank_account_id: 0,
    //                                 receiver_bank_id: 0,
    //                                 receiver_account_type_id: 0,
    //                                 receiver_account_number: ''
    //                             }
    //                         )
    //                     }
                        
    //                     setLoadingReceiverBusinessName(fetchingGetBusinessNameList);
                        
    //                 },
    //                 onError: (response: ServiceResponse) => {
    //                     setCheckingAccount({
    //                         ...checkingAccount,
    //                         receiver_business_entity: receiverBusinessEntitySelected.current
    //                     });
    //                     setLoadingReceiverBusinessName(fetchingGetBusinessNameList);
    //                 }
    //             });
    //         }
    //     }
        
    // }, [checkingAccount.receiver_business_entity]);


    useEffect(() => {
        if (checkingAccount.sender_business_name_id == undefined) {
            senderBusinessNameIdSelected.current = undefined;
        }

        if (checkingAccount.sender_bank_account_reason_id == undefined) {
            senderBankAccountReasonIdSelected.current = undefined;
        }
      
        

        if (
            checkingAccount.sender_business_name_id &&
            checkingAccount.sender_bank_account_reason_id &&
            (checkingAccount.sender_business_name_id != senderBusinessNameIdSelected.current ||
                checkingAccount.sender_bank_account_reason_id != senderBankAccountReasonIdSelected.current)
        ) {
            setLoadingSenderBankAccount(true);
            getBankAccountsByBusinessNameAndBankAccountReason(
                checkingAccount.sender_business_name_id,
                checkingAccount.sender_bank_account_reason_id,
                {
                    onSuccess: (response: ServiceResponse) => {
                        
                        setErrorFields(undefined);
                        setSenderBankAccounts(response.data.bank_accounts);
                        if(
                            checkingAccount.sender_business_name_id == checkingAccountEdit.sender_business_name_id &&
                            checkingAccount.sender_bank_account_reason_id === checkingAccountEdit.sender_bank_account_reason_id
                        ){

                            if(
                                transferType === TransferTypes.PROVIDER_PAY &&
                                checkingAccountEdit.sender_business_name_id == checkingAccount.sender_business_name_id &&
                                checkingAccount.sender_bank_account_reason_id == senderBankAccountReasonIdSelected.current
                            ){
                                setCheckingAccount({
                                    ...checkingAccount,
                                receiver_business_name_id: checkingAccountEdit.receiver_business_name_id,
                                sender_bank_account_id: checkingAccountEdit.sender_bank_account_id,
                                sender_bank_id: checkingAccountEdit.sender_bank_id,
                                sender_account_type_id: checkingAccountEdit.sender_account_type_id,
                                sender_account_number: checkingAccountEdit.sender_account_number,

                                })

                            }
                            else{
                                setCheckingAccount({
                                    ...checkingAccount,
                                    sender_bank_account_id: checkingAccountEdit.sender_bank_account_id,
                                    sender_bank_id: checkingAccountEdit.sender_bank_id,
                                    sender_account_type_id: checkingAccountEdit.sender_account_type_id,
                                    sender_account_number: checkingAccountEdit.sender_account_number,
                                    
                                });
                            }
                         
                           
                        }else{

                            if(
                                transferType === TransferTypes.PROVIDER_PAY &&
                                checkingAccountEdit.sender_business_name_id == checkingAccount.sender_business_name_id &&
                                checkingAccount.sender_bank_account_reason_id == senderBankAccountReasonIdSelected.current
                            ){
                                setCheckingAccount({
                                    ...checkingAccount,
                                    receiver_business_name_id: checkingAccountEdit.receiver_business_name_id,
                                    sender_bank_account_id: 0,
                                    sender_bank_id: 0,
                                    sender_account_type_id: 0,
                                    sender_account_number: ''
                                });
                            }
                            else{
                                setCheckingAccount({
                                    ...checkingAccount,
                                    sender_bank_account_id: 0,
                                    sender_bank_id: 0,
                                    sender_account_type_id: 0,
                                    sender_account_number: ''
                                });
                            }
                            
                            
                        }
                        senderBusinessNameIdSelected.current = checkingAccount.sender_business_name_id ?? 0;
                        senderBankAccountReasonIdSelected.current = checkingAccount.sender_bank_account_reason_id ?? 0;
                        
                        setLoadingSenderBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
                    },
                    onError: (response: ServiceResponse) => {
                        setCheckingAccount({
                            ...checkingAccount,
                            sender_business_name_id: senderBusinessNameIdSelected.current,
                            receiver_business_name_id:  receiverBusinessNameIdSelected.current,
                            sender_bank_account_reason_id: senderBankAccountReasonIdSelected.current
                        });
                        const businessName = senderBusinessNames.find(
                            (businessName: BusinessName) => businessName.id === senderBusinessNameIdSelected.current
                          );
                        if(businessName){
                            setReceiverBusinessNames(businessName.clients ?? []);
                        }
                        setLoadingSenderBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
                    }
                }
            );
        }
    }, [checkingAccount.sender_business_name_id, checkingAccount.sender_bank_account_reason_id]);



    useEffect(() => {
        if (checkingAccount.receiver_business_name_id == undefined) {
            receiverBusinessNameIdSelected.current = undefined;
        }

        if (checkingAccount.receiver_bank_account_reason_id == undefined) {
            receiverBankAccountReasonIdSelected.current = undefined;
        }

       
        if (
            checkingAccount.receiver_business_name_id &&
            checkingAccount.receiver_bank_account_reason_id &&
            (checkingAccount.receiver_business_name_id != receiverBusinessNameIdSelected.current ||
                checkingAccount.receiver_bank_account_reason_id != receiverBankAccountReasonIdSelected.current)
        ) {
            setLoadingReceiverBankAccount(true);
            getBankAccountsByBusinessNameAndBankAccountReason(
                checkingAccount.receiver_business_name_id,
                checkingAccount.receiver_bank_account_reason_id,
                {
                    onSuccess: (response: ServiceResponse) => {
                        
                        setErrorFields(undefined);
                        setReceiverBankAccounts(response.data.bank_accounts);
                        if(
                            checkingAccount.receiver_business_name_id == checkingAccountEdit.receiver_business_name_id &&
                            checkingAccount.receiver_bank_account_reason_id === checkingAccountEdit.receiver_bank_account_reason_id
                        ){

                            if(
                                transferType === TransferTypes.CLIENT_PAY &&
                                checkingAccountEdit.receiver_business_name_id == checkingAccount.receiver_business_name_id &&
                                checkingAccount.receiver_bank_account_reason_id == receiverBankAccountReasonIdSelected.current
                            ){
                                setCheckingAccount({
                                    ...checkingAccount,
                                    sender_business_name_id: checkingAccountEdit.sender_business_name_id,
                                    receiver_bank_account_id: checkingAccountEdit.receiver_bank_account_id,
                                    receiver_bank_id: checkingAccountEdit.receiver_bank_id,
                                    receiver_account_type_id: checkingAccountEdit.receiver_account_type_id,
                                    receiver_account_number: checkingAccountEdit.receiver_account_number
                                });
                            }
                            else{
                                setCheckingAccount({
                                    ...checkingAccount,
                                    receiver_bank_account_id: checkingAccountEdit.receiver_bank_account_id,
                                    receiver_bank_id: checkingAccountEdit.receiver_bank_id,
                                    receiver_account_type_id: checkingAccountEdit.receiver_account_type_id,
                                    receiver_account_number: checkingAccountEdit.receiver_account_number
                                });
                            }
                            
                        }
                        else{

                            if(
                                transferType === TransferTypes.CLIENT_PAY &&
                                checkingAccountEdit.receiver_business_name_id == checkingAccount.receiver_business_name_id &&
                                checkingAccount.receiver_bank_account_reason_id == receiverBankAccountReasonIdSelected.current
                            ){
                                setCheckingAccount({
                                    ...checkingAccount,
                                    sender_business_name_id: checkingAccountEdit.sender_business_name_id,
                                    receiver_bank_account_id: 0,
                                    receiver_bank_id: 0,
                                    receiver_account_type_id: 0,
                                    receiver_account_number: ''
                                });
                            }
                            else{
                                setCheckingAccount({
                                    ...checkingAccount,
                                    receiver_bank_account_id: 0,
                                    receiver_bank_id: 0,
                                    receiver_account_type_id: 0,
                                    receiver_account_number: ''
                                });
                            }
                            
                        }

                        receiverBusinessNameIdSelected.current = checkingAccount.receiver_business_name_id ?? 0;
                        receiverBankAccountReasonIdSelected.current = checkingAccount.receiver_bank_account_reason_id ?? 0;
                        
                        setLoadingReceiverBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason)
                    },
                    onError: (response: ServiceResponse) => {
                        setCheckingAccount({
                            ...checkingAccount,
                            receiver_business_name_id: receiverBusinessNameIdSelected.current,
                            sender_business_name_id:  senderBusinessNameIdSelected.current,
                            receiver_bank_account_reason_id: receiverBankAccountReasonIdSelected.current
                        });
                        const businessName = receiverBusinessNames.find(
                            (businessName: BusinessName) => businessName.id === receiverBusinessNameIdSelected.current
                          );
                        if(businessName){
                            setSenderBusinessNames(businessName.clients ?? []);
                        }
                        setLoadingReceiverBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason)
                    }
                }
            );
        }
    }, [checkingAccount.receiver_business_name_id, checkingAccount.receiver_bank_account_reason_id]);


    useEffect(() => {

        if(
            senderBusinessEntitySelected.current === checkingAccountEdit.sender_business_entity &&
            senderBusinessNameIdSelected.current == checkingAccountEdit.sender_business_name_id &&
            senderBankAccountReasonIdSelected.current === checkingAccountEdit.sender_bank_account_reason_id &&
            checkingAccount.sender_bank_account_id === checkingAccountEdit.sender_bank_account_id
        ){

            setCheckingAccount({
                ...checkingAccount,
                sender_bank_id: checkingAccountEdit.sender_bank_id,
                sender_account_type_id: checkingAccountEdit.sender_account_type_id,
                sender_account_number: checkingAccountEdit.sender_account_number

            });
        }else{

            const senderAccount = senderBankAccounts.find((bankAccount: BusinessNameBankAccount) => bankAccount.id === checkingAccount.sender_bank_account_id)

            if(senderAccount){
                setCheckingAccount({
                    ...checkingAccount,
                    sender_bank_id: senderAccount.bank_id,
                    sender_account_type_id: senderAccount.account_type_id,
                    sender_account_number: senderAccount.account_number
    
                });
            }
        }
    }, [checkingAccount.sender_bank_account_id])


    useEffect(() => {

        if(
            receiverBusinessEntitySelected.current === checkingAccountEdit.receiver_business_entity &&
            receiverBusinessNameIdSelected.current == checkingAccountEdit.receiver_business_name_id &&
            receiverBankAccountReasonIdSelected.current === checkingAccountEdit.receiver_bank_account_reason_id &&
            checkingAccount.receiver_bank_account_id === checkingAccountEdit.receiver_bank_account_id
        ){

            setCheckingAccount({
                ...checkingAccount,
                receiver_bank_id: checkingAccountEdit.receiver_bank_id,
                receiver_account_type_id: checkingAccountEdit.receiver_account_type_id,
                receiver_account_number: checkingAccountEdit.receiver_account_number

            });

        }else{
            const receiverAccount = receiverBankAccounts.find((bankAccount: BusinessNameBankAccount) => bankAccount.id === checkingAccount.receiver_bank_account_id)

            if(receiverAccount){
                setCheckingAccount({
                    ...checkingAccount,
                    receiver_bank_id: receiverAccount.bank_id,
                    receiver_account_type_id: receiverAccount.account_type_id,
                    receiver_account_number: receiverAccount.account_number
    
                });
            }
            
        }
          

    }, [checkingAccount.receiver_bank_account_id])

    const update = () => {
        if (showLoading) showLoading('loading', `Actualizando ${transferType === TransferTypes.PROVIDER_PAY ? "pago a proveedor" : "pago de cliente"}...`);
        updateCheckingAccount(checkingAccountId, checkingAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCheckingAccount ? (
        <LazyLoading height="300" />
    ) : (
        <CheckingAccountFormContainer
            fetching={fetchingUpdateCheckingAccount}
            action={update}
            cancel={cancel}
            checkingAccount={checkingAccount}
            setCheckingAccount={setCheckingAccount}
            transferType={transferType}
            senderBusinessNames={senderBusinessNames}
            senderBusinessEntities={senderBusinessEntities}
            senderBankAccountReasons={senderBankAccountReasons}
            senderBankAccounts={senderBankAccounts}
            senderBanks={senderBanks}
            senderBankAccountTypes={senderBankAccountTypes}
            receiverBusinessNames={receiverBusinessNames}
            receiverBusinessEntities={receiverBusinessEntities}
            receiverBankAccountReasons={receiverBankAccountReasons}
            receiverBankAccounts={receiverBankAccounts}
            receiverBanks={receiverBanks}
            receiverBankAccountTypes={receiverBankAccountTypes}
            currencies={currencies}
            errorFields={errorFields}
            loadingSenderBankAccount={loadingSenderBankAccount}
            loadingReceiverBankAccount={loadingReceiverBankAccount}
            setSenderBusinessNames={setSenderBusinessNames}
            setReceiverBusinessNames={setReceiverBusinessNames}
        />
    );
};

export default CheckingAccountEdit;
