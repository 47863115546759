import React, { useContext, useEffect, useState } from 'react'
import usePaymentMethodService from '../../app/services/hooks/usePaymentMethodService'
import { PaymentMethod, PaymentMethodFilters } from '../../app/models/PaymentMethod';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import useNavigationPage from '../../hooks/useNavigationPage';
import DefaultCard from '../../components/default/DefaultCard';
import PaymentMethodDataTable from './components/tables/PaymentMethodDataTable';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import DefaultModal from '../../components/default/DefaultModal';
import PaymentMethodCreate from './components/PaymentMethodCreate';
import PaymentMethodEdit from './components/PaymentMethodEdit';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Formas de pago',
        url: '/payment-methods',
        isActive: true
    }
];

const PaymentMethods = () => {

    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { getPaymentMethods, fetchingGetPaymentMethods, deletePaymentMethod } = usePaymentMethodService();

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const [paymentMethodIdEdit, setPaymentMethodIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [totalRows, setTotalRows] = useState<number>(0);
    const [filter, setFilter] = useState<PaymentMethodFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllPaymentMethods();
    }, [filter]);

    const getAllPaymentMethods = () => {
        getPaymentMethods(filter, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentMethods(response.data.payment_methods);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };


    const destroy = (paymentMethodId: number) => {
        const _text = 'Está a punto de eliminar el método de pago #' + paymentMethodId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, eliminar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando método de pago...');
                        deletePaymentMethod(paymentMethodId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El método de pago no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };
    
    const showEdit = (PaymentMethodId: number) => {
        setShowingEdit(true);
        setPaymentMethodIdEdit(PaymentMethodId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setPaymentMethodIdEdit(-1);
    };

    const reloadTable = () => {
        getAllPaymentMethods();
    };

    return (
        <>
            <Breadcrumbs pageSection="Formas de pago" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo metodo de pago" />}
                />
                <PaymentMethodDataTable
                    paymentMethods={paymentMethods}
                    loading={fetchingGetPaymentMethods}
                    edit={showEdit}
                    destroy={destroy}
                    totalRows={totalRows}
                    setFilter={setFilter}
                    filter={filter} 
                    callbackSuccess={reloadTable}                
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Forma de Pago"
                    backdrop={true}
                    showFooter={false}
                >
                    <PaymentMethodCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Forma de Pago"
                    backdrop={true}
                    showFooter={false}
                >
                    <PaymentMethodEdit
                        paymentMethodId={paymentMethodIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}

                    />
                </DefaultModal>
            ) : null}
        </>
    )
}

export default PaymentMethods
