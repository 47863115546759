import Pagination from 'react-bootstrap/Pagination';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

interface PaginatorProps {
    rowsPerPage: number;
    rowCount: number;
    currentPage: number;
    onChangePage: (page: number, totalRows: number) => void;
    onChangeRowsPerPage: (newRowsPerPage: number, currentPage: number) => void;
    paginationRowsPerPageOptions?: any[];
}

const getPageCountList = (n: number) => {
    const pageCountList = [];
    for (let i = 1; i <= n; i++) {
        pageCountList.push(i);
    }
    return pageCountList;
};

const Paginator = ({
    rowsPerPage,
    rowCount,
    currentPage,
    onChangePage,
    onChangeRowsPerPage,
    paginationRowsPerPageOptions
}: PaginatorProps) => {
    const totalPages = Math.ceil(rowCount / rowsPerPage);
    // const pageNumberList = getPageCountList(totalPages)

    const renderPaginationItems = () => {
        const paginationItems = [];
        const ellipsisThreshold = 3;

        for (let i = 1; i <= totalPages; i++) {
            if (
                i <= ellipsisThreshold ||
                (i >= currentPage - 2 && i <= currentPage + 2) ||
                i > totalPages - ellipsisThreshold
            ) {
                paginationItems.push(
                    <Pagination.Item
                        key={i}
                        onClick={() => onChangePage(i, rowCount)}
                        active={i === currentPage}
                        title={`${i}`}
                    >
                        {i}
                    </Pagination.Item>
                );
            } else if (i === ellipsisThreshold + 1 && currentPage > ellipsisThreshold + 2) {
                paginationItems.push(
                    <Pagination.Ellipsis
                        onClick={() => onChangePage(currentPage - ellipsisThreshold, rowCount)}
                        key="ellipsis-left"
                    />
                );
            } else if (
                i === totalPages - ellipsisThreshold &&
                currentPage < totalPages - ellipsisThreshold - 1
            ) {
                paginationItems.push(
                    <Pagination.Ellipsis
                        onClick={() => onChangePage(currentPage + ellipsisThreshold, rowCount)}
                        key="ellipsis-right"
                    />
                );
            }
        }

        return paginationItems;
    };

    return (
        <div className="mt-2 row justify-content-between">
            <div className="col-2">
                <DropdownButton id="dropdown-basic-button" title={rowsPerPage}>
                    {paginationRowsPerPageOptions?.map((option, key) => (
                        <Dropdown.Item
                            key={key}
                            href=""
                            onClick={() => onChangeRowsPerPage(option, currentPage)}
                        >
                            {option}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>

            <div className="col-sm-8 paginator-outer-container mt-2">
                <div className="paginator-inner-container">
                    <Pagination size="lg">
                        <Pagination.First
                            disabled={currentPage === 1}
                            onClick={() => onChangePage(1, rowCount)}
                        />
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => onChangePage(currentPage - 1, rowCount)}
                        />
                        {renderPaginationItems()}
                        <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => onChangePage(currentPage + 1, rowCount)}
                        />
                        <Pagination.Last
                            disabled={currentPage === totalPages}
                            onClick={() => onChangePage(totalPages, rowCount)}
                        />
                    </Pagination>
                </div>
            </div>
        </div>
    );
};

export default Paginator;
