export interface Brand {
    id?: number;
    name?: string;
    description?: string;
    is_active?: string;
}

export const defaultBrandDataForm: Brand = {
    name: '',
    description: ''
};

export interface BrandFilters {
    id?: number | null;
    name?: string | '';
    is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
