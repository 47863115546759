import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { BankAccount, BankAccountType } from '../../../../app/models/BankAccountType';
import { Currency } from '../../../../app/models/Currency';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';
import { formatAmount, formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { Bank } from '../../../../app/models/Bank';
import { CheckingAccountDataForm } from '../../../../app/models/CheckingAccount';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import { BankAccountReason } from '../../../../app/models/BankAccountReason';
import { BusinessNameBankAccount } from '../../../../app/models/BusinessNameBankAccount';
import { TransferTypes } from '../../../../app/shared/enums';
import { Society } from '../../../../app/models/Society';
import ProviderPayForm from './ProviderPayForm';
import ClientPayForm from './ClientPayForm';

type Props = {
    checkingAccountDataForm: CheckingAccountDataForm;
    setCheckingAccountDataForm: (checkingAccountDataForm: CheckingAccountDataForm) => void;
    transferType: string;
    senderBusinessNames: BusinessName[];
    senderBusinessEntities: BusinessEntity[];
    senderBankAccountReasons: BankAccountReason[];
    senderBankAccounts: BusinessNameBankAccount[];
    senderBanks: Bank[];
    senderBankAccountTypes: BankAccountType[];
    receiverBusinessNames: BusinessName[];
    receiverBusinessEntities: BusinessEntity[];
    receiverBankAccountReasons: BankAccountReason[];
    receiverBankAccounts: BusinessNameBankAccount[];
    receiverBanks: Bank[];
    receiverBankAccountTypes: BankAccountType[];
    currencies: Currency[];
    errorFields?: any;
    loadingSenderBankAccount: boolean;
    loadingReceiverBankAccount: boolean;
    setSenderBusinessNames: (businessNames: BusinessName[]) => void;
    setReceiverBusinessNames: (businessNames: BusinessName[]) => void;
};

const CheckingAccountForm = ({
    checkingAccountDataForm,
    setCheckingAccountDataForm,
    transferType,
    senderBusinessNames,
    senderBusinessEntities,
    senderBankAccountReasons,
    senderBankAccounts,
    senderBanks,
    senderBankAccountTypes,
    receiverBusinessNames,
    receiverBusinessEntities,
    receiverBankAccountReasons,
    receiverBankAccounts,
    receiverBanks,
    receiverBankAccountTypes,
    currencies,
    errorFields,
    loadingSenderBankAccount,
    loadingReceiverBankAccount,
    setSenderBusinessNames,
    setReceiverBusinessNames
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();
    
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setCheckingAccountDataForm({ ...checkingAccountDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, name: string) => {
        if (e) {
            
            const obj = { [name]:  e[0]};
            
            setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
    };

    const handleRemoveFile = (name: string) => {

        setCheckingAccountDataForm({
            ...checkingAccountDataForm,
            [name]: undefined
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }

    };
    
    return (
            <>
            { transferType === TransferTypes.PROVIDER_PAY &&

                <ProviderPayForm 
                    checkingAccountDataForm={checkingAccountDataForm}
                    setCheckingAccountDataForm={setCheckingAccountDataForm}
                    senderBusinessNames={senderBusinessNames}
                    senderBankAccountReasons={senderBankAccountReasons}
                    senderBankAccounts={senderBankAccounts}
                    senderBanks={senderBanks}
                    senderBankAccountTypes={senderBankAccountTypes}
                    receiverBusinessNames={receiverBusinessNames}
                    receiverBusinessEntities={receiverBusinessEntities}
                    currencies={currencies}
                    errorFields={errorFields}
                    loadingSenderBankAccount={loadingSenderBankAccount}
                    setReceiverBusinessNames={setReceiverBusinessNames}
                />

            }
            {
                transferType === TransferTypes.CLIENT_PAY &&

                <ClientPayForm
                    checkingAccountDataForm={checkingAccountDataForm}
                    setCheckingAccountDataForm={setCheckingAccountDataForm}
                    receiverBusinessNames={receiverBusinessNames}
                    receiverBankAccountReasons={receiverBankAccountReasons}
                    receiverBankAccounts={receiverBankAccounts}
                    receiverBanks={receiverBanks}
                    receiverBankAccountTypes={receiverBankAccountTypes}
                    senderBusinessNames={senderBusinessNames}
                    senderBusinessEntities={senderBusinessEntities}
                    currencies={currencies}
                    errorFields={errorFields}
                    loadingReceiverBankAccount={loadingReceiverBankAccount}
                    setSenderBusinessNames={setSenderBusinessNames}
                />
            }
            </>
    );

};


export default CheckingAccountForm;
