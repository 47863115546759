import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ServiceDataForm } from '../../../app/models/Service';
import { Sector } from '../../../app/models/Sector';
import ServiceForm from './forms/ServiceForm';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import { Tax } from '../../../app/models/Tax';
import { ItemCategory } from '../../../app/models/ItemCategory';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    service: ServiceDataForm;
    setService: (service: ServiceDataForm) => void;
    sectors: Sector[];
    itemCategories: ItemCategory[];
    currencies: Currency[];
    priceUnits: Unit[];
    units:Unit[];
    taxesData: Tax[];
    errorFields?: any;
}

const ServiceFormContainer = ({
    fetching,
    action,
    cancel,
    service,
    setService,
    sectors,
    itemCategories,
    currencies,
    priceUnits,
    units,
    taxesData,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceForm
                        serviceDataForm={service}
                        setServiceDataForm={setService}
                        currencies={currencies}
                        priceUnits={priceUnits}
                        units={units}
                        sectors={sectors}
                        itemCategories={itemCategories}
                        taxesData={taxesData}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ServiceFormContainer;
