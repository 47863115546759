import React, { useEffect, useState } from 'react';

import { ProviderType, ProviderTypeFilters } from '../../app/models/ProviderType';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useProviderTypeService from '../../app/services/hooks/useProviderTypeService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import ProviderTypeCreate from './components/ProviderTypeCreate';
import ProviderTypeEdit from './components/ProviderTypeEdit';
import ProviderTypeDataTable from './components/ProviderTypeDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Tipos de proveedores',
        url: '/ProviderTypes',
        isActive: true
    }
];

const ProviderTypes = () => {
    const { fetchingGetProviderTypes, getProviderTypes, deleteProviderType } =
        useProviderTypeService();
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);

    const [placeIdEdit, setProviderTypeIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const { navigationPage } = useNavigationPage();


    const [totalRows, setTotalRows] = useState<number>(0);
    const [filter, setFilter] = useState<ProviderTypeFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllProviderTypes();
    }, [filter]);

    const getAllProviderTypes = () => {
        getProviderTypes(filter, {
            onSuccess: (response: ServiceResponse) => {
                setProviderTypes(response.data.provider_types);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showEdit = (ProviderTypeId: number) => {
        setShowingEdit(true);
        setProviderTypeIdEdit(ProviderTypeId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setProviderTypeIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllProviderTypes();
    };

    const destroy = (ProviderTypeId: number) => {
        const _text = 'Está a punto de eliminar el tipo de proveedor #' + ProviderTypeId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteProviderType(ProviderTypeId, {
                    onSuccess: () => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el tipo de proveedor #' + ProviderTypeId
                        });
                        reloadTable();
                    },
                    onError: () => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'No se ha podido el tipo de proveedor #' + ProviderTypeId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El tipo de proveedor no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Tipos de proveedores" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate callbackCreate={showCreate} title="Nuevo tipo de proveedor" />
                    }
                />
                <ProviderTypeDataTable
                    providerTypes={providerTypes}
                    loading={fetchingGetProviderTypes}
                    edit={showEdit}
                    destroy={destroy}
                    totalRows={totalRows}
                    setFilter={setFilter}
                    filter={filter}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear tipo de proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderTypeCreate
                        onSaved={() => {
                            toast.success('Tipo de proveedor Registrado');
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar tipo de proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderTypeEdit
                        placeId={placeIdEdit}
                        onSaved={() => {
                            toast.success('Tipo de proveedor Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ProviderTypes;
