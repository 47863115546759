import React, { useContext, useEffect, useState } from 'react';

import { Truck, TruckFilters } from '../../app/models/Truck';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useTruckService from '../../app/services/hooks/useTruckService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import TruckCreate from './components/TruckCreate';
import TruckEdit from './components/TruckEdit';
import { AppContext } from '../../contexts/AppContext';
import TruckDataTable from './components/TruckDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Camiones',
        url: '/Trucks',
        isActive: true
    }
];

const Trucks = () => {
    const { fetchingGetTrucks, getTrucks, deleteTruck } = useTruckService();
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { navigationPage } = useNavigationPage();


    const [truckIdEdit, setTruckIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [filter, setFilter] = useState<TruckFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllTrucks();
    }, [filter]);

    const getAllTrucks = () => {
        getTrucks(filter, {
            onSuccess: (response: ServiceResponse) => {
                setTrucks(response.data.trucks);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showEdit = (TruckId: number) => {
        setShowingEdit(true);
        setTruckIdEdit(TruckId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTruckIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllTrucks();
    };

    const destroy = (TruckId: number) => {
        const _text = 'Está a punto de eliminar el Camion #' + TruckId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteTruck(TruckId, {
                    onSuccess: () => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el Camion #' + TruckId
                        });
                        reloadTable();
                    },
                    onError: () => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'No se ha podido el Camion #' + TruckId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El Camion no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Camiones" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo Camión" />}
                />
                {/* <TruckTable
                    trucks={trucks}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetTrucks}
                /> */}
                <TruckDataTable
                    trucks={trucks}
                    totalRows={totalRows}
                    loading={fetchingGetTrucks}
                    destroy={destroy}
                    edit={showEdit}
                    filter={filter}
                    setFilter={setFilter}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Camion"
                    backdrop={true}
                    showFooter={false}
                >
                    <TruckCreate
                        onSaved={() => {
                            toast.success('Camion Registrado');
                            reloadTable();
                            hideCreate();
                        }}
                        // se comenta mientras se prueba cambios de permisos
                        // onError={(message) => {
                        //     toast.error(message);
                        // }}
                        onCancel={hideCreate}
                        onError={hideCreate}

                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Camion"
                    backdrop={true}
                    showFooter={false}
                >
                    <TruckEdit
                        truckId={truckIdEdit}
                        onSaved={() => {
                            toast.success('Camion Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        // se comenta mientras se prueba cambio de permisos
                        // onError={(message) => {
                        //     toast.error(message);
                        // }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Trucks;
