import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { UserPasswordDataForm, defaultUserPasswordDataForm } from '../../../app/models/User';
import useUserService from '../../../app/services/hooks/useUserService';
import UserFormContainer from './UserFormContainer';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import UserPasswordForm from './forms/UserPasswordForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

interface Props {
    userId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserPasswordEdit = ({ userId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditPasswordUser,
        editPasswordUser,
        fetchingUpdatePasswordUser,
        updatePasswordUser
    } = useUserService();

    const [user, setUser] = useState<UserPasswordDataForm>(defaultUserPasswordDataForm);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando...');
        editPasswordUser(userId, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const updatePassword = () => {
        // useSweetAlert().spinnerAlert("Actualizando usuario...");
        if (showLoading) showLoading('loading', 'Actualizando contraseña...');
        updatePasswordUser(userId, user, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                // useSweetAlert().closeSpinnerAlert();
                // if(changeAnimation)changeAnimation('success','Actualización exitosa', true);
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                // useSweetAlert().closeSpinnerAlert();
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                // useSweetAlert().closeSpinnerAlert();
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditPasswordUser ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <UserPasswordForm
                        userForm={user}
                        setUserForm={setUser}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdatePasswordUser} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={updatePassword}
                        locked={fetchingUpdatePasswordUser}
                    />
                </div>
            </div>
        </>
    );
};

export default UserPasswordEdit;
