import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useContactTypeService from '../../../app/services/hooks/useContactTypeService';
import { ContactTypeDataForm } from '../../../app/models/ContactType';
import ContactTypeFormContainer from './ContactTypeFormContainer';
import { BusinessEntity } from '../../../app/models/BusinessEntity';

interface Props {
    contactTypeId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ContactTypeEdit = ({ contactTypeId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditContactType, editContactType, fetchingUpdateContactType, updateContactType } =
        useContactTypeService();

    const [contactType, setContactType] = useState<ContactTypeDataForm>({ name: '', business_entity:'' });
    const [businessEntities, setBusinessEntities] = useState<BusinessEntity[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando tipo de contacto...');
        editContactType(contactTypeId, {
            onSuccess: (response: ServiceResponse) => {
                setBusinessEntities(response.data.business_entities);
                setContactType(response.data.contact_type);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tipo de contacto...');
        updateContactType(contactTypeId, contactType, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditContactType ? (
        <LazyLoading height="300" />
    ) : (
        <ContactTypeFormContainer
            fetching={fetchingUpdateContactType}
            action={update}
            cancel={cancel}
            contactType={contactType}
            setContactType={setContactType}
            businessEntities={businessEntities}
            errorFields={errorFields}
        />
    );
};

export default ContactTypeEdit;
