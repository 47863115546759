import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import { ContactType } from '../../../app/models/ContactType';
import { BusinessNameContactDataForm } from '../../../app/models/BusinessNameContact';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';
import BusinessNameContactForm from './forms/BusinessNameContactForm';

interface Props {
    businessNameId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BusinessNameContactCreate = ({ onSaved, onCancel, onError, businessNameId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createBusinessNameContact,
        storeBusinessNameContact,
        fetchingCreateBusinessNameContact,
        fetchingStoreBusinessNameContact
    } = useBusinessNameContactService();

    const [businessNameContact, setBusinessNameContact] = useState<BusinessNameContactDataForm>(
        {
            business_name_id: businessNameId,
            name: '',
            email: '',
            contact_type_id: 0
        }
    );

    const [contactTypes, setContactTypes] = useState<ContactType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();
  
    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createBusinessNameContact({
            onSuccess: (response: ServiceResponse) => {
         
                setContactTypes(response.data.contact_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);


    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando contacto...');
        storeBusinessNameContact(businessNameContact, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateBusinessNameContact ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BusinessNameContactForm
                        businessNameContactDataForm={businessNameContact}
                        setBusinessNameContactDataForm={setBusinessNameContact}
                        contactTypes={contactTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreBusinessNameContact}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreBusinessNameContact} />
                </div>
            </div>
        </>
    );
};

export default BusinessNameContactCreate;
