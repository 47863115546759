import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Incoterm, IncotermFilters } from '../../models/Incoterm';

const useIncotermService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetIncoterms, setFetchingGetIncoterms] = useState(false);
    const [fetchingShowIncoterm, setFetchingShowIncoterm] = useState(false);
    const [fetchingStoreIncoterm, setFetchingStoreIncoterm] = useState(false);
    const [fetchingEditIncoterm, setFetchingEditIncoterm] = useState(false);
    const [fetchingUpdateIncoterm, setFetchingUpdateIncoterm] = useState(false);
    const [fetchingDeleteIncoterm, setFetchingDeleteIncoterm] = useState(false);
    const [fetchingActiveIncoterm, setFetchingActiveIncoterm] = useState(false);
    const [fetchingCreateIncoterm, setFetchingCreateIncoterm] = useState(false);


    const getIncoterms = (IncotermFilters: IncotermFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(IncotermFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.INCOTERM.GET_INCOTERMS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetIncoterms
        });
    };

    const showIncoterm = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCOTERM.SHOW_INCOTERM.replace(':id', id.toString()),
            setFetching: setFetchingShowIncoterm
        });
    };

    const createIncoterm = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCOTERM.CREATE_INCOTERM,
            setFetching: setFetchingCreateIncoterm
        });
    };

    const storeIncoterm = (incoterm: Incoterm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INCOTERM.STORE_INCOTERM,
            body: incoterm,
            setFetching: setFetchingStoreIncoterm
        });
    };

    const editIncoterm = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCOTERM.EDIT_INCOTERM.replace(':id', id.toString()),
            setFetching: setFetchingEditIncoterm
        });
    };

    const updateIncoterm = (id: number, incoterm: Incoterm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INCOTERM.UPDATE_INCOTERM.replace(':id', id.toString()),
            body: incoterm,
            setFetching: setFetchingUpdateIncoterm
        });
    };

    const deleteIncoterm = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.INCOTERM.DELETE_INCOTERM.replace(':id', id.toString()),
            setFetching: setFetchingDeleteIncoterm
        });
    };

    const activeIncoterm = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INCOTERM.ACTIVE_INCOTERM.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveIncoterm
        });
    };

    return {
        fetchingGetIncoterms,
        fetchingShowIncoterm,
        fetchingStoreIncoterm,
        fetchingEditIncoterm,
        fetchingUpdateIncoterm,
        fetchingDeleteIncoterm,
        fetchingActiveIncoterm,
        fetchingCreateIncoterm,
        createIncoterm,
        getIncoterms,
        showIncoterm,
        storeIncoterm,
        editIncoterm,
        updateIncoterm,
        deleteIncoterm,
        activeIncoterm
    };
};

export default useIncotermService;
