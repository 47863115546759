import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import SalesInvoiceForm from './forms/SalesInvoiceForm';
import { SalesInvoicePaymentDataForm } from '../../../app/models/SalesInvoice';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    salesInvoice: any;
    setSalesInvoice: (salesInvoice: any) => void;
    availableStatuses: SalesInvoicePaymentDataForm[];  
    errorFields?: any;
    updated: boolean;
    disabled: boolean;
    societies: Society[];
    clients: Client[];
    loadingClient?: boolean;


}

const SalesInvoiceFormContainer = ({
    fetching,
    action,
    cancel,
    salesInvoice,
    setSalesInvoice,
    availableStatuses,
    updated = false,
    disabled= false,
    errorFields = null,
    societies,
    clients,
    loadingClient
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <SalesInvoiceForm
                        salesInvoice={salesInvoice}
                        setSalesInvoice={setSalesInvoice}
                        societies={societies}
                        clients={clients}
                        availableStatuses={availableStatuses}  
                        errorFields={errorFields}
                        disabled={updated}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default SalesInvoiceFormContainer;
