import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { SocietyProviderDataForm, SocietyProviderFilters } from '../../models/SocietyProvider';

const useSocietyProviderService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSocietyProvidersBySociety, setFetchingGetSocietyProvidersBySociety] = useState(false);
    const [fetchingShowSocietyProvider, setFetchingShowSocietyProvider] = useState(false);
    const [fetchingStoreSocietyProvider, setFetchingStoreSocietyProvider] = useState(false);
    const [fetchingEditSocietyProvider, setFetchingEditSocietyProvider] = useState(false);
    const [fetchingCreateSocietyProvider, setFetchingCreateSocietyProvider] = useState(false);
    const [fetchingUpdateSocietyProvider, setFetchingUpdateSocietyProvider] = useState(false);
    const [fetchingDeleteSocietyProvider, setFetchingDeleteSocietyProvider] = useState(false);
    const [fetchingActiveSocietyProvider, setFetchingActiveSocietyProvider] = useState(false);
 
    const getSocietyProvidersBySociety = (filter: SocietyProviderFilters, society_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SOCIETY_PROVIDERS.GET_SOCIETY_PROVIDERS_BY_SOCIETY}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':society_id', society_id.toString()),
            setFetching: setFetchingGetSocietyProvidersBySociety
        });
    };

    const showSocietyProvider = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.SHOW_SOCIETY_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingShowSocietyProvider
        });
    };

    const storeSocietyProvider = (society: SocietyProviderDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.STORE_SOCIETY_PROVIDER,
            body: society,
            setFetching: setFetchingStoreSocietyProvider
        });
    };

    const editSocietyProvider = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.EDIT_SOCIETY_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingEditSocietyProvider
        });
    };

    const createSocietyProvider = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.CREATE_SOCIETY_PROVIDER,
            setFetching: setFetchingCreateSocietyProvider
        });
    };

    const updateSocietyProvider = (id: number, society: SocietyProviderDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.UPDATE_SOCIETY_PROVIDER.replace(':id', id.toString()),
            body: society,
            setFetching: setFetchingUpdateSocietyProvider
        });
    };

    const deleteSocietyProvider = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.DELETE_SOCIETY_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSocietyProvider
        });
    };

    const activeSocietyProvider = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETY_PROVIDERS.ACTIVE_SOCIETY_PROVIDER.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveSocietyProvider
        });
    };

  

    return {
        fetchingGetSocietyProvidersBySociety,
        fetchingShowSocietyProvider,
        fetchingStoreSocietyProvider,
        fetchingEditSocietyProvider,
        fetchingUpdateSocietyProvider,
        fetchingDeleteSocietyProvider,
        fetchingActiveSocietyProvider,
        fetchingCreateSocietyProvider,
        getSocietyProvidersBySociety,
        showSocietyProvider,
        storeSocietyProvider,
        createSocietyProvider,
        editSocietyProvider,
        updateSocietyProvider,
        deleteSocietyProvider,
        activeSocietyProvider,
    };
};

export default useSocietyProviderService;
