  import React, { useContext, useEffect, useState } from 'react';

import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { SalesInvoiceDataForm, SalesInvoiceFilters } from '../../app/models/SalesInvoice';
import { useHistory } from 'react-router-dom';
import SalesInvoiceDataTable from './components/tables/SalesInvoiceDataTable';
import SalesInvoiceCreate from './components/SalesInvoiceCreate';
// import SalesInvoiceEdit from './components/SalesInvoiceEdit';
import useNavigationPage from '../../hooks/useNavigationPage';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useSalesInvoiceService from '../../app/services/hooks/useSalesInvoiceService';
import { SalesInvoiceStatus } from '../../app/models/SalesInvoiceStatus';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Facturas de Venta',
        url: '/sales-invoices',
        isActive: true
    }
];

const SalesInvoices = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetSalesInvoices, getSalesInvoices, deleteSalesInvoice, activeSalesInvoice } = useSalesInvoiceService();
    const [salesInvoices, setSalesInvoices] = useState<SalesInvoiceDataForm[]>([]);
    const [statuses, setStatuses] = useState<SalesInvoiceStatus[]>([]);


    const [salesInvoiceIdEdit, setSalesInvoiceIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [salesInvoiceFilter, setSalesInvoiceFilter] = useState<SalesInvoiceFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [salesInvoiceFilter]);

    const getAllSalesInvoices = () => {
        getSalesInvoices(salesInvoiceFilter, {
            onSuccess: (response: ServiceResponse) => {
                setSalesInvoices(response.data.sales_invoices);
                setStatuses(response.data.statuses)
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    // const showEdit = (salesInvoiceId: number) => {
    //     setShowingEdit(true);
    //     setSalesInvoiceIdEdit(salesInvoiceId);
    // };

    const showEdit = (id: number) => {
        navigationPage(`/sales-invoices/${id}/edit`);
    };


    const hideEdit = () => {
        setShowingEdit(false);
        setSalesInvoiceIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllSalesInvoices();
    };

    const destroy = (salesInvoiceId: number) => {
        const _text = 'Está a punto de eliminar la factura de venta #' + salesInvoiceId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando factura de venta...');
                        deleteSalesInvoice(salesInvoiceId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La factura no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Facturas de Venta" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva Factura" />}
                />
                <SalesInvoiceDataTable
                    salesInvoices={salesInvoices}
                    statuses={statuses}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetSalesInvoices}
                    filter={salesInvoiceFilter}
                    setFilter={setSalesInvoiceFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Factura"
                    backdrop={true}
                    showFooter={false}
                >
                    <SalesInvoiceCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

        </>
    );
};

export default SalesInvoices;
