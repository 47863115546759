import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Tax, TaxFilters } from '../../models/Tax';

const useTaxService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTaxes, setFetchingGetTaxes] = useState(false);
    const [fetchingShowTax, setFetchingShowTax] = useState(false);
    const [fetchingStoreTax, setFetchingStoreTax] = useState(false);
    const [fetchingEditTax, setFetchingEditTax] = useState(false);
    const [fetchingUpdateTax, setFetchingUpdateTax] = useState(false);
    const [fetchingDeleteTax, setFetchingDeleteTax] = useState(false);
    const [fetchingActiveTax, setFetchingActiveTax] = useState(false);
    const [fetchingCreateTax, setFetchingCreateTax] = useState(false);


    const getTaxes = (TaxFilters: TaxFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(TaxFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TAX.GET_TAXES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTaxes
        });
    };

    const showTax = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX.SHOW_TAX.replace(':id', id.toString()),
            setFetching: setFetchingShowTax
        });
    };

    const storeTax = (tax: Tax, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TAX.STORE_TAX,
            body: tax,
            setFetching: setFetchingStoreTax
        });
    };

    const editTax = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX.EDIT_TAX.replace(':id', id.toString()),
            setFetching: setFetchingEditTax
        });
    };

    const createTax = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX.CREATE_TAX,
            setFetching: setFetchingCreateTax
        });
    };

    const updateTax = (id: number, tax: Tax, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TAX.UPDATE_TAX.replace(':id', id.toString()),
            body: tax,
            setFetching: setFetchingUpdateTax
        });
    };

    const deleteTax = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TAX.DELETE_TAX.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTax
        });
    };

    const activeTax = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TAX.ACTIVE_TAX.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveTax
        });
    };

    return {
        fetchingGetTaxes,
        fetchingShowTax,
        fetchingStoreTax,
        fetchingEditTax,
        fetchingUpdateTax,
        fetchingDeleteTax,
        fetchingActiveTax,
        fetchingCreateTax,
        getTaxes,
        showTax,
        storeTax,
        editTax,
        updateTax,
        deleteTax,
        activeTax,
        createTax,
    };
};

export default useTaxService;
