import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useSocietyClientService from '../../../app/services/hooks/useSocietyClientService';
import { SocietyClientDataForm } from '../../../app/models/SocietyClient';
import { ClientContact } from '../../../app/models/ClientContact';
import { Client } from '../../../app/models/Client';
import SocietyClientForm from './forms/SocietyClientForm';
import useClientContactService from '../../../app/services/hooks/useClientContactService';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';

interface Props {
    societyId: number;
    societyClientId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietyClientEdit = ({ societyId, societyClientId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditSocietyClient, editSocietyClient, fetchingUpdateSocietyClient, updateSocietyClient } =
        useSocietyClientService();

    const {
        fetchingGetBusinessNameContactsByClient,
        getBusinessNameContactByClient
    } = useBusinessNameContactService()

    const [societyClient, setSocietyClient] = useState<SocietyClientDataForm>(
        {
            client_id: 0,
            account_number: ''
        }
    )
    const [societyClientFirst, setSocietyClientFirst] = useState<SocietyClientDataForm>(
        {
            client_id: 0,
            account_number: ''
        }
    )
    const clientIdSelected = useRef<number | undefined>(0);

    const [clientContacts, setClientContacts] = useState<ClientContact[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando datos cliente...');
        editSocietyClient(societyClientId, {
            onSuccess: (response: ServiceResponse) => {

                const clients = response.data.clients.map((client: Client) => {
                    return {
                      ...client,
                      account_number: `${societyId}-${client.business_name_id}`
                    };
                  });

                setClients(clients);
                setClientContacts(response.data.client_contacts);
                clientIdSelected.current = response.data.society_client.client_id;
                setSocietyClient(response.data.society_client);
                setSocietyClientFirst(response.data.society_client);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {

        if (societyClient.client_id == undefined) {
            clientIdSelected.current = undefined;
        }

        if (societyClient.client_id && societyClient.client_id != clientIdSelected.current) {

            getBusinessNameContactByClient(societyClient.client_id, {
                    onSuccess: (response: ServiceResponse) => {

                        clientIdSelected.current = societyClient.client_id ?? 0;
                        setErrorFields(undefined);

                        setClientContacts(response.data.client_contacts);

                        if(societyClient.client_id == societyClientFirst.client_id){

                            setSocietyClient(
                                {
                                    ...societyClient,
                                    business_name_contact_id: societyClientFirst.business_name_contact_id ?? undefined,
                                    account_number: societyClientFirst.account_number
                                }
                            )

                        }else{
                            setSocietyClient(
                                {
                                    ...societyClient,
                                    business_name_contact_id: undefined
                                }
                            )
                        }
                        
                       
                    },
                    onError: (response: ServiceResponse) => {
                        setSocietyClient({
                            ...societyClient,
                            client_id: clientIdSelected.current
                        });
                    }
                })
                
            }
    }, [societyClient.client_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando información de cliente...');
        updateSocietyClient(societyClientId, societyClient, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditSocietyClient ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SocietyClientForm
                        societyClientForm={societyClient}
                        setSocietyClientForm={setSocietyClient}
                        clients={clients}
                        clientContacts={clientContacts}
                        errorFields={errorFields}
                        loadingClientContact={fetchingGetBusinessNameContactsByClient}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateSocietyClient}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateSocietyClient} />
                </div>
            </div>
        </>
    );
};

export default SocietyClientEdit;
