import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import BankAccountReasonForm from './forms/BankAccountReasonForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    bankAccountReason: BankAccountReason;
    setBankAccountReason: (bankAccountReason: BankAccountReason) => void;
    errorFields?: any;
}

const BankAccountReasonFormContainer = ({
    fetching,
    action,
    cancel,
    bankAccountReason,
    setBankAccountReason,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BankAccountReasonForm
                        bankAccountReasonDataForm={bankAccountReason}
                        setBankAccountReasonDataForm={setBankAccountReason}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default BankAccountReasonFormContainer;
