import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import SalesInvoiceFormContainer from './SalesInvoiceFormContainer';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { SalesInvoiceDataForm, SalesInvoicePaymentDataForm } from '../../../app/models/SalesInvoice';
import useSalesInvoiceService from '../../../app/services/hooks/useSalesInvoiceService';
import { SalesInvoiceDetail } from '../../../app/models/SalesInvoiceDetail';
import { SalesInvoiceHistoryDataForm } from '../../../app/models/SalesInvoiceHistory';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';


interface Props {
    salesInvoiceId: number;
    salesInvoiceDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    setSalesInvoiceDetailTable: (salesInvoiceDetail: SalesInvoiceDetail[]) => void;
    reloadSalesInvoiceTable: () => void;
    setSalesInvoiceHistoryTable: (salesInvoiceHistory: SalesInvoiceHistoryDataForm[]) => void;
    // reloadSalesInvoiceHistoryTable: () => void;
}

const SalesInvoicesEdit = ({ 
    salesInvoiceId, 
    salesInvoiceDetailId,
    onSaved,
    onCancel,
    onError 
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { editSalesInvoice, updateSalesInvoice, fetchingEditSalesInvoice } = useSalesInvoiceService();
    const [salesInvoice, setSalesInvoice] = useState<SalesInvoiceDataForm>({});
    const [errorFields, setErrorFields] = useState<any>();
    const [paymentStatuses, setPaymentStatuses] = useState<SalesInvoicePaymentDataForm[]>([]); 
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);

    // Payment statuses


    useEffect(() => {
        reloadModule();
    }, [salesInvoiceId]);


    const edit = (salesInvoiceId: number) => {
        if (showLoading) showLoading('loading', 'Cargando factura de venta...');
        editSalesInvoice(salesInvoiceId, {
            onSuccess: (response: ServiceResponse) => {
                setSalesInvoice(response.data.sales_invoice);
                setPaymentStatuses(response.data.payment_statuses)
                setSocieties(response.data.societies);
                setClients(response.data.clients)

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando factura de venta...');
        updateSalesInvoice(salesInvoiceId!, salesInvoice, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
                setErrorFields(response.data);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        }, false); // Pasa `false` como cuarto argumento
    };
    
    const reloadModule = () => {
        edit(salesInvoiceId);
    };


    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditSalesInvoice ? (
        <div>Cargando...</div>
    ) : (
        <SalesInvoiceFormContainer
            action={update}
            cancel={cancel}
            salesInvoice={salesInvoice}
            setSalesInvoice={setSalesInvoice}
            societies={societies}
            clients={clients}
            errorFields={errorFields}
            availableStatuses={paymentStatuses}
            updated={true}
            disabled={true}
        />
        
        
    );
};

export default SalesInvoicesEdit;
