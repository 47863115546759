import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import ClasificationForm from './form/ClasificationForm';
import ClasificationTwoForm from './form/ClasificationTwoForm';
import ClasificationThreeForm from './form/ClasificationThreeForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { defaultClasificationDataForm, Clasification } from '../../../app/models/Clasification';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useClasificationService from '../../../app/services/hooks/useClasificationService';

interface Props {
    type: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ClasificationCreate: React.FC<Props> = ({ type, onSaved, onCancel, onError }) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const history = useHistory();
    const { navigationPage } = useNavigationPage();


    const [clasifs, setClasifs] = useState<Clasification[]>([]);
    const [clasification, setClasification] = useState<Clasification>(defaultClasificationDataForm);
    const [clasifications, setClasifications] = useState<Clasification[]>([]);
    const [clasificationsTwo, setClasificationsTwo] = useState<Clasification[]>([]);
    const [clasificationsThree, setClasificationsThree] = useState<Clasification[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingCreateClasification, fetchingStoreClasification, storeClasification, createClasification } = useClasificationService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createClasification({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setClasifications(response.data.clasifs);
                setClasificationsTwo(response.data.clasifs_two);
                setClasificationsThree(response.data.clasifs_three);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando clasificación...');
        storeClasification(clasification, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    const renderForm = () => {
        switch (type) {
            case 1:
                return (
                    <ClasificationForm
                        clasificationDataForm={clasification}
                        setClasificationsDataForm={setClasification}
                        errorFields={errorFields}
                        type={type}
                    />
                );
            case 2:
                return (
                    <ClasificationTwoForm
                        clasificationDataForm={clasification}
                        setClasificationsDataForm={setClasification}
                        errorFields={errorFields}
                        clasifications={clasifications}
                        type={type}
                    />
                );
            case 3:
                return (
                    <ClasificationThreeForm
                        clasificationDataForm={clasification}
                        setClasificationsDataForm={setClasification}
                        errorFields={errorFields}
                        clasifications={clasifications}
                        clasificationsTwo={clasificationsTwo}
                        type={type}
                    />
                );
            default:
                return null;
        }
    };

    return fetchingCreateClasification ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    {renderForm()}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreClasification} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreClasification} />
                </div>
            </div>
        </>
    );
};

export default ClasificationCreate;
