import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NotificationDropdown from '../components/NotificationDropdown';
import useAuthService from '../../../app/services/hooks/useAuthService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import origen_sidebar from '../../../assets/images/op1_horizontal-azul.png';
import PasswordProfile from '../../../pages/Profile/EditPassword';


const MenuTop = () => {
    const { showingMainMenu, showMainMenu, hideMainMenu } = useContext(AppContext);
    const { auth, logout } = useContext(AuthContext);

    const [showingPasswordEdit, SetShowingPasswordEdit] = useState(false);
    const { fetchingLogoutAuth, logoutAuth } = useAuthService();

    const showPasswordEdit = () => {
        SetShowingPasswordEdit(true);
    };

    const hidePasswordEdit = () => {
        SetShowingPasswordEdit(false);
    };

    // const handleMenu = () => {
    //     if (showingMainMenu) {
    //         if (hideMainMenu) {
    //             hideMainMenu();
    //         }
    //     } else {
    //         if (showMainMenu) {
    //             showMainMenu();
    //         }
    //     }

    //     const body = document.body;
    //     if (window.screen.width <= 998) {
    //         body.classList.toggle('sidebar-enable');
    //     } else {
    //         body.classList.toggle('vertical-collapsed');
    //         body.classList.toggle('sidebar-enable');
    //     }
    // };

    const show = () => {
        if (showMainMenu) {
            showMainMenu();
        }
    };

    useEffect(() => {
        const body = document.body;
        body.classList.toggle('vertical-collapsed', !showingMainMenu);
        body.classList.toggle('sidebar-enable', showingMainMenu);
    }, [showingMainMenu]);

    const logoutApp = () => {
        const _html = '<p>¿Desea cerrar la sesión?</p>';

        useSweetAlert().requestConfirmation({
            title: 'Desconexión del sistema',
            html: _html,
            text: '',
            confirmButtonText: 'Si, cerrar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                goLogout();
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El cierre de sesión ha sido cancelado.'
                });
            }
        });
    };

    const goLogout = () => {
        useSweetAlert().spinnerAlert('Cerrando sesión...');
        logoutAuth({
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                if (logout) logout();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
            }
        });
    };

    return (
    
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        {!showingMainMenu && (
                            <button
                                type="button"
                                onClick={show}
                                className="btn btn-sm px-3 font-size-16 header-item "
                                id="vertical-menu-btn"
                            >
                                <i className="fa fa-fw fa-bars" />
                            </button>
                        )}
                        <div className="navbar-brand-box" style={{ backgroundColor: '#ffffff' }}>
                            <Link to="/" className="logo logo-dark">
                                <span className="">
                                    <img src={origen_sidebar} alt="" height="70" />
                                </span>
                            </Link>

                            <Link to="/" className="">
                                <span className="">
                                    <img src={origen_sidebar} alt="" height="70" />
                                </span>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <NotificationDropdown />
                        <Dropdown className="d-inline-block menu-auth-top">
                            <Dropdown.Toggle
                                className="btn header-item "
                                id="page-header-user-dropdown"
                            >
                                <img
                                    className="rounded-circle header-profile-user"
                                    src={
                                        auth?.avatar
                                            ? auth.avatar
                                            : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                                    }
                                />
                                <span className="d-none d-xl-inline-block ms-2 me-1">
                                    {auth?.firstName}
                                </span>
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-end">
                                <Dropdown.Item tag="a" href="/">
                                    <i className="bx bxs-dashboard font-size-16 align-middle me-1" />
                                    Dashboard
                                </Dropdown.Item>
                                <Dropdown.Item
                                        // style={{ color: '#CD0204' }}
                                        tag="a"
                                        onClick={showPasswordEdit}
                                    >
                                        <i className="bx bxs-lock-open font-size-16 align-middle me-1" />
                                        <span>Editar contraseña</span>
                                </Dropdown.Item>



                                {/*<Dropdown.Item tag="a" href="#">*/}
                                {/*    <span className="badge bg-success float-end">11</span>*/}
                                {/*    <i className="bx bx-wrench font-size-16 align-middle me-1"/>*/}
                                {/*    Settings*/}
                                {/*</Dropdown.Item>*/}
                                <div className="dropdown-divider" />
                                <div onClick={logoutApp} className="dropdown-item pointer">
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                                    Salir
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </header>
            {showingPasswordEdit && (
                <PasswordProfile
                    showingPasswordEdit={showingPasswordEdit}
                    hidePasswordEdit={hidePasswordEdit}
                />
            )}
        </>
    );
};

export default MenuTop;
