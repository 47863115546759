import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { formatAmount, formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import LazyLoading from '../../../../components/LazyLoading';
import { Batch } from '../../../../app/models/Batch';
import { ServiceManifestDetailDataForm } from '../../../../app/models/ServiceManifestDetail';
import { AccountingDataForm } from '../../../../app/models/Accounting';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { Account } from '../../../../app/models/Account';
import { AccountingStatuses, Items } from '../../../../app/shared/enums';
import { Tax } from '../../../../app/models/Tax';

interface Props {
    accountingDataForm: AccountingDataForm;
    setAccountingDataForm: (accountingDataForm: AccountingDataForm) => void;
    serviceManifestDetail: ServiceManifestDetailDataForm;
    accounts: Account[];
    errorFields?: any;
    isShow?: boolean;
    itemType: string;
}

const AccountingForm = ({
    accountingDataForm,
    setAccountingDataForm,
    serviceManifestDetail,
    errorFields,
    accounts,
    isShow = false,
    itemType
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setAccountingDataForm({ ...accountingDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setAccountingDataForm({ ...accountingDataForm, ...obj });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setAccountingDataForm({ ...accountingDataForm, ['file']: e[0] });
        }
    };

    const handleRemoveFile = (name: string) => {
        setAccountingDataForm({ 
            ...accountingDataForm, 
            [name]: undefined 
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    if (!accountingDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedad</label>
                    <input
                        type="text"
                        name="society"
                        id="society"
                        className="form-control"
                        value={serviceManifestDetail.society}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedor</label>
                    <input
                        type="text"
                        name="provider"
                        id="provider"
                        className="form-control"
                        value={serviceManifestDetail.provider}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>

            {itemType == Items.PRODUCT && (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Producto</label>
                            <input
                                type="text"
                                name="product"
                                id="product"
                                className="form-control"
                                value={serviceManifestDetail.product}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('product_id')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Lote</label>
                            <input
                                type="text"
                                name="batch"
                                id="batch"
                                className="form-control"
                                value={serviceManifestDetail.batch}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('batch_id')}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {itemType == Items.SERVICE && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Servicio</label>
                        <input
                            type="text"
                            name="service"
                            id="service"
                            className="form-control"
                            value={serviceManifestDetail.service}
                            disabled={true}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('service_id')}
                        </div>
                    </div>
                </div>
            )}

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Precio</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="price"
                                id="price"
                                value={serviceManifestDetail.price}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('price')}
                            </div>
                        </div>

                        <div className="col-3">
                            <input
                                className="form-control"
                                type="text"
                                name="currency_price"
                                id="currency_price"
                                value={serviceManifestDetail.currency_price}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_price_id')}
                            </div>
                        </div>

                        <div className="col-3">
                            <input
                                className="form-control"
                                type="text"
                                name="unit_price"
                                id="unit_price"
                                value={serviceManifestDetail.unit_price}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_price_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Cantidad</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="quantity"
                                id="quantity"
                                value={serviceManifestDetail.quantity}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity')}
                            </div>
                        </div>
                        {itemType == Items.PRODUCT && (
                            <div className="col-6">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="unit"
                                    id="unit"
                                    value={serviceManifestDetail.unit}
                                    disabled={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('unit_id')}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="mb-2 row g-1">
                <label className="col-form-label">Valor neto</label>

                <div className="col-md-4">
                    <input
                        type="text"
                        name="gross_total"
                        id="gross_total"
                        className="form-control"
                        value={formatMoney(serviceManifestDetail.gross_total ?? 0)}
                        disabled={true}
                    />
                </div>
                <div className="col-md-2">
                    <input
                        type="text"
                        name="currency_gross_total"
                        id="currency_gross_total"
                        className="form-control"
                        value={serviceManifestDetail.currency_price}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <div>
                        <label className="col-form-label">IMPUESTOS</label>

                        {serviceManifestDetail.json_tax ? (
                            <div className="">
                                {serviceManifestDetail.json_tax.map((tax: Tax, index: number) => (
                                    <React.Fragment key={index}>
                                        <div className="row g-1">
                                            <label className="col-form-label">{tax.name}</label>
                                            <div className="col-4">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={formatMoney(
                                                        Number(serviceManifestDetail.price) *
                                                            Number(tax.value) *
                                                            Number(serviceManifestDetail.quantity)
                                                    )}
                                                    disabled={true}
                                                />
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: 'flex' }}
                                                >
                                                    {fieldErrorMessage('price')}
                                                </div>
                                            </div>
                                            {/*<div className="col-3">*/}
                                            {/*    <input*/}
                                            {/*        className="form-control"*/}
                                            {/*        type="text"*/}
                                            {/*        name="price"*/}
                                            {/*        id="price"*/}
                                            {/*        value={tax.name}*/}
                                            {/*        disabled={true}*/}
                                            {/*    />*/}
                                            {/*    <div*/}
                                            {/*        className="invalid-feedback"*/}
                                            {/*        style={{ display: 'flex' }}*/}
                                            {/*    >*/}
                                            {/*        {fieldErrorMessage('price')}*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-2">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={serviceManifestDetail.currency_price}
                                                    disabled={true}
                                                />
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: 'flex' }}
                                                >
                                                    {fieldErrorMessage('currency_price_id')}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment> // Cierra correctamente el fragmento aquí
                                ))}
                            </div>
                        ) : (
                            <div className="fs-6">No tiene impuestos asignados.</div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Precio total</label>
                    <input
                        type="text"
                        name="tax_total"
                        id="tax_total"
                        className="form-control"
                        value={formatMoney(serviceManifestDetail.tax_total ?? 0)}
                        disabled={true}
                    />
                </div>
            </div>

            <hr />
            {itemType == Items.SERVICE && (
                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-md-12 col-form-label">Documento</label>
                        {serviceManifestDetail.status_text == AccountingStatuses.ACCOUNTED ||
                        isShow ? (
                            accountingDataForm.file ? (
                                <div>
                                    <a
                                        href={String(accountingDataForm.file)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-success btn-sm"
                                        role="button"
                                        aria-pressed="true"
                                    >
                                        Documento
                                    </a>
                                </div>
                            ) : (
                                <div>
                                    <p>No tiene documento</p>
                                </div>
                            )
                        ) : (
                            <>
                                <InputButtonFile
                                    name={'file'}
                                    objectFile={accountingDataForm.file}
                                    handleChangeDocumentFile={handleChangeDocumentFile}
                                    handleRemoveFile={(value: any) => handleRemoveFile('file')}
                                    onFocus={() => onFocusRemove('file')}
                                />

                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('file')}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Fecha Contabilización</label>

                    <input
                        type="date"
                        className={`form-control ${fieldHasError('issue_date')}`}
                        name="issue_date"
                        id="issue_date"
                        value={accountingDataForm.issue_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('issue_date')}
                        disabled={
                            serviceManifestDetail.status_text == AccountingStatuses.ACCOUNTED ||
                            isShow
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
                
            </div>

            <div className="col-md-12">
                <label className="col-form-label">Cuentas contables</label>
                <SelectTwo
                    name="groups"
                    id="groups"
                    inputValue={accountingDataForm.accounts}
                    options={SelectTwoMapperOptions(accounts!)}
                    hasError={fieldHasError('groups') !== ''}
                    onChange={(value: any) =>
                        setAccountingDataForm({
                            ...accountingDataForm,
                            accounts: value
                        })
                    }
                    isMulti={true}
                    placeholder={'Seleccione c. contable'}
                    onFocus={() => onFocusRemove('accounts')}
                    disabled={
                        serviceManifestDetail.status_text == AccountingStatuses.ACCOUNTED || isShow
                    }
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('accounts')}
                </div>
            </div>
        </>
    );
};

export default AccountingForm;
