import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import { BusinessNameBankAccountDataForm } from '../../../app/models/BusinessNameBankAccount';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Bank } from '../../../app/models/Bank';
import BusinessNameBankAccountForm from './forms/BusinessNameBankAccountForm';

interface Props {
    businessNamebankAccountId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BusinessNameBankAccountEdit = ({ businessNamebankAccountId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { 
        fetchingEditBusinessNameBankAccount, 
        editBusinessNameBankAccount, 
        fetchingUpdateBusinessNameBankAccount, 
        updateBusinessNameBankAccount 
    } = useBusinessNameBankAccountService();

    const [businessNamebankAccount, setBusinessNameBankAccount] = useState<BusinessNameBankAccountDataForm>(
        { 
            bank_id: 0,
            bank_account_reason_id: 0,
            account_type_id: 0,
            account_number: '',
        }
    );
    const [bankAccountReasons, setBankAccountReasons] = useState<BankAccountReason[]>([])
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([])
    const [banks, setBanks] = useState<Bank[]>([])

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando cuenta bancaria...');
        editBusinessNameBankAccount(businessNamebankAccountId, {
            onSuccess: (response: ServiceResponse) => {
                setBankAccountReasons(response.data.bank_account_reasons);
                setBankAccountTypes(response.data.account_types);
                setBanks(response.data.banks);
                setBusinessNameBankAccount(response.data.bank_account);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando cuenta bancaria...');
        updateBusinessNameBankAccount(businessNamebankAccountId, businessNamebankAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditBusinessNameBankAccount ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BusinessNameBankAccountForm
                        businessNameBankAccountDataForm={businessNamebankAccount}
                        setBusinessNameBankAccountDataForm={setBusinessNameBankAccount}
                        bankAccountReasons={bankAccountReasons} 
                        bankAccountTypes={bankAccountTypes} 
                        banks={banks}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateBusinessNameBankAccount}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateBusinessNameBankAccount} />
                </div>
            </div>
        </>
    );
};

export default BusinessNameBankAccountEdit;
