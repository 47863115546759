import React, { useContext, useEffect, useState } from 'react';

import { DailyCarrier, DailyCarrierFilters } from '../../app/models/DailyCarrier';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useDailyCarrierService from '../../app/services/hooks/useDailyCarrierService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import DailyCarrierCreate from './components/DailyCarrierCreate';
import DailyCarrierEdit from './components/DailyCarrierEdit';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import DailyCarrierDataTable from './components/DailyCarrierDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Transportes',
        url: '/daily-carriers',
        isActive: true
    }
];

const DailyCarriers = () => {
    const { navigationPage } = useNavigationPage();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetDailyCarriers, getDailyCarriers, deleteDailyCarrier } =
        useDailyCarrierService();
    const [DailyCarriers, setDailyCarriers] = useState<DailyCarrier[]>([]);

    const [dailyCarrierIdEdit, setDailyCarrierIdEdit] = useState<number>(-1);
    const [dailyCarrierIdShow, setDailyCarrierIdShow] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [totalRows, setTotalRows] = useState(0);

    const [filter, setFilter] = useState<DailyCarrierFilters>({
        // daily_carrier_id: null,
        // provider: '',
        // carrier: '',
        // truck: '',
        // hopper: '',
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getDailyCarriers(filter, {
            onSuccess: (response: ServiceResponse) => {
                setDailyCarriers(response.data.daily_carriers);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showEdit = (DailyCarrierId: number) => {
        setShowingEdit(true);
        setDailyCarrierIdEdit(DailyCarrierId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setDailyCarrierIdEdit(-1);
    };

    const showShow = (DailyCarrierId: number) => {
        setShowingShow(true);
        setDailyCarrierIdShow(DailyCarrierId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setDailyCarrierIdShow(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const onError = () => {
        setShowingEdit(false);
        setDailyCarrierIdEdit(-1);
    };

    const destroy = (DailyCarrierId: number) => {
        const _text = 'Está a punto de eliminar el transporte #' + DailyCarrierId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando transporte...');
                        deleteDailyCarrier(DailyCarrierId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El transporte no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Transportes" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo Transporte" />}
                />

                <DailyCarrierDataTable
                    dailyCarriers={DailyCarriers}
                    totalRows={totalRows}
                    edit={showEdit}
                    destroy={destroy}
                    // show={showShow}
                    loading={fetchingGetDailyCarriers}
                    filter={filter}
                    setFilter={setFilter}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <DailyCarrierCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}

                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <DailyCarrierEdit
                        dailyCarrierId={dailyCarrierIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideEdit}
                    title="Editar Transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <DailyCarrierEdit
                        dailyCarrierId={dailyCarrierIdEdit}
                        onSaved={() => {
                            toast.success('Transporte Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default DailyCarriers;
