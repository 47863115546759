import React, { useEffect, useState } from 'react';
import { Provider } from '../../../app/models/Provider';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/SelectTwo';
type Props = {
    Provider: Provider;
    setProvider: (Provider: Provider) => void;
    errorFields?: any;
    providerTypes?: any[];
    nextId?: number; 
};

const ProviderForm = ({ Provider, setProvider, providerTypes, errorFields, nextId = 0 }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();
    const [tempRut, setTempRut] = useState<string | null>(null);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (Provider.is_national === undefined) {
            setProvider({ ...Provider, is_national: true });
        }
    }, []);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if (name == 'rut') {
            setProvider({ ...Provider, [name]: formatRut(value) });
        } else {
            setProvider({ ...Provider, [name]: value });
        }
    };

    const handleCheckChange = () => {
        const isNational = !Provider.is_national;
        const updatedProvider = { ...Provider, is_national: isNational };
        if (!isNational) {
            setTempRut(Provider.rut); // Save the current RUT before clearing it
            updatedProvider.rut = null;
        } else if (tempRut !== null) {
            updatedProvider.rut = tempRut; // Restore the saved RUT
        }
        setProvider(updatedProvider);
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setProvider({ ...Provider, [name]: value });
    };

    const [image, setImage] = useState(
        Provider.logo
            ? Provider.logo
            : 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg'
    );

    return (
        <>
                        <div className="col-md-12 text-center d-flex">
                            <div className="mx-auto mb-3">
                                <label htmlFor="logo">
                                    <img
                                        src={
                                            typeof image === 'string' && image !== ''
                                                ? image
                                                : window.URL.createObjectURL(image as Blob)
                                        }
                                        className="rounded-circle pointer bg-white"
                                        style={{
                                            width: '150px',
                                            height: '150px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </label>
                            </div>
                       
                        </div>
                        <div className="mb-2 row needs-validation">
                            <label className="col-form-label">Origen</label>
                            <div className="col-md-12">
                                <input
                                    name="is_national"
                                    id="is_national"
                                    type="text"
                                    className={`form-control ${fieldHasError('is_national')}`}
                                    value={Provider.is_national?"Nacional": "Extranjero"}
                                    readOnly={true}
                                />
                            </div>
                    </div>
                       
                    <div className="mb-2 row needs-validation">
                        <label className="col-form-label">Nombre</label>
                        <div className="col-md-12">
                            <input
                                name="name"
                                id="name"
                                type="text"
                                className={`form-control ${fieldHasError('name')}`}
                                value={Provider.name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('name')}
                                placeholder="Ingrese el nombre del proveedor"
                                readOnly={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('name')}
                            </div>
                        </div>
                    </div>

                    {Provider.is_national && 
                        <div className="mb-2 row">
                            <label className="col-form-label">Rut</label>
                            <div className="col-md-12">
                                <input
                                    name="rut"
                                    id="rut"
                                    type="text"
                                    className="form-control"
                                    value={Provider.rut || ''}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('rut')}
                                    placeholder="Ingrese el RUT del proveedor"
                                    readOnly={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('rut')}
                                </div>
                            </div>
                        </div>
                    }

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Email</label>
                    <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={Provider.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese email"
                        readOnly={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>               
            </div>
            <div className='mb-2 row"'>
                <div className="col-md-12">
                    <label className="col-form-label">Dirección</label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className="form-control"
                        value={Provider.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese domicilio"
                        readOnly={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>               
            </div>
            <div className='mb-2 row'>
                <div className="col-md-12">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                onChange={handleChange}
                                value={Provider.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                                disabled={true}
                            >
                                <option value="">--</option>
                                <option value="+56">+56</option>
                            </select>
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone_code')}
                            </div>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                className="form-control"
                                value={Provider.phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                                readOnly={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                    <div className="mb-2 row needs-validation">
                        <label className="col-form-label">Tipo Proveedor</label>
                        <div className="col-md-12">
                            <SelectTwo
                                name="provider_type_id"
                                inputValue={Provider.provider_type_id}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('provider_type_id', value.value)
                                }
                                options={SelectTwoMapperOptions(providerTypes ?? [])}
                                placeholder="Seleccione el tipo de proveedor"
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('provider_type_id')}
                            </div>
                        </div>
                    </div>
        </>
    );
};

export default ProviderForm;
