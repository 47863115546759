import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { DispatchProgramDocument } from '../../models/DispatchProgramDocument';

const useDispatchProgramDocumentService = () => {
    const { doGet, doPost } = useFetch();

    const [fetchingGetDispatchProgramDocument, setFetchingGetDispatchProgramDocument] =
        useState(false);
    const [fetchingUpdateDispatchProgramDocument, setFetchingUpdateDispatchProgramDocument] =
        useState(false);

    const getDispatchProgramDocument = (
        dispatch_program_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAM_DOCUMENTS.GET_DISPATCH_PROGRAM_DOCUMENTS.replace(
                ':dispatch_program_id',
                dispatch_program_id.toString()
            ),
            setFetching: setFetchingGetDispatchProgramDocument
        });
    };

    const updateDispatchProgramDocument = (
        dispatchProgramDocumentId: number,
        dispatchProgramDocument: DispatchProgramDocument[],
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAM_DOCUMENTS.UPDATE_DISPATCH_PROGRAM_DOCUMENTS.replace(
                ':dispatch_program_id',
                dispatchProgramDocumentId.toString()
            ),
            body: dispatchProgramDocument,
            setFetching: setFetchingUpdateDispatchProgramDocument
        });
    };

    return {
        fetchingGetDispatchProgramDocument,
        fetchingUpdateDispatchProgramDocument,
        getDispatchProgramDocument,
        updateDispatchProgramDocument
    };
};

export default useDispatchProgramDocumentService;
