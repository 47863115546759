import React, { useEffect, useState } from 'react';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import { DispatchProgramItemShowDataForm } from '../../../app/models/DispatchProgramItem';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DispatchProgramItemShowForm from './forms/DispatchProgramItemShowForm';
import LazyLoading from '../../../components/LazyLoading';

interface Props {
    dispatchProgramItemId: number;
    onError?: (message?: string) => void;
}

const DispatchProgramItemShow = ({ dispatchProgramItemId, onError }: Props) => {
    const { fetchingShowDispatchProgramItem, showDispatchProgramItem } =
        useDispatchProgramItemService();

    const [dispatchProgramItemShowDataForm, setDispatchProgramItemShowDataForm] =
        useState<DispatchProgramItemShowDataForm>({});

    useEffect(() => {
        if (dispatchProgramItemId) {
            show();
        }
    }, [dispatchProgramItemId]);

    const show = () => {
        showDispatchProgramItem(dispatchProgramItemId, {
            onSuccess: (response: ServiceResponse) => {
                setDispatchProgramItemShowDataForm(response.data.dispatch_program_item);
            },
            onError: (response: ServiceResponse) => {
                onError && onError(response.message);
            }
        });
    };

    return !fetchingShowDispatchProgramItem ? (
        <DispatchProgramItemShowForm
            dispatchProgramItemShowDataForm={dispatchProgramItemShowDataForm}
        />
    ) : (
        <LazyLoading height={300} />
    );
};

export default DispatchProgramItemShow;
