import React, { useContext } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import ActiveControl from '../../../components/ActiveControl';
import usePackingFormService from '../../../app/services/hooks/usePackingFormService';

interface Props {
    packingFormId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const PackingFormSwitchChange = ({ packingFormId, checkValue, callbackSuccess }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [checked, setChecked] = React.useState<boolean>(checkValue);

    const { activePackingForm, fetchingActivePackingForm } = usePackingFormService();

    const active = (value: boolean) => {
        setChecked(value);

        activePackingForm(packingFormId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={packingFormId} callback={active} />;
};

export default PackingFormSwitchChange;
