import React, { useContext, useEffect, useState } from 'react';

import { defaultPlace, Place } from '../../../app/models/Place';
import usePlaceService from '../../../app/services/hooks/usePlaceService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import PlaceFormContainer from './PlaceFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { AppContext } from '../../../contexts/AppContext';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PlaceCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { fetchingStorePlace, storePlace, createPlace, fetchingCreatePlace } = usePlaceService();
    const [place, setPlace] = useState<Place>(defaultPlace);

    const [errorFields, setErrorFields] = useState<any>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);



    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createPlace({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando origen/destino...', 400);
        storePlace(place, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreatePlace ? (
        <LazyLoading height={300} />
    ) : (
        <PlaceFormContainer
            fetching={fetchingStorePlace}
            action={store}
            cancel={cancel}
            place={place}
            setPlace={setPlace}
            errorFields={errorFields}
        />
    );
};

export default PlaceCreate;
