import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useProviderContactService from '../../../app/services/hooks/useProviderContactService';
import { ProviderContactDataForm } from '../../../app/models/ProviderContact';
import { ContactType } from '../../../app/models/ContactType';
import ProviderContactForm from './forms/ProviderContactForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

interface Props {
    providerContactId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderContactEdit = ({ providerContactId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditProviderContact, editProviderContact, fetchingUpdateProviderContact, updateProviderContact } =
        useProviderContactService();

    const [providerContact, setProviderContact] = useState<ProviderContactDataForm>(
        { 
            name: '', 
            email: '', 
            contact_type_id: 0 
        }
    );
    const [contactTypes, setContactTypes] = useState<ContactType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando moneda...');
        editProviderContact(providerContactId, {
            onSuccess: (response: ServiceResponse) => {
                setContactTypes(response.data.contact_types);
                setProviderContact(response.data.provider_contact);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando contacto proveedor...');
        updateProviderContact(providerContactId, providerContact, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditProviderContact ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ProviderContactForm
                        providerContactDataForm={providerContact}
                        setProviderContactDataForm={setProviderContact}
                        contactTypes={contactTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateProviderContact}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateProviderContact} />
                </div>
            </div>
        </>
    );
};

export default ProviderContactEdit;
