export const centeredHeaderStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    whiteSpace: 'pre-line',
    textAlign: 'center',
};

export const createCenteredHeader = (headerText: string) => {
    const lines = headerText.split(' ');
    return (
        <div style={centeredHeaderStyle}>
            {lines.map((line, index) => (
                <span key={index}>{line}</span>
            ))}
        </div>
    );
};