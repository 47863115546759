import React, { useContext } from 'react';
import {
    TransportationOrderItemDataForm,
    TransportationOrderItemsFilters
} from '../../../../app/models/TransportationOrderItem';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
//import TransportationOrderFilter from '../TransportationOrderFilter'
import { formatMoney, formatNumber } from '../../../../utils/utils';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Roles } from '../../../../app/shared/enums';
import { momentParseDate } from '../../../../helpers';
import TransportationSubOrderItemChangeStatus from '../TransportationOrderItemChangeStatus';
import { TransportationOrderItemStatus } from '../../../../app/models/TransportationOrderItemStatus';

interface Props {
    transportationOrderItems: TransportationOrderItemDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (transportationOrderId: number) => void;
    destroy?: (transportationOrderId: number) => void;
    filter?: TransportationOrderItemsFilters;
    setFilter: (filter: TransportationOrderItemsFilters) => void;
    paginationRowsPerPageOptions?: any[];
    show?: (quoteId: number) => void;
    changeStatus: boolean;
    statuses: TransportationOrderItemStatus[];
    callbackSuccess?: () => void;
    reloadModule?: () => void;
    canBeEdited?: boolean;
}

const TransportationOrderItemsDataTable = ({
    transportationOrderItems,
    totalRows,
    loading = false,
    edit,
    destroy,
    filter,
    setFilter,
    show,
    changeStatus = false,
    statuses,
    callbackSuccess,
    reloadModule,
    canBeEdited
}: Props) => {
    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Posición',
            selector: (row: any) => row.position,
            sortable: true,
            sortField: 'position',
            cell: (row: any, index: any, column: any) => <span className="">{row.position}</span>
        },
        {
            name: 'Producto',
            selector: (row: any) => row.product_name,
            sortable: true,
            sortField: 'product',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.product_name}</span>
            )
        },
        {
            name: 'Servicio',
            selector: (row: any) => row.service_name,
            sortable: true,
            sortField: 'service_name',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.service_name}</span>
            )
        },
        {
            name: 'Tramo',
            selector: (row: any) => row.route_segment_id,
            sortable: true,
            sortField: 'route_segment_id',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.route_segment_id}</span>
            )
        },
        {
            name: 'Origen',
            selector: (row: any) => row.origin_name,
            sortable: true,
            sortField: 'origin_name',
            cell: (row: any, index: any, column: any) => {
                return row.origin_name;
            }
        },
        {
            name: 'Destino',
            selector: (row: any) => row.destiny_name,
            sortable: true,
            sortField: 'destiny_name',
            cell: (row: any, index: any, column: any) => {
                return row.destiny_name;
            }
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: any, index: any, column: any) => {
                return formatNumber(row.quantity ?? 0) + ' ' + row.product_unit;
            }
        },
        {
            name: 'Tarifa venta',
            selector: (row: any) => row.rate,
            sortable: true,
            sortField: 'rate',
            cell: (row: any, index: any, column: any) => {
                if (auth?.roles && auth?.roles.includes(Roles.RUNNER)) {
                    return '';
                }
                return (
                    formatMoney(row.rate, row.currency_name) +
                    ' ' +
                    row.currency_name +
                    '/' +
                    row.product_unit
                );
            }
        },
        {
            name: 'F. Despacho',
            selector: (row: any) => row.dispatch_date,
            sortable: true,
            sortField: 'dispatch_date',
            cell: (row: any, index: any, column: any) => {
                return momentParseDate(row.dispatch_date);
            }
        },
        {
            name: 'Cantidad Guía',
            selector: (row: any) => row.dispatch_date,
            sortable: true,
            sortField: 'total_guide',
            cell: (row: any, index: any, column: any) => {
                return row.total_guide
                    ? formatNumber(row.total_guide) + ' ' + row.product_unit
                    : null;
            }
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            width: '140px',
            cell: (row: any, index: any, column: any) => {
                const status = statuses.find((quoteStatus) => quoteStatus.id === row.status);
                if (statuses.length > 0) {
                    if (canBeEdited) {
                        return (
                            <TransportationSubOrderItemChangeStatus
                                callbackSuccess={callbackSuccess}
                                statuses={statuses}
                                selectedStatus={status!}
                                transportationOrderItemId={row.id!}
                            />
                        );
                    } else {
                        return row.status_selected.name;
                    }
                }
            }
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            width: '135px',
            cell: (row: TransportationOrderItemDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    
    return (
        <div className="row">
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={transportationOrderItems}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default TransportationOrderItemsDataTable;
