import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { PaymentMethod, PaymentMethodFilters } from '../../models/PaymentMethod';

const usePaymentMethodService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetPaymentMethods, setFetchingGetPaymentMethods] = useState(false);
    const [fetchingCreatePaymentMethod, setFetchingCreatePaymentMethod] = useState(false);
    const [fetchingStorePaymentMethod, setFetchingStorePaymentMethod] = useState(false);
    const [fetchingEditPaymentMethod, setFetchingEditPaymentMethod] = useState(false);
    const [fetchingUpdatePaymentMethod, setFetchingUpdatePaymentMethod] = useState(false);
    const [fetchingDeletePaymentMethod, setFetchingDeletePaymentMethod] = useState(false);
    const [fetchingActivePaymentMethod, setFetchingActivePaymentMethod] = useState(false);
   

    const getPaymentMethods = (filter: PaymentMethodFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PAYMENT_METHOD.GET_PAYMENT_METHODS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPaymentMethods
        });
    };

    const storePaymentMethod = (paymentMethod: PaymentMethod, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PAYMENT_METHOD.STORE_PAYMENT_METHOD,
            body: paymentMethod,
            setFetching: setFetchingStorePaymentMethod
        });
    };

    const editPaymentMethod = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PAYMENT_METHOD.EDIT_PAYMENT_METHOD.replace(':id', id.toString()),
            setFetching: setFetchingEditPaymentMethod
        });
    };

    const updatePaymentMethod = (id: number, paymentMethod: PaymentMethod, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_METHOD.UPDATE_PAYMENT_METHOD.replace(':id', id.toString()),
            body: paymentMethod,
            setFetching: setFetchingUpdatePaymentMethod
        });
    };

    const deletePaymentMethod = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PAYMENT_METHOD.DELETE_PAYMENT_METHOD.replace(':id', id.toString()),
            setFetching: setFetchingDeletePaymentMethod
        });
    };

    const activePaymentMethod = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PAYMENT_METHOD.ACTIVE_PAYMENT_METHOD.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActivePaymentMethod
        });
    };

    return {
        fetchingGetPaymentMethods,
        fetchingStorePaymentMethod,
        fetchingEditPaymentMethod,
        fetchingUpdatePaymentMethod,
        fetchingActivePaymentMethod,
        getPaymentMethods,
        storePaymentMethod,
        editPaymentMethod,
        updatePaymentMethod,
        deletePaymentMethod,
        activePaymentMethod
    };
};

export default usePaymentMethodService;
