import React from 'react';
import useHandleErrorFields from '../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../SelectTwo';
import LazyLoading from '../LazyLoading';
import { Commune } from '../../app/models/Commune';
import { Region } from '../../app/models/Region';
import { Country } from '../../app/models/Country';
import { ProviderDataForm } from '../../app/models/Provider';
import { SocietyDataForm } from '../../app/models/Society';

type Props = {
    dataForm: ProviderDataForm | SocietyDataForm;
    countries: Country[];
    regions: Region[];
    communes: Commune[];
    fetchingCommunes?: boolean;
    fetchingRegions?: boolean;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    handleChangeSelectTwo: (name: string, value: number | string | null | undefined) => void;
    fieldErrorMessage: (name: string) => string;
    fieldHasError: (name: string) => string;
    onFocusRemove: (name: string) => void;
};

const AddressForm = ({
    dataForm,
    countries,
    regions,
    communes,
    fetchingCommunes,
    fetchingRegions,
    handleChange,
    handleChangeSelectTwo,
    fieldErrorMessage,
    fieldHasError,
    onFocusRemove
}: Props) => {
    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">País</label>
                    <SelectTwo
                        name="country_id"
                        id="country_id"
                        inputValue={dataForm.country_id}
                        onChange={(value: any) => handleChangeSelectTwo('country_id', value?.value)}
                        options={SelectTwoMapperOptions(countries ?? [])}
                        hasError={fieldHasError('country_id') !== ''}
                        placeholder="Seleccione país"
                        onFocus={() => onFocusRemove('country_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('country_id')}
                    </div>
                </div>
            </div>
            {countries.find((x) => x.id === dataForm.country_id)?.name === 'Chile' && (
                <>
                    {fetchingRegions ? (
                        <LazyLoading height={300} />
                    ) : (
                        <>
                            <div className="mb-2 row">
                                <div className="col-md-12">
                                    <label className="col-form-label">Región</label>
                                    <SelectTwo
                                        name="region_id"
                                        id="region_id"
                                        inputValue={dataForm.region_id}
                                        options={SelectTwoMapperOptions(regions)}
                                        hasError={fieldHasError('region_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('region_id', value?.value)
                                        }
                                        placeholder={'Seleccione región'}
                                        onFocus={() => onFocusRemove('region_id')}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('region_id')}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {fetchingCommunes ? (
                        <LazyLoading height={300} />
                    ) : (
                        <div className="mb-2 row">
                            <div className="col-md-12">
                                <label className="col-form-label">Comuna</label>
                                <SelectTwo
                                    name="commune_id"
                                    id="commune_id"
                                    inputValue={dataForm.commune_id}
                                    options={SelectTwoMapperOptions(communes)}
                                    hasError={fieldHasError('commune_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('commune_id', value?.value)
                                    }
                                    placeholder={'Seleccione comuna'}
                                    onFocus={() => onFocusRemove('commune_i' + 'd')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('commune_id')}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Dirección</label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className={`form-control ${fieldHasError('address')}`}
                        value={dataForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddressForm;
