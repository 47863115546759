import React, { FC } from 'react';

interface ToggleBoxProps {
    onClick: () => void;
    isSelected: boolean;
    title: string;
    name: string;
    description?: string;
    timeBlock?: string;
    height?: number;
}

const ToggleBox: FC<ToggleBoxProps> = ({
    onClick,
    isSelected,
    title,
    name,
    description,
    timeBlock,
    height = 45
}) => {
    const _class = isSelected ? 'text-white bg-primary' : '';
    return (
        <div className="col-md-4" onClick={onClick}>
            <div className={`card-surgery-selection card border ${_class}`}>
                <div className="card-body">
                    <div className="h5 mt-0 card-title">{title}</div>
                    <div style={{ height: height }}>
                        <h6 className="text-uppercase">{name}</h6>
                    </div>
                    {description ? <p className="card-text font-11">{description}</p> : null}
                    {timeBlock ? (
                        <p className="card-text font-10 text-right">
                            <i className="mdi mdi-briefcase-clock-outline" /> {timeBlock}
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ToggleBox;
