import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { useHistory } from 'react-router-dom';
import { ServiceDataForm, ServiceFilters } from '../../app/models/Service';
import useServiceService from '../../app/services/hooks/useServiceService';
import ServiceDataTable from './components/tables/ServiceDataTable';
import ServiceCreate from './components/ServiceCreate';
import ServiceEditEdit from './components/ServiceEdit';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Servicios',
        url: '/services',
        isActive: true
    }
];

const Services = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetServices, getServices, deleteService, activeService } = useServiceService();
    const [services, setServices] = useState<ServiceDataForm[]>([]);
    const [serviceIdEdit, setServiceIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [serviceFilter, setServiceFilter] = useState<ServiceFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    useEffect(() => {
        reloadTable();
    }, [serviceFilter]);

    const getAllServices = () => {
        getServices(serviceFilter, {
            onSuccess: (response: ServiceResponse) => {
                setServices(response.data.services);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (serviceId: number) => {
        setShowingEdit(true);
        setServiceIdEdit(serviceId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setServiceIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllServices();
    };

    const destroy = (serviceId: number) => {
        const _text = 'Está a punto de eliminar el servicio #' + serviceId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando servicio...');
                        deleteService(serviceId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El servicio no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Servicios" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo servicio" />}
                />
                <ServiceDataTable
                    services={services}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetServices}
                    filter={serviceFilter}
                    setFilter={setServiceFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear servicio"
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar servicio"
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceEditEdit
                        serviceId={serviceIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Services;
