import React from 'react';
import HopperForm from './HopperForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Hopper } from '../../../app/models/Hopper';
import { Carrier } from '../../../app/models/Carrier';
import { Truck } from '../../../app/models/Truck';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    hopper: Hopper;
    setHopper: (Hopper: Hopper) => void;
    errorFields?: any;
    carriers: Carrier[];
    trucks: Truck[];
}

const HopperFormContainer = ({
    fetching,
    action,
    cancel,
    hopper,
    setHopper,
    errorFields = null,
    carriers,
    trucks
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <HopperForm hopper={hopper} setHopper={setHopper} errorFields={errorFields} />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default HopperFormContainer;
