import React, { useState } from 'react';
import { TransportationOrderItemShowDataForm } from '../../../../app/models/TransportationOrderItem';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { Button, Collapse } from 'react-bootstrap';

interface Props {
    transportationOrderItemShowForm: TransportationOrderItemShowDataForm;
}

const TransportationOrderItemShowForm = ({ transportationOrderItemShowForm }: Props) => {
    const [openRates, setOpenRates] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    if (!transportationOrderItemShowForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Producto</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="product_name"
                        className="form-control"
                        readOnly={true}
                        value={transportationOrderItemShowForm.product_name}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Servicio</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="service"
                        className="form-control"
                        readOnly={true}
                        value={transportationOrderItemShowForm.service}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                <div className="col-md-9">
                    <input
                        type="date"
                        className="form-control"
                        name="dispatch_date"
                        id="dispatch_date"
                        value={transportationOrderItemShowForm.dispatch_date}
                        readOnly={true}
                    />
                    <div className="invalid-feedback" />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Descripción</label>
                <div className="col-md-9">
                    <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        value={transportationOrderItemShowForm.description}
                        readOnly={true}
                    />
                    <div className="invalid-feedback" />
                </div>
            </div>
            <hr />

            <>
                <Button
                    onClick={() => setOpenInfo(!openInfo)}
                    aria-controls="transportation-order-item-info"
                    aria-expanded={openInfo}
                    className="container text-center mb-4"
                >
                    Información ruta
                </Button>
                <Collapse in={openInfo}>
                    <div id="transportation-order-item-info">
                        {transportationOrderItemShowForm.quote_route_segment_id && (
                            <div className="mb-2 row">
                                <label className="col-md-3 col-form-label">n° Opción ruta</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        name="quote_route_segment"
                                        className="form-control"
                                        readOnly={true}
                                        value={
                                            transportationOrderItemShowForm.quote_route_segment_id
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">n° Tramo</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="route_segment"
                                    className="form-control"
                                    readOnly={true}
                                    value={transportationOrderItemShowForm.route_segment_id}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Origen</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="origin"
                                    className="form-control"
                                    readOnly={true}
                                    value={transportationOrderItemShowForm.origin}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Destino</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="destiny"
                                    className="form-control"
                                    readOnly={true}
                                    value={transportationOrderItemShowForm.destiny}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Tarifa venta</label>
                            <div className="col-md-9 ">
                                <div className="row g-1">
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            name="rate"
                                            className="form-control"
                                            readOnly={true}
                                            value={transportationOrderItemShowForm.rate}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="rate"
                                            className="form-control"
                                            readOnly={true}
                                            value={transportationOrderItemShowForm.currency}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Cantidad</label>
                            <div className="col-md-9">
                                <div className="row g-1">
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            name="quantity"
                                            className="form-control"
                                            readOnly={true}
                                            value={transportationOrderItemShowForm.quantity}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="quantity_unit"
                                            className="form-control"
                                            readOnly={true}
                                            value={transportationOrderItemShowForm.quantity_unit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Distancia acordada km</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="agreed_distance"
                                    className="form-control"
                                    readOnly={true}
                                    value={formatNumber(
                                        transportationOrderItemShowForm.agreed_distance ?? 0
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </>

            <hr />

            <>
                <Button
                    onClick={() => setOpenRates(!openRates)}
                    aria-controls="rates"
                    aria-expanded={openRates}
                    className="container text-center mb-4"
                >
                    Ver tarifas
                </Button>
                <Collapse in={openRates}>
                    <div id="rates">
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Tarifa Runner</label>
                            <div className="col-md-9">
                                <div className="row g-1">
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="rate_runner"
                                            className="form-control"
                                            readOnly={true}
                                            value={formatMoney(
                                                transportationOrderItemShowForm.rate_runner ?? 0,
                                                transportationOrderItemShowForm.currency_rate_runner ??
                                                    undefined
                                            )}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="currency_rate_runner"
                                            className="form-control"
                                            readOnly={true}
                                            value={
                                                transportationOrderItemShowForm.currency_rate_runner
                                            }
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="quantity_unit_rate_runner"
                                            className="form-control"
                                            readOnly={true}
                                            value={
                                                transportationOrderItemShowForm.quantity_unit_rate_runner
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Tarifa Transportista</label>
                            <div className="col-md-9">
                                <div className="row g-1">
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="rate_transporter"
                                            className="form-control"
                                            readOnly={true}
                                            value={formatMoney(
                                                transportationOrderItemShowForm.rate_transporter ??
                                                    0,
                                                transportationOrderItemShowForm.currency_rate_transporter ??
                                                    undefined
                                            )}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="currency_rate_transporter"
                                            className="form-control"
                                            readOnly={true}
                                            value={
                                                transportationOrderItemShowForm.currency_rate_transporter
                                            }
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            name="quantity_unit_rate_transporter"
                                            className="form-control"
                                            readOnly={true}
                                            value={
                                                transportationOrderItemShowForm.quantity_unit_rate_transporter
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </>

            <hr />
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Creación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="created_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            transportationOrderItemShowForm.created_at
                                ? new Date(
                                      transportationOrderItemShowForm.created_at
                                  ).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Modificación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="updated_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            transportationOrderItemShowForm.updated_at
                                ? new Date(
                                      transportationOrderItemShowForm.updated_at
                                  ).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemShowForm;
