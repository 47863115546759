import React, { useContext } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { PaymentStatusesStatus } from '../../../app/models/PaymentStatus';
import usePaymentStatusService from '../../../app/services/hooks/usePaymentStatusService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';

interface Props {
    transportationOrderId: number;
    statuses: TransportationOrderStatus[];
    selectedStatus: TransportationOrderStatus;
    callbackSuccess?: () => void;
 }

const TransportationOrderChangeStatus = ({
    transportationOrderId,
    statuses,
    selectedStatus,
    callbackSuccess
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);

    const [selected, setSelected] = React.useState<TransportationOrderStatus>(selectedStatus);

    const {
        canChangeStatusTransportationOrder,
        fetchingCanChangeStatusTransportationOrder,
        changeStatusTransportationOrder,
        fetchingChangeStatusTransportationOrder
    } = useTransportationOrderService();


    const requestChangeStatus = (valueStatus: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');

        if (valueStatus == selected.id){
            if (hideLoading) hideLoading();
            return null;
        }
        
        const _status = statuses.find((status: TransportationOrderStatus) => status.id === valueStatus);
       
        if(!_status){
            if (hideLoading) hideLoading();
            return null;
        }
        setSelected(_status!);
        canChangeStatusTransportationOrder(transportationOrderId, valueStatus, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(valueStatus, response.message);
            },
            onError: (response: ServiceResponse) => {
                setSelected(selectedStatus);
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data?.message_list ?? []
                });
                toast.error(response.message);
            }
        });
    };

    const changeStatus = (status: string, message: string) => {
        const _text = `Está a punto de cambiar el estado del pedido de transporte #${transportationOrderId}.${message ?? ''}`;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusTransportationOrder(transportationOrderId, status, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                if(callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                setSelected(selectedStatus);
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            setSelected(selectedStatus);
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El pedido de transporte no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                },
            },
            onClickOutsideFunction: () => {
                setSelected(selectedStatus);
            }
        });
    };
    

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => requestChangeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses
                .filter((status) => status.id !== selectedStatus.id) 
                .map((status) => (
                <option key={status.id} value={status.id}>
                    {status.name}
                </option>
            ))}
        </select>
    );
};

export default TransportationOrderChangeStatus;
