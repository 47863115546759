import React, { ChangeEvent, useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { BusinessNameDataForm } from '../../../../app/models/BusinessName';
import useRut from '../../../../hooks/useRut';
import { ProviderType } from '../../../../app/models/ProviderType';


interface Props {
    businessNameDataForm: BusinessNameDataForm;
    setBusinessNameDataForm: (businessNameDataForm: BusinessNameDataForm) => void
    providerTypes: ProviderType[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const BusinessNameForm = ({
    businessNameDataForm,
    setBusinessNameDataForm,
    providerTypes,
    errorFields,
    setErrorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();
    const [tempRut, setTempRut] = useState<string | null>(null);
   
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value, type } = event.target;

        if (name == 'rut') {
            setBusinessNameDataForm({ ...businessNameDataForm, [name]: formatRut(value) });
        } else {
            setBusinessNameDataForm({ ...businessNameDataForm, [name]: value });
        }
    };


    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        
        if(name === 'is_national' || name === 'foreign'){
            console.log(name)
            const isNational = !businessNameDataForm.is_national;

            const updatedBusinessName = { ...businessNameDataForm, is_national: isNational };
    
            if (!isNational) {
                setTempRut(businessNameDataForm.rut??'');
                updatedBusinessName.rut = null;
            } else if (tempRut !== null) {
                updatedBusinessName.rut = tempRut; 
            }
            setBusinessNameDataForm(updatedBusinessName);
        }
      
        else{
            setBusinessNameDataForm({ ...businessNameDataForm, [name]: checked });
        }
   
    };


    const [image, setImage] = useState(
        businessNameDataForm.logo
            ? businessNameDataForm.logo
            : 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg'
    );

    const dataOnChangeImages = (e: any) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setBusinessNameDataForm({
            ...businessNameDataForm,
            [e.target.name]: e.target.files[0]
        });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setBusinessNameDataForm({ ...businessNameDataForm, [name]: value });
    };

    if (!businessNameDataForm) return null;

    return (
        <>

            <div className="col-md-12 text-center d-flex">
                <div className="mx-auto mb-3">
                    <label htmlFor="logo">
                        <img
                            src={
                                typeof image === 'string' && image !== ''
                                    ? image
                                    : window.URL.createObjectURL(image as Blob)
                            }
                            className="rounded-circle pointer bg-white"
                            style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover'
                            }}
                        />
                    </label>
                </div>
                <input
                    type="file"
                    className="custom-file-input"
                    style={{ height: '0px', width: '0px' }}
                    id="logo"
                    name="logo"
                    onChange={dataOnChangeImages}
                />
            </div>

            <div className="mb-4 mt-3 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_national"
                            value="is_national"
                            checked={businessNameDataForm.is_national}
                            id="is_national"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="is_national">
                            Nacional
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="foreign"
                            value="foreign"
                            checked={!businessNameDataForm.is_national}
                            id="foreign"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="foreign">
                            Extranjero
                        </label>
                    </div>
                </div>
            </div>
       
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={businessNameDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            {
                businessNameDataForm.is_national &&
                <div className='mb-2 row"'>
                    <div className="col-md-12">
                        <label className="col-form-label">Rut</label>
                        <input
                            name="rut"
                            id="rut"
                            type="text"
                            className="form-control"
                            value={businessNameDataForm.rut || ''}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('rut')}
                            placeholder="Ingrese el RUT"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('rut')}
                        </div>
                    </div>               
                </div>
            }
            
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Email</label>
                    <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={businessNameDataForm.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>               
            </div>
            <div className='mb-2 row"'>
                <div className="col-md-12">
                    <label className="col-form-label">Dirección</label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className="form-control"
                        value={businessNameDataForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese domicilio"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>               
            </div>
            <div className='mb-2 row'>
                <div className="col-md-12">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                onChange={handleChange}
                                value={businessNameDataForm.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option value="">--</option>
                                <option value="+56">+56</option>
                            </select>
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone_code')}
                            </div>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                className="form-control"
                                value={businessNameDataForm.phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="mb-2 row">
            <div className="col-md-12 mb-2">
                <label className="col-form-label">Entidades</label>
                <div className="col-12">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="has_society"
                            checked={businessNameDataForm.has_society}
                            id="has_society"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label ms-2" htmlFor="has_society">
                            Sociedad
                        </label>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('has_society')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="has_client"
                            checked={businessNameDataForm.has_client}
                            id="has_client"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label ms-2" htmlFor="has_client">
                            Cliente
                        </label>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('has_client')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="has_provider"
                            checked={businessNameDataForm.has_provider}
                            id="has_provider"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label ms-2" htmlFor="has_provider">
                            Proveedor
                        </label>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('has_provider')}
                    </div>
                    {businessNameDataForm.has_provider && (
                        <div className="mb-2 row needs-validation">
                            <label className="col-form-label">Tipo Proveedor</label>
                            <div className="col-md-12">
                                <SelectTwo
                                    name="provider_type_id"
                                    inputValue={businessNameDataForm.provider_type_id}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('provider_type_id', value.value)
                                    }
                                    options={SelectTwoMapperOptions(providerTypes ?? [])}
                                    placeholder="Seleccione T. proveedor"
                                    onFocus={() => onFocusRemove('provider_type_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('provider_type_id')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>


        </>
    );
};

export default BusinessNameForm;
