import React, { useEffect } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Carrier } from '../../../../app/models/Carrier';
import { Provider } from '../../../../app/models/Provider';
import { Truck } from '../../../../app/models/Truck';
import { Hopper } from '../../../../app/models/Hopper';
import { DailyCarrier } from '../../../../app/models/DailyCarrier';
import { ServiceManifestDetailDataForm } from '../../../../app/models/ServiceManifestDetail';

interface DispatchProgramItemProps {
    dailyCarrier: DailyCarrier;
    carriers: Carrier[];
    providers: Provider[];
    trucks: Truck[];
    hoppers: Hopper[];
    setDailyCarrier: (dailyCarrier: DailyCarrier | ServiceManifestDetailDataForm)  => void;
    errorFields?: any;
}

const DispatchProgramItemForm = ({
    dailyCarrier,
    setDailyCarrier,
    carriers,
    providers,
    trucks,
    hoppers,
    errorFields
}: DispatchProgramItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setDailyCarrier({ ...dailyCarrier, ...obj });
    };

    return (
        <>
            
            <h5 className="mb-3">Datos transporte</h5>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Proveedor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={dailyCarrier.provider_id}
                        options={SelectTwoMapperOptions(providers)}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder={'Seleccione proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Conductor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="carrier_id"
                        id="carrier_id"
                        inputValue={dailyCarrier.carrier_id}
                        options={SelectTwoMapperOptions(carriers)}
                        hasError={fieldHasError('carrier_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('carrier_id', value.value)}
                        placeholder={'Seleccione conductor'}
                        onFocus={() => onFocusRemove('carrier_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('carrier_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tracto</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="truck_id"
                        id="truck_id"
                        inputValue={dailyCarrier.truck_id}
                        options={SelectTwoMapperOptions(trucks)}
                        hasError={fieldHasError('truck_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('truck_id', value.value)}
                        placeholder={'Seleccione tracto'}
                        onFocus={() => onFocusRemove('truck_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('truck_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tolva</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="hopper_id"
                        id="hopper_id"
                        inputValue={dailyCarrier.hopper_id}
                        options={SelectTwoMapperOptions(hoppers)}
                        hasError={fieldHasError('hopper_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('hopper_id', value.value)}
                        placeholder={'Seleccione tolva'}
                        onFocus={() => onFocusRemove('hopper_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('hopper_id')}
                    </div>
                </div>
            </div>

            <br />
        </>
    );
};

export default DispatchProgramItemForm;
