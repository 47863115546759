import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Provider } from '../../../../app/models/Provider';
import { Society } from '../../../../app/models/Society';
import LazyLoading from '../../../../components/LazyLoading';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentType
} from '../../../../app/models/ServiceManifestDocument';

interface Props {
    serviceManifestDocumentDataForm: ServiceManifestDocumentDataForm;
    setServiceManifestDocumentDataForm: (
        serviceManifestDocumentDataForm: ServiceManifestDocumentDataForm
    ) => void;
    documentTypes: ServiceManifestDocumentType[];
    errorFields?: any;
}

const ServiceManifestDocumentForm = ({
    serviceManifestDocumentDataForm,
    setServiceManifestDocumentDataForm,
    documentTypes,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setServiceManifestDocumentDataForm({ ...serviceManifestDocumentDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setServiceManifestDocumentDataForm({
                ...serviceManifestDocumentDataForm,
                ['file']: e[0]
            });
        }
    };

    const handleRemoveFile = (name: string) => {
        setServiceManifestDocumentDataForm({ 
            ...serviceManifestDocumentDataForm, 
            [name]: undefined 
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setServiceManifestDocumentDataForm({ ...serviceManifestDocumentDataForm, ...obj });
    };

    if (!serviceManifestDocumentDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-12 col-form-label">Nombre</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className={`form-control ${fieldHasError('name')}`}
                        value={serviceManifestDocumentDataForm.name}
                        onChange={handleChange}
                        placeholder="Ingrese nombre"
                        onFocus={() => onFocusRemove('name')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Tipo de documento</label>
                    <SelectTwo
                        name="document_type"
                        id="document_type"
                        inputValue={serviceManifestDocumentDataForm.document_type}
                        options={SelectTwoMapperOptions(documentTypes, 'id', ['name'])}
                        hasError={fieldHasError('document_type') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('document_type', value.value)
                        }
                        placeholder={'Seleccione tipo doc..'}
                        onFocus={() => onFocusRemove('document_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('document_type')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Documento</label>
                    <InputButtonFile
                        name={'file'}
                        objectFile={serviceManifestDocumentDataForm.file}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        handleRemoveFile={(value: any) => handleRemoveFile('file')}
                        onFocus={() => onFocusRemove('file')}
                    />

                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDocumentForm;
