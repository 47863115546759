import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import useRut from '../../../../hooks/useRut';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';
import { ProductDataForm } from '../../../../app/models/Product';
import { Unit } from '../../../../app/models/Unit';
import { PackingForm } from '../../../../app/models/PackingForm';
import { OriginArea } from '../../../../app/models/OriginArea';
import { Sector } from '../../../../app/models/Sector';
import { Currency } from '../../../../app/models/Currency';
import { Tax } from '../../../../app/models/Tax';
import { ItemCategory } from '../../../../app/models/ItemCategory';

type Props = {
    productDataForm: ProductDataForm;
    setProductDataForm: (product: ProductDataForm) => void;
    currencies: Currency[];
    priceUnits: Unit[];
    units: Unit[];
    packingForms: PackingForm[];
    originAreas: OriginArea[];
    sectors: Sector[];
    itemCategories: ItemCategory[];
    taxesData: Tax[];
    errorFields?: any;
};

const ProductForm = ({
    productDataForm,
    setProductDataForm,
    currencies,
    priceUnits,
    units,
    packingForms,
    originAreas,
    sectors,
    itemCategories,
    taxesData,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setProductDataForm({ ...productDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setProductDataForm({ ...productDataForm, ...obj });
    };

    const changeMultipleSelectTwo = (taxesSelect: any) => {
        const obj = { taxes: taxesSelect };
        
        setProductDataForm({
            ...productDataForm,
            ...obj
        });
       
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>

                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={productDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre de producto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Sector</label>
                    <SelectTwo
                        name="sector_id"
                        inputValue={productDataForm.sector_id}
                        onChange={(value: any) => handleChangeSelectTwo('sector_id', value?.value)}
                        options={SelectTwoMapperOptions(sectors ?? [])}
                        placeholder="Seleccione sector"
                        onFocus={() => onFocusRemove('sector_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sector_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Categoría</label>
                    <SelectTwo
                        name="item_category_id"
                        inputValue={productDataForm.item_category_id}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('item_category_id', value?.value)
                        }
                        options={SelectTwoMapperOptions(itemCategories ?? [])}
                        placeholder="Seleccione categoría"
                        onFocus={() => onFocusRemove('item_category_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('item_category_id')}
                    </div>
                </div>
            </div>
           
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Precio</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-4">
                            <input
                                name="price"
                                id="price"
                                type="number"
                                className={`form-control ${fieldHasError('rate')}`}
                                value={productDataForm.price}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('rate')}
                                placeholder="Ingrese precio"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('price')}
                            </div>
                        </div>

                        <div className="col-4">
                            <SelectTwo
                                name="currency_price_id"
                                id="currency_price_id"
                                inputValue={productDataForm.currency_price_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_price_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_price_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_price_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_price_id')}
                            </div>
                        </div>

                        <div className="col-4">
                            <SelectTwo
                                name="unit_price_id"
                                id="unit_price_id"
                                inputValue={productDataForm.unit_price_id}
                                options={SelectTwoMapperOptions(priceUnits)}
                                hasError={fieldHasError('unit_price_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_price_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_price_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_price_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Impuestos</label>

                    <SelectTwo
                        name="taxes"
                        id="taxes"
                        inputValue={productDataForm.taxes}
                        options={SelectTwoMapperOptions(taxesData)}
                        hasError={fieldHasError('taxes') !== ''}
                        onChange={changeMultipleSelectTwo}
                        isMulti={true}
                        placeholder={'Seleccione impuesto(s)'}
                        onFocus={() => onFocusRemove('taxes')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('taxes')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={productDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Stock</label>

                    <input
                        name="stock"
                        id="stock"
                        type="number"
                        className={`form-control ${fieldHasError('stock')}`}
                        value={productDataForm.stock}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('stock')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('stock')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Unidad medida</label>
                    <SelectTwo
                        name="unit_id"
                        inputValue={productDataForm.unit_id}
                        onChange={(value: any) => handleChangeSelectTwo('unit_id', value?.value)}
                        options={SelectTwoMapperOptions(units ?? [])}
                        placeholder="Seleccione unidad"
                        onFocus={() => onFocusRemove('unit_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('unit_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Forma de empaque</label>
                    <SelectTwo
                        name="packing_form_id"
                        inputValue={productDataForm.packing_form_id}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('packing_form_id', value?.value)
                        }
                        options={SelectTwoMapperOptions(packingForms ?? [])}
                        placeholder="Selec.. F. empaque"
                        onFocus={() => onFocusRemove('packing_form_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('packing_form_id')}
                    </div>
                </div>
                {/*<div className="col-md-6">*/}
                {/*    <label className="col-form-label">Zona origen</label>*/}
                {/*    <SelectTwo*/}
                {/*        name="origin_area_id"*/}
                {/*        inputValue={productDataForm.origin_area_id}*/}
                {/*        onChange={(value: any) =>*/}
                {/*            handleChangeSelectTwo('origin_area_id', value?.value)*/}
                {/*        }*/}
                {/*        options={SelectTwoMapperOptions(originAreas ?? [])}*/}
                {/*        placeholder="Seleccione zona origen"*/}
                {/*        onFocus={() => onFocusRemove('origin_area_id')}*/}
                {/*    />*/}
                {/*    <div className="invalid-feedback" style={{ display: 'flex' }}>*/}
                {/*        {fieldErrorMessage('origin_area_id')}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default ProductForm;
