import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BusinessNameBankAccountDataForm, BusinessNameBankAccountFilters } from '../../models/BusinessNameBankAccount';

const useBusinessNameBankAccountService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetAllBusinessNameBankAccounts, setFetchingGetAllBusinessNameBankAccounts] = useState(false);
    const [fetchingGetAllBusinessNameBankAccountsByBusinessName, setFetchingGetAllBusinessNameBankAccountsByBusinessName] = useState(false);
    const [fetchingGetAllBusinessNameBankAccountsByClient, setFetchingGetAllBusinessNameBankAccountsByClient] = useState(false);
    const [fetchingGetAllBusinessNameBankAccountsByProvider, setFetchingGetAllBusinessNameBankAccountsByProvider] = useState(false);
    const [fetchingGetAllBusinessNameBankAccountsBySociety, setFetchingGetAllBusinessNameBankAccountsBySociety] = useState(false);
    const [fetchingCreateBusinessNameBankAccount, setFetchingCreateBusinessNameBankAccount] = useState(false);
    const [fetchingStoreBusinessNameBankAccount, setFetchingStoreBusinessNameBankAccount] = useState(false);
    const [fetchingEditBusinessNameBankAccount, setFetchingEditBusinessNameBankAccount] = useState(false);
    const [fetchingUpdateBusinessNameBankAccount, setFetchingUpdateBusinessNameBankAccount] = useState(false);
    const [fetchingDeleteBusinessNameBankAccount, setFetchingDeleteBusinessNameBankAccount] = useState(false);
    const [fetchingActiveBusinessNameBankAccount, setFetchingActiveBusinessNameBankAccount] = useState(false);
    const [
        fetchingGetBankAccountsByBusinessNameAndBankAccountReason, 
        setFetchingGetBankAccountsByBusinessNameAndBankAccountReason
    ] = useState(false);

    const getAllBusinessNameBankAccounts = (filter: BusinessNameBankAccountFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_BUSINESS_NAME.GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetAllBusinessNameBankAccounts
        });
    };

    const getAllBusinessNameBankAccountsByBusinessName = (filter: BusinessNameBankAccountFilters, business_name_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_BUSINESS_NAME.GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_BUSINESS_NAME}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':business_name_id', business_name_id.toString()),
            setFetching: setFetchingGetAllBusinessNameBankAccountsByBusinessName
        });
    };

    const getAllBusinessNameBankAccountsByClient = (filter: BusinessNameBankAccountFilters, client_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_BUSINESS_NAME.GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_CLIENT}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':client_id', client_id.toString()),
            setFetching: setFetchingGetAllBusinessNameBankAccountsByClient
        });
    };

    const getAllBusinessNameBankAccountsByProvider = (filter: BusinessNameBankAccountFilters, provider_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_BUSINESS_NAME.GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_PROVIDER}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':provider_id', provider_id.toString()),
            setFetching: setFetchingGetAllBusinessNameBankAccountsByProvider
        });
    };

    const getAllBusinessNameBankAccountsBySociety = (filter: BusinessNameBankAccountFilters, society_id: number, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANK_ACCOUNT_BUSINESS_NAME.GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_SOCIETY}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':society_id', society_id.toString()),
            setFetching: setFetchingGetAllBusinessNameBankAccountsBySociety
        });
    };

    const createBusinessNameBankAccount = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.CREATE_BUSINESS_NAME_BANK_ACCOUNT,
            setFetching: setFetchingCreateBusinessNameBankAccount
        });
    };

    const storeBusinessNameBankAccount = (businessNamebankAccount: BusinessNameBankAccountDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.STORE_BUSINESS_NAME_BANK_ACCOUNT,
            body: businessNamebankAccount,
            setFetching: setFetchingStoreBusinessNameBankAccount
        });
    };

    const editBusinessNameBankAccount = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.EDIT_BUSINESS_NAME_BANK_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingEditBusinessNameBankAccount
        });
    };

    const updateBusinessNameBankAccount = (id: number, businessNamebankAccount: BusinessNameBankAccountDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.UPDATE_BUSINESS_NAME_BANK_ACCOUNT.replace(':id', id.toString()),
            body: businessNamebankAccount,
            setFetching: setFetchingUpdateBusinessNameBankAccount
        });
    };

    const deleteBusinessNameBankAccount = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.DELETE_BUSINESS_NAME_BANK_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBusinessNameBankAccount
        });
    };

    const activeBusinessNameBankAccount = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.ACTIVE_BUSINESS_NAME_BANK_ACCOUNT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBusinessNameBankAccount
        });
    };

    const getBankAccountsByBusinessNameAndBankAccountReason = (
        business_name_id: number, 
        bank_account_reason_id: number, 
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT_BUSINESS_NAME.GET_BUSINESS_NAME_BANK_ACCOUNT_BY_BUSINESS_NAME_AND_BANK_ACCOUNT_REASON
            .replace(':business_name_id', business_name_id.toString())
            .replace(':bank_account_reason_id', bank_account_reason_id.toString()),
            setFetching: setFetchingGetBankAccountsByBusinessNameAndBankAccountReason
        });
    };


    return {
        fetchingGetAllBusinessNameBankAccounts,
        fetchingGetAllBusinessNameBankAccountsByBusinessName,
        fetchingGetAllBusinessNameBankAccountsByClient,
        fetchingGetAllBusinessNameBankAccountsByProvider,
        fetchingGetAllBusinessNameBankAccountsBySociety,
        fetchingCreateBusinessNameBankAccount,
        fetchingStoreBusinessNameBankAccount,
        fetchingEditBusinessNameBankAccount,
        fetchingUpdateBusinessNameBankAccount,
        fetchingDeleteBusinessNameBankAccount,
        fetchingActiveBusinessNameBankAccount,
        fetchingGetBankAccountsByBusinessNameAndBankAccountReason,
        getAllBusinessNameBankAccounts,
        getAllBusinessNameBankAccountsByBusinessName,
        getAllBusinessNameBankAccountsByClient,
        getAllBusinessNameBankAccountsByProvider,
        getAllBusinessNameBankAccountsBySociety,
        createBusinessNameBankAccount,
        storeBusinessNameBankAccount,
        editBusinessNameBankAccount,
        updateBusinessNameBankAccount,
        deleteBusinessNameBankAccount,
        activeBusinessNameBankAccount,
        getBankAccountsByBusinessNameAndBankAccountReason
    };
};

export default useBusinessNameBankAccountService;
