import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Society } from '../../../app/models/Society';

import useNavigationPage from '../../../hooks/useNavigationPage';
import useBatchService from '../../../app/services/hooks/useBatchService';
import { BatchDataForm, defaultBatchDataForm } from '../../../app/models/Batch';
import { BatchType } from '../../../app/models/BatchType';
import { Unit } from '../../../app/models/Unit';
import { Depot } from '../../../app/models/Depot';
import { Product } from '../../../app/models/Product';
import BatchForm from './forms/BatchForm';
import { Brand } from '../../../app/models/Brand';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BatchCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const { createBatch, storeBatch, fetchingCreateBatch, fetchingStoreBatch } = useBatchService();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [batchTypes, setBatchTypes] = useState<BatchType[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [unitCapacities, setUnitCapacities] = useState<Unit[]>([]);
    const [depots, setDepots] = useState<Depot[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const [batch, setBatch] = useState<BatchDataForm>(defaultBatchDataForm);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createBatch({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setBatchTypes(response.data.batch_types);
                setBrands(response.data.brands);
                setUnitCapacities(response.data.units);
                setDepots(response.data.depots);
                setProducts(response.data.products);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando lote...');
        storeBatch(batch, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                navigationPage('/batches/' + response.data.batch_id + '/edit');
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateBatch ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BatchForm
                        batchDataForm={batch}
                        setBatchDataForm={setBatch}
                        societies={societies}
                        batchTypes={batchTypes}
                        brands={brands}
                        unitCapacities={unitCapacities}
                        depots={depots}
                        products={products}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreBatch} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreBatch} />
                </div>
            </div>
        </>
    );
};

export default BatchCreate;
