import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../../contexts/AppContext';

type Props = {
    callbackFunction: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    errorMessage?: string;
    classIcon?: string;
    colorIcon?: string;
    sizeIcon?: string;
};

const ButtonTableAction = ({
    callbackFunction,
    title,
    loading = false,
    locked = false,
    errorMessage,
    classIcon,
    colorIcon,
    sizeIcon = '18'
}: Props) => {
    const { changeEventClick } = useContext(AppContext);

    const showLockedMessage = () => {
        toast.error(errorMessage);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (changeEventClick) {
            changeEventClick(e);
        }
        callbackFunction();
    };

    return !loading ? (
        <span
            // onClick={locked ? showLockedMessage : callbackFunction}
            onMouseDown={locked ? showLockedMessage : handleMouseDown}
            className="icon-pointer mx-2"
            title={title}
        >
            <i className={`${colorIcon} ${classIcon} font-size-${sizeIcon}`} />
        </span>
    ) : (
        <span className="icon-pointer mx-2 disabled" title={title}>
            <i className={`${colorIcon} ${classIcon} font-size-${sizeIcon}`} />
        </span>
    );
};

export default ButtonTableAction;
