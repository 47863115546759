import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { fetchDefaultHeaders } from '../shared/vars';
import { RouteSegmentDataForm, RouteSegmentFilters } from '../../models/RouteSegment';

const useRouteSegmentService = () => {
    const { doPost, doGet, doPut, doDelete } = useFetch();

    const [fetchingGetRouteSegments, setFetchingGetRouteSegments] = useState(false);
    const [fetchingCreateRouteSegment, setFetchingCreateRouteSegment] = useState(false);
    const [fetchingStoreRouteSegment, setFetchingStoreRouteSegment] = useState(false);
    const [fetchingEditRouteSegment, setFetchingEditRouteSegment] = useState(false);
    const [fetchingUpdateRouteSegment, setFetchingUpdateRouteSegment] = useState(false);
    const [fetchingDeleteRouteSegment, setFetchingDeleteRouteSegment] = useState(false);

    const getRouteSegments = (
        routeSegmentFilters: RouteSegmentFilters,
        events: ServiceEvents = {}
    ) => {
        let url = new URL(EndPoints.ROUTE_SEGMENTS.GET_ROUTE_SEGMENTS);
        url = getUrlRouteSegmentFilters(
            url,
            routeSegmentFilters.page,
            routeSegmentFilters.per_page,
            routeSegmentFilters.sort,
            routeSegmentFilters.order,
            routeSegmentFilters.route_segment_id,
            routeSegmentFilters.origin,
            routeSegmentFilters.destiny,
            routeSegmentFilters.start_date,
            routeSegmentFilters.end_date
        );
        doGet({
            ...events,
            url: url.href,
            setFetching: setFetchingGetRouteSegments
        });
    };

    const createRouteSegment = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.CREATE_ROUTE_SEGMENTS,
            setFetching: setFetchingCreateRouteSegment
        });
    };

    const storeRouteSegment = (
        routeSegmentDataForm: RouteSegmentDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.STORE_ROUTE_SEGMENT,
            body: routeSegmentDataForm,
            setFetching: setFetchingStoreRouteSegment
        });
    };

    const editRouteSegment = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.EDIT_ROUTE_SEGMENT.replace(':id', id.toString()),
            setFetching: setFetchingEditRouteSegment
        });
    };

    const updateRouteSegment = (
        id: number,
        routeSegmentDataFrom: RouteSegmentDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.UPDATE_ROUTE_SEGMENT.replace(':id', id.toString()),
            body: routeSegmentDataFrom,
            setFetching: setFetchingUpdateRouteSegment
        });
    };

    const deleteRouteSegment = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.DELETE_ROUTE_SEGMENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteRouteSegment
        });
    };

    const getUrlRouteSegmentFilters = (
        url: URL,
        page: number,
        per_page: number,
        sort: string,
        order: string,
        route_segment_id: number | null = null,
        origin: string | null = null,
        destiny: string | null = null,
        start_date: string | null = null,
        end_date: string | null = null
    ) => {
        if (page) {
            url.searchParams.append('page', String(page));
        }
        if (per_page) {
            url.searchParams.append('per_page', String(per_page));
        }
        if (sort) {
            url.searchParams.append('sort', String(sort));
        }
        if (order) {
            url.searchParams.append('order', String(order));
        }
        if (route_segment_id) {
            url.searchParams.append('id', String(route_segment_id));
        }
        if (start_date) {
            url.searchParams.append('start_date', start_date);
        }
        if (end_date) {
            url.searchParams.append('end_date', end_date);
        }
        if (origin) {
            url.searchParams.append('origin', String(origin));
        }
        if (destiny) {
            url.searchParams.append('destiny', String(destiny));
        }

        return url;
    };

    return {
        fetchingGetRouteSegments,
        fetchingCreateRouteSegment,
        fetchingStoreRouteSegment,
        fetchingEditRouteSegment,
        fetchingUpdateRouteSegment,
        fetchingDeleteRouteSegment,
        getRouteSegments,
        createRouteSegment,
        storeRouteSegment,
        editRouteSegment,
        updateRouteSegment,
        deleteRouteSegment
    };
};

export default useRouteSegmentService;
