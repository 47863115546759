import React from 'react';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';
import HopperFilter from './HopperFilter';
import { Hopper, HopperFilters } from '../../../app/models/Hopper';

interface Props {
    hoppers: Hopper[];
    totalRows: number;
    loading?: boolean;
    edit?: (hopperId: any) => void;
    destroy?: (hopperId: any) => void;
    filter: HopperFilters;
    setFilter: (filter: HopperFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const HopperDataTable = ({
    hoppers,
    totalRows,
    loading = false,
    edit,
    destroy,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Hopper, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Patente',
            selector: (row: any) => row.patent,
            sortable: true,
            sortField: 'patent',
            cell: (row: Hopper, index: any, column: any) => <span className="">{row.patent}</span>
        },
        {
            name: 'Patente camión',
            selector: (row: any) => row.truck_patent,
            sortable: true,
            sortField: 'truck_patent',
            cell: (row: Hopper, index: any, column: any) => (
                <span className="">{row.truck_patent}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: Hopper, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-md-0 mt-3">
                <div className="row">
                    <div className="col-auto">
                        <HopperFilter filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={hoppers}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default HopperDataTable;
