import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentFilters
} from '../../models/ServiceManifestDocument';

const useServiceManifestDocumentService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetServiceManifestDocumentsByServiceManifest,
        setFetchingGetServiceManifestDocumentsByServiceManifest
    ] = useState(false);
    const [fetchingShowServiceManifestDocument, setFetchingShowServiceManifestDocument] =
        useState(false);
    const [fetchingCreateServiceManifestDocument, setFetchingCreateServiceManifestDocument] =
        useState(false);
    const [fetchingStoreServiceManifestDocument, setFetchingStoreServiceManifestDocument] =
        useState(false);
    const [fetchingEditServiceManifestDocument, setFetchingEditServiceManifestDocument] =
        useState(false);
    const [fetchingUpdateServiceManifestDocument, setFetchingUpdateServiceManifestDocument] =
        useState(false);
    const [fetchingDeleteServiceManifestDocument, setFetchingDeleteServiceManifestDocument] =
        useState(false);

    const getServiceManifestDocumentsByServiceManifest = (
        serviceManifestDocumentFilters: ServiceManifestDocumentFilters,
        service_manifest_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(serviceManifestDocumentFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SERVICE_MANIFEST_DOCUMENT.GET_SERVICE_MANIFEST_DOCUMENTS_BY_SERVICE_MANIFEST}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':service_manifest_id', service_manifest_id.toString()),
            setFetching: setFetchingGetServiceManifestDocumentsByServiceManifest
        });
    };

    const showServiceManifestDocument = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DOCUMENT.SHOW_SERVICE_MANIFEST_DOCUMENT.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowServiceManifestDocument
        });
    };

    const createServiceManifestDocument = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DOCUMENT.CREATE_SERVICE_MANIFEST_DOCUMENT,
            setFetching: setFetchingCreateServiceManifestDocument
        });
    };

    const storeServiceManifestDocument = (
        serviceManifestDocument: ServiceManifestDocumentDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DOCUMENT.STORE_SERVICE_MANIFEST_DOCUMENT,
            body: serviceManifestDocument,
            setFetching: setFetchingStoreServiceManifestDocument
        });
    };

    const editServiceManifestDocument = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DOCUMENT.EDIT_SERVICE_MANIFEST_DOCUMENT.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditServiceManifestDocument
        });
    };

    const updateServiceManifestDocument = (
        id: number,
        serviceManifestDocument: ServiceManifestDocumentDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DOCUMENT.UPDATE_SERVICE_MANIFEST_DOCUMENT.replace(
                ':id',
                id.toString()
            ),
            body: serviceManifestDocument,
            setFetching: setFetchingUpdateServiceManifestDocument
        });
    };

    const deleteServiceManifestDocument = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SERVICE_MANIFEST_DOCUMENT.DELETE_SERVICE_MANIFEST_DOCUMENT.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingDeleteServiceManifestDocument
        });
    };

    return {
        fetchingGetServiceManifestDocumentsByServiceManifest,
        fetchingShowServiceManifestDocument,
        fetchingCreateServiceManifestDocument,
        fetchingStoreServiceManifestDocument,
        fetchingEditServiceManifestDocument,
        fetchingUpdateServiceManifestDocument,
        fetchingDeleteServiceManifestDocument,
        getServiceManifestDocumentsByServiceManifest,
        showServiceManifestDocument,
        createServiceManifestDocument,
        storeServiceManifestDocument,
        editServiceManifestDocument,
        updateServiceManifestDocument,
        deleteServiceManifestDocument
    };
};

export default useServiceManifestDocumentService;
