import React, { useEffect, useState } from 'react';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import {
    defaultTransportationOrderItemDataForm,
    SplitOrderItem,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import { toast } from 'react-toastify';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import TransportationOrderItemSplitForm from './forms/TransportationOrderItemSplitForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { formatNumber } from '../../../utils/utils';
import AlertBootstrap from '../../../components/AlertBootstrap';

interface Props {
    canBeEdited: boolean;
    transportationOrderItemId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationOrderItemSplit = ({
    canBeEdited,
    transportationOrderItemId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        fetchingEditTransportationOrderItem,
        editTransportationOrderItem,
        fetchingSplitTransportationOrderItem,
        splitTransportationOrderItem
    } = useTransportationOrderItemService();

    const [transportationOrderItem, setTransportationOrderItem] =
        useState<TransportationOrderItemDataForm>(defaultTransportationOrderItemDataForm);

    const [splitData, setSplitData] = useState<SplitOrderItem[]>([]);
    const [quantityAvailable, setQuantityAvailable] = useState<number>(0);
    const [quantityUsed, setQuantityUsed] = useState<number>(0);

    // useEffect(() => {
    //     console.log(splitData);
    // }, [splitData]);

    useEffect(() => {
        if (transportationOrderItemId) {
            edit();
        }
    }, [transportationOrderItemId]);

    const edit = () => {
        editTransportationOrderItem(transportationOrderItemId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationOrderItem(response.data.transportation_order_item);
            },
            onError: (response: ServiceResponse) => {
                onError && onError(response.message);
            }
        });
    };

    const addSplit = () => {
        // if (!hasQuantityToSplit()) {
        //     toast.error('No queda cantidad para dividir');
        //     return;
        // }
        setSplitData([
            ...splitData,
            {
                quantity: (getQuantityAvailable() < 0 ? 0 : getQuantityAvailable()) ?? 0,
                dispatch_date: transportationOrderItem.dispatch_date ?? ''
            }
        ]);
    };

    const getQuantityAdded = () => {
        return (
            splitData.reduce((total, split) => {
                return total + split.quantity;
            }, 0) + (transportationOrderItem.quantity_used ?? 0)
        );
    };

    const getQuantityAvailable = () => {
        return transportationOrderItem.quantity ?? 0 - getQuantityAdded();
    };

    const hasQuantityToSplit = () => {
        return transportationOrderItem.quantity ?? 0 > getQuantityAdded();
    };

    const canBeSaved = () => {
        return transportationOrderItem.quantity == getQuantityAdded();
    };

    const cancel = () => {
        onCancel && onCancel();
    };

    const storeSplit = () => {
        // if (!canBeSaved()) {
        //     toast.error('Aún quedan cantidades por dividir');
        //     return;
        // }
        splitTransportationOrderItem(
            {
                transportation_order_item_id: transportationOrderItemId,
                transportation_sub_order_items: splitData
            },
            {
                onSuccess: (response: ServiceResponse) => {
                    toast.success(response.message);
                    onSaved && onSaved();
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                    onError && onError();
                }
            }
        );
    };

    return (
        <>
            {canBeEdited && (
                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm table-bordered">
                            <thead className="table-header-45">
                                <tr className="table-primary">
                                    <th colSpan={4} className="text-center py-0">
                                        CANTIDADES
                                    </th>
                                </tr>
                                <tr className="table-primary">
                                    <th className="text-center py-0 w-25">TOTAL</th>
                                    <th className="text-center py-0 w-25">DIVIDIDA</th>
                                    <th className="text-center py-0 w-25">RESTANTE</th>
                                    <th className="text-center py-0 w-25">EN SUB ORDER</th>
                                </tr>
                            </thead>
                            <tr>
                                <td className="text-center">
                                    {formatNumber(transportationOrderItem.quantity ?? 0)}
                                </td>
                                <td className="text-center">{formatNumber(getQuantityAdded())}</td>
                                <td className="text-center">
                                    {formatNumber(getQuantityAvailable())}
                                </td>
                                <td className="text-center">
                                    {formatNumber(transportationOrderItem.quantity_used ?? 0)}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-12">
                    {!fetchingEditTransportationOrderItem ? (
                        <>
                            {canBeEdited ? (
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <ButtonCreate callbackCreate={addSplit} />
                                    </div>
                                </div>
                            ) : (
                                <AlertBootstrap type="warning">
                                    Este pedido ya no puede ser dividido.
                                </AlertBootstrap>
                            )}
                            <TransportationOrderItemSplitForm
                                splitData={splitData}
                                setSplitData={setSplitData}
                                canBeEdited={canBeEdited}
                            />
                        </>
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingSplitTransportationOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={storeSplit}
                        locked={fetchingSplitTransportationOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemSplit;
