import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Provider } from '../../../../app/models/Provider';
import { Society } from '../../../../app/models/Society';
import LazyLoading from '../../../../components/LazyLoading';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import {
    ServiceManifestDocumentDataForm,
    ServiceManifestDocumentType
} from '../../../../app/models/ServiceManifestDocument';

interface Props {
    serviceManifestDocumentDataForm: ServiceManifestDocumentDataForm;
}

const ServiceManifestDocumentFormShow = ({ serviceManifestDocumentDataForm }: Props) => {
    if (!serviceManifestDocumentDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Nombre</label>
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="name"
                        value={serviceManifestDocumentDataForm.name}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de documento</label>
                    <input
                        className="form-control"
                        type="text"
                        name="document_type"
                        id="document_type"
                        value={serviceManifestDocumentDataForm.document_type}
                        disabled={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Documento</label>
                    {serviceManifestDocumentDataForm.file_url ? (
                        <div>
                            <a
                                href={serviceManifestDocumentDataForm.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success btn-sm"
                                role="button"
                                aria-pressed="true"
                            >
                                Documento
                            </a>
                        </div>
                    ) : (
                        <div>
                            <p>No tiene documento</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDocumentFormShow;
