import Swal from 'sweetalert2';
import { defaultSwalStyleButtons, defaultSwalStyleTwoButtons } from '../config';

interface requestConfirmationProps {
    title: string;
    text: string;
    html?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

interface requestConfirmationOptionsProps {
    title: string;
    text: string;
    html?: string;
    addButtonText?: string;
    updateButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    onConfirmAdd?: () => void;
    onConfirmUpdate?: () => void;
}

interface alertNotificationProps {
    title: string;
    text: string;
    icon: 'success' | 'error' | 'warning' | 'info' | 'question';
    confirmButtonText?: string;
}

interface alertNotificationTypeProps {
    title: string;
    text: string;
    confirmButtonText?: string;
}

const useSweetAlert = () => {
    const requestConfirmation = ({
        title = '¿Está seguro?',
        text = 'Confirme para continuar',
        confirmButtonText = 'Si,confirmar',
        cancelButtonText = 'No, cancelar',
        showCancelButton = true,
        html = '',
        onConfirm = () => {
            return;
        },
        onCancel = () => {
            return;
        }
    }: requestConfirmationProps) => {
        const swalWithBootstrapButtons = Swal.mixin(defaultSwalStyleButtons);

        swalWithBootstrapButtons
            .fire({
                title: title,
                text: text,
                html: html,
                icon: 'warning',
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText,
                showCancelButton: showCancelButton,
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    onConfirm();
                } else {
                    onCancel();
                }
            });
    };

    const requestConfirmationTwoOptions = ({
        title = '¿Está seguro?',
        text = 'Confirme para continuar',
        addButtonText = 'Agregar',
        updateButtonText = 'Actualizar',
        cancelButtonText = 'Cancelar',
        showCancelButton = true,
        html = '',
        onConfirmAdd = () => {
            return;
        },
        onConfirmUpdate = () => {
            return;
        },
        onCancel = () => {
            return;
        }
    }: requestConfirmationOptionsProps) => {
        const swalWithBootstrapButtons = Swal.mixin(defaultSwalStyleTwoButtons);

        swalWithBootstrapButtons
            .fire({
                title: title,
                text: text,
                html: html,
                icon: 'warning',
                confirmButtonText: addButtonText,
                cancelButtonText: cancelButtonText,
                showCancelButton: showCancelButton,
                showDenyButton: true,
                denyButtonText: updateButtonText,
                reverseButtons: false,
                showCloseButton: false,
                focusConfirm: false,
                focusCancel: false,
                allowEscapeKey: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    onConfirmAdd();
                } else if (result.isDenied) {
                    onConfirmUpdate();
                } else {
                    onCancel();
                }
            });
    };

    const alertNotification = ({
        title,
        text,
        confirmButtonText = 'Ok',
        icon = 'success'
    }: alertNotificationProps) => {
        const swalWithBootstrapButtons = Swal.mixin(defaultSwalStyleButtons);

        swalWithBootstrapButtons.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: confirmButtonText,
            // cancelButtonText: 'Cancelar',
            showCancelButton: false,
            reverseButtons: true
        });
    };

    const successAlert = ({
        title,
        text,
        confirmButtonText = 'Ok',
    }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'success',
        });
    };

    const errorAlert = ({ title, text, confirmButtonText = 'Ok' }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'error'
        });
    };

    const warningAlert = ({
        title,
        text,
        confirmButtonText = 'Ok'
    }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'warning'
        });
    };

    const loadingAlert = () => {
        Swal.mixin({
            title: 'Cargando...',
            allowOutsideClick: false,
            timer: 2000,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    };

    const spinnerAlert = (title = 'Cargando...', width = 300) => {
        Swal.fire({
            width: width,
            title: title,
            allowOutsideClick: false,
            // timer: 5000,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    };

    const closeSpinnerAlert = () => {
        Swal.close();
    };

    const infoAlert = ({ title, text, confirmButtonText = 'Ok' }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'info'
        });
    };

    const questionAlert = ({
        title,
        text,
        confirmButtonText = 'Ok'
    }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'question'
        });
    };
    return {
        requestConfirmation,
        requestConfirmationTwoOptions,
        successAlert,
        loadingAlert,
        errorAlert,
        warningAlert,
        infoAlert,
        questionAlert,
        spinnerAlert,
        closeSpinnerAlert
    };
};
export default useSweetAlert;
