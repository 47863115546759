import React, { useEffect } from 'react';
import { SalesInvoiceDataForm } from '../../../../app/models/SalesInvoice';
import { momentParseDate } from '../../../../helpers';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

interface Props {
    salesInvoice: SalesInvoiceDataForm;
    setSalesInvoice: (salesInvoice: SalesInvoiceDataForm) => void;
    errorFields?: any;
    disabled?: boolean;

}

const SalesInvoiceDetailForm = ({ salesInvoice, setSalesInvoice, errorFields,disabled }: Props) => {


    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();


    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);
    
    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setSalesInvoice({ ...salesInvoice, [name]: value });
    };
    return (
        <div className="sales-invoice-detail-form">
            {/* Fecha de Emisión and Folio */}
            <div className="form-group-row" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="issueDate">Fecha de Emisión:</label>
                        <input
                            type="date"
                            className="form-control"
                            id="issue_date"
                            name="issue_date"
                            value={salesInvoice.issue_date}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('issue_date')}
                            disabled={disabled}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex', minHeight: '20px', alignItems: 'center' }}>
                            {fieldErrorMessage('issue_date')}
                        </div>
                    </div>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="folio">Folio:</label>
                        <input
                            className={`form-control ${fieldHasError('folio')}`}
                            name="folio"
                            id="folio"
                            type="text"
                            value={salesInvoice.folio}
                            onChange={handleChange}
                            placeholder="Ingrese el folio de la factura"
                            onFocus={() => onFocusRemove('folio')}
                            disabled={disabled}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex', minHeight: '20px', alignItems: 'center' }}>
                            {fieldErrorMessage('folio')}
                        </div>
                    </div>
                </div>

            {/* Espacio adicional */}
            <div className="mt-3"></div>

            {/* Datos del Emisor */}
            {/* <h4>Datos del Emisor</h4> */}
            <div className="h2 mt-0 mb-2 card-title">Datos del Emisor</div>
            <div className="form-group mb-3">
                <label htmlFor="issuerSociety">Sociedad:</label>
                <input
                    type="text"
                    id="issuerSociety"
                    className="form-control"
                    value={salesInvoice?.society_name || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="issuerAddress">Direccion:</label>
                <input
                    type="text"
                    id="issuerAddress"
                    className="form-control"
                    value={salesInvoice?.society_address || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="issuerEmail">Email:</label>
                <input
                    type="text"
                    id="issuerEmail"
                    className="form-control"
                    value={salesInvoice?.society_email || ''}
                    disabled
                />
            </div>

            {/* Datos del Receptor */}
            <div className="h2 mt-0 mb-2 card-title">Datos del Receptor</div>
            <div className="form-group mb-3">
                <label htmlFor="receiverClient">Cliente:</label>
                <input
                    type="text"
                    id="receiverClient"
                    className="form-control"
                    value={salesInvoice?.client_name || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="receiverAddress">Direccion:</label>
                <input
                    type="text"
                    id="receiverAddress"
                    className="form-control"
                    value={salesInvoice?.client_address || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="receiverEmail">Email:</label>
                <input
                    type="text"
                    id="receiverEmail"
                    className="form-control"
                    value={salesInvoice?.client_email || ''}
                    disabled
                />
            </div>
        </div>
    );
};

export default SalesInvoiceDetailForm;
