import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ItemCategory } from '../../../app/models/ItemCategory';
import ItemCategoryForm from './forms/ItemCategoryForm';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    itemCategory: ItemCategory;
    setItemCategory: (itemCategory: ItemCategory) => void;
    errorFields?: any;
}

const ItemCategoryFormContainer = ({
    fetching,
    action,
    cancel,
    itemCategory,
    setItemCategory,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ItemCategoryForm
                        itemCategoryDataForm={itemCategory}
                        setItemCategoryDataForm={setItemCategory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ItemCategoryFormContainer;
