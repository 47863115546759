import { Group } from './Group';

export interface User {
    id?: number;
    name?: string;
    email?: string;
    password?: string;
    first_name?: string;
    last_name?: string;
    rut: string;
    second_last_name?: string;
    birthdate: string;
    nationality?: string;
    gender?: string;
    address?: string;
    phone_code?: string;
    phone?: string;
    is_active: boolean;
    groups?: Group[];
}

export interface UserSimpleList {
    id?: number;
    name?: string;
}

export interface UserPasswordDataForm {
    old_password?: string;
    password?: string;
    repeat_password?: string;
}

export interface IconPasswordChange {
    value: boolean;
    type: string;
}

export const defaultIconPasswordChange: IconPasswordChange = {
    value: true,
    type: 'password'
};

export const defaultUserDataForm: User = {
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
    password: '',
    address: '',
    groups: undefined,
    birthdate: '',
    rut: '',
    is_active: true
};

export const defaultUserPasswordDataForm: UserPasswordDataForm = {
    password: '',
    repeat_password: ''
};

export interface UserFilters {
    rut?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    second_last_name?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface UserDataForm {
    id?: number;
    name?: string;

    rut: string;
    second_last_name?: string;
    first_name?: string;
    last_name?: string;
    password?: string;
    email?: string;
    birthdate: string;
    nationality?: string;
    gender?: string;
    address?: string;
    phone_code?: string;
    phone?: string;
    is_active: boolean;
    groups?: Group[];
    avatar?: File;
    avatar_url?: string;
}
