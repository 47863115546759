import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { Society } from '../../../../app/models/Society';

import LazyLoading from '../../../../components/LazyLoading';
import { BatchDataForm } from '../../../../app/models/Batch';
import { BatchType } from '../../../../app/models/BatchType';
import { Unit } from '../../../../app/models/Unit';
import { Depot } from '../../../../app/models/Depot';
import { Product } from '../../../../app/models/Product';
import { Brand } from '../../../../app/models/Brand';

interface Props {
    batchDataForm: BatchDataForm;
    setBatchDataForm: (batchDataForm: BatchDataForm) => void;
    societies: Society[];
    batchTypes: BatchType[];
    brands: Brand[];
    unitCapacities: Unit[];
    depots: Depot[];
    products: Product[];
    errorFields?: any;
}

const BatchForm = ({
    batchDataForm,
    setBatchDataForm,
    societies,
    batchTypes,
    brands,
    unitCapacities,
    depots,
    products,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setBatchDataForm({ ...batchDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setBatchDataForm({ ...batchDataForm, ...obj });
    };

    const changeMultipleSelectTwo = (taxesSelect: any) => {
        const obj = { taxes: taxesSelect };

        setBatchDataForm({
            ...batchDataForm,
            ...obj
        });
    };

    if (!batchDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedad</label>
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={batchDataForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Código</label>
                    <input
                        name="code"
                        id="code"
                        type="text"
                        value={batchDataForm.code}
                        className={`form-control ${fieldHasError(`code`)}`}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`code`)}
                        placeholder="Ingrese código"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`code`)}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Tipo lote</label>
                    <SelectTwo
                        name="batch_type_id"
                        id="batch_type_id"
                        inputValue={batchDataForm.batch_type_id}
                        options={SelectTwoMapperOptions(batchTypes, 'id', ['name'])}
                        hasError={fieldHasError('batch_type_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('batch_type_id', value.value)
                        }
                        placeholder={'Seleccione tipo lote'}
                        onFocus={() => onFocusRemove('batch_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('batch_type_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Marca</label>
                    <SelectTwo
                        name="brand_id"
                        id="brand_id"
                        inputValue={batchDataForm.brand_id}
                        options={SelectTwoMapperOptions(brands, 'id', ['name'])}
                        hasError={fieldHasError('brand_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('brand_id', value.value)}
                        placeholder={'Seleccione marca'}
                        onFocus={() => onFocusRemove('brand_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('brand_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Producto etiqueta</label>
                    <SelectTwo
                        name="product_label_id"
                        id="product_label_id"
                        inputValue={batchDataForm.product_label_id}
                        options={SelectTwoMapperOptions(products, 'id', ['name'])}
                        hasError={fieldHasError('product_label_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('product_label_id', value.value)
                        }
                        placeholder={'Seleccione producto'}
                        onFocus={() => onFocusRemove('product_label_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('product_label_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Almacén</label>
                    <SelectTwo
                        name="depot_id"
                        id="depot_id"
                        inputValue={batchDataForm.depot_id}
                        options={SelectTwoMapperOptions(depots, 'id', ['name'])}
                        hasError={fieldHasError('depot_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('depot_id', value.value)}
                        placeholder={'Seleccione almacén'}
                        onFocus={() => onFocusRemove('depot_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('depot_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Capacidad</label>
                    <input
                        name="capacity"
                        id="capacity"
                        type="number"
                        value={batchDataForm.capacity}
                        className={`form-control ${fieldHasError(`capacity`)}`}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`capacity`)}
                        placeholder="Ingrese capacidad"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`capacity`)}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Unidad capacidad</label>
                    <SelectTwo
                        name="unit_capacity_id"
                        id="unit_capacity_id"
                        inputValue={batchDataForm.unit_capacity_id}
                        options={SelectTwoMapperOptions(unitCapacities, 'id', ['name'])}
                        hasError={fieldHasError('unit_capacity_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('unit_capacity_id', value.value)
                        }
                        placeholder={'Seleccione unidad'}
                        onFocus={() => onFocusRemove('unit_capacity_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('unit_capacity_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={batchDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BatchForm;
