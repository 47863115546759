import React from 'react';
import { IconProps } from './types'; 

export const DefaultIcon: React.FC<any> = ({ svgHTML, size = 20, ...rest }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: svgHTML
          .replace('width="', `width="${size}"`)
          .replace('height="', `height="${size}"`)

      }}
    />
  );
};
