import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { BatchDataForm } from '../../../app/models/Batch';
import BatchDetailContainer from '../components/BatchDetailContainer';
import BatchFormShow from '../components/forms/BatchFormShow';
import { Tab, Tabs } from 'react-bootstrap';
import BatchDetailAccountingContainer from '../components/BatchDetailAccountingContainer';
import BatchHistoryContainer from '../components/BatchHistoryContainer';
import useBatchService from '../../../app/services/hooks/useBatchService';

const BatchShow = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Lotes',
            url: '/batches',
            isActive: false
        },
        {
            name: 'Ver lote #' + id,
            url: '/batches/' + id + '/show',
            isActive: true
        }
    ];

    const [batch, setBatch] = useState<BatchDataForm>({});

    const { auth } = useContext(AuthContext);

    const { fetchingShowBatch, showBatch } = useBatchService();

    useEffect(() => {
        reloadModule();
    }, [id]);

    const reloadModule = () => {
        show(parseInt(id));
    };

    const show = (id: number) => {
        showBatch(id, {
            onSuccess: (response: ServiceResponse) => {
                setBatch(response.data.batch);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/batches');
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar lote #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingShowBatch ? (
                            <>
                                <BatchFormShow batchDataForm={batch} />
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6"></div>
            </div>
            <Tabs defaultActiveKey="general-data" id="batch-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Disponible">
                    <BatchDetailContainer batchId={parseInt(id)} />
                </Tab>
                <Tab eventKey="parameters-data" title="Parametros"></Tab>
                <Tab eventKey="in-progress-data" title="Reservado">
                    <BatchDetailAccountingContainer batchId={parseInt(id)} />
                </Tab>
                <Tab eventKey="batch-history-data" title="Historial lote">
                    <BatchHistoryContainer batchId={parseInt(id)} />
                </Tab>
            </Tabs>

            {/*             
            <BatchChangeStatus
                status={String(Batch?.status)}
                statuses={statuses}
                requestChangeStatus={requestChangeStatus}
                fetchingChangeStatus={fetchingChangeStatusBatch}
            /> */}
        </>
    );
};

export default BatchShow;
