import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import ServiceFormContainer from './ServiceFormContainer';
import { ServiceDataForm, defaultServiceDataForm } from '../../../app/models/Service';
import useServiceService from '../../../app/services/hooks/useServiceService';
import { Sector } from '../../../app/models/Sector';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import { Tax } from '../../../app/models/Tax';
import { ItemCategory } from '../../../app/models/ItemCategory';

interface Props {
    serviceId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceEditEdit = ({ serviceId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditService, editService, fetchingUpdateService, updateService } =
        useServiceService();
    const [service, setService] = useState<ServiceDataForm>(defaultServiceDataForm);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [priceUnits, setPriceUnits] = useState<Unit[]>([]);
    const[units,SetUnits] =useState<Unit[]>([]);

    const [itemCategories, setItemCategories] = useState<ItemCategory[]>([]);
    const [taxes, setTaxes] = useState<Tax[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando servicio...');
        editService(serviceId, {
            onSuccess: (response: ServiceResponse) => {
                
                setSectors(response.data.sectors);
                setCurrencies(response.data.currencies);
                setPriceUnits(response.data.price_units);
                setItemCategories(response.data.item_categories);
                setTaxes(response.data.taxes);
                setTaxes(response.data.taxes);
                SetUnits(response.data.units);
                const _service = response.data.service;
                _service.taxes = response.data.service.taxes.map(
                    (tax: any) => {
                        return {
                            value: tax.id,
                            label: tax.name,
                         
                        };
                    }
                );
            
                setService(_service);


                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando servicio...');
        updateService(serviceId, service, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditService ? (
        <LazyLoading height="300" />
    ) : (
        <ServiceFormContainer
            fetching={fetchingUpdateService}
            sectors={sectors}
            itemCategories={itemCategories}
            currencies={currencies}
            priceUnits={priceUnits}
            units={units}
            taxesData={taxes}
            action={update}
            cancel={cancel}
            service={service}
            setService={setService}
            errorFields={errorFields}
        />
    );
};

export default ServiceEditEdit;
