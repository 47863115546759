import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { Unit } from '../../../app/models/Unit';
import useUnitService from '../../../app/services/hooks/useUnitService';
import UnitFormContainer from './UnitFormContainer';

interface Props {
    unitId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UnitEdit = ({ unitId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditUnit, editUnit, fetchingUpdateUnit, updateUnit } = useUnitService();

    const [unit, setUnit] = useState<Unit>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando unidad...');
        editUnit(unitId, {
            onSuccess: (response: ServiceResponse) => {
                setUnit(response.data.unit);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando unidad...');
        updateUnit(unitId, unit, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditUnit ? (
        <LazyLoading height="300" />
    ) : (
        <UnitFormContainer
            fetching={fetchingUpdateUnit}
            action={update}
            cancel={cancel}
            unit={unit}
            setUnit={setUnit}
            errorFields={errorFields}
        />
    );
};

export default UnitEdit;
