import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { AccountDataForm, AccountFilters } from '../../models/Account';

const useAccountService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetAccounts, setFetchingGetAccounts] = useState(false);
    const [fetchingShowAccount, setFetchingShowAccount] = useState(false);
    const [fetchingCreateAccount, setFetchingCreateAccount] = useState(false);
    const [fetchingStoreAccount, setFetchingStoreAccount] = useState(false);
    const [fetchingEditAccount, setFetchingEditAccount] = useState(false);
    const [fetchingUpdateAccount, setFetchingUpdateAccount] = useState(false);
    const [fetchingDeleteAccount, setFetchingDeleteAccount] = useState(false);
    const [fetchingActiveAccount, setFetchingActiveAccount] = useState(false);

    const getAccounts = (accountFilters: AccountFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(accountFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.ACCOUNT.GET_ACCOUNTS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetAccounts
        });
    };

    const showAccount = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACCOUNT.SHOW_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingShowAccount
        });
    };

    const createAccount = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACCOUNT.CREATE_ACCOUNT,
            setFetching: setFetchingCreateAccount
        });
    };

    const storeAccount = (account: AccountDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.ACCOUNT.STORE_ACCOUNT,
            body: account,
            setFetching: setFetchingStoreAccount
        });
    };

    const editAccount = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACCOUNT.EDIT_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingEditAccount
        });
    };

    const updateAccount = (id: number, account: AccountDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.ACCOUNT.UPDATE_ACCOUNT.replace(':id', id.toString()),
            body: account,
            setFetching: setFetchingUpdateAccount
        });
    };

    const deleteAccount = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.ACCOUNT.DELETE_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteAccount
        });
    };

    const activeAccount = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.ACCOUNT.ACTIVE_ACCOUNT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveAccount
        });
    };

    return {
        fetchingGetAccounts,
        fetchingShowAccount,
        fetchingCreateAccount,
        fetchingStoreAccount,
        fetchingEditAccount,
        fetchingUpdateAccount,
        fetchingDeleteAccount,
        fetchingActiveAccount,
        getAccounts,
        showAccount,
        createAccount,
        storeAccount,
        editAccount,
        updateAccount,
        deleteAccount,
        activeAccount
    };
};

export default useAccountService;
