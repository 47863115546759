import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ItemCategory, ItemCategoryFilters } from '../../models/ItemCategory';

const useItemCategoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetItemCategories, setFetchingGetItemCategories] = useState(false);
    const [fetchingShowItemCategory, setFetchingShowItemCategory] = useState(false);
    const [fetchingStoreItemCategory, setFetchingStoreItemCategory] = useState(false);
    const [fetchingEditItemCategory, setFetchingEditItemCategory] = useState(false);
    const [fetchingUpdateItemCategory, setFetchingUpdateItemCategory] = useState(false);
    const [fetchingDeleteItemCategory, setFetchingDeleteItemCategory] = useState(false);
    const [fetchingActiveItemCategory, setFetchingActiveItemCategory] = useState(false);
    const [fetchingCreateItemCategory, setFetchingCreateItemCategory] = useState(false);


    const getItemCategories = (ItemCategoryFilters: ItemCategoryFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(ItemCategoryFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.ITEM_CATEGORY.GET_ITEM_CATEGORIES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetItemCategories
        });
    };

    const showItemCategory = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ITEM_CATEGORY.SHOW_ITEM_CATEGORY.replace(':id', id.toString()),
            setFetching: setFetchingShowItemCategory
        });
    };

    const createItemCategory = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ITEM_CATEGORY.CREATE_ITEM_CATEGORY,
            setFetching: setFetchingCreateItemCategory
        });
    };

    const storeItemCategory = (ItemCategory: ItemCategory, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.ITEM_CATEGORY.STORE_ITEM_CATEGORY,
            body: ItemCategory,
            setFetching: setFetchingStoreItemCategory
        });
    };

    const editItemCategory = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ITEM_CATEGORY.EDIT_ITEM_CATEGORY.replace(':id', id.toString()),
            setFetching: setFetchingEditItemCategory
        });
    };

    const updateItemCategory = (id: number, itemCategory: ItemCategory, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.ITEM_CATEGORY.UPDATE_ITEM_CATEGORY.replace(':id', id.toString()),
            body: itemCategory,
            setFetching: setFetchingUpdateItemCategory
        });
    };

    const deleteItemCategory = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.ITEM_CATEGORY.DELETE_ITEM_CATEGORY.replace(':id', id.toString()),
            setFetching: setFetchingDeleteItemCategory
        });
    };

    const activeItemCategory = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.ITEM_CATEGORY.ACTIVE_ITEM_CATEGORY.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveItemCategory
        });
    };

    return {
        fetchingGetItemCategories,
        fetchingShowItemCategory,
        fetchingStoreItemCategory,
        fetchingEditItemCategory,
        fetchingUpdateItemCategory,
        fetchingDeleteItemCategory,
        fetchingActiveItemCategory,
        fetchingCreateItemCategory,
        getItemCategories,
        showItemCategory,
        storeItemCategory,
        editItemCategory,
        updateItemCategory,
        deleteItemCategory,
        activeItemCategory,
        createItemCategory
    };
};

export default useItemCategoryService;
