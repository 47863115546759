import { useHistory } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { UserDataForm, UserFilters } from '../../app/models/User';

import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import useUserService from '../../app/services/hooks/useUserService';
import DefaultModal from '../../components/default/DefaultModal';
import UserCreate from './components/UserCreate';
import UserEdit from './components/UserEdit';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import UserDataTable from './components/tables/UserDataTable';
import { toast } from 'react-toastify';
import UserPasswordEdit from './components/UserPasswordEdit';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Usuarios',
        url: '/users',
        isActive: true
    }
];

const User = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetUsers, getUsers } = useUserService();

    const { auth } = useContext(AuthContext);

    const [userIdEdit, setUserIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);

    const [userPasswordIdEdit, setUserPasswordIdEdit] = useState<number>(-1);
    const [showingPasswordEdit, setShowingPasswordEdit] = useState(false);
    const [users, setUsers] = useState<UserDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);

    const [showingCreate, setShowingCreate] = useState(false);

    const [filter, setFilter] = useState<UserFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getUsers(filter, {
            onSuccess: (response: ServiceResponse) => {
                setUsers(response.data.users);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (UserId: number) => {
        setShowingEdit(true);
        setUserIdEdit(UserId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setUserIdEdit(-1);
    };

    const onError = () => {
        setShowingEdit(false);
        setUserIdEdit(-1);
    };

    const showPasswordEdit = (UserId: number) => {
        setShowingPasswordEdit(true);
        setUserPasswordIdEdit(UserId);
    };

    const hidePasswordEdit = () => {
        setShowingPasswordEdit(false);
        setUserPasswordIdEdit(-1);
    };

    // const destroy = (userId: number) => {
    //     const _text = 'Está a punto de eliminar el usuario #' + userId;
    //
    //     useReactConfirmAlert().requestConfirmation({
    //         title: '¿Estás seguro?',
    //         message: _text,
    //         buttons: {
    //             confirmButton: {
    //                 label: 'Si, confirmar',
    //                 onClick: () => {
    //                     if (showLoading) showLoading('loading', 'Eliminando usuario...');
    //                     deleteUser(userId, {
    //                         onSuccess: (response: ServiceResponse) => {
    //                             if (hideLoading) hideLoading();
    //                             // useSweetAlert().successAlert({
    //                             //     title: 'Éxito',
    //                             //     text: response.message
    //
    //                             // });
    //                             useReactConfirmAlert().successAlert({
    //                                 title: 'Éxito',
    //                                 message: response.message
    //                             });
    //                             // if(changeAnimation)changeAnimation('success', response.message, true);
    //                             reloadTable();
    //                         },
    //                         onError: (response: ServiceResponse) => {
    //                             if (hideLoading) hideLoading();
    //
    //                             useReactConfirmAlert().errorAlert({
    //                                 title: 'Error',
    //                                 message: response.message
    //                             });
    //                         }
    //                     });
    //                 }
    //             },
    //             cancelButton: {
    //                 label: 'No, cancelar',
    //                 onClick: () => {
    //                     setTimeout(() => {
    //                         useReactConfirmAlert().infoAlert({
    //                             title: 'Cancelado',
    //                             message: 'El usuario no se ha eliminado.'
    //                         });
    //                     }, 0);
    //                 }
    //             }
    //         }
    //     });
    //
    // };

    return (
        <>
            <Breadcrumbs pageSection="Usuarios" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate callbackCreate={showCreate} title="Nuevo usuario" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <UserDataTable
                            users={users}
                            totalRows={totalRows}
                            loading={fetchingGetUsers}
                            edit={showEdit}
                            editPassword={showPasswordEdit}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear usuario"
                    backdrop={true}
                    showFooter={false}
                >
                    <UserCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Usuario"
                    backdrop={true}
                    showFooter={false}
                >
                    <UserEdit
                        userId={userIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}

            {showingPasswordEdit ? (
                <DefaultModal
                    show={showingPasswordEdit}
                    handleClose={hidePasswordEdit}
                    title="Editar contraseña"
                    backdrop={true}
                    showFooter={false}
                >
                    <UserPasswordEdit
                        userId={userPasswordIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hidePasswordEdit();
                        }}
                        onCancel={hidePasswordEdit}
                        onError={hidePasswordEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default User;
