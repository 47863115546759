import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';

const useCommuneService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCommunesByRegion, setFetchingGetCommunesByRegion] = useState(false);

    const getCommunesByRegion = (region_id: number, events: ServiceEvents = {}) => {
        const url = EndPoints.COMMUNES.GET_COMMUNES_BY_REGION;
        doGet({
            ...events,
            url: url.replace(':region_id', region_id.toString()),
            setFetching: setFetchingGetCommunesByRegion
        });
    };

    return {
        fetchingGetCommunesByRegion,
        getCommunesByRegion
    };
};

export default useCommuneService;
