import React, { useContext, useEffect, useState } from 'react';

import { defaultProvider, Provider } from '../../../app/models/Provider';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ProviderFormContainer from './ProviderFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStoreProvider, storeProvider, createProvider, fetchingCreateProvider } =
        useProviderService();
    const [provider, setProvider] = useState<Provider>({...defaultProvider, is_national: true});
    const [providerTypes, setProviderTypes] = useState([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        createProvider({
            onSuccess: (response: ServiceResponse) => {
                setProviderTypes(response.data.provider_types);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Agregando proveedor...');

        storeProvider(provider, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateProvider ? (
        <LazyLoading height={300} />
    ) : (
        <ProviderFormContainer
            fetching={fetchingStoreProvider}
            action={store}
            cancel={cancel}
            providerTypes={providerTypes}
            provider={provider}
            setProvider={setProvider}
            errorFields={errorFields}
        />
    );
};

export default ProviderCreate;
