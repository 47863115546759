import React from 'react';
import { momentParseDate } from '../../../../helpers';
import { BatchHistoryFilters } from '../../../../app/models/BatchHistory';


type Props = {
    filter: BatchHistoryFilters;
    setFilter: (filter: BatchHistoryFilters) => void;
};

const BatchHistoryFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="item_name">
                            Producto
                        </label>
                        <input
                            title="Producto etiqueta"
                            type="text"
                            id="item_name"
                            name="item_name"
                            className="form-control form-control-sm"
                            value={filter.item_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="batch_type">
                            Tipo lote
                        </label>
                        <input
                            title="Tipo lote"
                            type="text"
                            id="batch_type"
                            name="batch_type"
                            className="form-control form-control-sm"
                            value={filter.batch_type}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <label htmlFor="selectGrande" className="mb-1 font-size-10 ">
                        Estado lote
                    </label>
                    <select
                        name="batch_status"
                        className="form-select form-select-sm"
                        value={filter.batch_status}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option key={''} value="">
                            Todos
                        </option>
                        <option key={'OPERATIONAL'} value="OPERATIONAL">
                            Operativa
                        </option>
                        <option key={'CLEANING'} value="CLEANING">
                            Limpieza
                        </option>
                        <option key={'MAINTENANCE'} value="MAINTENANCE">
                            Mantención
                        </option>
                        <option key={'BUSY'} value="BUSY">
                            Ocupada
                        </option>
                        <option key={'DELIST'} value="DELIST">
                            De baja
                        </option>
                    </select>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="depot">
                            Almacén
                        </label>
                        <input
                            title="Almacén"
                            type="text"
                            id="depot"
                            name="depot"
                            className="form-control form-control-sm"
                            value={filter.depot}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {/*<div className="col-md-auto">*/}
                {/*    <div className="mb-3">*/}
                {/*        <label className="font-size-10 mb-1" htmlFor="product_label">*/}
                {/*            Producto etiqueta lote*/}
                {/*        </label>*/}
                {/*        <input*/}
                {/*            title="Producto etiqueta lote"*/}
                {/*            type="text"*/}
                {/*            id="product_label"*/}
                {/*            name="product_label"*/}
                {/*            className="form-control form-control-sm"*/}
                {/*            value={filter.product_label}*/}
                {/*            onChange={(e) => handlerInputFilter(e)}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-md-auto">
                    <label htmlFor="selectGrande" className="mb-1 font-size-10 ">
                        Movimiento
                    </label>
                    <select
                        name="action_status"
                        className="form-select form-select-sm"
                        value={filter.action_status}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option key={''} value="">
                            Todos
                        </option>
                        <option
                            key={'STORE_FROM_SERVICE_MANIFEST_DETAIL'}
                            value="STORE_FROM_SERVICE_MANIFEST_DETAIL"
                        >
                            Ingreso desde hoja entrada
                        </option>
                        <option key={'CHANGE_BATCH_DETAIL'} value="CHANGE_BATCH_DETAIL">
                            Cambio en el detalle de lote
                        </option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default BatchHistoryFilter;
