import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login';
import RecoveryPassword from './pages/Auth/RecoveryPassword';
import MainTheme from './template/MainTheme';
import AppProvider from './contexts/AppContext';
import AuthProvider, { AuthContext } from './contexts/AuthContext';
import NotificationProvider from './contexts/NotificationContext';
import Dashboard from './pages/Dashboard';
import Quotes from './pages/Quotes';
import QuoteEdit from './pages/Quotes/subpages/QuoteEdit';
import PrivateMiddleware from './routes/middleware/PrivateMiddleware';
import QuoteShow from './pages/Quotes/subpages/QuoteShow';
import Clients from './pages/Clients';
import Places from './pages/Places';
import PurchaseOrders from './pages/PurchaseOrder';
import PurchaseOrderEdit from './pages/PurchaseOrder/subpages/PurchaseOrderEdit';
import PurchaseOrderShow from './pages/PurchaseOrder/subpages/PurchaseOrderShow';
import Taxes from './pages/Taxes';
import Products from './pages/Products';
import Services from './pages/Services';

import Carriers from './pages/Carriers';
import  SocietySignatures  from './pages/SocietySignature';
import Pusher from 'pusher-js';
import Notifications from './pages/Notifications';
import TransportationOrder from './pages/TransportationOrders';
import TransportationOrderEdit from './pages/TransportationOrders/subpages/TransportationOrderEdit';
import TransportationOrderShow from './pages/TransportationOrders/subpages/TransportationOrderShow';
import DispatchPrograms from './pages/DispatchProgram';
import DispatchProgramEdit from './pages/DispatchProgram/subpages/DispatchProgramEdit';
import DispatchProgramShow from './pages/DispatchProgram/subpages/DispatchProgramShow';
import DailyCarriers from './pages/DailyCarriers';
import Hoppers from './pages/Hoppers';
import Trucks from './pages/Trucks';
import Providers from './pages/Providers';
import PaymentStatuses from './pages/PaymentStatus';
import ProviderTypes from './pages/ProviderTypes';
import RouteSegment from './pages/RouteSegment';
import { Roles } from './app/shared/enums';
import { LOCAL_STORAGE } from './config';
import NotAuthorizes from './pages/NotAuthorized';
import useAuthService from './app/services/hooks/useAuthService';
import Incoterms from './pages/Incoterms';
import Sectors from './pages/Sectors';
import PackingForms from './pages/PackingForms';
import OriginAreas from './pages/OriginAreas';
import ItemCategories from './pages/ItemCategories';
import User from './pages/Users';
import Currencies from './pages/Currencies';
import Units from './pages/Units';
import ForeignExchange from './pages/ForeignExchange';
import ServiceManifests from './pages/ServiceManifest';
import ServiceManifestEdit from './pages/ServiceManifest/subpages/ServiceManifestEdit';
import ServiceManifestShow from './pages/ServiceManifest/subpages/ServiceManifestShow';
import BatchShow from './pages/Batches/subpages/BatchShow';
import BatchEdit from './pages/Batches/subpages/BatchEdit';
import Batches from './pages/Batches';
import BatchTypes from './pages/BatchTypes';
import Depots from './pages/Depots';
import Centers from './pages/Centers';
import Brands from './pages/Brands';
import Clasifications from './pages/Clasifications';
import Accounts from './pages/Accounts';
import Society from './pages/Societies';
import PaymentStatusEdit from './pages/PaymentStatus/components/subpages/PaymentStatusEdit';
import CheckingAccount from './pages/CheckingAccount';
import Banks from './pages/Banks';
import BankAccountTypes from './pages/BankAccountTypes';
import InternalSettlements from './pages/InternalSettlements';
import ContactTypes from './pages/ContactTypes';
import ClientEdit from './pages/Clients/subpages/ClientEdit';
import ProviderEdit from './pages/Providers/subpages/ProviderEdit';
import Groups from './pages/Groups';
import SocietyEdit from './pages/Societies/subpages/SocietyEdit';
import BankAccountReasons from './pages/BankAccountReasons';
import BusinessNames from './pages/BusinessName';
import BusinessNameEdit from './pages/BusinessName/subpages/BusinessNameEdit';
import PaymentMethods from './pages/PaymentMethods';
import SalesInvoices from './pages/SalesInvoice';
import SalesInvoiceEdit from './pages/SalesInvoice/components/subpages/SalesInvoiceEdit';
import Guides from './pages/Guides';


export const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
    // userAuthentication: {
    //     endpoint: EndPoints.AUTH.PUSHER_AUTH,
    //     transport: "ajax",
    //     params: {},
    //     headers: {},
    //   },
});

export const channel = pusher.subscribe('notifications');

function App() {
    const { getRoles } = useAuthService();

    const [roles, setRoles] = useState<string[]>([]);

    useEffect(() => {
        getRoles({
            onSuccess: (response) => {
                setRoles(response.data.roles);
            }
        });
    }, []);

    return (
        <AuthProvider>
            <AppProvider>
                <NotificationProvider>
                    <Router>
                        <Switch>
                            <Route path="/login">
                                <MainTheme showLayouts={false}>
                                    <Login />
                                </MainTheme>
                            </Route>
                            <Route path="/recovery-password">
                                <MainTheme showLayouts={false}>
                                    <RecoveryPassword />
                                </MainTheme>
                            </Route>
                            <PrivateMiddleware>
                                <Switch>
                                    <Route path="/dispatch-programs/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <DispatchProgramEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/dispatch-programs/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <DispatchProgramShow />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/quotes/:id/edit">
                                        <MainTheme showLayouts={true}>

                                                <QuoteEdit />

                                        </MainTheme>
                                    </Route>

                                    <Route path="/transportation-orders/:id/edit">
                                        <MainTheme showLayouts={true}>

                                                <TransportationOrderEdit />

                                        </MainTheme>
                                    </Route>
                                    <Route path="/transportation-orders/:id/show">
                                        <MainTheme showLayouts={true}>

                                                <TransportationOrderShow />

                                        </MainTheme>
                                    </Route>

                                    <Route path="/quotes/:id/show">
                                        <MainTheme showLayouts={true}>

                                                <QuoteShow />

                                        </MainTheme>
                                    </Route>

                                    <Route path="/clients/:id/edit">
                                        <MainTheme showLayouts={true}>

                                                <ClientEdit />

                                        </MainTheme>
                                    </Route>

                                    <Route path="/societies/:id/edit">
                                        <MainTheme showLayouts={true}>

                                                <SocietyEdit />

                                        </MainTheme>
                                    </Route>

                                    <Route path="/providers/:id/edit">
                                        <MainTheme showLayouts={true}>

                                                <ProviderEdit />

                                        </MainTheme>
                                    </Route>

                                    <Route path="/purchase-orders/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <PurchaseOrderEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/purchase-orders/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <PurchaseOrderShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/service-manifests/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <ServiceManifestEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/service-manifests/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <ServiceManifestShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/batches/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <BatchEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/batches/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <BatchShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/payment-statuses/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <PaymentStatusEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/business-names/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <BusinessNameEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/quotes">
                                        <MainTheme showLayouts={true}>

                                                <Quotes />

                                        </MainTheme>
                                    </Route>
                                    <Route path="/transportation-orders">
                                        <MainTheme showLayouts={true}>

                                                <TransportationOrder />

                                        </MainTheme>
                                    </Route>
                                    <Route path="/dispatch-programs">
                                        <MainTheme showLayouts={true}>
                                            <DispatchPrograms />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/guides">
                                        <MainTheme showLayouts={true}>
                                            <Guides />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/societies">
                                        <MainTheme showLayouts={true}>
                                            <Society />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/clients">
                                        <MainTheme showLayouts={true}>

                                                <Clients />

                                        </MainTheme>
                                    </Route>
                                    <Route path="/business-names">
                                        <MainTheme showLayouts={true}>
                                            <BusinessNames />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/providers">
                                        <MainTheme showLayouts={true}>
                                            <Providers />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/sales-invoices/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <SalesInvoiceEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/services">
                                        <MainTheme showLayouts={true}>
                                            <Services />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/currencies">
                                        <MainTheme showLayouts={true}>
                                            <Currencies />
                                        </MainTheme>
                                    </Route> 
                                    <Route path="/contact-types">
                                        <MainTheme showLayouts={true}>
                                            <ContactTypes />
                                        </MainTheme>
                                    </Route>  
                                    <Route path="/units">
                                        <MainTheme showLayouts={true}>
                                            <Units />
                                        </MainTheme>
                                    </Route>  
                                    <Route path="/foreign-exchanges">
                                        <MainTheme showLayouts={true}>
                                            <ForeignExchange />
                                        </MainTheme>
                                    </Route>           
                                    <Route path="/purchase-orders">
                                        <MainTheme showLayouts={true}>
                                            <PurchaseOrders />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/service-manifests">
                                        <MainTheme showLayouts={true}>
                                            <ServiceManifests />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/checking-accounts">
                                        <MainTheme showLayouts={true}>
                                            <CheckingAccount />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/internal-settlements">
                                        <MainTheme showLayouts={true}>
                                            <InternalSettlements />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/banks">
                                        <MainTheme showLayouts={true}>
                                            <Banks />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/bank-account-reasons">
                                        <MainTheme showLayouts={true}>
                                            <BankAccountReasons />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/bank-account-types">
                                        <MainTheme showLayouts={true}>
                                            <BankAccountTypes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/centers">
                                        <MainTheme showLayouts={true}>
                                            <Centers />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/depots">
                                        <MainTheme showLayouts={true}>
                                            <Depots />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/roles">
                                        <MainTheme showLayouts={true}>
                                            <Groups />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/sales-invoice">
                                        <MainTheme showLayouts={true}>
                                            <SalesInvoices />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/taxes">
                                        <MainTheme showLayouts={true}>
                                            <Taxes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/incoterms">
                                        <MainTheme showLayouts={true}>
                                            <Incoterms />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/sectors">
                                        <MainTheme showLayouts={true}>
                                            <Sectors />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/packing-forms">
                                        <MainTheme showLayouts={true}>
                                            <PackingForms />
                                        </MainTheme>
                                    </Route>
                                    {/*<Route path="/origin-areas">*/}
                                    {/*    <MainTheme showLayouts={true}>*/}
                                    {/*        <OriginAreas />*/}
                                    {/*    </MainTheme>*/}
                                    {/*</Route>*/}
                                    <Route path="/item-categories">
                                        <MainTheme showLayouts={true}>
                                            <ItemCategories />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/products">
                                        <MainTheme showLayouts={true}>
                                            <Products />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/users">
                                        <MainTheme showLayouts={true}>
                                            <User />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/places">
                                        <MainTheme showLayouts={true}>

                                                <Places />

                                        </MainTheme>
                                    </Route>
                                    <Route path="/provider-types">
                                        <MainTheme showLayouts={true}>
                                            <ProviderTypes />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/societies-signatures">
                                        <MainTheme showLayouts={true}>
                                            <SocietySignatures />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/trucks">
                                        <MainTheme showLayouts={true}>
                                            <Trucks />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/carriers">
                                        <MainTheme showLayouts={true}>
                                            <Carriers />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/accounts">
                                        <MainTheme showLayouts={true}>
                                            <Accounts/>
                                        </MainTheme>
                                    </Route>
                                    <Route path="/clasifications">
                                        <MainTheme showLayouts={true}>
                                            <Clasifications/>
                                        </MainTheme>
                                    </Route>
                                    <Route path="/hoppers">
                                        <MainTheme showLayouts={true}>
                                            <Hoppers />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/route-segments">
                                        <MainTheme showLayouts={true}>
                                            <RouteSegment />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/daily-carriers">
                                        <MainTheme showLayouts={true}>
                                            <DailyCarriers />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/payment-statuses">
                                        <MainTheme showLayouts={true}>
                                            <PaymentStatuses />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/payment-methods">
                                        <MainTheme showLayouts={true}>
                                            <PaymentMethods />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/notifications">
                                        <MainTheme showLayouts={true}>
                                            <Notifications />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/batches">
                                        <MainTheme showLayouts={true}>
                                            <Batches />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/batch-types">
                                        <MainTheme showLayouts={true}>
                                            <BatchTypes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/brands">
                                        <MainTheme showLayouts={true}>
                                            <Brands />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/">
                                        <MainTheme showLayouts={true}>
                                            <Dashboard />
                                        </MainTheme>
                                    </Route>
                                </Switch>
                            </PrivateMiddleware>
                        </Switch>
                    </Router>
                </NotificationProvider>
            </AppProvider>
        </AuthProvider>
    );
}

export default App;
