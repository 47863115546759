import React from 'react';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
};

const RouteSegmentFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            {/* primera fila */}
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="route_segment_id">
                            Tramo
                        </label>
                        <input
                            title="Tramo"
                            type="number"
                            id="route_segment_id"
                            name="route_segment_id"
                            className="form-control form-control-sm"
                            value={filter.route_segment_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Origen
                        </label>
                        <input
                            title="Origen"
                            type="text"
                            id="origin"
                            name="origin"
                            className="form-control form-control-sm"
                            value={filter.origin}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="destiny">
                            Destino
                        </label>
                        <input
                            title="Destino"
                            type="text"
                            id="destiny"
                            name="destiny"
                            className="form-control form-control-sm"
                            value={filter.destiny}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RouteSegmentFilter;
