import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { PaymentStatusDetail, PaymentStatusDetailDataForm, PaymentStatusDetailFilters } from '../../../../app/models/PaymentStatusDetail';
import { momentParseDate, momentParseDateTime } from '../../../../helpers';
import { formatAmount, formatMoney } from '../../../../utils/utils';


interface Props {
    paymentStatusDetails: PaymentStatusDetailDataForm[];
    totalRows: number;
    loading?: boolean;
    filter: PaymentStatusDetailFilters;
    setFilter: (filter: PaymentStatusDetailFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const PaymentStatusDetailDataTable = ({
    paymentStatusDetails,
    totalRows,
    loading = false,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => (
                <span className="">
                    {' '}
                    {row.id}
                </span>
            )
        },
        {
            name: 'Tramo',
            selector: (row: any) => row.route_segment_id,
            sortable: true,
            sortField: 'route_segment_id',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => (
                <span className="">{row.route_segment_id}</span>
            )
        },
        {
            name: 'Origen',
            selector: (row: any) => row.origin_name,
            sortable: true,
            sortField: 'origin_name',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => {
                return row.origin_name;
            }
        },
        {
            name: 'Destino',
            selector: (row: any) => row.destiny_name,
            sortable: true,
            sortField: 'destiny_name',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => {
                return row.destiny_name;
            }
        },
        {
            name: 'Fecha de Despacho',
            selector: (row: any) => row.dispatch_date,
            sortable: true,
            sortField: 'dispatch_date',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row.dispatch_date)}</span>
            )
        },
        {
            name: 'Tarifa',
            selector: (row: any) => row.rate,
            sortable: true,
            sortField: 'rate',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => (
                <span className="">{row.rate?formatMoney(row.rate, row?.currency_rate ?? 'CLP'): ''}</span>
            )
        },
        
        {
            name: 'Cantidad',
            selector: (row: any) => row.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => (
                <span className="">{row.quantity?formatAmount(row.quantity): ''} {row?.unit_quantity}</span>
            )
        },
        
        {
            name: 'Monto Total',
            selector: (row: any) => row.total_amount,
            sortable: true,
            sortField: 'total_amount',
            cell: (row: PaymentStatusDetailDataForm, index: any, column: any) => (
                <span className="">{row.total_amount?formatMoney(row.total_amount, row?.currency_rate ?? 'CLP'): ''}</span>
            )
        },
       
    ];

    return (
        <>

            <DefaultDataTable
                columns={columns}
                data={paymentStatusDetails}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default PaymentStatusDetailDataTable;
