import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Notification } from '../../../app/models/Notification';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Link } from 'react-router-dom';
import { momentParseDateTime } from '../../../helpers';
import useNotificationService from '../../../app/services/hooks/useNotificationService';

const NotificationDropdown = () => {
    const { notifications } = useContext(NotificationContext);
    const { setNotificationAsViewed } = useNotificationService();

    // const history = useHistory();

    const hasNotifications = () => notifications.length > 0;

    const truncateDescription = (description: string) => {
        if (description.length > 50) {
            return description.substring(0, 50) + '...';
        }
        return description;
    };

    const goToNotification = (notification: Notification) => {
        // history.replace('/notifications', {replace: true});
        window.location.replace('/notifications');
        setNotificationAsViewed(notification.id ?? -1);
    };

    return (
        <Dropdown className="d-inline-block menu-auth-top">
            <Dropdown.Toggle className="btn header-item noti-icon" id="page-header-user-dropdown">
                <i className={`bx bx-bell ${hasNotifications() && 'bx-tada'}`} />
                {hasNotifications() && (
                    <span className="badge bg-danger rounded-pill">{notifications.length}</span>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end">
                <div className="notifications-scroller">
                    {notifications.map((notification, key) => {
                        return (
                            <Dropdown.Item
                                key={`notify-${key}`}
                                className="py-2"
                                onClick={() => goToNotification(notification)}
                                style={{
                                    whiteSpace: 'unset'
                                }}
                            >
                                <h6 className="mt-0 mb-1">{notification.title}</h6>
                                <div className="font-size-12 text-muted">
                                    <p className="mb-1 text-break">
                                        {truncateDescription(notification.description ?? '')}
                                    </p>
                                    <p className="mb-1 font-size-1">
                                        <i className="mdi mdi-clock-outline" />{' '}
                                        {momentParseDateTime(notification.created_at)}
                                    </p>
                                </div>
                            </Dropdown.Item>
                        );
                    })}

                    {notifications.length === 0 && (
                        <Dropdown.Item>
                            <div className="font-size-12 text-muted">
                                <p className="mb-1 text-break">No hay nuevas notificaciones</p>
                            </div>
                        </Dropdown.Item>
                    )}
                </div>
                <div className="dropdown-divider" />
                <div className="dropdown-item pointer">
                    <Link to={'/notifications'}>Ver todas las notificaciones</Link>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NotificationDropdown;
