import React, { useState } from 'react';
import useSalesInvoiceService from '../../../app/services/hooks/useSalesInvoiceService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { SalesInvoiceStatusRequestDataForm } from '../../../app/models/SalesInvoice';
import { SalesInvoiceStatusEnum } from '../../../app/shared/enums';

interface Props {
    salesInvoiceId: number;
    statuses: SalesInvoiceStatusRequestDataForm[];
    selectedStatus: SalesInvoiceStatusRequestDataForm;
    callbackSuccess?: () => void;
}

const SalesInvoiceChangeStatus = ({ salesInvoiceId, statuses, selectedStatus, callbackSuccess }: Props) => {
    const [selected, setSelected] = useState<SalesInvoiceStatusRequestDataForm>(selectedStatus);
    const [paymentStatusIds, setPaymentStatusIds] = useState<number[]>([]);  // Lista para los payment_status_ids
    const [cancellationReason, setCancellationReason] = useState<string>('');  // Estado para la razón de anulación

    const { getSalesInvoiceDetails, salesInvoiceAccounted, salesInvoiceReview, salesInvoiceAnnulled } = useSalesInvoiceService();

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            useSweetAlert().successAlert({
                title: 'Éxito',
                text: 'Se ha cambiado el estado de la factura #' + salesInvoiceId
            });
        },
        onError: (response: ServiceResponse) => {
            useSweetAlert().closeSpinnerAlert();
            if (callbackSuccess) {
                callbackSuccess();
            }
            toast.error(response.message);
        }
    };

    const changeStatus = (value: string) => {
        if (value === selected.id) return null;
    
        const _status = statuses.find((status: SalesInvoiceStatusRequestDataForm) => status.id === value);
    
        setSelected(_status!);
    
        getSalesInvoiceDetails(salesInvoiceId, {
            onSuccess: (response: ServiceResponse) => {
                const paymentStatusIdsResponse = response.data.sales_invoice.payment_status_ids || [];
                setPaymentStatusIds(paymentStatusIdsResponse);
    
                let _text = '';
                if (value === SalesInvoiceStatusEnum.ANULADA) {
                    _text = `Está a punto de cambiar el estado de la factura #${salesInvoiceId} a Anulada. Los estados de pago [${paymentStatusIdsResponse.join(', ')}] serán desvinculados de la factura al realizar la anulación.`;
                } else if (value === SalesInvoiceStatusEnum.CONTABILIZADA) {
                    _text = `Está a punto de cambiar el estado de la factura #${salesInvoiceId} a Contabilizada con los siguientes estados de pago [${paymentStatusIdsResponse.join(', ')}].`;
                } else if (value === SalesInvoiceStatusEnum.REVISION) {
                    _text = `Está a punto de cambiar el estado de la factura #${salesInvoiceId} a Borrador.`;
                }
    
                useSweetAlert().requestConfirmation({
                    title: '¿Está seguro?',
                    text: '',  // Agrega la propiedad `text` que es requerida, puede estar vacía si ya usas `html`
                    html: value === SalesInvoiceStatusEnum.ANULADA 
                        ? `<p>Está a punto de cambiar el estado de la factura #${salesInvoiceId} a Anulada. Los estados de pago [${paymentStatusIdsResponse.join(', ')}] serán desvinculados de la factura al realizar la anulación.</p>
                           <textarea id="cancellationReason" placeholder="Escriba la razón de anulación" class="form-control" rows="3"></textarea>`
                        : _text,
                    confirmButtonText: 'Sí, cambiar estado',
                    cancelButtonText: 'Cancelar',
                    onConfirm: () => {
                        // Verificar si el estado es "ANULADA"
                        if (value === SalesInvoiceStatusEnum.ANULADA ) {
                            // Capturar la razón de anulación
                            const reason = (document.getElementById('cancellationReason') as HTMLTextAreaElement)?.value;
                    
                            console.log("Razón capturada:", reason);
                            
                            // Verificar si el valor de reason es válido antes de continuar
                            if (!reason || reason.trim() === '') {
                                useSweetAlert().errorAlert({
                                    title: 'Error',
                                    text: 'Debe proporcionar una razón para la anulación.',
                                });
                                setSelected(selectedStatus);  // Revertimos al estado anterior en caso de error

                                return;  // Detener si no hay razón
                            }
                    
                            // Mostrar mensaje de cargando
                            useSweetAlert().spinnerAlert('Cambiando estado...');
                    
                            // Enviar la razón junto con la anulación
                            salesInvoiceAnnulled(salesInvoiceId, { reason }, eventChangeStatus);  // Aquí pasamos el objeto { reason }
                        } else {
                            // Para otros estados, realiza la lógica correspondiente
                            if (value === 'CONTABILIZADA') {
                                salesInvoiceAccounted(salesInvoiceId, eventChangeStatus);
                            } else if (value === SalesInvoiceStatusEnum.REVISION) {
                                salesInvoiceReview(salesInvoiceId, eventChangeStatus);
                            } else {
                                useSweetAlert().errorAlert({
                                    title: 'Error',
                                    text: 'Opción no encontrada.'
                                });
                                setSelected(selectedStatus);  // Revertimos al estado anterior en caso de error
                            }
                        }
                    },
                    onCancel: () => {
                        useSweetAlert().infoAlert({
                            title: 'Cancelado',
                            text: 'El estado de la factura no se ha cambiado.'
                        });
                        setSelected(selectedStatus);
                    }
                });
            },
            onError: (response: ServiceResponse) => {
                toast.error('Error al obtener los detalles de la factura.');
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                    {status.name}
                </option>
            ))}
        </select>
    );
};

export default SalesInvoiceChangeStatus;
