import { Commune } from "./Commune";
import { Country } from "./Country";
import { Region } from "./Region";


export interface Center {
    id?: number;
    name?: string;
    address?: string;
    country?: Country;
    country_id?: number;
    region?: Region;
    region_id?: number;
    commune?: Commune;
    commune_id?: number;
    latitude?: number;
    longitude?: number;
    is_owner?: boolean;
    is_active?: boolean;
}

export interface CenterDataForm {
    id?: number;
    name?: string;
    address?: string;
    country?: string;
    country_id?: number;
    region?: string;
    region_id?: number;
    commune?: string;
    commune_id?: number;
    latitude?: number;
    longitude?: number;
    is_owner?: boolean;
    is_active?: boolean;
}

export const defaultCenterDataForm: CenterDataForm = {
    name: '',
    region_id: undefined,
    commune_id: undefined,
    country_id: undefined,
    address: '',
    is_owner: false
};

export interface CenterFilters {
    id?: number | null;
    name?: string | '';
    is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
