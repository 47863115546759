import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { ContactTypeDataForm } from '../../../../app/models/ContactType';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

type Props = {
    contactTypeDataForm: ContactTypeDataForm
    setContactTypeDataForm: (contactTypeDataForm: ContactTypeDataForm) => void;
    businessEntities: BusinessEntity[];
    errorFields?: any;
};

const ContactTypeForm = (
    { 
        contactTypeDataForm, 
        setContactTypeDataForm,
        businessEntities, 
        errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        
        setContactTypeDataForm({ ...contactTypeDataForm, [name]: value });
        
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setContactTypeDataForm({ ...contactTypeDataForm, ...obj });
    };

    return (
        <>

            <div className="mb-2 row needs-validation">
                <label className="col-md-12 col-form-label">Nombre</label>
                <div className="col-md-12">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={contactTypeDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Entidad</label>
                    <SelectTwo
                        name="business_entity"
                        id="business_entity"
                        inputValue={contactTypeDataForm.business_entity}
                        options={SelectTwoMapperOptions(businessEntities, 'id', ['name'])}
                        hasError={fieldHasError('business_entity') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('business_entity', value.value)}
                        placeholder={'Seleccione entidad'}
                        onFocus={() => onFocusRemove('business_entity')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('business_entity')}
                    </div>
                </div>
            </div>
         
        </>
    );
};

export default ContactTypeForm;
