import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useBatchService from '../../../app/services/hooks/useBatchService';
import { Society } from '../../../app/models/Society';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';

import useNavigationPage from '../../../hooks/useNavigationPage';
import { BatchDataForm, BatchStatus, defaultBatchDataForm } from '../../../app/models/Batch';
import { BatchType } from '../../../app/models/BatchType';
import { Unit } from '../../../app/models/Unit';
import { Depot } from '../../../app/models/Depot';
import { Product } from '../../../app/models/Product';
import BatchForm from '../components/forms/BatchForm';
import BatchDetailContainer from '../components/BatchDetailContainer';
import { Brand } from '../../../app/models/Brand';
import { Tab, Tabs } from 'react-bootstrap';
import BatchHistoryContainer from '../components/BatchHistoryContainer';
import BatchDetailAccountingContainer from '../components/BatchDetailAccountingContainer';

const BatchEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Lotes',
            url: '/batches',
            isActive: false
        },
        {
            name: 'Editar lote #' + id,
            url: '/batches/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const { editBatch, fetchingEditBatch, fetchingShowBatch, fetchingUpdateBatch, updateBatch } =
        useBatchService();

    const [batch, setBatch] = useState<BatchDataForm>(defaultBatchDataForm);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [batchTypes, setBatchTypes] = useState<BatchType[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [unitCapacities, setUnitCapacities] = useState<Unit[]>([]);

    const [depots, setDepots] = useState<Depot[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const [statuses, setStatuses] = useState<BatchStatus[]>([]);

    useEffect(() => {
        reloadModule();
    }, [id]);

    const reloadModule = () => {
        edit(parseInt(id));
    };

    const edit = (id: number) => {
        editBatch(id, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setBatchTypes(response.data.batch_types);
                setBrands(response.data.brands);
                setUnitCapacities(response.data.unit_capacities);
                setDepots(response.data.depots);
                setProducts(response.data.products);
                setStatuses(response.data.statuses);

                setBatch(response.data.batch);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/batches');
                }
            }
        });
    };

    const update = () => {
        if (batch != null) {
            if (showLoading) showLoading('loading', 'Actualizando lote...');
            updateBatch(Number(batch?.id), batch, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    // const requestChangeStatus = (status: string) => {
    //     if (showLoading) showLoading('loading', 'Cargando...');
    //     hasAllFinishedStatuses(parseInt(id), status, {
    //         onSuccess: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             changeStatus(status, response.message);
    //         },
    //         onError: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             useReactConfirmAlert().errorAlert({
    //                 title: 'Error',
    //                 message: response.message
    //             });
    //             toast.error(response.message);
    //         }
    //     });
    // };

    // const changeStatus = (status: string, message: string) => {
    //     const _text = `Está a punto de cambiar el estado del lote #${id}.${
    //         message ?? ''
    //     }`;

    //     useReactConfirmAlert().requestConfirmation({
    //         title: '¿Estás seguro?',
    //         message: _text,
    //         buttons: {
    //             confirmButton: {
    //                 label: 'Si, cambiar',
    //                 onClick: () => {
    //                     if (showLoading) showLoading('loading', 'Cambiando estado...');
    //                     changeStatusBatch(Number(id), status, {
    //                         onSuccess: (response: ServiceResponse) => {
    //                             if (hideLoading) hideLoading();
    //                             reloadModule();
    //                             useReactConfirmAlert().successAlert({
    //                                 title: 'Éxito',
    //                                 message: response.message
    //                             });
    //                         },
    //                         onError: (response: ServiceResponse) => {
    //                             if (hideLoading) hideLoading();

    //                             useReactConfirmAlert().errorAlert({
    //                                 title: 'Error',
    //                                 message: response.message
    //                             });
    //                         }
    //                     });
    //                 }
    //             },
    //             cancelButton: {
    //                 label: 'No, cancelar',
    //                 onClick: () => {
    //                     setTimeout(() => {
    //                         useReactConfirmAlert().infoAlert({
    //                             title: 'Cancelado',
    //                             message: 'El lote no ha cambiado el estado.'
    //                         });
    //                     }, 0);
    //                 }
    //             }
    //         }
    //     });
    // };

    return (
        <>
            <Breadcrumbs pageSection={`Editar lote #${id}`} breadcrumbs={breadcrumbs} />

            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditBatch ? (
                            <>
                                <BatchForm
                                    batchDataForm={batch}
                                    setBatchDataForm={setBatch}
                                    societies={societies}
                                    batchTypes={batchTypes}
                                    brands={brands}
                                    unitCapacities={unitCapacities}
                                    depots={depots}
                                    products={products}
                                    errorFields={errorFields}
                                />
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateBatch}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6"></div>
            </div>

            <Tabs defaultActiveKey="general-data" id="batch-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Disponible">
                    <BatchDetailContainer batchId={parseInt(id)} />
                </Tab>
                <Tab eventKey="parameters-data" title="Parametros"></Tab>
                <Tab eventKey="in-progress-data" title="Reservado">
                    <BatchDetailAccountingContainer batchId={parseInt(id)} />
                </Tab>
                <Tab eventKey="batch-history-data" title="Historial lote">
                    <BatchHistoryContainer batchId={parseInt(id)} />
                </Tab>
            </Tabs>

            {/*             
            <BatchChangeStatus
                status={String(Batch?.status)}
                statuses={statuses}
                requestChangeStatus={requestChangeStatus}
                fetchingChangeStatus={fetchingChangeStatusBatch}
            /> */}
        </>
    );
};

export default BatchEdit;
