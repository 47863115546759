import {
    SHOW_MAIN_MENU,
    HIDE_MAIN_MENU,
    SHOW_LOADING,
    HIDE_LOADING,
    CHANGE_ANIMATION,
    CHANGE_MAIN_MENU,
    CHANGE_EVENT_CLICK
} from './types';

import { AppContextStateInterface } from './interfaces';
import { ReducerActionInterface } from '../Shared/interfaces';
import { DEFAULT, PROGRESSIVE } from './loadingTypes';

const reducerApp = (state: AppContextStateInterface, action: ReducerActionInterface) => {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                showingLoading: true,
                loadingData: {
                    nameLoading: action.payload.name,
                    messageLoading: action.payload.message,
                    typeLoading: action.payload.type,
                    finishAnimation: false,
                    progressPercentage: action.payload.progressPercentage,
                    loadingType: action.payload.loadingType
                }
            };
        case CHANGE_ANIMATION:
            return {
                ...state,
                loadingData: {
                    nameLoading: action.payload.name,
                    messageLoading: action.payload.message,
                    finishAnimation: action.payload.finishAnimation,
                    loadingType: DEFAULT
                }
            };
        case CHANGE_EVENT_CLICK:
            return {
                ...state,
                eventClick: action.payload.eventClick
            };
        case HIDE_LOADING:
            return {
                ...state,
                showingLoading: false,
                loadingData: {
                    nameLoading: '',
                    messageLoading: 'Cargando...',
                    typeLoading: null,
                    finishAnimation: true,
                    progressPercentage: 0,
                    loadingType: DEFAULT
                }
            };
        case SHOW_MAIN_MENU:
            return {
                ...state,
                showingMainMenu: true
            };
        case HIDE_MAIN_MENU:
            return {
                ...state,
                showingMainMenu: false
            };
        case CHANGE_MAIN_MENU:
            return {
                ...state,
                showingMainMenu: !state.showingMainMenu
            };
        default:
            return state;
    }
};
export default reducerApp;
